<app-header></app-header>

<section class="society-community-page">
  <section class="banner clearfix">
    <div class="img"></div>
    <div class="txt">
      <p>국내 최초 오토바이 동호회 플랫폼</p>
      <p>동호회 <span class="red">활동 소식</span></p>
      <p>전국 오토바이 동호회 활동을 확인해보세요!</p>
    </div>
  </section>

  <div class="clearfix notice-link">
    <section class="notice">
      <div class="clearfix">
        <h3 class="section-title">바마 동호회 새소식</h3>
        <a class="more" (click)="goBoard('society_news')">더보기</a>
      </div>
      <div class="notice-list">
        <div class="clearfix" *ngFor="let key of societyNewsList">
          <a  (click)="view(key)">
            <div class="title">{{key.subject}}</div>
            <div class="date">{{key.write_date}}</div>
          </a>
        </div>
      </div>
    </section>


    <section class="link">
      <ul class="inner">
        <li><a routerLink="/guide/society-benefit/society/info"><span></span>바마동호회란?</a></li>
        <li><a routerLink="/guide/society-benefit/society"><span></span>동호회 혜택보기</a></li>
        <li><a routerLink="/company/society"><span></span>동호회 등록</a></li>
        <li><a routerLink="/mypage/society"><span></span>동호회 회원등록</a></li>
        <!-- <li><a routerLink="/product/list"><span></span>FAQ</a></li> -->
      </ul>
    </section>
  </div>

  <section class="section inner">
    <h3 class="section-title">동호회 목록</h3>
    <div class="txt">다양한 동호회를 한 눈에!</div>
    <div class="community-slide">
      <swiper [config]="SwiperConfigInterface_community">
        <ng-container *ngFor="let chunk of societyChunks">
          <ul class="community-list">
            <li *ngFor="let item of chunk" (click)="communityView(item)">
              <div class="img">
                <img [src]="item.url ? item.url : 'assets/images/logo2.png'" alt="동호회로고" />
              </div>
              <div class="name">{{ item.society_name }}</div>
            </li>
          </ul>
        </ng-container>
      </swiper>
      <div class="swiper-button-prev swiper-button-prev-community-list" ></div>
      <div class="swiper-button-next swiper-button-next-community-list" ></div>
    </div>
  </section>

  <section class="section inner">
    <h3 class="section-title">동호회 지도</h3>
    <div class="txt">지역별 동호회를 한 눈에!</div>

    <div class="map-list clearfix">
      <div class="map">
        <div class="seoul map-area" [ngClass]="{on:hoverArea=='서울'}"></div>
        <div class="seoul-txt map-txt" (click)="getRegionSocietyList('서울특별시')" (mouseenter)="hoverArea='서울'" (mouseleave)="hoverArea=''">서울특별시</div>
        <div class="incheon map-area" [ngClass]="{on:hoverArea=='인천'}"></div>
        <div class="incheon-txt map-txt" (click)="getRegionSocietyList('인천광역시')" (mouseenter)="hoverArea='인천'" (mouseleave)="hoverArea=''">인천광역시</div>
        <div class="gyeonggi map-area" [ngClass]="{on:hoverArea=='경기'}"></div>
        <div class="gyeonggi-txt map-txt" (click)="getRegionSocietyList('경기도')" (mouseenter)="hoverArea='경기'" (mouseleave)="hoverArea=''">경기도</div>
        <div class="gangwondo map-area" [ngClass]="{on:hoverArea=='강원'}"></div>
        <div class="gangwondo-txt map-txt" (click)="getRegionSocietyList('강원도')" (mouseenter)="hoverArea='강원'" (mouseleave)="hoverArea=''">강원도</div>
        <div class="chungcheongN map-area" [ngClass]="{on:hoverArea=='충북'}"></div>
        <div class="chungcheongN-txt map-txt" (click)="getRegionSocietyList('충청북도')" (mouseenter)="hoverArea='충북'" (mouseleave)="hoverArea=''">충청북도</div>
        <div class="chungcheongS map-area" [ngClass]="{on:hoverArea=='충남'}"></div>
        <div class="chungcheongS-txt map-txt" (click)="getRegionSocietyList('충청남도')" (mouseenter)="hoverArea='충남'" (mouseleave)="hoverArea=''">충청남도</div>
        <div class="sejong map-area" [ngClass]="{on:hoverArea=='세종'}"></div>
        <div class="sejong-txt map-txt" (click)="getRegionSocietyList('세종특별자치시')" (mouseenter)="hoverArea='세종'" (mouseleave)="hoverArea=''">세종특별자치시</div>
        <div class="daejeon map-area" [ngClass]="{on:hoverArea=='대전'}"></div>
        <div class="daejeon-txt map-txt" (click)="getRegionSocietyList('대전광역시')" (mouseenter)="hoverArea='대전'" (mouseleave)="hoverArea=''">대전광역시</div>
        <div class="gyeongsangN map-area" [ngClass]="{on:hoverArea=='경북'}"></div>
        <div class="gyeongsangN-txt map-txt" (click)="getRegionSocietyList('경상북도')" (mouseenter)="hoverArea='경북'" (mouseleave)="hoverArea=''">경상북도</div>
        <div class="gyeongsangS map-area" [ngClass]="{on:hoverArea=='경남'}"></div>
        <div class="gyeongsangS-txt map-txt" (click)="getRegionSocietyList('경상남도')" (mouseenter)="hoverArea='경남'" (mouseleave)="hoverArea=''">경상남도</div>
        <div class="daegu map-area" [ngClass]="{on:hoverArea=='대구'}"></div>
        <div class="daegu-txt map-txt" (click)="getRegionSocietyList('대구광역시')" (mouseenter)="hoverArea='대구'" (mouseleave)="hoverArea=''">대구광역시</div>
        <div class="ulsan map-area" [ngClass]="{on:hoverArea=='울산'}"></div>
        <div class="ulsan-txt map-txt" (click)="getRegionSocietyList('울산광역시')" (mouseenter)="hoverArea='울산'" (mouseleave)="hoverArea=''">울산광역시</div>
        <div class="busan map-area" [ngClass]="{on:hoverArea=='부산'}"></div>
        <div class="busan-txt map-txt" (click)="getRegionSocietyList('부산광역시')" (mouseenter)="hoverArea='부산'" (mouseleave)="hoverArea=''">부산광역시</div>
        <div class="jeollaN map-area" [ngClass]="{on:hoverArea=='전북'}"></div>
        <div class="jeollaN-txt map-txt" (click)="getRegionSocietyList('전라북도')" (mouseenter)="hoverArea='전북'" (mouseleave)="hoverArea=''">전라북도</div>
        <div class="jeollaS map-area" [ngClass]="{on:hoverArea=='전남'}"></div>
        <div class="jeollaS-txt map-txt" (click)="getRegionSocietyList('전라남도')" (mouseenter)="hoverArea='전남'" (mouseleave)="hoverArea=''">전라남도</div>
        <div class="gwangju map-area" [ngClass]="{on:hoverArea=='광주'}"></div>
        <div class="gwangju-txt map-txt" (click)="getRegionSocietyList('광주광역시')" (mouseenter)="hoverArea='광주'" (mouseleave)="hoverArea=''">광주광역시</div>
        <div class="jeju map-area" [ngClass]="{on:hoverArea=='제주'}"></div>
        <div class="jeju-txt map-txt" (click)="getRegionSocietyList('제주도')" (mouseenter)="hoverArea='제주'" (mouseleave)="hoverArea=''">제주도</div>
      </div>
      <div class="list">
        <div class="list-header clearfix">
          <h4 class="area">{{paramsRegion.selectedRegion}}</h4>
          <div class="cnt">{{regionSocietyList.length}}개</div>
        </div>
        <div class="list-wrap">
          <ul>
            <li *ngFor='let item1 of regionSocietyList' (click)="communityView(item1)">
              <div class="logo">
                <img [src]="item1.url ? item1.url : 'assets/images/logo2.png'" alt="로고없음" />
              </div>
              <div class="name">{{ item1.society_name }}</div>
              </li>
          </ul>
        </div>
      </div>
    </div>
  </section>

  <section>
    <div class="banner1 pc-only"><a href="#"><img src="assets/images/society/society_banner01.png" alt="banner" /></a></div>
    <div class="banner1 mobile-only"><a href="#"><img src="assets/images/society/society_banner01m.png" alt="banner" /></a></div>
  </section>

  <section class="section inner">
    <h3 class="section-title">라이딩 코스 자랑하기</h3>
    <div class="section-txt"><span class="txt" >내 동호회가 다녀온 멋진 라이딩코스를 자랑해 보세요!</span><a class="txt fr"  id="goBoard" (click)="goBoard('society')">더보기</a></div>
    <div></div>
    <ul class="board-list">
      <li *ngFor='let item of societyCourseList'>
        <a  (click)="view(item)">
          <div class="img">
              <img [src]="item.upload_file ? item.upload_file : 'assets/images/logo2.png'" alt="동호회로고" />
           </div>
          <div class="title">{{ item.subject }}</div>
          <div class="user">{{ item.user_id}}</div>
          <div class="info" hidden>{{ item.write_date}}<span>ㆍ조회</span>{{ item.hit}}</div>
        </a>
      </li>
    </ul>
  </section>
  <section class="section inner">
    <h3 class="section-title mt50">동호회 추천 카페</h3>
    <div class="section-txt"><span class="txt" >라이딩 하면서 경치 좋은 카페에 들러보세요.!</span><a class="txt fr"  id="goBoard" (click)="goBoard('society_cafe')">더보기</a></div>
    <div></div>
    <ul class="board-list">
      <li *ngFor='let item of societyCafeList'>
        <a  (click)="view(item)">
          <div class="img">
              <img [src]="item.upload_file ? item.upload_file : 'assets/images/logo2.png'" alt="동호회로고" />
           </div>
          <div class="title">{{ item.subject }}</div>
          <div class="user">{{ item.user_id}}</div>
          <div class="info" hidden>{{ item.write_date}}<span>ㆍ조회</span>{{ item.hit}}</div>
        </a>
      </li>
    </ul>
  </section>
</section>

<app-footer></app-footer>