/*******************************************************************************
  설  명 : 마이페이지 - 현금영수증 신청 모달
  생성일 : 2021-07-21
  생성자 : 김종현
*******************************************************************************/
import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';

import { UtilService } from '@app/service/util.service';
import { AuthService } from '@app/service/auth.service';
import { SOrderService } from '@app/service/order.service';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-mypage-order-detail-cashreceipt',
  templateUrl: './mypage-order-detail-cashreceipt.component.html',
  styleUrls: ['./mypage-order-detail-cashreceipt.component.scss']
})
export class MypageOrderDetailCashreceiptComponent implements OnInit {
  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  public form: FormGroup;
  public formErrors = {};
  public memberInfo: any = {};

  public isLogIn: boolean;

  isLogin$: Observable<boolean>; // 로그인여부확인

  @Input() orderInfo: any;
  @Input() detail: any;
  @Input() pay: any;

  /*******************************************************************************
    설  명 : 빌드폼 생성
  *******************************************************************************/
  buildForm(): void {
    this.form = this.formBuilder.group({
      LGD_OID:[ '', [Validators.required] ],
      LGD_AMOUNT:[ 0, [Validators.required]], // 결제금액
      vos:[ 0, []], // 공급가액
      vat:[ 0, [Validators.required] ], // 부가가치세
      tip:[ 0, [Validators.required] ], // 봉사료
      LGD_PRODUCTINFO:['', [] ],
      orderqty:['', [] ],
      email: ['', []],
      LGD_CASHCARDNUM: ['', [Validators.required]], // 신분확인번호
      LGD_CASHRECEIPTUSE: ['', [Validators.required]], // 현금영수증발급용도('1':소득공제, '2':지출증빙)
      LGD_PAYTYPE: ['SC0100', [Validators.required]] // 결제수단 코드 (SC0100:무통장입금 단독)
    });

    this.form.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.form, this.formErrors );
    });
  };

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private formBuilder: FormBuilder,
    private utilService: UtilService,
    public activeModal: NgbActiveModal,
    private sOrderService: SOrderService,
    private authService: AuthService,
    private toastrService: ToastrService

  ) { 
    this.buildForm();
    // 로그인 여부 가져오기
    this.isLogin$ = this.authService.isLoggedIn;
    
    this.isLogin$.subscribe( login => {
      this.isLogIn = login;
    });
  }

  /*******************************************************************************
    설  명 : 데이터 로딩 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit(): void {
    this.authService.getLoginInfo.subscribe(data => {
      this.memberInfo = data;
    });

    const pay_amt: any = this.pay.reduce((a,c) => {
      let amt: any = 0;
      if( c.pay_method == '0002' ) amt += parseInt(c.pay_amt || 0);

      return a + amt;
    }, 0);

    this.form.patchValue({
      LGD_OID: this.orderInfo.seq,
      LGD_AMOUNT: pay_amt,
      vos: Math.floor(pay_amt / 1.1),
      vat: pay_amt - Math.floor(pay_amt / 1.1),

      orderqty: this.orderInfo.order_qty,
      email: this.memberInfo.email
    });

    if( this.isLogIn ) {
      this.form.patchValue({
        LGD_PRODUCTINFO: this.detail[0].children[0].product_name,
      });
    } else {
      this.form.patchValue({
        LGD_PRODUCTINFO: this.detail[0].product[0].product_name,
      });
    }

  }

  /*******************************************************************************
    설  명 : 용도 선택 이벤트
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/  
  selectedUse(event) {
    this.form.patchValue({
      LGD_CASHRECEIPTUSE: event.target.value
    });
  }

  /*******************************************************************************
    설  명 : 현금영수증 신청
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  submit() {
    this.utilService.makeFormDirtyAndUpdateErrors(this.form, this.formErrors);

    if ( this.form.valid ) {
      this.sOrderService.setCashReceipt( this.form ).then( response => {
        if ( response.ResultCode ){
          this.toastrService.success(response.ResultMessage, '');

          this.activeModal.close(true);
        } else {
          this.toastrService.error( response.msg, response.ResultMessage );
        }
      });
    }
  }
}
