import { Component, OnInit , AfterViewInit, ViewChild } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { SwiperConfigInterface} from 'ngx-swiper-wrapper';
import { Router, ActivatedRoute } from '@angular/router';
import Swiper from 'swiper';
import { Observable } from 'rxjs';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

import { config } from '@app/service/config.service';
import { SMemberService } from '@app/service/member.service';
import { SBoardService } from '@app/service/board.service';
import { AuthService } from '@app/service/auth.service';

import { ToastrService } from 'ngx-toastr';
import { SocietyDetailModalComponent } from '@page/society/society-community/society-detail-modal/society-detail-modal.component';

const options: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  size: '480',
  centered: true,
  windowClass: 'modal-fadeInDown'
};


@Component({
  selector: 'app-society-community',
  templateUrl: './society-community.component.html',
  styleUrls: ['./society-community.component.scss']
})
export class SocietyCommunityComponent implements OnInit , AfterViewInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  public isLogIn: boolean;
  isLogin$: Observable<boolean>; // 로그인여부확인

  public societyList: any = [];
  public societyChunks: any = [];

  public regionSocietyList: any = [];
  public societyCourseList: any = [];
  public societyCafeList: any = [];
  public societyNewsList: any = [];



  public currentSlideIndex: number = 0;
  public totalSlides: number = 0;

  public swiper:Swiper;

  public baseURL: string = config.baseUrl;

  public paramsRegion: any = {
    selectedRegion: '대구',

  };

  public hoverArea = '';

  public params: any = {
    type: 'gallery',
    board_id: '',
    group_id: '',
    row: 10,
    no: 1,
    group: 'subject',
    text: ''

  };

  public SwiperConfigInterface_community: SwiperConfigInterface = {
    loop: false,
    slidesPerView: 1,
    spaceBetween: 0,
    navigation: {
      nextEl: ".swiper-button-next-community-list",
      prevEl: ".swiper-button-prev-community-list",
    }
  };

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private authService: AuthService,
    private modalService: NgbModal,
    private memberService: SMemberService,
    private toastrService: ToastrService,
    private breakpointObserver: BreakpointObserver,
    private sBoardService: SBoardService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) {
    // 로그인 여부 가져오기
    this.isLogin$ = this.authService.isLoggedIn;

    this.isLogin$.subscribe(login => {
      this.isLogIn = login;
    });

    this.breakpointObserver.observe([Breakpoints.Handset]).subscribe(result => {
      if (result.matches) {
        this.SwiperConfigInterface_community.slidesPerView = 1;
        this.chunkSocietyList(4); // Mobile: 4 items per slide
      } else {
        this.SwiperConfigInterface_community.slidesPerView = 1;
        this.chunkSocietyList(25); // Desktop: 25 items per slide
      }
      this.updateTotalSlides();
    });
  }

  ngOnInit(): void {
    this.getSocietyList();

    this.getSocietyNewsList();

    this.getRegionSocietyList('서울특별시');

    this.activatedRoute.queryParams.subscribe(params => {
      this.params.board_id = 'society';
      this.params.group_id = '' ;
      this.params.type = 'gallery';
      this.params.row = ( typeof params.row == 'undefined' || params.row == '' ) ? 10 : parseInt(params.row);
      this.params.no = ( typeof params.no == 'undefined' || params.no == '' ) ? 1 : parseInt(params.no);
      this.params.group = ( typeof params.group == 'undefined' || params.group == '' ) ? 'subject' : params.group;
      this.params.text = ( typeof params.text == 'undefined' ) ? '' : params.text;

      this.getSocietyCourseList();
      this.getSocietyCafeList();
    });



  }

  ngAfterViewInit(): void {
    this.initSwiper();
  }


  /*******************************************************************************
    설  명 : 동호회 설명 모달
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  communityView(item: any) {
    const modalRef = this.modalService.open(SocietyDetailModalComponent, options);
    modalRef.componentInstance.item = item;

    modalRef.result.then((result) => {
      if (result) {
      }
    }, (reason) => {
    });
  }

  /*******************************************************************************
    설  명 : 동호회 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getSocietyList() {
    this.memberService.getSocietyList().then(response => {
      if (response.ResultCode) {
        this.societyList = response.data;
        this.chunkSocietyList(25); // Initial load with 25 items per slide
        this.updateTotalSlides();
      } else {
        this.toastrService.error(response.ResultMessage);
      }
    });
  }

  /*******************************************************************************
    설  명 : 동호회 리스트를 특정 개수씩 나누기
    입력값 : chunkSize (한 슬라이드에 들어갈 항목 수)
    리턴값 : 없음
  *******************************************************************************/
  chunkSocietyList(chunkSize: number) {
    this.societyChunks = [];
    for (let i = 0; i < this.societyList.length; i += chunkSize) {
      this.societyChunks.push(this.societyList.slice(i, i + chunkSize));
    }
  }


  updateTotalSlides() {
    this.totalSlides = this.societyChunks.length;
  }


  initSwiper() {
    this.swiper = new Swiper('.swiper-container', {
      loop: this.SwiperConfigInterface_community.loop,
      slidesPerView: this.SwiperConfigInterface_community.slidesPerView,
      spaceBetween: this.SwiperConfigInterface_community.spaceBetween,
      navigation: this.SwiperConfigInterface_community.navigation,
      on: {
        slideChange: (swiper: Swiper) => {
          this.onSlideChange();
        }
      }
    });
  }

  onSlideChange() {
    this.currentSlideIndex = this.swiper.activeIndex;
  }

  /*******************************************************************************
    설  명 : 지역별 동호회 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
    getRegionSocietyList(region:any) {
      this.paramsRegion.selectedRegion = region ;

      this.memberService.getRegionSocietyList(region).then(response => {
        if (response.ResultCode) {
          this.regionSocietyList = response.data;
        } else {
          this.toastrService.error(response.ResultMessage);
        }
      });
    }


  /*******************************************************************************
    설  명 : 동호회 추천코스 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
    getSocietyCourseList() {
      this.memberService.getSocietyCourseList().then(response => {
        if (response.ResultCode) {
          this.societyCourseList = response.data;
          console.log(this.societyCourseList);

        } else {
          this.toastrService.error(response.ResultMessage);
        }
      });
    }

    /*******************************************************************************
    설  명 : 동호회 추천카페 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
    getSocietyCafeList() {
      this.memberService.getSocietyCafeList().then(response => {
        if (response.ResultCode) {
          this.societyCafeList = response.data;
          console.log(this.societyCourseList);

        } else {
          this.toastrService.error(response.ResultMessage);
        }
      });
    }  

  /*******************************************************************************
    설  명 : 동호회 새소식 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
    getSocietyNewsList() {
      this.memberService.getSocietyNewsList().then(response => {
        if (response.ResultCode) {
          this.societyNewsList = response.data;
        } else {
          this.toastrService.error(response.ResultMessage);
        }
      });
    }



  /*******************************************************************************
    설  명 : 동호회게시판 상세보기 (is_notice여부에 따른 a태그 사용)
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
    view( row: any ) {
      this.params.board_id = row.board_id;
      this.params.board_seq = row.board_seq;

      this.router.navigate(
      ['/board/view'],
      {
        relativeTo: this.activatedRoute,
        queryParams: this.params,
        queryParamsHandling: 'merge', // remove to replace all query params by provided
      });
    }



   /*******************************************************************************
    설  명 : 페이지로 이동 수정자:
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
    goBoard( board_id ) {
      let routerUri = '/board/gallery';
      let routerType = 'gallery';  
      if (board_id == 'society_news') {
        routerUri = '/board/list';
        routerType = 'list'; 
      } else {
        routerUri = '/board/gallery';
        routerType = 'gallery';
      }

      this.router.navigate(
      [routerUri],
      {
        relativeTo: this.activatedRoute,
        queryParams: {board_id: board_id, type: routerType, text: ''},
        queryParamsHandling: 'merge', // remove to replace all query params by provided
      });
    }



}
