/*******************************************************************************
  설  명 : 마이페이지 - 바마쿠폰
  생성일 : 2024-06-08
  생성자 : 권세창
*******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { NgbInputDatepicker, NgbCalendar, NgbDateStruct, NgbModal, ModalDismissReasons, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

import * as moment from 'moment';
import { SMemberService } from '@app/service/member.service';
import { CommonService } from '@app/service/common.service';


@Component({
  selector: 'app-mypage-coupon',
  templateUrl: './mypage-coupon.component.html',
  styleUrls: ['./mypage-coupon.component.scss']
})
export class MypageCouponComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  search: any = {
    sdate: '',
    edate: '',
    searchdate_group: 9999999999,
    pageNo: 1,
    pageRow: 10,
    status: ''
  };
  
  searchdate_group: any = [
    {title: '오늘', value: 0},
    {title: '1주일', value: -7},
    {title: '1개월', value: -30},
    {title: '3개월', value: -90},
    {title: '6개월', value: -180}
  ];  

  today: string;

  public availableCoupon = 0;
  public couponList:any = [];
  public statusList:any = [];

  public totalCount: number = 0;
  public used: number = 0;

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private calendar: NgbCalendar, 
    private memberService: SMemberService,
    private CommonService: CommonService
  ) {
    this.today = moment().format('YYYY-MM-DD');  
   }

  /*******************************************************************************
    설  명 : 데이터 로딩 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit(): void {
    this.getSumAvailableCoupon();
    this.getCouponList();
  }
  
  /*******************************************************************************
    설  명 : 오늘 선택 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getToday( obj: NgbInputDatepicker, check: any ) {
    if( check == 1 ) {
      this.search.sdate = this.calendar.getToday();
      obj.close();
    } else if( check == 2 ) {
      this.search.edate = this.calendar.getToday();
      obj.close();
    }
  }
  
  /*******************************************************************************
    설  명 : 검색 기간 버튼 클릭
    입력값 : 값
    리턴값 : 없음
  *******************************************************************************/
  onSearchDateClick( val ) {
    this.search.searchdate_group = val;

    let dateModel: NgbDateStruct;

    dateModel = {
      year: parseInt( moment().format('YYYY') ),
      month: parseInt( moment().format('MM') ),
      day: parseInt( moment().format('DD') )
    };

    this.search.edate = dateModel;

    dateModel = {
      year: parseInt( moment().add(val, 'day').format('YYYY') ),
      month: parseInt( moment().add(val, 'day').format('MM') ),
      day: parseInt( moment().add(val, 'day').format('DD') )
    };

    this.search.sdate = dateModel;
  }  

  /*******************************************************************************
    설  명 : 마이페이지 대시보드 남은 마일리지 정보 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getSumAvailableCoupon() {
    this.memberService.getSumAvailableCoupon().then( response => {
      if ( response.ResultCode ) {
        this.availableCoupon = response.data;
        this.used = response.used;
      }
    });    
  }

  /*******************************************************************************
    설  명 : 쿠폰 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getCouponList() {
    this.memberService.getCouponList( this.search ).then( response => {
      if ( response.ResultCode ) {
        this.couponList = response.data;
        this.totalCount = response.total;
      }
    });
  }

  /*******************************************************************************
    설  명 : 페이지 선택 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  loadPage( page ) {
    this.search.pageNo = page;

    this.getCouponList();
  }

  /*******************************************************************************
    설  명 : 주문상태 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getCommonListCode() {
    this.CommonService.getCommonListCode( 'MGT' ).then( response => {
      if( response.ResultCode ) {
        this.statusList = response.data;
      } else {
      }
    });
  }

  /*******************************************************************************
    설  명 : 상태 선택 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  selectedStatus(event) {
    this.search.status = event.target.value;

    this.getCouponList();
  }

}