import { Injectable } from '@angular/core';

import { RestfulService } from '@app/service/restful.service';

@Injectable({
  providedIn: 'root'
})
export class SCategoryService {

  constructor(
    private restful: RestfulService,
  ) { }

  // 헤더 표시용 카테고리 리스트 가져오기
  getHeaderCategoryList(): Promise<any> {
    return this.restful.get({
        program: 'shop',
        service: 'category',
        version: '1.0',
        action: 'getHeaderCategoryList'
      }, {}
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

}
