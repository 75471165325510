<ng-container *ngIf="isMobileApp === false">
  <app-header></app-header>
</ng-container>

<ul class="menu clearfix" *ngIf="isMobileApp === false">
  <li><a routerLink="/company/info">회사소개</a></li>
  <li><a routerLink="/company/history">회사연혁</a></li>
  <li><a routerLink="/company/society">동호회등록</a></li>
  <li><a routerLink="/company/supply">입점안내</a></li>
  <li><a routerLink="/company/story">창업스토리</a></li>
  <li><a routerLink="/company/contact">가맹문의</a></li>
  <li><a routerLink="/company/policy/service" class="on">이용약관</a></li>
</ul>

<ul class="menu menu-mobile clearfix" *ngIf="isMobileApp === true">
  <li><a routerLink="/mobile/company/info">회사소개</a></li>
  <li><a routerLink="/mobile/company/history">회사연혁</a></li>
  <li><a routerLink="/company/society">동호회등록</a></li>
  <li><a routerLink="/mobile/company/supply">입점안내</a></li>
	<li><a routerLink="/mobile/company/story">창업스토리</a></li>
	<li><a routerLink="/mobile/company/contact">가맹문의</a></li>
  <li><a routerLink="/mobile/company/policy/service" class="on">이용약관</a></li>
</ul>

<div style="margin-top: 37px" *ngIf="isMobileApp === true"></div>

<section class="contents">

  <ul class="sub-menu clearfix" *ngIf="isMobileApp === false">
    <li><a routerLink="/company/policy/service">쇼핑몰이용약관</a></li>
    <li><a routerLink="/company/policy/purchase/agency" class="on">구매대행약관</a></li>
    <li><a routerLink="/company/policy/privacy">개인정보취급방침</a></li>
  </ul>

  <ul class="sub-menu clearfix" *ngIf="isMobileApp === true">
	  <li><a routerLink="/mobile/company/policy/service">쇼핑몰이용약관</a></li>
	  <li><a routerLink="/mobile/company/policy/purchase/agency" class="on">구매대행약관</a></li>
	  <li><a routerLink="/mobile/company/policy/privacy">개인정보취급방침</a></li>
	</ul>

  <div class="section inner">
    <div class="section-header clearfix">
      <div class="title">구매대행약관</div>
      <div class="date">현행 시행 일자 : 2013년 6월 3일</div>
      <div class="line"></div>
    </div>

    <div class="terms">
      <div class="clearfix">
        <p>제1장 총칙</p>
        <ul>
	        <li>제1조 목적</li>
	        <li>제2조 정의</li>
	        <li>제3조 약관의 명시와 설명 및 개정</li>
	        <li>제4조 서비스의 제공 및 변경</li>
	        <li>제5조 서비스의 중단</li>
	        <li>제6조 계약의 성립</li>
	        <li>제7조 서비스 대상물품</li>
	        <li>제8조 지급방법</li>
	        <li>제9조 수신확인통지, 배송신청 변경 및 취소</li>
	        <li>제10조 서비스별 요금 결제, 재화 등의 공급 및 보관</li>
	        <li>제11조 국제운송 및 통관</li>
	        <li>제12조 반품, 환급 등</li>
	        <li>제13조 차액정산</li>
	        <li>제14조 긴급조치</li>
	        <li>제15조 BIKEMART의 의무</li>
	        <li>제16조 분쟁해결</li>
	        <li>제17조 재판권 및 준거법</li>
	        <li>제18조 통지</li>
	        <li>제19조 고지의 의무</li>
        </ul>
      </div>
      <pre>
제1조(목적)

이 약관은 주식회사 바이크마트(이하 "회사"라 함)가 운영하는 BIKEMART 인터넷 홈페이지(http://www.bikemart.co.kr ; 이하 "BIKEMART 사이트" 또는 "BIKEMART"라 함)에서 제공하는 " 구매대행 서비스"를 이용함에 있어 회사와 회원의 권리·의무 및 책임사항을 규정함을 목적으로 합니다.

제2조(정의)

1. "BIKEMART"라 함은 회사가 본 약관에 의하여 재화나 용역을 회원에게 제공하기 위하여 컴퓨터 등 정보 통신설비를 이용하여 재화나 용역을 거래할 수 있도록 설정한 가상의 영업장을 말하며, 아울러 BIKEMART를 운영하는 회사의 의미로도 사용합니다.

2. "이용자"라 함은 "BIKEMART"에 접속하여 이 약관에 따라 "BIKEMART"가 제공하는 서비스를 받는 회원 및 비회원을 말합니다. 단, 법인이나 회사명의 등의로의 서비스 이용은 불가능합니다.

3. "회원"이라 함은 BIKEMART에 개인정보를 제공하여 회원등록을 한 개인 이용자로서, BIKEMART의 정보를 지속적으로 제공받으며 BIKEMART가 제공하는 서비스를 계속적으로 이용할 수 있는 자를 말합니다. 단, 법인이나 회사명의 등으로의 가입은 별도의 공지가 없는 한 BIKEMART의 회원등록이 불가능합니다.

4. "비회원"이라 함은 회원에 가입하지 않고 "BIKEMART"가 제공하는 서비스를 이용하는 자를 말합니다. 단, 비회원은 "구매대행 서비스"가 이용이 안되오니, 만약 해당 서비스를 이용하고자 할 경우에는 회원으로 가입하셔서 이용할 수 있습니다.

5. BIKEMART와 회원간에 발생하는 거래계약 유형은 "수입대행형"이며 거래계약 유형에 대한 정의는 다음과 같습니다.
가. "수입대행형" 거래계약 유형은 BIKEMART가 BIKEMART 사이트를 통해 소개된 해외에서 구매대행 가능한 상품에 대하여 이용자가 BIKEMART에 수입대행을 의뢰하면 BIKEMART가 해당 상품을 판매하는 해외 인터넷 쇼핑몰 등에서 이용자를 대신해 구매 및 결제를 하고 구매대행된 상품이 BIKEMART가 제공하는 해외의 중간배송처에 입고된 이후 BIKEMART 책임으로 국제운송 및 수입통관을 거쳐 이용자의 국내 수취처까지 운송을 하여 이용자가 수령할 수 있도록 하는 것을 의미합니다.

6. BIKEMART에서 제공하는 서비스에 대한 정의는 다음과 같습니다.
가. "구매대행 서비스"는 수입대행형 거래계약 유형에 해당하는 서비스로서, BIKEMART 인터넷 홈페이지내 "BIKEMART"카테고리에 게시된 물품에 대하여 이용자가 수입대행에 따르는 총 비용을 결제시 BIKEMART가 해외사용이 가능한 기명식 법인신용카드 및 해외송금(Wire Transfer) 등을 통해 해당 상품을 판매하는 해외 인터넷 쇼핑몰 등에서 이용자를 대신해 구매 및 결제를 하고 구매대행된 상품이 BIKEMART가 제공하는 해외의 중간 배송처에 입고된 이후BIKEMART 책임으로 국제운송 및 수입통관을 거쳐 이용자가 지정하는 국내 수취처까지 배송하여 이를 이용자가 수령할 수 있도록 하는 서비스를 의미합니다.

제3조(약관의 명시와 설명 및 개정)

1. BIKEMART는 이 약관의 내용과 상호 및 대표자 성명, 영업소 소재지 주소(소비자의 불만을 처리할 수 있는 곳의 주소를 포함), 전화번호, FAX전송번호, 전자우편주소, 사업자등록번호, 통신판매업 신고번호, 개인정보 관리책임자 등을 회원이 쉽게 알 수 있도록 BIKEMART의 초기 서비스화면(전면)에 게시합니다. 다만, 약관의 내용은 이용자가 연결화면을 통하여 볼 수 있도록 할 수 있습니다.

2. BIKEMART는 이용자가 약관에 동의하기에 앞서 약관에 정하여져 있는 내용 중 청약철회, 배송책임, 환불조건 등과 같은 중요한 내용을 이용자가 이해할 수 있도록 별도로 구성하여 이용자의 확인을 구하여야 합니다.

3. BIKEMART는 전자상거래 등에서의 소비자보호에 관한 법률, 약관의 규제에 관한 법률, 전자거래기본법, 전자서명법, 정보통신망이용촉진 등에 관한 법률, 방문판매 등에 관한 법률, 소비자보호법 등 관련법을 위배하지 않는 범위에서 이 약관을 개정할 수 있습니다.

4. BIKEMART가 약관을 개정할 경우에는 적용일자 및 개정사유를 명시하여 현행 약관과 함께 BIKEMART의 초기 화면에 그 적용일자 7일 이전부터 적용일자 전일까지 공지합니다. 다만, 이용자에게 불리하게 약관 내용을 변경하는 경우에는 최소한 15일 이상의 사전 유예기간을 두고 공지합니다. 이 경우 BIKEMART는 개정 전 내용과 개정 후 내용을 명확하게 비교하여 이용자가 알기 쉽도록 표시합니다.

5. BIKEMART가 약관을 개정할 경우에는 그 개정약관은 그 적용일자 이후에 체결되는 계약에만 적용되고 그 이전에 이미 체결된 계약에 대해서는 개정전의 약관조항이 그대로 적용됩니다. 다만 이미 계약을 체결한 이용자가 개정약관 조항의 적용을 받기를 원하는 뜻을 제3항에 의한 개정약관의 공지기간 내에 BIKEMART에 송신하여 BIKEMART의 동의를 받은 경우에는 개정약관 조항이 적용됩니다.

6. 이 약관에서 정하지 아니한 사항과 이 약관의 해석에 관하여는 전자상거래등에서의 소비자보호에 관한 법률, 약관의 규제 등에 관한 법률, 공정거래위원회가 정하는 전자상거래 등에서의 소비자 보호 지침 및 관계법령 또는 상관례에 따릅니다.

제4조(서비스의 제공 및 변경)

1. BIKEMART는 다음과 같은 업무를 수행합니다.
가. 재화 또는 용역에 대한 정보제공 및 수입대행계약의 체결
나. 이용자가 해외에서 구매 또는 수입대행을 의뢰한 물건에 대한 운송계약의 체결
다. 이용자가 구매 또는 수입대행을 의뢰한 재화의 배송
라. 통관대행 서비스
마. 기타 BIKEMART가 정하는 업무

2. BIKEMART는 재화 또는 용역의 품절 또는 기술적 사양의 변경 등의 경우에는 장차 체결되는 계약에 의해 제공할 재화 또는 용역의 내용을 변경할 수 있습니다. 이 경우에는 변경된 재화 또는 용역의 내용 및 현재의 재화 또는 용역의 내용을 게시한 곳에 즉시 공지합니다.

3. BIKEMART가 제공하기로 이용자와 계약을 체결한 서비스의 내용을 재화 등의 품절 또는 기술적 사양의 변경 등의 사유로 변경할 경우에는 그 사유를 이용자에게 즉시 통지합니다.

4. 전항의 경우 BIKEMART는 이로 인하여 이용자가 입은 손해를 배상합니다. 다만, BIKEMART가 고의 또는 과실이 없음을 입증하는 경우에는 그러하지 아니합니다.

제5조(서비스의 중단)

1. BIKEMART는 컴퓨터 등 정보통신설비의 보수, 점검, 교체 및 고장, 통신의 두절 등의 사유가 발생한 경우에는 서비스의 제공을 일시적으로 중단할 수 있습니다.

2. BIKEMART는 제1항의 사유로 서비스의 제공이 일시적 중단됨으로 인하여 이용자 또는 제3자가 입은 손해에 대하여 배상합니다. 단, BIKEMART가 고의 또는 과실이 없음을 입증하는 경우에는 그러하지 아니합니다.

3. 사업종목의 전환, 사업의 포기, 업체간의 통합 등의 이유로 서비스를 제공할 수 없게 되는 경우에는 BIKEMART는 공지사항 또는 E-Mail을 통해 이용자에게 통지하고 이용자가 이로 인하여 손해를 입은 경우에는 당초 BIKEMART에서 제시한 조건에 따라 이용자에게 보상합니다.

제6조(계약의 성립)

BIKEMART는 구매대행서비스 이용과 관련하여 이용자와 아래의 절차를 통해 구매대행 계약을 체결합니다.

1. 구매대행서비스
가. 이용자가 BIKEMART 사이트내 구매대행카테고리에 있는 상품에 대하여 수입대행에 따르는 비용결제를 통해 수입대행을 의뢰하고 이에 대해 BIKEMART가 결제확인 메일을 이용자에게 송부 또는 BIKEMART 사이트에서 이를 통보하여 이용자에게 도착된 시점에 이용자와 BIKEMART간에 수입대행계약이 성립됩니다.
나. BIKEMART와 이용자의 계약성립은 해외 인터넷 쇼핑몰에서의 구매 및 결제 등 수입대행 전과정과 국제운송 및 수입통관, 국내 수취처 인도까지의 운송 전 과정을 BIKEMART에게 일임하였음을 의미합니다.

3. BIKEMART는 이용자의 수입대행 및 배송대행계약 요청에 대해 다음 각 호에 해당하면 승낙하지 않을 수 있습니다.
가. 신청내용에 허위, 기재누락, 오기가 있는 경우
나. 이용자가 관련법령 및 동 약관에서 금지하는 재화에 대해 수입대행 또는 운송을 요청하는 경우
다. 기타 수입대행 또는 배송대행신청을 승낙하는 것이 BIKEMART 기술상 현저히 지장이 있다고 판단하는 경우

제7조(서비스 대상물품)

BIKEMART는 이용자가 자가 사용의 목적으로 구매하여 수입하는 경우에만 서비스를 제공하며, 한편 아래의 경우 고객의 동의를 구한 후 한국으로 발송하거나 해당 발송자에게로 반송할 수 있으며 이용자의 귀책사유로 인해 이용자의 소재가 불명확한 경우 BIKEMART는 당해 물품을 발송인에게 반송하고 이를 이용자에게 통보하며, 소요비용은 이용자로부터 정산하거나 BIKEMART의 임의대로 물품을 처리하여 충당할 수 있습니다.
가. 동물, 금·은괴, 화폐, 의약품, 무기류, 인체의 일부, 포르노그래피, 정밀금속, 석재류, 냉장보관물품, 냉동 또는 냉장을 요하는 물품 및 폭발물, 가연성 위험물 등
나. 수입, 수출 불가 / 항공운송 불가 품목일 경우
다. 송장(Invoice) 부실기재, 포장불량 등으로 본 약관에 따른 서비스를 제공하기에 적절하지 않다고 BIKEMART가 판단하는 경우
라. 기타 관련법령에 의하여 수출입이 제한되는 물품을 대상으로 한 경우
마. 하단 제18조 통지에 서비스배제 대상을 별도로 명시합니다.
제8조(지급방법)

"BIKEMART" 서비스 이용에 대한 대금지급방법은 다음 각 호의 방법 중 가용한 방법으로 할 수 있습니다.
가. 폰뱅킹, 인터넷 뱅킹 등의 계좌이체
나. 신용카드 결제
다. 온라인 무통장 입금
라. 쿠폰 및 회사가 정하는 마일리지
마. 기타 BIKEMART가 인정하는 결제수단

제9조(수신확인통지, 배송신청 변경 및 취소)

1. "BIKEMART"는 이용자의 운송 및 수입대행신청이 있는 경우 이용자에게 수신확인통지를 합니다.

2. 수신확인통지를 받은 이용자는 의사표시의 불일치 등이 있는 경우에 수신확인통지를 받은 후 즉시 배송 신청 변경 및 취소를 요청할 수 있습니다.

3. BIKEMART 주소에 도달한 물품이 제7조 각호에 해당하는 경우에는 당해 물품에 대한 배송 계약을 해지할 수 있으며, 이 경우 물품의 반송 등 사후조치를 위해 소요되는 일체의 비용은 이용자의 부담으로 합니다.

제10조(서비스별 요금 결제, 재화 등의 공급 및 보관)

1. 바마 구매대행 서비스
가. 이용자가 BIKEMART에서 상품의 수입대행을 의뢰할 경우에는 BIKEMART 내 각 상품에 구현된 물품의 가격을 제8조 각 항의 형태로 결제해야 합니다.
나. BIKEMART와 이용자간의 수입대행계약에 의해 구매대행된 상품이 BIKEMART주소에 입고된 물품에 대해 검수 후 국내의 수취처까지 배송하여 이용자가 수령할 수 있도록 합니다.
다. BIKEMART는 BIKEMART 상품의 수입대행을 수행함에 따른 전체 금액을 이용자가 결제하기 전에 각각의 거래계약 유형별로 총지불가격, 수입대행가격, 국제운송료형태로 구분하여 이용자에게 고지합니다.
라. BIKEMART에 명기된 가격은 수입대행가격(해외 인터넷쇼핑몰 등에서의 물품 구입가격, 해외 BIKEMART 물류센터까지의 운송료, Sales Tax등 구매대행을 위한 고객예치금 + BIKEMART의 구매대행 수수료 + 수입관세, 수입부가세, 기타세금을 위한 고객예치금)과 국제운송료가 모두 포함된 가격이며 이용자가 이외에 추가적으로 부담하는 금액은 없습니다.
마. BIKEMART는 상품을 판매하는 해외 인터넷쇼핑몰 등 공급자 정보(쇼핑몰 명 등)와 BIKEMART에 명기된 가격의 상세내역에 대하여 이용자의 별도 요청이 있을시 이를 E-Mail 또는 Q&A등을 통해 제공합니다.
바. BIKEMART는 이용자가 BIKEMART에서 수입대행을 의뢰한 상품에 대해서 이용자의 대금 결제단위를 기준으로 포장하여 배송함을 원칙으로 합니다. 다만 결제 단위당 복수의 상품의 경우 각 상품의 해외 인터넷쇼핑몰 등 공급자가 다를 경우에는 이를 BIKEMART 해외 물류센터에 도착하는 순서대로 배송 할 수 있으며, 의도적으로 관세 등 제세금을 절감하기 위한 행위(분할 재포장 등)는 하지 않습니다.

제11조(국제운송 및 통관)

1. 국제운송
가. BIKEMART는 BIKEMART와 국제복합운송계약을 체결한 운송 및 통관업무제휴사를 통하여 BIKEMART 해외물류센터에서부터 대한민국까지의 항공운송, 수입통관, 이용자가 지정한 수취처까지의 국내배송의 용역을 제공합니다.
나. 상기 "가" 항에서의 BIKEMART 해외물류센터에서 이용자가 지정한 수취처까지의 운송구간에서 물품의 파손 등 하자가 발생하였을 때, 수입대행가격 및 국제운송료를 포함한 총 금액을 보상합니다.

2. 통관
가. BIKEMART는 개인이 자가사용 목적으로 수입하는 개인수입통관원칙에 의거, 이용자를 납세의무자로하고 수입요건을 구비하여 운송 및 통관업무제휴사를 통하여 통관절차를 수행합니다.
나. 이 때 발생하는 관세 및 수입부가세 등 제세금은 운송 및 통관업무제휴사가 이용자를 대신하여 대한민국 세관에 대납하고 BIKEMART는 이용자가 기결제한 관세 및 수입부가세 등 제세금을 운송 및 통관업무제휴사와 정산하는 절차를 거칩니다.
다. 회사명의로의 회원가입과 구매대행서비스는 이용할 수 없습니다.
제12조(반품, 환급 등)

1. Wiz-Shop 서비스
가. BIKEMART에서 이용자가 수입대행을 신청한 재화가 품절 등의 사유로 인도를 할 수 없을 때에는 지체없이 그 사유를 이용자에게 통지하고 사전에 재화 대금을 받은 경우에는 대금을 받은 날로부터 4일(영업일기준) 이내에, 그렇지 않은 경우에는 그 사유발생일로부터 5일(영업일기준) 이내에 계약해지 및 환급절차를 취합니다.
나. 이용자의 반품 신청에 의한 반품, 환급 규정은 다음과 같습니다.
① 단순변심에 의한 반품
서비스 이행구간별로 해외 인터넷쇼핑몰 등에서 이용자를 대신하여 구매하는데 제공된 수입대행수수료, 기발생한 국제운송비 및 제세금 등에 대해 이용자가 부담하는 것을 조건으로 반품을 받을 수 있습니다.
② 상품 상이, 결실, 파손, 손상, 오염에 의한 반품
BIKEMART의 해외 주소로부터 국내 이용자의 수취전까지 국제복합운송 중 수입대행 상품의 분실,파손, 손상, 오배송, 오염 등이 발생할 경우 이에 대한 책임은 BIKEMART에 있으며 상품 대금을 포함한 총결제금액 전액을 이용자에게 직접 배상합니다. 또한, 상기된 하자로 인한 반품이 BIKEMART의 해외주소에서 수취전 해외쇼핑몰의 귀책사유 임이 객관적으로 증명될 경우 BIKEMART는 이용자에게 반품을 받은 후, BIKEMART가 이용자를 대신하여 해외쇼핑몰에 클레임을 제기할 수 있습니다.

제13조(차액정산)

1. 상품 가격 변경, 관세율표 개정, 세번분류 변경, 전산시스템 오류, 환율차이 등으로 인해 이용자가 지불한 금액과 BIKEMART의 수입대행시 발생한 실제 비용이 차이가 발생하는 경우에 대해 과부족금액을 이용자가 요청할 경우에 한해 BIKEMART는 이용자와 사후정산(이후 '차액정산' 이라함)을 할 수 있습니다.

2. 차액정산 범위는 원 결제금액의 + - 10% 오차범위를 초과하는 경우에 한해 행해지며, 무통장 입금 비용 등 차액정산에 소요되는 비용을 BIKEMART 또는 이용자가 부담하는 조건으로 이루어 집니다.

제14조(긴급조치)

1. 이용자가 위법, 불법 또는 부당한 목적을 위해 서비스를 이용한다고 BIKEMART가 판단하는 때에는 BIKEMART는 관련 물품의 수취나 배송을 거절할 권리를 가집니다.

2. 관할관청 또는 당국에 의해 BIKEMART에 의해 서비스되는 물품에 대해 제재를 받았을 때 BIKEMART는 해당물품을 관할관청 또는 당국에 인도하는 것을 원칙으로 합니다. 이로 인하여 이용자가 손해를 입었다고 할지라도 해당 손해에 대해서 BIKEMART는 일체의 책임을 지지 않으며 또한 이용자는 해당 물품에 대한 서비스 이용요금 및 관련비용 등의 지급의무를 면하지 아니합니다.

3. BIKEMART 주소로 배송된 물품에 악취, 액체누수 그 외 이상이 있다고 인정됐을 경우 및 기타 긴급을 필요로 하고 정당한 이유가 있다고 인정됐을 경우 BIKEMART는 이용자에게 해당사실을 통지하고 해당 물품을 별도 장소로 이동 보관하는 등 임시조치를 취할 수 있습니다. 이로 인해 발생하는 추가비용은 이용자가 부담하여야 하며 또한 이용자에게 손해가 발생하더라도 해당 손해에 대하여 BIKEMART는 일체의 책임을 지지 않습니다.

제15조(BIKEMART의 의무)

1. BIKEMART는 법령과 이 약관이 금지하거나 공서양속에 반하는 행위를 하지 않으며 이 약관이 정하는 바에 따라 지속적이고, 안정적으로 재화·용역을 제공하는데 최선을 다하여야 합니다.

2. BIKEMART는 이용자가 안전하게 인터넷 서비스를 이용할 수 있도록 이용자의 개인정보(신용정보 포함) 보호를 위한 보안 시스템을 갖추어야 합니다.

3. BIKEMART는 상품이나 용역에 대하여 「표시·광고의 공정화에 관한 법률」 제3조 소정의 부당한 표시 ·광고 행위를 함으로써 이용자가 손해를 입은 때에는 이를 배상할 책임을 집니다.

4. BIKEMART는 이용자가 원하지 않는 영리목적의 광고성 전자우편을 발송하지 않습니다.

제16조(분쟁해결)

1. BIKEMART는 이용자가 제기하는 정당한 의견이나 불만을 반영하고 그 피해를 보상처리하기 위하여 피해 보상 처리기구를 설치·운영합니다.

2. BIKEMART는 이용자로부터 제출되는 불만사항 및 의견은 우선적으로 그 사항을 처리합니다.

다만, 신속한 처리가 곤란한 경우에는 이용자에게 그 사유와 처리일정을 즉시 통보해 드립니다.

3. BIKEMART와 이용자간에 발생한 분쟁은 전자거래기본법 제28조 및 동 시행령 제15조에 의하여 설치된 전자거래분쟁조정위원회의 조정에 따를 수 있습니다.

제17조(재판권 및 준거법)

1. BIKEMART와 이용자간에 발생한 전자상거래 분쟁에 관한 소송은 제소 당시의 이용자의 주소에 의하고, 주소가 없는 경우에는 거소를 관할하는 지방법원의 전속관할로 합니다. 다만, 제소 당시 이용자의 주소 또는 거소가 분명하지 않거나 외국 거주자의 경우에는 민사소송법상의 관할법원에 제기합니다.

2. BIKEMART와 이용자간에 제기된 전자상거래 소송에는 한국법을 적용합니다.

제18조(통지)

1. 구매대행 서비스 품목 중 관세법 제234조에 의한 수출입금지품에 대해서는 취급불가하며, 목록통관배제물품은 일반수입신고 하여야 합니다.

  관세법 제234조(수출입의 금지) 다음 각호의 1에 해당하는 물품은 수출 또는 수입할 수 없다.
    1) 헌법질서를 문란하게 하거나 공공의 안녕질서 또는 풍속을 해치는 서적·간행물·도화·영화·음반·비디오물·조각물 기타 이에 준하는 물품
    2) 정부의 기밀을 누설하거나 첩보활동에 사용되는 물품
    3) 화폐·채권 기타 유가증권의 위조품.변조품 또는 모조품

  목록통관 배재대상물품
    1) 의약품
    2) 한약재
    3) 야생동물 관련 제품
    4) 농림축수산물 등 검역대상물품
    5) 건강기능식품
    6) 지식재산권 위반 의심물품
    7) 식품류·과자류
    8) 화장품(기능성화장품, 태반함유화장품, 스테로이드제 함유화장품 및 성분미상 등 유해화장품에 한함)
    9) 「전자상거래물품 등의 특별통관절차에 관한 고시」 제3-3조 제3항에 따라 특별통관대상업체로 지정되지 아니한 전자상거래업체가 수입하는 물품
    10) 통관물품 중 품명·규격·수량·가격 등이 부정확하게 기재된 물품
    11) 그 밖에 법 제226조에 따른 세관장확인대상물품 등 목록통관이 타당하지 아니하다고 세관장이 인정하는 물품

2. 구매대행 서비스 품목에 한하여 법령에 따라 제품의 재고를 보유하고 있지 않습니다.

3. 구매대행의 경우 소액면세 기준인 과세가격 15만원 이하인 경우에도 합산하여 과세대상에 포함될 수 있습니다.

제19조 고지의 의무

이 이용약관은 법령, 정책의 변경에 따라 내용의 추가, 삭제 및 수정이 있을 시에는 수정변경사항에 대해서 시행일의 1일전부터 (주)바이크마트 사이트의 공지사항을 통하여 고지할 것 입니다

부　칙

본 약관은 2013년 6월 3일에 개정변경 되었습니다.
      </pre>
    </div>
  </div>
</section>

<ng-container *ngIf="isMobileApp === false">
  <app-footer></app-footer>
</ng-container>
