/*******************************************************************************
  설  명 : 마이페이지 - 동호회 등록
  생성일 : 2024-03-29
  생성자 : 권세창
*******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

import { SMemberService } from '@app/service/member.service';

import { ToastrService } from 'ngx-toastr';
import { AuthService } from '@app/service/auth.service';



@Component({
  selector: 'app-mypage-society',
  templateUrl: './mypage-society.component.html',
  styleUrls: ['./mypage-society.component.scss']
})
export class MypageSocietyComponent implements OnInit { 
/*******************************************************************************
설명 : 전역 변수 선언부
*******************************************************************************/
public isLogIn: boolean;
isLogin$: Observable<boolean>; // 로그인여부확인

public societyList: any = [];

public memberInfo: any = {};

public params: any = {
  society_seq:'',
  society_name:''
};


/*******************************************************************************
설  명 : 생성자
입력값 : 없음
리턴값 : 없음
*******************************************************************************/
constructor(
private authService: AuthService,
private toastrService: ToastrService,
private memberService: SMemberService,
) { 
// 로그인 여부 가져오기
this.isLogin$ = this.authService.isLoggedIn;

this.isLogin$.subscribe( login => {
  this.isLogIn = login;
});

}

/*******************************************************************************
설  명 : 데이터 로딩 처리
입력값 : 없음
리턴값 : 없음
*******************************************************************************/
ngOnInit(): void {
this.authService.getLoginInfo.subscribe(data => {
  this.memberInfo = data;
});

this.getSocietyList();
   
}

/*******************************************************************************
    설  명 : 동호회리스트  가져오기
    입력값 : 없음
    리턴값 : 없음
*******************************************************************************/
getSocietyList() {
      this.memberService.getSocietyList().then( response => {
        if( response.ResultCode ) {
          this.societyList = response.data;
        } else {
          this.toastrService.error(response.ResultMessage);
        }
  
      });
}

/*******************************************************************************
    설  명 : 동호회 선택 처리
    입력값 : 없음
    리턴값 : 없음
*******************************************************************************/
selectedSociety(event) {
      this.params.society_seq = event.target.value;
      this.params.society_name = event.target.title;
}

/*******************************************************************************
    설  명 : 동호회 등록 처리
    입력값 : 동호회 순번
    리턴값 : 처리결과
*******************************************************************************/
registrationSociety() {
  if (this.params.society_seq == '') {
    this.toastrService.error('동호회를 선택하세요.', '');
  } else {
    this.memberService.setSocietyMember( this.params.society_seq ).then( response => {
      if ( response.ResultCode ){
        this.toastrService.success(response.ResultMessage, '');
        window.location.reload();
      } else {
        this.toastrService.error(response.ResultMessage, '');
      }

    });
    
  }
}




}