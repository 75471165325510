<app-header class="pc-view"></app-header>

<div class="inner clearfix">
  <!-- 왼쪽 마이페이지 메뉴 -->
  <app-mypage-menu></app-mypage-menu>
  <app-mypage-header class="pc-view"></app-mypage-header>

  <ul class="header2-coupon clearfix">
    <li>
      <p>사용 가능 쿠폰</p>
      <p>{{availableCoupon | number}}<span>장</span></p>
    </li>
  </ul>
  
  <div class="mypage-contents">
  
    <div class="section order-list">
      <div class="title page-title mobile-view">바마쿠폰</div>
      <div class="title">사용가능쿠폰</div>

      <div class="mypage-search-area form-inline">
        <span class="bold pc-view">기간</span>

        <div class="btn-group ml10 mr10">
          <button *ngFor="let item of searchdate_group" type="button" class="btn btn-default btn-small" [ngClass]="{active:search.searchdate_group==item.value}" (click)="onSearchDateClick(item.value)">{{item.title}}</button>
        </div>

        <input type="text" id="sdate" [(ngModel)]="search.sdate" class="form-control form-control-small pc-view" ngbDatepicker [footerTemplate]="footerTemplate" #d1="ngbDatepicker" [readonly]="true" (click)="d1.toggle()" />
        <ng-template #footerTemplate>
          <hr class="my-0">
          <button type="button" class="btn btn-primary btn-small m-2 float-left" (click)="getToday(d1, 1)">오늘</button>
          <button type="button" class="btn btn-secondary btn-small m-2 float-right" (click)="d1.close()">닫기</button>
        </ng-template>
        <span class="pl5 pr5 pc-view">~</span>
        <input type="text" id="edate" [(ngModel)]="search.edate" class="form-control form-control-small ml5  pc-view" ngbDatepicker [footerTemplate]="footerTemplate2" #d2="ngbDatepicker" [readonly]="true" (click)="d2.toggle()" />
        <ng-template #footerTemplate2>
          <hr class="my-0">
          <button type="button" class="btn btn-primary btn-small m-2 float-left" (click)="getToday(d2, 2)">오늘</button>
          <button type="button" class="btn btn-secondary btn-small m-2 float-right" (click)="d2.close()">닫기</button>
        </ng-template>

        
        <button class="basic-btn sm-btn pc-view" (click)="getCouponList()">조회</button>

      </div>

      <table class="list-table pc-view">
      <colgroup>
        <col style="width: 12%">
        <col style="width: 20%">
        <col style="width: 35%">
        <col style="width: 12%">
        <col style="width: 11%">
      </colgroup>    
    
      <thead>
      <tr>
        <th>쿠폰구분</th>
        <th>쿠폰명</th>
        <th>사용기한</th>
        <th>잔여일수</th>
        <th>할인</th>
      </tr>    
      </thead>
      <tbody>
        <tr *ngFor="let row of couponList;let i = index;">
          <td>{{row.coupon_kind_name}}</td>
          <td>{{row.coupon_name}}</td>
          <td>{{row.coupon_period}}</td>
          <td>{{row.remainDay}}일</td>
          <td>{{row.discountNumber}}</td>
        </tr>
        <tr *ngIf="couponList.length < 1">
          <td colspan="5">내용이 없습니다</td>
        </tr>
      </tbody>
      </table>
      
      <div class="mobile-view">
	      <table class="list-table">
	      <colgroup>
	        <col style="width: 30%">
	        <col style="width: 50%">
	        <col style="width: 20%">
	      </colgroup>    
	      <tbody>
          <tr *ngFor="let row of couponList;let i = index;">
            <td>{{row.coupon_name}}</td>
            <td>{{row.coupon_period}}</td>
            <td>{{row.discountNumber}}</td> 
          </tr>        
	      </tbody>
	      </table>       
      </div>
      <div class="pagination-area">
        <ngb-pagination
          [(page)]="search.pageNo"
          [pageSize]="search.pageRow"
          [collectionSize]="totalCount"
          [boundaryLinks]="true"
          (pageChange)="loadPage($event)"
          >
          <ng-template ngbPaginationFirst>처음</ng-template>
          <ng-template ngbPaginationLast>마지막</ng-template>
          <ng-template ngbPaginationPrevious>이전</ng-template>
          <ng-template ngbPaginationNext>다음</ng-template>
        </ngb-pagination>
      </div>  
      
      <!-- <div class="guide">
        <div class="title"란?</div>
        <p class="dot">·</p><p class="dot1">마일리지 제도는 바이크마트 회원님들께 알찬 쇼핑의 즐거움을 드리기 위한 제도로 바이크마트 회원님들을 위해 태어났습니다. 바이크마트에서 쇼핑하시면 구매하시는 상품마다 마일리지를 적립해 드리며, 적립된 마일리지는 다음 구매시에 바로 현금처럼 사용하실 수 있는 결제 수단이며 모으면 모을수록 혜택도 커집니다.</p> 
        <br/><br/>       
        <div class="title">마일리지 사용</div>
        <p class="dot">·</p><p class="dot1">마일리지 적용상품은 현금처럼 사용가능합니다.</p>
				<p class="dot">·</p><p class="dot1">마일리지는 상품구입 후 입금이 확인되는데로 적립해 드립니다.</p> 
				<p class="dot">·</p><p class="dot1">마일리지 사용은 제품 발송완료 후 적립된 마일리지를 사용하실 수 있습니다.</p> 
				<p class="dot">·</p><p class="dot1">제품 구입 후 환불 시 적립된 마일리지는 취소(차감)됩니다. 또한, 초과사용 마일리지는 다음 주문/결제시 자동 청구됩니다.</p> 
				<p class="dot">·</p><p class="dot1"><span class="red">마일리지를 사용하였으나, 미결제(미입금) 주문건일 경우,</span> 구매내역 > 주문목록에서 취소처리시 마일리지 복구처리됩니다</p>  
				<br/><br/>  
        <div class="title">마일리지 사용시 유의사항</div>
				<p class="dot">·</p><p class="dot1">마일리지는 마일리지 자체로만 사용 가능하며 '현금+마일리지','카드+마일리지'처럼 혼합결제는 할 수 있습니다.</p>
				<p class="dot">·</p><p class="dot1">마일리지는 회원간에 선물이나 현금거래가 금지되어 있습니다.</p>
				<p class="dot">·</p><p class="dot1">마일리지 적용 퍼센트[%]는 각 제품마다 다르게 적용 될 수 있습니다.</p> 
				<p class="dot">·</p><p class="dot1">마일리지는 인터넷으로 직접 주문/구입하셔야 적립해 드립니다</p> 
				<p class="dot">·</p><p class="dot1">제품 구입 후 환불 시 적립된 마일리지는 취소됩니다.</p>
				<p class="dot">·</p><p class="dot1">마일리지는 1년이상 미접속(접속시 로그인)하시면 데이터 관리를 위해 소멸처리 됩니다.</p> 
				<p class="dot">·</p><p class="dot1">회원 탈퇴 시에는 마일리지가 소멸합니다.</p> 
				<p class="dot">·</p><p class="dot1">회원가입을 하지 않거나 로그인을 하지 않은 상태에서 상품을 구입하시면 마일리지는 적용되지 않습니다.</p>                 
      </div>        -->
    </div>   
         
  </div>   
</div>

<app-footer></app-footer>