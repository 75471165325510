import { Injectable } from '@angular/core';

import { RestfulService } from '@app/service/restful.service';

@Injectable({
  providedIn: 'root'
})
export class BasicService {

  constructor(
    private restful: RestfulService,
  ) { }

  private extractData(res: Response) {
    let body = res;
    return body || [ ];
  }

  private handleErrorPromise(error: Response | any) {
    console.error(error.message || error);
    return Promise.reject(error.message || error);
  }

  // OFF 매장 리스트 가져오기
  getStoreListOff( search ): Promise<any> {
    return this.restful.get({
        program: 'shop',
        service: 'basic',
        version: '1.0',
        action: 'getStoreListOff'
      }, search
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 매장 상세정보 가져오기
  getStoreDetail( seq: number ): Promise<any> {
    return this.restful.get({
        program: 'shop',
        service: 'basic',
        version: '1.0',
        action: 'getStoreDetail'
      }, {
        seq: seq
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

}