<div class="modal-header">
  <h4 class="modal-title fl">{{ item.society_name }}</h4>
  <button type="button" class="close" (click)="activeModal.dismiss()">&times;</button>
</div>

<div class="modal-body">
  <div class="info">
    <p><span>동호회장 : </span><span>{{ item.rep_name }}</span></p>
    <p><span>활동지역 : </span><span>{{ item.region_name }}</span></p>
    <p><span>동호회원수 : </span><span>{{ item.member_count }}명</span></p>
    <p><span>소개 : </span><span></span></p>
    <p><span></span><span>{{ item.society_description }}</span></p>
  </div>

  <div class="btn-area">
   <button type="button" class="btn1" (click)="closeModal()">닫기</button>
  </div>
</div>

