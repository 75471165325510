<ng-container *ngIf="isMobileApp === false">
  <app-header></app-header>
</ng-container>

<ul class="menu clearfix cb" *ngIf="isMobileApp === false">
  <li><a routerLink="/company/info">회사소개</a></li>
  <li><a routerLink="/company/history">회사연혁</a></li>
  <li><a routerLink="/company/society">동호회등록</a></li>
  <li><a routerLink="/company/supply">입점안내</a></li>
  <li><a routerLink="/company/story">창업스토리</a></li>
  <li><a routerLink="/company/contact" class="on">가맹문의</a></li>
  <li><a routerLink="/company/policy/service">이용약관</a></li>
</ul>

<ul class="menu menu-mobile clearfix cb" *ngIf="isMobileApp === true">
  <li><a routerLink="/mobile/company/info">회사소개</a></li>
  <li><a routerLink="/mobile/company/history">회사연혁</a></li>
  <li><a routerLink="/company/society">동호회등록</a></li>
  <li><a routerLink="/mobile/company/supply">입점안내</a></li>
	<li><a routerLink="/mobile/company/story">창업스토리</a></li>
	<li><a routerLink="/mobile/company/contact" class="on">가맹문의</a></li>
  <li><a routerLink="/mobile/company/policy/service">이용약관</a></li>
</ul>

<div style="margin-top: 37px" *ngIf="isMobileApp === true"></div>

<section class="contents">

  <div class="main-img">
    <img src="/assets/images/company_contact.jpg">
    <div class="title">가맹안내</div>
  </div>

  <div class="section inner">
    <div class="icon">
      <li style="background-position: calc(-45px * 7 ) 0;"></li>
      <li style="background-position: calc(-45px * 8 ) 0;"></li>
      <li style="background-position: calc(-45px * 9 ) 0;"></li>
      <li style="background-position: calc(-45px * 10) 0;"></li>
      <li style="background-position: calc(-45px * 11) 0;"></li>
      <li style="background-position: calc(-45px * 12) 0;"></li>
    </div>

    <article class="article">
      <div class="title">가맹문의</div>
      <form [formGroup]="form">
        <div class="btn-group" style="width: 100%;">
          <p class="radio-p"><span>*</span>가맹구분</p>
          <div style="line-height: 40px; padding: 0;margin: 0;">
            <label class="f16">
              <input type="radio" class="" formControlName="associate_type" value="2001" [ngClass]="{formErrors:formErrors['associate_type']}"> 프랜차이즈
              <span class="checkmark"></span>
            </label>
            <label class="f16 ml15">
              <input type="radio" class="" formControlName="associate_type" value="1001" [ngClass]="{formErrors:formErrors['associate_type']}"> 협력점
              <span class="checkmark"></span>
            </label>
          </div>
        </div>
        <div>
          <p>이름</p>
          <input type="text" formControlName="name" placeholder="" [ngClass]="{formErrors:formErrors['name']}" />
          <div *ngIf="formErrors['name']" class="error-message-contact">{{formErrors['name']}}</div>
        </div>
        <div>
          <p><span>*</span>연락처</p>
          <input type="text" formControlName="phone" placeholder="공백은 제외하고 입력" [ngClass]="{formErrors:formErrors['phone']}" />
          <div *ngIf="formErrors['phone']" class="error-message-contact">{{formErrors['phone']}}</div>
        </div>
        <div>
          <p>이메일</p>
          <input type="text" formControlName="email" placeholder="" [ngClass]="{formErrors:formErrors['email']}" />
          <div *ngIf="formErrors['email']" class="error-message-contact">{{formErrors['email']}}</div>
        </div>
        <div>
          <p>창업희망지역</p>
          <input type="text" formControlName="location" placeholder="" />
        </div>
        <!-- <div>
          <p>예상창업일</p>
          <input type="text" formControlName="openday" placeholder="" />
        </div>
        <div>
          <p>예상창업비용</p>
          <input type="text" formControlName="price" placeholder="" />
        </div> -->
        <div>
          <p style="vertical-align: top;"><span>*</span>문의내용</p>
          <textarea formControlName="content" placeholder="" style="height: 160px;" [ngClass]="{formErrors:formErrors['content']}"></textarea>
          <div *ngIf="formErrors['content']" class="error-message-contact">{{formErrors['content']}}</div>
        </div>
        <div>
          <input type="checkbox" id="개인정보동의" formControlName="agreement">
          <label for="개인정보동의">
            <a [routerLink]="isMobileApp === false ? '/company/policy/privacy' : '/mobile/company/policy/privacy'">개인정보취급방침</a> 에 동의합니다.
          </label>
        </div>
        <button type="submit" (click)="submit()">문의하기</button>
      </form>
    </article>

    <section class="table-div clearfix">
      <div class="fl">
        <div class="table-header">
          <h4>입점문의 현황</h4>
          <a [routerLink]="isMobileApp === false ? '/company/supply' : '/mobile/company/supply'">입점문의하기</a>
        </div>
        <table class="list-table">
          <colgroup class="pc-view">
            <col style="width: 30%">
            <col style="width: 30%">
            <col style="width: 40%">
          </colgroup>

          <tbody>
            <ng-container *ngFor="let row of contactList">
            <tr *ngIf="row.type == '2001'">
              <td>{{row.type_name}}</td>
              <td>{{row.name}}</td>
              <td>{{row.tel}}</td>
            </tr>
            </ng-container>
          </tbody>
        </table>
      </div>

      <div class="fr">
        <div class="table-header">
          <h4>가맹문의 현황</h4>
          <!-- <a routerLink="/company/contact">가맹문의하기</a> -->
        </div>
        <table class="list-table">
          <colgroup class="pc-view">
            <col style="width: 30%">
            <col style="width: 30%">
            <col style="width: 40%">
          </colgroup>

          <tbody>
            <ng-container *ngFor="let row of contactList">
              <tr *ngIf="row.type == '1001'">
                <td>{{row.type_name}}</td>
                <td>{{row.name}}</td>
                <td>{{row.tel}}</td>
              </tr>
              </ng-container>
          </tbody>
        </table>
      </div>
    </section>

    <article class="article">
      <div class="title">다양하고 합리적인 PB 상품,<br>바이크마트만의 경쟁력입니다.</div>
      <div class="subtitle">
        <p>오토바이 용품점 창업을 고려하고 계신가요?</p>
        <p>지금 문의하세요.</p>
        <p>성공적인 창업을 도와드리겠습니다.</p>
      </div>
    </article>
    <article class="article">
      <div class="title">창업스토리</div>
      <ul>
        <li class="storyList" id="daejeon">
          <img src="/assets/images/daejeon_banner.jpg">
          <p>[성공스토리 - 대전점] 바이크마트 4년차 베테랑, 성공한 사업가!</p>
        </li>
        <li class="storyList" id="ulsan">
          <img src="/assets/images/ulsan_banner.jpg">
          <p>[창업스토리 - 울산점] 20대 여성 창업 성공 스토리</p>
        </li>
        <li class="storyList" id="pohang">
          <img src="/assets/images/pohang_banner.jpg">
          <p>[창업스토리 - 포항점] 평범한 직장인 월5000만원 매출?</p>
        </li>
        <li class="storyList" id="gyeonggi_seobu">
          <img src="/assets/images/gyeonggi_seobu_banner.jpg">
          <p>[창업스토리 - 경기서부점] 첫 달 매출 5000만원의 비결은?</p>
        </li>
        <li class="storyList">
          <a routerLink="/company/supply">
            <img src="/assets/images/empty.jpg">
            <p></p>
          </a>
        </li>
      </ul>
    </article>
    <article class="article">
      <div class="title">가맹혜택</div>
      <ul>
        <li class="item">
          <div class="image" style="background-position: 0 0;"></div>
          <p>01</p>
          <p>브랜드</p>
          <p>바이크마트 CI/BI를 사용할 수 있습니다.</p>
        </li>
        <li class="item">
          <div class="image" style="background-position: -150px 0;"></div>
          <p>02</p>
          <p>영업</p>
          <p>바이크마트만의 영업 노하우를 제공합니다.</p>
        </li>
        <li class="item">
          <div class="image" style="background-position: -300px 0;"></div>
          <p>03</p>
          <p>마케팅</p>
          <p>자사몰을 통한 온라인 홍보를 지원합니다.</p>
        </li>
        <li class="item">
          <div class="image" style="background-position: -450px 0;"></div>
          <p>04</p>
          <p>가맹비 무료</p>
          <p>별도의 가맹비가 없어 부담이 적습니다.</p>
        </li>
      </ul>
    </article>
  </div>

</section>

<ng-container *ngIf="isMobileApp === false">
  <app-footer></app-footer>
</ng-container>
