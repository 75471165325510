<div class="member-page">
  <div class="member-inner">
    <div class="logo">
      <a routerLink="/"><img src="/assets/images/logo1.png?date=20210913" alt="바이크마트 로고"></a>
    </div>
    
    <form [formGroup]="memberAddForm">
      <div class="title">회원가입<span> 정보입력</span></div>
      <div class="info-txt">
				<p class="dot">·</p><p class="dot1">BIKEMART에서는 제공서비스 이용을 위해 회원가입 시 최소한의 정보만 등록받고 있으며, 	회원의 신상정보에 대해서는 회원이 동의없이 외부로 절대 유출되지 않을 것입니다.</p>
				<p class="dot">·</p><p class="dot1"><span class="red">만14세 미만은 가입신청을 받지 않습니다.</span></p>
				<p class="dot">·</p><p class="dot1">개인정보보호법 강화 및 정보통신망 법률개정으로 일부서비스는 별도의 인증절차를 거처야 이용 가능할 수 있습니다</p>
				<p class="dot">·</p><p class="dot1">고의적으로 정보 누락/변경 입력에 따라 향후 적발시에는 별도의 동의없이 서비스 제한됩니다.</p>
				<p class="dot">·</p><p class="dot1">회원정보가 정확하지 않을 경우 쇼핑몰 및 중고/신차/셀프장터 서비스가 이용 불가능할 수 있으니 신중히 작성 부탁드립니다.</p>
				<p class="dot">·</p><p class="dot1"><span class="check"></span>표시는 <span class="red">등록 후 수정불가항목</span>이니 신중히 작성 부탁드립니다.</p>
				<p class="dot">·</p><p class="dot1">언제나 최선을 다하는 BIKEMART가 되겠습니다. 감사합니다.</p>
      
      </div>
      <label class="overlap">
        <p>아이디<span class="check"></span></p>
        <div *ngIf="!formErrors['id'] && formErrors['id_check']" class="error-message error-message2">아이디 중복검색을 하시기 바랍니다.</div>
        <input type="text" formControlName="id" [ngClass]="[formErrors['id'] ? 'formErrors' : '', formErrors['id_check'] ? 'formErrors' : '']" placeholder="아이디 (띄어쓰기 없이 6~20자의 영문, 숫자)" (keydown)="setIdCheckInit()" autocomplete="off" autofocus />
        <button class="basic-btn" (click)="checkIdDuplicate()">중복확인</button>
        <input type="hidden" formControlName="id_check" pattern="{{checkIdDup}}" />
      </label>
      <div *ngIf="formErrors['id']" class="error-message-under">{{formErrors['id']}}</div>
      <label>
        <p>이름<span class="check"></span></p>
        <input type="text" formControlName="name" [ngClass]="{formErrors:formErrors['name']}" placeholder="실명을 입력해주세요. 예) 홍길동" autocomplete="off" />
      </label>
      <div *ngIf="formErrors['name']" class="error-message-under">{{formErrors['name']}}</div>
      <label>
        <p>비밀번호<span class="check"></span></p>
        <input type="password" formControlName="pw" [ngClass]="{formErrors:formErrors['pw']}" placeholder="영문/숫자 조합 8자이상" autocomplete="off" />
      </label>
      <div *ngIf="formErrors['pw']" class="error-message-under">{{formErrors['pw']}}</div>
      <label>
        <p>비밀번호 확인<span class="check"></span></p>
        <input type="password" formControlName="pw_check" [ngClass]="{formErrors:formErrors['pw_check']}" placeholder="비밀번호를 한번 더 입력해 주십시오." pattern="{{ this.memberAddForm.get('pw').value }}" autocomplete="off" />
      </label>
      <div *ngIf="formErrors['pw_check']" class="error-message-under">{{formErrors['pw_check']}}</div>
      <label class="tel" for="hp2">
        <p>전화번호<span class="check"></span></p>
        <div class="telinfo">
          <select formControlName="hp1">
            <option value="010">010</option>
            <option value="011">011</option>
            <option value="016">016</option>
            <option value="017">017</option>
            <option value="018">018</option>
            <option value="019">019</option>
          </select>
          <span class="dash">-</span>
          <input type="text" class="hp2" formControlName="hp2" maxlength="4" [ngClass]="{formErrors:formErrors['hp2']}" placeholder="" />
          <span class="dash">-</span>
          <input type="text" class="hp3" formControlName="hp3" maxlength="4" [ngClass]="{formErrors:formErrors['hp3']}" placeholder="" />
        </div>
      </label>
      <div *ngIf="formErrors['hp2'] || formErrors['hp3']" class="error-message-under">{{formErrors['hp2']}}</div>
      <label class="email">
        <p>이메일주소</p>
        <input type="text" formControlName="email1" [ngClass]="{formErrors:formErrors['email1']}"  placeholder="이메일주소" />
        <span class="division">@</span>
        <input type="text" formControlName="email2" [ngClass]="{'read-input': this.memberAddForm.value.email3 != '', formErrors:formErrors['email2']}" [readonly]="( this.memberAddForm.value.email3 != '' ) ? true : false" #email2 />
        <select formControlName="email3" (change)="changeEmail($event, 'email2')">
          <option value="">직접입력</option>
          <option value="naver.com">naver.com</option>
          <option value="gmail.com">gmail.com</option>
          <option value="daum.net">daum.net</option>
          <option value="nate.com">nate.com</option>
          <option value="dreamwiz.com">dreamwiz.com</option>
          <option value="yahoo.co.kr">yahoo.co.kr</option>
          <option value="outlook.com">outlook.com</option>
          <option value="hotmail.com">hotmail.com</option>
        </select>
      </label>
      <div *ngIf="formErrors['email1'] || formErrors['email2']" class="error-message-under">{{formErrors['email1']}}</div>
      <label>
        <p>성별</p>
        <ul class="clearfix">
          <li [ngClass]="{active:memberAddForm.controls.gender.value=='M'}" (click)="memberAddForm.get('gender').setValue( 'M' )">남성</li>
          <li [ngClass]="{active:memberAddForm.controls.gender.value=='F'}" (click)="memberAddForm.get('gender').setValue( 'F' )">여성</li>
        </ul>
      </label>
      <div *ngIf="formErrors['gender']" class="error-message-under">{{formErrors['gender']}}</div>
      <label class="date">
        <p>생년월일</p>
        <div class="detail">
          <select formControlName="birthdate1" class="mr5" [ngClass]="{formErrors:formErrors['birthdate1']}">
            <option value="">년도 선택</option>
            <option *ngFor="let row of yearList" value="{{row}}">{{row}}</option>
          </select>
          <select formControlName="birthdate2" class="mr5" [ngClass]="{formErrors:formErrors['birthdate2']}">
            <option value="">월 선택</option>
            <option *ngFor="let row of monthList" value="{{row}}">{{row}}</option>
          </select>
          <select formControlName="birthdate3" [ngClass]="{formErrors:formErrors['birthdate3']}">
            <option value="">일 선택</option>
            <option *ngFor="let row of dayList" value="{{row}}">{{row}}</option>
          </select>
        </div>
        <div *ngIf="formErrors['birthdate1']" class="error-message-under">년도: {{formErrors['birthdate1']}}</div>
        <div *ngIf="formErrors['birthdate2']" class="error-message-under">월: {{formErrors['birthdate2']}}</div>
        <div *ngIf="formErrors['birthdate3']" class="error-message-under">일: {{formErrors['birthdate3']}}</div>
      </label>
      <div *ngIf="formErrors['birthdate']" class="error-message-under">{{formErrors['birthdate']}}</div>
      <label class="region">
        <p>회원지역</p>
        <div class="detail">
          <select formControlName="region1" class="mr5" [ngClass]="{formErrors:formErrors['region1']}" (change)="changeSido($event)">
            <option value="">시도 선택</option>
            <option *ngFor="let row of region_A_list" value="{{row.seq}}">{{row.sido_name}}</option>
          </select>
          <select formControlName="region2" [ngClass]="{formErrors:formErrors['region2']}">
            <option value="">구군 선택</option>
            <option *ngFor="let row of region_B_list" value="{{row.seq}}">{{row.sigungu_name}}</option>
          </select>
        </div>
        <div *ngIf="formErrors['region1']" class="error-message-under">시도: {{formErrors['region1']}}</div>
        <div *ngIf="formErrors['region2']" class="error-message-under">구군: {{formErrors['region2']}}</div>
        <div class="mt5">
          <button type="button" (click)="getLocationUseKakao()" class="basic-btn">회원지역을 현재위치로 설정</button>
        </div>
      </label>
      <div *ngIf="formErrors['email1'] || formErrors['email2']" class="error-message-under">{{formErrors['email1']}}</div>
      <label>
        <p>SMS 수신여부</p>
        <ul class="clearfix">
          <li [ngClass]="{active:memberAddForm.controls.sms_yn.value=='1'}" (click)="memberAddForm.get('sms_yn').setValue( '1' )" >수신동의</li>
          <li [ngClass]="{active:memberAddForm.controls.sms_yn.value =='0'}" (click)="memberAddForm.get('sms_yn').setValue( '0' )" >수신하지 않음</li>
        </ul>
      </label>
      <div *ngIf="formErrors['sms_yn']" class="error-message-under" >{{formErrors['sms_yn']}}</div>
      <label>
        <p>바이크 유무</p>
        <ul class="clearfix">
          <li [ngClass]="{active:memberAddForm.controls.bike_yn.value=='1'}" (click)="memberAddForm.get('bike_yn').setValue( '1' )" >갖고 있다</li>
          <li [ngClass]="{active:memberAddForm.controls.bike_yn.value=='0'}" (click)="memberAddForm.get('bike_yn').setValue( '0' )" >구입할 계획이다</li>
        </ul>
      </label>
      <div *ngIf="formErrors['bike_yn']" class="error-message-under">{{formErrors['bike_yn']}}</div>
      <input type="submit" value="가입하기" (click)="submit()" class="basic-btn red join-btn"/>
    </form>
    
    <div class="member-footer">
      <ul class="clearfix">
        <li><a routerLink="#">이용약관</a></li>
        <li><a routerLink="#" class="bold">개인정보처리방침</a></li>
        <li><a routerLink="#">고객센터 1544-5022</a></li>
      </ul>
      <div>Copyright © 2008 <span class="bold">bikemart.co.kr</span> ALL RIGHTS RESERVED.</div>
    </div>    
  </div>
</div>
