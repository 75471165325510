/*******************************************************************************
  설  명 : 마이페이지 - 업체회원전환
  생성일 : 2020-09-10
  생성자 : 송영석
*******************************************************************************/
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FileUploader, FileSelectDirective, FileItem, FileLikeObject } from 'ng2-file-upload';
import { ToastrService } from 'ngx-toastr';

import { config } from '@app/service/config.service';
import { UtilService } from '@app/service/util.service';
import { RestfulService } from '@app/service/restful.service';
import { SMemberService } from '@app/service/member.service';

const URL = config.apiImageUploadUrl;

@Component({
  selector: 'app-mypage-company-change',
  templateUrl: './mypage-company-change.component.html',
  styleUrls: ['./mypage-company-change.component.scss']
})
export class MypageCompanyChangeComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  @ViewChild('email2', {static: false}) inputEmail2: ElementRef;
  @ViewChild('address_detail', { static: false }) addressDetail: ElementRef;

  public currPage = 'main'; // 보이는 page

  public form: FormGroup;
  public formErrors = {};

  public baseURL = config.baseUrl;

  public uploader: FileUploader;
  public uploadProgress: any = 0;
  public imageList: any = [];

  public daumAddressOptions =  {
    class: ['btn', 'overlap-btn', 'h35', 'f12'],
    type: 'inline',
    target: 'daumAddressApiWrap'
  };

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private formBuilder: FormBuilder,
    private toastrService: ToastrService,
    private utilService: UtilService,
    private restful: RestfulService,
    private sMemberService: SMemberService
  ) {
    this.buildForm();
  }

  /*******************************************************************************
    설  명 : 빌드폼 생성
  *******************************************************************************/
  buildForm(): void {
    this.form = this.formBuilder.group({
      application_gbn:[ '', [Validators.required] ],
      company_name:[ '', [Validators.required] ],
      biz_no:[ '', [Validators.required, Validators.pattern('^[0-9-]{10,12}$')] ],
      biz_no_check:[ null, [Validators.required] ],
      ceo_name:[ '', [Validators.required] ],
      hp1: [ '010', [Validators.required] ],
      hp2: [ '', [Validators.required, Validators.maxLength(4), Validators.pattern('^[0-9]{3,4}$')] ],
      hp3: [ '', [Validators.required, Validators.maxLength(4), Validators.pattern('^[0-9]{4}$')] ],
      email1: [ '', [Validators.required, Validators.pattern('^[a-zA-Z0-9._-]*$')] ],
      email2: [ '', [Validators.required, Validators.pattern('^[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,6}$')] ],
      email3: [ '' ],
      zipcode: [ '', [Validators.required] ],
      address: [ '', [Validators.required] ],
      address_detail: [ '', [] ],
      agree:[ null, [Validators.required] ],
      files:[ [], [] ]
    });

    this.form.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.form, this.formErrors );
    });
  };

  /*******************************************************************************
    설  명 : 데이터 로딩 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit(): void {

    // 업로드 허용 파일 설정
    var filetype: string[] = ['image', 'doc'];

    // 업로더 생성
    this.uploader = new FileUploader({
      url: URL,
      itemAlias: 'file',
      maxFileSize: 50 * (1024 * 1024), // 최대 업로드 허용 용량
      allowedFileType: filetype // 허용 업로드 파일 타입
    });

    // 파일업로드 설정
    this.uploader.onAfterAddingFile = (file) => {
      file.withCredentials = true;
    };

    // 업로드 용량 초과시 처리
    this.uploader.onWhenAddingFileFailed = (item: FileLikeObject, filter: any, options: any) => {
      if( filter.name == 'fileSize' ) {
        this.toastrService.error( '파일 업로드 용량(50MB)을 초과하였습니다.', '파일 업로드');
      } else if( filter.name == 'fileType' ) {
        this.toastrService.error( '허용되는 업로드 파일 타입이 아닙니다.', '파일 업로드');
      }

      // indicator 표시 숨김
      setTimeout (() => {
        this.restful.indicator.next(false);
      });
    };


    // 파일업로드 완료시 처리
    this.uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      response = $.parseJSON( response );

      if( response.result ) {
        this.toastrService.success( response.message, '');

        this.form.patchValue( {
          logo_url: config.baseUrl + response.url,
          logo_origin: response.origin_filename
        });

        this.imageList.push(
          {
            filename: response.filename,
            origin_filename: response.origin_filename,
            size: response.size,
            filepath: response.url,
            thumbnail_org: response.thumbnail_org,
            thumbnail_path: response.thumbnail_path,
            url: this.baseURL + response.url
          }
        );
          
        this.form.patchValue({
          files: this.imageList
        });

      } else {
        this.toastrService.error( response.message, '');
      }

      // indicator 표시 숨김
      this.restful.indicator.next(false);
    };
  }

  /*******************************************************************************
    설  명 : 파일 변경시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  handleUploadFileChanged(event) {
    this.uploader.clearQueue();

    // 파일 없을 경우 return false;
    if( event.target.files.length < 1 ) return false;

    // 전체 허용 용량 검증

    const files: File[] = event.target.files;
    const filteredFiles: File[] = [];
    for(const f of files) {
      filteredFiles.push(f);
    }

    const options = null;
    const filters = null;

    this.uploader.addToQueue(filteredFiles, options, filters);

    // indicator 표시
    this.restful.indicator.next(true);

    this.uploader.uploadAll();
  }

  /*******************************************************************************
    설  명 : 확장명 가져오기
    입력값 : filename
    리턴값 : ext
  *******************************************************************************/
  getExt( filename ) {
    if( typeof filename == 'undefined' ) return '';
    else return filename.substr(filename.lastIndexOf('.') + 1);
  }

  /*******************************************************************************
    설  명 : 콤마 표시
    입력값 : 숫자
    리턴값 : 콤마 숫자
  *******************************************************************************/
  getComma( num ) {
    var str = String(num);
    return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
  }

  /*******************************************************************************
    설  명 : 파일 삭제
    입력값 : 파일
    리턴값 : 없음
  *******************************************************************************/
  deleteFile( file: any, index ) {
    if( confirm("선택하신 파일을 삭제하시겠습니까?") ) {
      let tmp = this.form.controls.files.value;

      tmp.splice( index, 1 );

      this.form.patchValue({files: tmp});
    }
  }

  /*******************************************************************************
    설  명 : 파일 삭제
    입력값 : 업로드 파일 정보
    리턴값 : 없음
  *******************************************************************************/
  setDeleteUploadFile( upload: any, index: any ) {
    if( confirm("선택하신 파일을 삭제하시겠습니까?") ) {
      /*
      this.sMemberService.setDeleteUploadFile( upload.seq ).then( response => {
        if( response.ResultCode ) {
          let uploadData = this.form.controls.upload.value;
          uploadData.splice( index, 1 );
          this.form.patchValue(uploadData);

          this.toastrService.success( response.ResultMessage, '');
        } else {
          this.toastrService.error( response.ResultMessage, '');
        }
      });
      */
    }
  }

  /*******************************************************************************
    설  명 : 업로드 된 파일 명이 이미지 인지 체크
    입력값 : 확장명
    리턴값 : true / false
  *******************************************************************************/
  checkImage( ext: string ) {
    let extArray = ['jpg', 'jpeg', 'gif', 'bmp', 'png'];

    if( extArray.indexOf( ext.toLowerCase() ) == -1 ) return false;
    else return true;
  }

  /*******************************************************************************
    설  명 : 사업자번호 중복 검사
  *******************************************************************************/
  checkBizNoDuplicate() {
    this.utilService.makeFieldDirtyAndUpdateErrors(this.form, this.formErrors, 'biz_no');

    if( this.form.get('biz_no').valid ) {
      this.sMemberService.checkBizNoDuplicate( this.form.get( 'biz_no' ).value ).then( response => {
        if( response.ResultCode ) {
          this.form.get( 'biz_no_check' ).setValue( true );
          this.toastrService.success( response.ResultMessage, '사업자번호 중복확인' );
        } else {
          this.form.get( 'biz_no_check' ).setValue( null );
          this.toastrService.error( response.ResultMessage, '사업자번호 중복확인' );
        }
      });
    }
  }

  /*******************************************************************************
    설  명 : 아이디 변경시 중복 검사 초기화
  *******************************************************************************/
  setBizNoCheckInit() {
    this.form.get('biz_no_check').setValue( null );
  }

  /*******************************************************************************
    설  명 : 신청하기 처리
  *******************************************************************************/
  submit() {
    this.utilService.makeFormDirtyAndUpdateErrors(this.form, this.formErrors);

    if( this.form.valid ) {
      this.sMemberService.setMemberTransitionApplication( this.form ).then(response => {
        if( response.ResultCode == true ) {
          this.toastrService.success(response.ResultMessage, '');
          
          this.buildForm();
          this.cancel();
        } else {
          this.toastrService.error(response.ResultMessage, '');
        }
      })
      .catch(response => {
        this.utilService.handleFormSubmitError(response, this.form, this.formErrors);
      });
    }
  }

  /*******************************************************************************
    설  명 : 취소
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  cancel() {
    this.currPage = 'main';

    this.top();
  }

  /*******************************************************************************
    설  명 : 상사회원 OR 프랜차이즈
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setCurrPage(value : any) {
    this.currPage = value;

    if( value == 'sub1' ) {
      this.form.patchValue({ application_gbn: '0002' });
    } else if( value == 'sub2' ) {
      this.form.patchValue({ application_gbn: '0007' });
    } else {
      this.form.patchValue({ application_gbn: '' });
    }
  }

  /*******************************************************************************
    설  명 : 가입동의 동의여부 체크
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setAgree(event : any) {
    let checked = ( event.target.checked ) ? true : null;

    this.form.get('agree').setValue(checked);
  }
  
  /*******************************************************************************
    설  명 : 이메일 주소 선택 시
  *******************************************************************************/
  changeEmail(event, element) {
    this.form.get(element).setValue(event.target.value);
    if( event.target.value == "" ) {
      if( element == "email2" ) this.inputEmail2.nativeElement.focus();
    }
  }

  /*******************************************************************************
    설  명 : 다음 우편번호찾기 API
  *******************************************************************************/
  setDaumAddressApi(data) {
    this.form.patchValue({
      zipcode: data.zip,
      address: data.addr
    });

    this.addressDetail.nativeElement.focus();
  }
  
  /*******************************************************************************
    설  명 : 화면 맨 위로 이동
  *******************************************************************************/
  top() {
    $('html, body').animate({
      scrollTop : 0
    }, 0);
  }

}
