<ng-container *ngIf="isMobileApp === false">
  <app-header></app-header>
</ng-container>

<ul class="menu clearfix" *ngIf="isMobileApp === false">
  <li><a routerLink="/company/info">회사소개</a></li>
  <li><a routerLink="/company/history">회사연혁</a></li>
  <li><a routerLink="/company/society">동호회등록</a></li>
  <li><a routerLink="/company/supply" class="on">입점안내</a></li>
  <li><a routerLink="/company/story">창업스토리</a></li>
  <li><a routerLink="/company/contact">가맹문의</a></li>
  <li><a routerLink="/company/policy/service">이용약관</a></li>
</ul>
 
<ul class="menu menu-mobile clearfix" *ngIf="isMobileApp === true">
  <li><a routerLink="/mobile/company/info">회사소개</a></li>
  <li><a routerLink="/mobile/company/history">회사연혁</a></li>
  <li><a routerLink="/company/society">동호회등록</a></li>
  <li><a routerLink="/mobile/company/supply" class="on">입점안내</a></li>
	<li><a routerLink="/mobile/company/story">창업스토리</a></li>
	<li><a routerLink="/mobile/company/contact">가맹문의</a></li>
  <li><a routerLink="/mobile/company/policy/service">이용약관</a></li>
</ul>

<div style="margin-top: 37px" *ngIf="isMobileApp === true"></div>

<section class="contents">

  <div class="main-img">
    <img src="/assets/images/company_supply.jpg">
  </div>

  <div class="section inner">
    <div>
      <p class="txt1">바이크마트는 여러분의 소중한 제안을 기다립니다.</p>
      <p class="txt2">사업제휴, 대리점, 협력점, 상품건의 등 제안이 있으신가요?</p>
      <p class="txt2">양식을 작성하여 보내주시면, 검토 후 최대한 빠른 시일 내에 연락드리겠습니다.</p>
      <p class="txt2">바이크마트는 언제나 파트너 여러분과 함께 나아가겠습니다.</p>
    </div>

    <!-- <div class="div1">
      <div><span class="dept">전략소싱팀</span>하준성 팀장</div>
	    <p>bikemart@daum.net</p>
	    <a href="/assets/download/2020_bikemart_joinshop_form.docx" download="">신청양식 다운받기</a>
	  </div> -->

    <article class="article">
      <div class="title">입점문의</div>
      <form [formGroup]="form">
        <div>
          <p>이름</p>
          <input type="text" formControlName="name" placeholder="" [ngClass]="{formErrors:formErrors['name']}" />
          <div *ngIf="formErrors['name']" class="error-message-contact">{{formErrors['name']}}</div>
        </div>
        <div>
          <p><span>*</span>연락처</p>
          <input type="text" formControlName="phone" placeholder="공백은 제외하고 입력" [ngClass]="{formErrors:formErrors['phone']}" />
          <div *ngIf="formErrors['phone']" class="error-message-contact">{{formErrors['phone']}}</div>
        </div>
        <div>
          <p>이메일</p>
          <input type="text" formControlName="email" placeholder="" [ngClass]="{formErrors:formErrors['email']}" />
          <div *ngIf="formErrors['email']" class="error-message-contact">{{formErrors['email']}}</div>
        </div>
        <div>
          <p style="vertical-align: top;"><span>*</span>문의내용</p>
          <textarea formControlName="content" placeholder="" style="height: 160px;" [ngClass]="{formErrors:formErrors['content']}"></textarea>
          <div *ngIf="formErrors['content']" class="error-message-contact">{{formErrors['content']}}</div>
        </div>
        <div>
          <input type="checkbox" id="개인정보동의" formControlName="agreement">
          <label for="개인정보동의">
            <a [routerLink]="isMobileApp === false ? '/company/policy/privacy' : '/mobile/company/policy/privacy'">개인정보취급방침</a> 에 동의합니다.
          </label>
        </div>
        <button type="submit" (click)="submit()">문의하기</button>
      </form>
    </article>

    <section class="table-div clearfix">
      <div class="fl">
        <div class="table-header">
          <h4>입점문의 현황</h4>
          <!-- <a routerLink="/company/supply">입점문의하기</a> -->
        </div>
        <table class="list-table">
          <colgroup class="pc-view">
            <col style="width: 30%">
            <col style="width: 30%">
            <col style="width: 40%">
          </colgroup>

          <tbody>
            <ng-container *ngFor="let row of contactList">
            <tr *ngIf="row.type == '2001'">
              <td>{{row.type_name}}</td>
              <td>{{row.name}}</td>
              <td>{{row.tel}}</td>
            </tr>
            </ng-container>
          </tbody>
        </table>
      </div>

      <div class="fr">
        <div class="table-header">
          <h4>가맹문의 현황</h4>
          <div class="fr" *ngIf="isMobileApp === false">
            <a routerLink="/company/contact">가맹문의하기</a>
          </div>

          <div class="fr" *ngIf="isMobileApp === true">
            <a routerLink="/mobile/company/contact">가맹문의하기</a>
          </div>
        </div>
        <table class="list-table">
          <colgroup class="pc-view">
            <col style="width: 30%">
            <col style="width: 30%">
            <col style="width: 40%">
          </colgroup>

          <tbody>
            <ng-container *ngFor="let row of contactList">
              <tr *ngIf="row.type == '1001'">
                <td>{{row.type_name}}</td>
                <td>{{row.name}}</td>
                <td>{{row.tel}}</td>
              </tr>
              </ng-container>
          </tbody>
        </table>
      </div>
    </section>

  </div>
</section>

<ng-container *ngIf="isMobileApp === false">
  <app-footer></app-footer>
</ng-container>
