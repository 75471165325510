/*******************************************************************************
  설  명 : 마이페이지 - 구매상세내역
  생성일 : 2020-09-10
  생성자 : 송영석
*******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { Router,ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';

import { SOrderService } from '@app/service/order.service';

import { MypageSpecificationsTransactionComponent } from '@page/mypage/mypage-specifications-transaction/mypage-specifications-transaction.component';
import { MypageOrderDetailCashreceiptComponent } from '@page/mypage/mypage-order/mypage-order-detail/mypage-order-detail-cashreceipt/mypage-order-detail-cashreceipt.component';
import { MypageOrderStatementComponent } from '@page/mypage/mypage-order/mypage-order-statement/mypage-order-statement.component';


const options: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  size: '885',
  centered: true,
  windowClass:'modal-fadeInDown'
};

const optionLG: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  size: 'lg',
  centered: true,
  windowClass:'modal-fadeInDown'
};

const options1: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  size: '480',
  centered: true,
  windowClass:'modal-fadeInDown'
};

@Component({
  selector: 'app-mypage-order-detail',
  templateUrl: './mypage-order-detail.component.html',
  styleUrls: ['./mypage-order-detail.component.scss']
})
export class MypageOrderDetailComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  public detailList: any = [];
  public payList: any = [];
  public deliveryList: any = [];

  public orderInfo: any = {};
  public payCashInfo: any = {};

  private params: any = {
    orderSeq: '',
    pageNo: '',
    pageRow: '',
    sdate: '',
    edate: '',
    searchdate_group: '',
    order_status: ''
  }

  public total_delivery: any = 0;
  public total_order: any = 0;
  public discount_amt: any = 0;
  public inicisSeq: any = null;
  public tid: any = '';
  
  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private modalService: NgbModal,   
    private router: Router,
    private activatedRoute: ActivatedRoute, 
    private sOrderService: SOrderService,
    private toastrService: ToastrService,
  ) { }

  /*******************************************************************************
    설  명 : 데이터 로딩 처리 수정자:2/23 김종현
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      this.params.orderSeq = ( typeof params.orderSeq == 'undefined' || params.orderSeq == '' ) ? '' : params.orderSeq;
      this.params.pageNo = ( typeof params.pageNo == 'undefined' || params.pageNo == '' ) ? '1' : params.pageNo;
      this.params.pageRow = ( typeof params.pageRow == 'undefined' || params.pageRow == '' ) ? '10' : params.pageRow;
      this.params.sdate = ( typeof params.sdate == 'undefined' || params.sdate == '' ) ? '' : params.sdate;
      this.params.edate = ( typeof params.edate == 'undefined' || params.edate == '' ) ? '' : params.edate;
      this.params.searchdate_group = ( typeof params.searchdate_group == 'undefined' || params.searchdate_group == '' ) ? 1 : params.searchdate_group;
      this.params.order_status = ( typeof params.order_status == 'undefined' || params.order_status == '' ) ? '' : params.order_status;

      this.getOrderDetail();
    });
  }

  /*******************************************************************************
    설  명 : 주문 상세 내역 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getOrderDetail() {
    this.sOrderService.getOrderDetail( this.params.orderSeq ).then( response => {
      if( response.ResultCode ) {
        this.orderInfo = response.data;
        this.detailList = response.detail;
        this.payList = response.pay;
        this.deliveryList = response.delivery;

        this.isInicis();
        this.checkOrderPayCash();
        this.setTotalDelivery();
      } else {
        this.detailList = [];
        this.toastrService.error( response.ResultMessage, '');
      }
    });
  }

  /*******************************************************************************
    설  명 : 금액 세팅
  *******************************************************************************/  
  setTotalDelivery() {
    this.detailList.map( rows => {
      this.total_order += rows.order_amt
    });

    this.total_delivery = ( this.total_order > 0 ) ? this.orderInfo.delivery_price : 0;
  }

  /*******************************************************************************
    설  명 : 신용거래 조회
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/  
  isInicis() {
    this.sOrderService.isInicis( this.orderInfo.seq ).then( response => {
      if ( response.ResultCode ){
        this.inicisSeq = response.seq;
        this.tid = response.tid;
      }
    });
  }

  /*******************************************************************************
    설  명 : 신용거래 조회
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/  
  checkOrderPayCash() {
    this.sOrderService.checkOrderPayCash( this.orderInfo.seq ).then( response => {
      if ( response.ResultCode ){
        this.payCashInfo = response.data;
      }
    });
  }
  
  /*******************************************************************************
    설  명 : 거래명세서
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/  
  specificationsTransaction() {
    const modalRef = this.modalService.open(MypageSpecificationsTransactionComponent, options);

    modalRef.result.then((result) => {
    }, (reason) => {
    });  
  }    

  /*******************************************************************************
    설  명 : 찜 리스트 상품 상세보기로 가기
    입력값 : 없음
    리턴값 : 없음
  ******************************************************************************/
  goProduct(productSeq) {
    this.router.navigate(
      ['/product/info'],
      {
        relativeTo: this.activatedRoute,
        queryParams: {productSeq: productSeq},
        queryParamsHandling: 'merge', // remove to replace all query params by provided
      }
    );
  }


  /*******************************************************************************
    설  명 : 배송조회
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/  
  delivery( row: any ) {
    const deliveryCompany = row.delivery_company;
    const deliveryNo = row.delivery_no.replace(/-/g,'');

    if ( deliveryCompany == "1004" ){
      var gourl = "http://service.epost.go.kr/trace.RetrieveRegiPrclDeliv.postal?sid1=" + deliveryNo;
      window.open(gourl, 'popup','scrollbars=yes, width=1200, height=900');
    } else if ( deliveryCompany == "1001" ){
      var gourl = "http://d2d.ilogen.com/d2d/delivery/invoice_tracesearch_quick.jsp?slipno=" + deliveryNo;
      window.open(gourl, 'popup','scrollbars=yes, width=1200, height=900');
    } else if ( deliveryCompany == "1002" ){
      var gourl = "https://www.hanjin.co.kr/kor/CMS/DeliveryMgr/WaybillResult.do?mCode=MN038&schLang=KR&wblnumText2=" + deliveryNo;
      window.open(gourl, 'popup','scrollbars=yes, width=1200, height=900');
    } else if ( deliveryCompany == "0010" ){
      var gourl = "https://www.doortodoor.co.kr/parcel/doortodoor.do?fsp_action=PARC_ACT_002&fsp_cmd=retrieveInvNoACT&invc_no=" + deliveryNo;
      window.open(gourl, 'popup','scrollbars=yes, width=1200, height=900');
    } else if ( deliveryCompany == "1003" ){
      var gourl = " https://www.lotteglogis.com/home/reservation/tracking/linkView?InvNo=" + deliveryNo;
      window.open(gourl, 'popup','scrollbars=yes, width=1200, height=900');
    } else {
      this.toastrService.error('택배사 코드를 다시 확인하시기 바랍니다.');
    }
  }

  /*******************************************************************************
    설  명 : 현금 영수증 신청페이지
    입력값 : 없음
    리턴값 : 없음
  ******************************************************************************/
  goCashReceipt() {
    const modalRef = this.modalService.open(MypageOrderDetailCashreceiptComponent, options1);

    modalRef.componentInstance.orderInfo = this.orderInfo;
    modalRef.componentInstance.detail = this.detailList;
    modalRef.componentInstance.pay = this.payList;

    modalRef.result.then((result) => {
      if( result ) {
      }
    }, (reason) => {
    });  
  }

  /*******************************************************************************
    설  명 : 현금 영수증 신청 가능한지 체크
    입력값 : 없음
    리턴값 : 없음
  ******************************************************************************/
  checkCashReceipt(data: any) {
    if( data === null || !data.pay_date || data.pay_date == null ) return false;

    return moment().diff(moment(data.pay_date), 'days') <= 15 ? true : false;
  }

  /*******************************************************************************
    설  명 : 주문내역서 연결
    입력값 : 없음
    리턴값 : 없음
  ******************************************************************************/
  getOrderList() {
    const modalRef = this.modalService.open(MypageOrderStatementComponent, optionLG);

    modalRef.componentInstance.orderInfo = this.orderInfo;
    modalRef.componentInstance.detail = this.detailList;
    modalRef.componentInstance.pay = this.payList;

    modalRef.result.then((result) => {
    }, (reason) => {
    });  
  }

  /*******************************************************************************
    설  명 : 영수증조회 연결
    입력값 : 없음
    리턴값 : 없음
  ******************************************************************************/
  getReceipt() {
    let gourl = 'https://iniweb.inicis.com/DefaultWebApp/mall/cr/cm/mCmReceipt_head.jsp?noTid=' + this.tid + '&noMethod=1';
    window.open(gourl, 'popup','scrollbars=yes, width=1200, height=900');
  }

  /*******************************************************************************
    설  명 : 목록으로
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/  
  goList() {
    this.params.orderSeq = '';

    this.router.navigate(
      ['/mypage/order'],
      {
        relativeTo: this.activatedRoute,
        queryParams: this.params,
        queryParamsHandling: '', // remove to replace all query params by provided
      });
  }
  
}
