import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { SBoardService } from '@app/service/board.service';

import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-mypage-myqna-view',
  templateUrl: './mypage-myqna-view.component.html',
  styleUrls: ['./mypage-myqna-view.component.scss']
})
export class MypageMyqnaViewComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  public boardInfo: any = {};

  public params: any = {
    type: 'list',
    board_id: 'question',
    group_id: '전체',
    row: 10,
    no: 1,
    group: 'subject',
    text: ''
  }
  public linecnt: any = [];
  public boardList = [];
  public boardcontent: any = {};


  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
		private sBoardService: SBoardService,
    private toastrService: ToastrService,
  ) { }


  /*******************************************************************************
    설  명 : 데이터 로딩 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      this.params.board_id = ( typeof params.board_id == 'undefined' || params.board_id == '' ) ? 'question' : params.board_id;
      this.params.board_seq = ( typeof params.board_seq == 'undefined' || params.board_seq == '' ) ? '' : params.board_seq;
      this.params.group_id = ( typeof params.group_id == 'undefined' || params.group_id == '' ) ? '전체' : params.group_id;
      this.params.type = ( typeof params.type == 'undefined' || params.type == '' ) ? '' : params.type;
      this.params.row = ( typeof params.row == 'undefined' || params.row == '' ) ? 10 : parseInt(params.row);
      this.params.no = ( typeof params.no == 'undefined' || params.no == '' ) ? 1 : parseInt(params.no);
      this.params.group = ( typeof params.group == 'undefined' || params.group == '' ) ? 'subject' : params.group;
      this.params.text = ( typeof params.text == 'undefined' ) ? '' : params.text;

      this.getBoardInfo( this.params.board_id );
    });
  }

  /*******************************************************************************
    설  명 : 게시판 정보 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  async getBoardInfo( board_id ) {
    await this.sBoardService.getBoardInfoId( board_id ).then( response => {
      if( response.data == null ) {
        this.toastrService.error( "존재하지 않는 게시판입니다.", "게시판 에러" );

        this.router.navigate( ['/main'] );
        return false;
      }

      this.boardInfo = response.data;

      if( this.params.type == '' ){
        switch( this.boardInfo.lvt_default ){
          default: case 'L': this.params.type = 'list';
        }
      } // 게시판 내용 부분 faq, question, list로 분류한 뒤 요청에 따라 불러옴.

      if( this.params.type == 'list' ) this.linecnt = this.boardInfo.linecnt_list.split('/');

      this.getBoardList();
    });
  }

  /*******************************************************************************
    설  명 : 게시판 리스트 정보 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getBoardList() {
    this.sBoardService.getBoardList( this.params ).then( response => {
      this.boardList = response.data;
      this.boardInfo.total_count = response.total;
      this.getContext();
    });
  }

  /*******************************************************************************
    설  명 : 게시판 내용 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getContext() {
    this.boardList.map( rows => {
      if ( this.params.board_seq == rows.board_seq ) {
        this.boardcontent = rows;
      }
    });
  }

  /*******************************************************************************
    설  명 : 목록으로
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  goList() {
    this.router.navigate(
      ['/mypage/myqna'],
      {
        relativeTo: this.activatedRoute,
        queryParamsHandling: 'merge', // remove to replace all query params by provided
      }
    );
  }
}
