<div class="goods-card">
  <div class="sold-out">[관리자] 일시품절</div>
  <a routerLink="/product/info" [queryParams]="{productSeq: productInfo.seq, eventSeq: productInfo.event_seq}" [target]="linkTarget">
    <ng-container *ngIf="productInfo.is_freeDelivery == '1';">
      <div class="free-delivery">무료배송</div>
    </ng-container>
    <div class="goods-img">
      <img [src]="productInfo.thumbnail_url" alt="{{productInfo.product_name | nohtml}}" />
    </div>
    <div class="goods-info">
      <p class="kind1">{{productInfo.parent_category_name}} > {{productInfo.category_name}}</p>
      <p class="kind2">{{productInfo.brand_name}}</p>
      <p class="name" [innerHTML]="productInfo.product_name | safe:'html'"></p>
      <ng-container *ngIf="productInfo.is_mach == '1';">
        <div class="mach">오늘출발</div>
      </ng-container>
      <p class="price">
        <!-- <span>{{productInfo.consumer_price | number}}원</span> -->
        <span class="sale" *ngIf="comparePrice(productInfo.display_price, productInfo.consumer_price)">{{ (productInfo.display_price - productInfo.consumer_price) / productInfo.display_price | percent }}</span>
        <span>{{ productInfo.consumer_price | number }}원</span>
        <del *ngIf="comparePrice(productInfo.display_price, productInfo.consumer_price)">{{ productInfo.display_price | number }}원</del>
        
      </p>
      
    </div>
  </a>
</div>