<app-header></app-header>

<p class="search_state" *ngIf="!search_mode">검색 조건이 설정되어 있지 않습니다.<br>categoryCode 또는 searchText 값이 필요합니다.</p>
<div class="inner" *ngIf="search_mode">
  <div style="position: relative;">
    <table class="category">
      <colgroup>
        <col class="col1">
        <col>
      </colgroup>
      <tbody>
        <tr *ngIf="category_list.length > 0">
          <th class="title">카테고리</th>
          <td class="list">
            <ul class="clearfix" [ngClass]="{open:moreView==1}">
              <li *ngFor="let item of category_list; let idx = index;">
                <!-- <p [ngClass]="{active:menuSelected == idx+1}" (click)="select_category(idx+1, item);">{{item.category_name}}</p> -->
                <p [ngClass]="{active: active('category', idx, item)}" (click)="select_category(idx+1, item);">{{item.category_name}}</p>
              </li>
            </ul>
            <button class="more-btn mobile-view" (click)="moreView==1?moreView=0:moreView=1">더보기</button>
          </td>
        </tr>
        <tr *ngIf="brand_category_list.length > 0">
          <th class="title">카테고리</th>
          <td class="list">
            <ul class="clearfix" [ngClass]="{open:moreView==1}">
              <li *ngFor="let item of brand_category_list; let idx = index;">
                <!-- <p [ngClass]="{active:menuSelected == idx+1}" (click)="select_category(idx+1, item);">{{item.category_name}}</p> -->
                <p [ngClass]="{active: active('category', idx, item)}" (click)="select_brand_category(idx+1, item);">{{item.category_name}}</p>
              </li>
            </ul>
            <button class="more-btn mobile-view" (click)="moreView==1?moreView=0:moreView=1">더보기</button>
          </td>
        </tr>
        <tr *ngIf="brand_list.length > 0">
          <th class="title">브랜드</th>
          <td class="list">
            <ul class="brand clearfix" [ngClass]="{open:moreView==2}">
              <li *ngFor="let item of brand_list; let idx = index;">
                <p [ngClass]="{active: active('brand', idx, item)}" (click)="select_brand(idx, item);">{{item.brand_name}}<span>{{item.total}}</span></p>
              </li>
            </ul>
            <button class="more-btn mobile-view" (click)="moreView==2?moreView=0:moreView=2">더보기</button>
          </td>
        </tr>
        <ng-container *ngIf="showColorSizeCheck()">
        <!-- <tr>
          <th class="title">사이즈</th>
          <td class="list">
            <ul class="clearfix" [ngClass]="{open:moreView==3}">
              <li *ngFor="let item of size_list; let idx = index;">
                <p [ngClass]="{active: active('size', idx, item)}" (click)="select_size(idx, item);">{{item.size_name}}</p>
              </li>
            </ul>
            <button class="more-btn mobile-view" (click)="moreView==3?moreView=0:moreView=3">더보기</button>
          </td>
        </tr> -->
        <tr>
          <th class="title">색상</th>
          <td class="list">
            <ul class="clearfix" [ngClass]="{open:moreView==4}">
              <li class="color" *ngFor="let item of color_list; let idx = index;" [ngClass]="{active: active('color', idx, item)}" [ngStyle]="{'background': item.color_ename}" (click)="select_color(idx, item);"><!--<p [ngClass]="{active:colorSelected == idx+1}"></p>-->&#10003;</li>
            </ul>
            <button class="more-btn mobile-view" (click)="moreView==4?moreView=0:moreView=4">더보기</button>
          </td>
        </tr>
        </ng-container>
      </tbody>
    </table>
  </div>

  <div class="selected-category clearfix" *ngIf="root_category.code">
    <div class="title pc-view">선택한 옵션</div>
    <div class="selected">
      <ul class="clearfix">
        <li *ngFor="let item of choice_list" (click)="delete_choice(item);">{{item.value}}<span></span></li>
      </ul>
      <div class="reset" (click)="delete_choice('all');">검색초기화</div>
    </div>
  </div>

  <div class="path pc-view" *ngIf="search_mode == 1">
    <img [src]="main_icon()" [alt]="root_category.name">
    <span class="menu1">{{root_category.name}}</span>
    <span class="menu2">{{sub_category || "전체"}}</span>
    <!--<span class="brand" *ngIf="choice.brand_name">{{choice.brand_name}}</span>-->
  </div>

  <p class="search_state" *ngIf="is_search == true && !product_total">검색 결과가 없습니다.</p>

  <div class="result clearfix" *ngIf="product_total">
    <div class="cnt pc-view"><span>{{product_total | number}}</span>개의 상품이 있습니다.</div>
    <ul class="sort clearfix">
      <li *ngFor="let item of [
      {name: '추천순', value: 1}, {name: '최신순', value: 2}, {name: '높은가격순', value: 3}, {name: '낮은가격순', value: 4}, {name: '할인율 높은순', value: 5}
      ]" [ngClass]="{active:searchParams.sort == item.value}" (click)="select_sort(item.value)">{{item.name}}</li>
    </ul>
    <div class="view-btn mobile-view" [ngClass]="{type1:viewType==2,type2:viewType==3}" (click)="viewType==3?viewType=1:viewType=viewType+1"></div>
  </div>

  <div class="path path1 mobile-view" *ngIf="search_mode == 1">
    <span class="menu1">{{root_category.name}}</span>
    <span class="menu2">{{sub_category || "전체"}}</span>
    <!--<span class="brand" *ngIf="choice.brand_name">{{choice.brand_name}}</span>-->
  </div>

  <!-- <div class="goods-list inner clearfix">
    <div class="goods-card"  [ngClass]="{type1:viewType==2,type2:viewType==3}" *ngFor='let key of [1,2,3,4]'>
      <div class="sold-out">[관리자] 일시품절</div>
      <a routerLink="/product/info">
        <div class="goods-img">
          <img src="/assets/images/229589_1.jpg">
        </div>
        <div class="goods-info">
          <p class="code">상품통합코드 : 200321696</p>
          <p class="pr-text">NEW 야간라이딩 필수품!</p>
          <p class="name">GRAVITY FLASH LIGHT 바람막이-GRAY</p>
          <p class="price"><span class="sale">51%</span><span>58,000원</span><del>118,000원</del></p>
          <p class="mileage">적립 <span>500</span> M</p>
        </div>
      </a>
    </div>
  </div> -->

  <div class="goods-list inner clearfix" *ngIf="product_total">
    <div class="goods-card" [ngClass]="{type1:viewType==2,type2:viewType==3}" *ngFor="let item of product_list">
      <div class="sold-out" [ngClass]="{on:item.isOption == 0}">일시품절</div>
      <a routerLink="/product/info" [queryParams]="{productSeq: item.seq, eventSeq: item.event_seq}" [target]="linkTarget">
        <div class="goods-img">
          <img [src]="item.thumbnail_url" alt="item.product_name" />
        </div>
        <div class="goods-info">
          <p class="code">상품통합번호 : {{item.seq}}</p>
          <p class="prev-text" [innerHTML]="item.comment | nohtml"></p>
          <p class="name" [innerHTML]="item.product_name | safe: 'html'"></p>
          <p class="next-text" [innerHTML]="item.product_overview | nohtml"></p>
          <p class="price">
            <ng-container *ngIf="item.brand_name == 'HJC'; else notHJC">
              <span>{{ item.unit_price | number }}원</span>
            </ng-container>
            <ng-template #notHJC>
              <span class="sale" *ngIf="comparePrice(item.display_price, item.consumer_price)">{{ (item.display_price - item.consumer_price) / item.display_price | percent }}</span>
              <span>{{ item.consumer_price | number }}원</span>
              <del *ngIf="comparePrice(item.display_price, item.consumer_price)">{{ item.display_price | number }}원</del>
            </ng-template>
          </p>
          <p class="mileage">적립 <span>{{ item.mileage | number }}</span> M</p>
          <ng-container *ngIf="item.is_mach == '1' && item.isOption != 0">
          <div class="mach"> 오늘출발 </div>
          </ng-container>
          <ng-container *ngIf="item.is_freeDelivery == '1' ">
            <div class="free-delivery"> 무료배송 </div>
            </ng-container>
          </div>
      </a>
    </div>
  </div>

	 <div class="pagination-area" *ngIf="product_total">
	   <ngb-pagination
	     [(page)]="searchParams.pageNo"
	     [pageSize]="searchParams.pageRow"
	     [collectionSize]="product_total"
	     [boundaryLinks]="true"
       (pageChange)="loadPage($event)"
       [maxSize]="pageMaxSize"
	     >
	     <ng-template ngbPaginationFirst>처음</ng-template>
	     <ng-template ngbPaginationLast>마지막</ng-template>
	     <ng-template ngbPaginationPrevious>이전</ng-template>
	     <ng-template ngbPaginationNext>다음</ng-template>
	   </ngb-pagination>
	 </div>
</div>

<app-footer></app-footer>