/*******************************************************************************
  설  명 : 마이페이지 -메뉴
  생성일 : 2020-09-10
  생성자 : 송영석
*******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { SMemberService } from '@app/service/member.service';
import { AuthService } from '@app/service/auth.service';


@Component({
  selector: 'app-mypage-menu',
  templateUrl: './mypage-menu.component.html',
  styleUrls: ['./mypage-menu.component.scss']
})
export class MypageMenuComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  menuOpen: boolean = false; // 모바일 메뉴 보이기

  public isLogIn: boolean;

  public isLogin$: Observable<boolean>; // 로그인여부확인

  public memberInfo: any = {};


  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private authService: AuthService,
    private memberService: SMemberService,

  ) { 
    // 로그인 여부 가져오기
    this.isLogin$ = this.authService.isLoggedIn;

    this.isLogin$.subscribe( login => {
      this.isLogIn = login;
    });

    this.authService.getLoginInfo.subscribe(data => {
      this.memberInfo = data;
    });
     
  }
  

  /*******************************************************************************
    설  명 : 데이터 로딩 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit(): void {

  }

}
