<div class="member-page">
  <div class="member-inner">
    <div class="logo">
      <a routerLink="/"><img src="/assets/images/logo1.png?date=20210913" alt="바이크마트 로고"></a>
    </div>

    <div>
      <div class="title">회원가입<span> 약관동의</span></div>

      <div class="agree-div" [formGroup]="memberAddForm">
        <div>
                바이크마트 사이트의 회원가입은 무료로 운영되고 있으며, 회원가입 하시면 제품 정보와  각종 이벤트에 우선적으로 참여하실 수 있습니다.<br/>
               회원가입 하신 후 더 편리하고 즐겁게 바이크마트 사이트를 이용하세요.
        </div>
        <div class="box" [innerHTML]="joinGuide[0]?.contents | safe: 'html'"></div>
        <div>아래의 약관을 잘 읽어보신 후 회원가입 신청을 해주시기 바랍니다.</div>

        <div class="title">이용약관<span class="red">(필수)</span></div>
        <div class="terms" [innerHTML]="joinGuide[1]?.contents | safe: 'html'"></div>
        <label class="check-txt">
          <input type="checkbox" formControlName="agree1" value="">이용약관을 읽었으며 내용에 동의합니다.
        </label>

        <div class="title">개인정보처리방침<span class="red">(필수)</span></div>
        <div class="terms" [innerHTML]="joinGuide[2]?.contents | safe: 'html'"></div>
        <label class="check-txt">
          <input type="checkbox" formControlName="agree2" value="">개인정보처리방침을 읽었으며 내용에 동의합니다.
        </label>

        <div class="title">구매대행 서비스 이용약관<span class="red">(필수)</span></div>
        <div class="terms" [innerHTML]="joinGuide[3]?.contents | safe: 'html'">

        </div>
        <label class="check-txt">
          <input type="checkbox" formControlName="agree3" value="">구매대행 서비스 이용약관을 읽었으며 내용에 동의합니다.
        </label>

        <div class="all-check" (click)="all_check()">
          <input type="checkbox" id="allCheck" name="all-check" formControlName="all_check">
          <label class="check-txt" for="allCheck" (click)="all_check()">
            위의 약관에 모두 동의
          </label>
        </div>
      </div>
      <button class="basic-btn red" (click)="memberinfo()">바이크마트 회원가입</button>
      <div class="line"></div>
      <a class="basic-btn naver-btn cp" (click)="login_naver()"><span>네이버 아이디로 가입</span></a>
      <a class="basic-btn kakao-btn cp" (click)="login_kakao()"><span>카카오 아이디로 가입</span></a>
      <a routerLink="#" class="basic-btn facebook-btn"><span>페이스북 아이디로 가입</span></a>
    </div>
    <div class="member-footer">
      <ul class="clearfix">
        <li><a routerLink="#" class="bold">이용약관</a></li>
        <li><a routerLink="#">개인정보처리방침</a></li>
        <li><a routerLink="#">고객센터 1544-5022</a></li>
      </ul>
      <div>Copyright © 2008 <span class="bold">bikemart.co.kr</span> ALL RIGHTS RESERVED.</div>
    </div>
  </div>
</div>