<app-header class="pc-view"></app-header>

<div class="inner clearfix">
  <!-- 왼쪽 마이페이지 메뉴 -->
  <app-mypage-menu></app-mypage-menu>
  <div class="mypage-contents">
    <div class="section order-list">
        <div class="title page-title">동호회 등록</div>

        <div class="guide">
            <div class="title">안내사항</div>
                    <p class="dot">·</p><p class="dot1">동호회 등록페이지는  현재 동호회 활동을 하고 계신 회원께서 자신의 동호회를 등록하는 페이지입니다.</p>
                    <p class="dot">·</p><p class="dot1">바이크마트 등록 동호회 혜택이 적용되기 위해서는 반드시 동호회를 등록해야합니다.</p>
                    <p class="dot">·</p><p class="dot1">하나의 동호회에만 등록이 가능합니다.</p>
                    <p class="dot">·</p><p class="dot1">동호회 변경을 원할 시는 내 동호회 메뉴에서 등록해제 하신 후 다시 동호회 등록을 해주세요.</p>
                    <p class="dot">·</p><p class="dot1">동호회의 가입은 해당 동호회의 대표자 또는 커뮤니티 링크를 통해 진행해 주십시요.</p>
        </div>
        <!-- <div class="title page-title"></div> -->
        <div class="mypage-search-area form-inline">
            <select (change)="selectedSociety($event)" class="form-control form-control-small ml10">
                <option value='' ng-selected="true">동호회 선택</option> <!-- selected 자동선택 기능 해결해야함-->
                <option *ngFor="let row of societyList" value="{{row.society_seq}}">{{row.society_name}}</option>
            </select>
            <button type="button" class="basic-btn sm-btn" (click)="registrationSociety()">등록</button>

        </div>
  
		        
    </div>
         
  </div>   
</div>

<app-footer></app-footer>