import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { ApiResponse } from '@app/service/api-response';
import { UtilService } from '@app/service/util.service';

import { SBoardService } from '@app/service/board.service';

import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-add',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.scss']
})

export class AddComponent implements OnInit {
  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  @Input() boardId: any;
  @Input() groupTab: any;
  @Input() rowData: any;

  errorResponse: ApiResponse;
  public boardWriteForm: FormGroup;
  public formErrors = {
    'board_seq': '',
    'board_id': '',
    'subject': '',
    'group_id': '',
    'contents': '',
  };


  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private formBuilder: FormBuilder,
    public sBoardService: SBoardService,
    private utilService: UtilService,
    private toastrService: ToastrService,
    public activeModal: NgbActiveModal,
  ) { 
    this.buildForm();
  }

  /*******************************************************************************
    설  명 : 폼 생성 수정자:2/19 김종현
  *******************************************************************************/
  buildForm(): void {
    this.boardWriteForm = this.formBuilder.group({
      board_seq: [""],
      board_id: ["", [Validators.required]],
      subject: ["", [Validators.required]],
      group_id: ["", [Validators.required]],
      contents: ["", [Validators.required]],
    });

    this.boardWriteForm.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors(this.boardWriteForm, this.formErrors);
    });
  };
  /*******************************************************************************
    설  명 : 데이터 로딩 처리 수정자:2/19 김종현
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit(): void {
    this.boardWriteForm.get('board_id').setValue( this.boardId );

    if( this.rowData != null && this.rowData != '' ) {
      this.boardWriteForm.patchValue( this.rowData );
    }
  }

  /*******************************************************************************
    설  명 : 문의하기 처리
  *******************************************************************************/
  submit() {
    this.utilService.makeFormDirtyAndUpdateErrors(this.boardWriteForm, this.formErrors);

    if( this.boardWriteForm.valid ) {
      this.sBoardService.setWrite( this.boardWriteForm )
      .then( response => {
        if( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, '');
        } else {
          this.toastrService.error( response.ResultMessage, '');
        }

        this.activeModal.close();
      })
      .catch(response => {
        this.errorResponse = response;
        this.utilService.handleFormSubmitError(this.errorResponse, this.boardWriteForm, this.formErrors);
      });
    }

    location.href = location.href;
  }

}
