<div class="mypage-header">
  <div class="header1">
    <!-- <div class="name"><span>{{memberInfo.name}}</span>({{memberInfo.grade_name}}) 님 환영합니다!</div> -->
    <div class="name"><span>{{memberInfo.name}}</span>님 환영합니다!</div>
    <div *ngIf="memberInfo.buy_grade_code" class="grade-div">
      <div class="grade" [ngClass]="{gradef:memberInfo.buy_grade_code=='1000',gradet:memberInfo.buy_grade_code=='2000',gradeg:memberInfo.buy_grade_code=='3000',graded:memberInfo.buy_grade_code=='4000',gradev:memberInfo.buy_grade_code=='5000'}"></div>
      <div class="rate rateg mr10">최대<span>{{memberInfo.display_dc_rate || 0}}</span> % 할인</div>
      <a routerLink="/guide/membership/grade">회원혜택보기 ></a>
    </div>
    <div class="mileage clearfix">
      <a routerLink="/mypage/mileage">마일리지 <span>{{remainedMileage | number}} M</span></a>
      <a routerLink="/mypage/coupon">쿠폰 <span>{{availableCoupon | number}}장</span></a> 
    </div>
  </div>
  <ul class="header2">
    <li>
      <p>입금대기중</p>
      <a>{{zero}}</a>
    </li>
    <li>
      <p>결제완료</p>
      <a>{{one}}</a>
    </li>
    <li>
      <p>배송준비중</p>
      <a>{{two}}</a>
    </li>
    <li>
      <p>배송중</p>
      <a>{{eight}}</a>
    </li>
    <li>
      <p>배송완료</p>
      <a>{{nine}}</a>
    </li>
  </ul>
  
</div>
