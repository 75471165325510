<div class="modal-header">
  <h4 class="modal-title fl">일반회원 전환</h4>
  <button class="close" (click)="activeModal.dismiss()">&times;</button>
</div>

<div class="modal-body">
  <div class="txt1">[{{this.dormant_data.name}}] 님은 장기간 로그인하지 않아 휴면회원으로 전환되었습니다.</div>
  <div class="txt2">휴면회원 전환일자 : {{this.dormant_data.trans_date}}</div>
  <div class="txt2">휴면회원 해제를 원하시면 아래 버튼을 클릭하시기 바랍니다.</div>

  <div class="btn-area">
    <button class="btn1 mr10" (click)="activeModal.dismiss()">취소</button>
    <button class="btn1 red" (click)="submit()">해제하기</button>
  </div>
</div>

