import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
@Component({
  selector: 'app-society-benefit',
  templateUrl: './society-benefit.component.html',
  styleUrls: ['./society-benefit.component.scss']
})
export class SocietyBenefitComponent implements OnInit {

  public isMobileApp: Boolean = false;

  constructor(
    private location: Location,
  ) { }

  ngOnInit(): void {
    if( this.location.path().indexOf( '/mobile' ) > -1 ) {
      this.isMobileApp = true;
    }
  }

}

