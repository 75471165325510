<div class="mypage-menu">
  <div class="menu-header">
    <a class="menu-btn mobile-view" (click)="menuOpen=true"></a>
    <div class="title">마이페이지</div>
    <a routerLink="/" class="close-btn mobile-view"></a>
  </div>
  <div class="menu-list" [ngClass]="{open:menuOpen}">
    <div class="list-header mobile-view">
	    <div class="title"><a routerLink="/mypage/dashboard">마이페이지</a></div>
	    <a class="close-btn" (click)="menuOpen=false"></a>    
    </div>
    <div class="menu">
      <div>쇼핑</div>
      <ul>
        <li><a routerLink="/mypage/order">구매내역/배송 조회</a></li>
        <li><a routerLink="/mypage/cancel">반품/교환 조회</a></li>
        <li><a routerLink="/mypage/mileage">마일리지</a></li>
        <li><a routerLink="/mypage/coupon">바마쿠폰</a></li>
        <!-- <li><a routerLink="/mypage/coupon">할인쿠폰</a></li> -->
        <li><a routerLink="/mypage/favorite">찜 리스트</a></li>
        <li><a routerLink="/mypage/review">구매후기</a></li>
      </ul>
    </div>
    <!-- <div class="menu">
      <div>결제내역</div>  
      <ul>
        <li><a href="https://bike.bikemart.co.kr/myinfo/order_list.php" target="_blank">Mall 전체</a></li>
        <li><a href="https://members.bikemart.co.kr/myinfo/order_list_secondhand.php" target="_blank">중고차/셀프장터</a></li>
        <li><a routerLink="#">신차(BOM)</a></li>
      </ul>
    </div> -->
    <div class="menu">
      <div>중고차/셀프장터 거래</div>  
      <ul>
        <!-- <li><a href="https://bike.bikemart.co.kr/myinfo/my_sell_list.html" target="_blank">중고차</a></li> -->
        <li><a href="https://bikeweb.bikemart.co.kr/" target="_blank">중고차</a></li>
        <li><a href="https://www.bikemart.co.kr/trade/doumi/ndoumi_guide.php?category=info" target="_blank">결제도우미센터</a></li>
      </ul>
    </div>
  <div class="menu">
    <div>동호회</div>
    <ul>
      <li *ngIf="memberInfo.isSociety==0"><a routerLink="/mypage/society">내 동호회 등록</a></li>
      <li *ngIf="memberInfo.isSociety>0"><a routerLink="/mypage/society/info">내 동호회</a></li>
    </ul>
  </div>
  <div class="menu">
      <div>개인정보</div>
      <ul>
        <li><a routerLink="/mypage/myqna">1:1문의</a></li>
        <li><a routerLink="/mypage/modify">회원정보수정</a></li>
        <li><a routerLink="/mypage/company/change">업체회원전환/변경</a></li>
        <li><a routerLink="/mypage/member/out">회원탈퇴</a></li>
      </ul>
    </div>   
  </div>
</div>
<div class="overlay mobile-view" [ngClass]="{open:menuOpen}"></div>
