import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-event-prize',
  templateUrl: './event-prize.component.html',
  styleUrls: ['./event-prize.component.scss']
})
export class EventPrizeComponent implements OnInit {

  @Input() name;
  @Input() mileage;

  constructor(
    public activeModal: NgbActiveModal
  ) { }

  ngOnInit(): void {
  }

}
