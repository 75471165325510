/*******************************************************************************
  설  명 : 마이페이지 - 내 동호회
  생성일 : 2024-03-29
  생성자 : 송영석
*******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';


import { SMemberService } from '@app/service/member.service';

import { ToastrService } from 'ngx-toastr';
import { AuthService } from '@app/service/auth.service';

@Component({
  selector: 'app-mypage-society-info',
  templateUrl: './mypage-society-info.component.html',
  styleUrls: ['./mypage-society-info.component.scss']
})
export class MypageSocietyInfoComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  public isLogIn: boolean;
  isLogin$: Observable<boolean>; // 로그인여부확인

  public societyInfo: any = [];
  
  public societyMemberList: any = {};
  public memberInfo: any = {};

  public search: any = {
    society_seq: ''
  };
  
  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private authService: AuthService,
    private toastrService: ToastrService,
    private memberService: SMemberService,
  ) { 
    // 로그인 여부 가져오기
    this.isLogin$ = this.authService.isLoggedIn;

    this.isLogin$.subscribe( login => {
      this.isLogIn = login;
    });

  }

  /*******************************************************************************
    설  명 : 데이터 로딩 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit(): void {
    this.authService.getLoginInfo.subscribe(data => {
      this.memberInfo = data;
    });
    this.getSocietyInfo();
    this.getSocietyMember();

  }
  
  /*******************************************************************************
    설  명 : 로그인 회원의 동호회 정보 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getSocietyInfo() {
    this.memberService.getMemberSocietyInfo().then( response => {
      if( response.ResultCode ) {
        this.societyInfo = response.data[0];
      } else {
        this.toastrService.error(response.ResultMessage);
      }
    });
  }

  /*******************************************************************************
    설  명 : 주문상태 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
getSocietyMember() {
    this.memberService.getSocietyMemberList().then( response => {
      if( response.ResultCode ) {
        this.societyMemberList = response.data;
      } else {
        this.toastrService.error(response.ResultMessage);
      }
    });
  }

  /*******************************************************************************
    설  명 : 회원 탈퇴 처리 
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
    delSocietyMember(mem_no) {
      this.memberService.delSocietyMember(this.societyInfo.seq , mem_no).then( response => {
        if( response.ResultCode ) {
          this.toastrService.success(response.ResultMessage);          

          this.getSocietyMember() ;

        } else {
          this.toastrService.error(response.ResultMessage);
        }
      });
    }

  /*******************************************************************************
    설  명 : 회원 승인/미승인 처리  
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
    appSocietyMember(mem_no) {
      this.memberService.appSocietyMember(this.societyInfo.seq , mem_no).then( response => {
        if( response.ResultCode ) {
          this.toastrService.success(response.ResultMessage);          

          this.getSocietyMember() ;

        } else {
          this.toastrService.error(response.ResultMessage);
        }
      });
    }


  
}

