import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
@Component({
  selector: 'app-society-info',
  templateUrl: './society-info.component.html',
  styleUrls: ['./society-info.component.scss']
})
export class SocietyInfoComponent implements OnInit {

  public isMobileApp: Boolean = false;

  constructor(
    private location: Location,
  ) { }

  ngOnInit(): void {
    if( this.location.path().indexOf( '/mobile' ) > -1 ) {
      this.isMobileApp = true;
    }
  }

}
