import { NgModule } from '@angular/core';

import { DateFormatPipe } from './date-format.pipe';
import { SafePipe } from './safe.pipe';
import { SafeHtmlPipe } from './safe-html.pipe';
import { SafeResourceUrlPipe } from './safe-resource-url.pipe';
import { KCurrencyPipe } from './kcurrency.pipe';
import { NoHtmlPipe } from './nohtml.pipe';

@NgModule({
  declarations: [
    DateFormatPipe,
    SafePipe,
    SafeHtmlPipe,
    SafeResourceUrlPipe,
    KCurrencyPipe,
    NoHtmlPipe
  ],
  exports: [
    DateFormatPipe,
    SafePipe,
    SafeHtmlPipe,
    SafeResourceUrlPipe,
    KCurrencyPipe,
    NoHtmlPipe
  ]
})
export class PipesModule {
}
