import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import * as $ from 'jquery';

import { AuthService } from '@app/service/auth.service';
import { SMemberService } from '@app/service/member.service';

@Component({
  selector: 'app-member-logout',
  templateUrl: './member-logout.component.html',
  styleUrls: ['./member-logout.component.scss']
})
export class MemberLogoutComponent implements OnInit {

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,    
    private authService: AuthService,
    private memberService: SMemberService,
    private toastrService: ToastrService
  ) { }

  ngOnInit(): void {
    this.setLogout();
  }

  /*******************************************************************************
    설  명 : 로그아웃
  *******************************************************************************/
  setLogout() {
    this.memberService.setLogout().then( response => {
      if( response['ResultCode'] == true ) {
        this.toastrService.success(response.ResultMessage, '');

        this.authService.logout('shop');

        this.router.navigate(['/main']);
      } else {
        this.toastrService.error(response.ResultMessage, '');
      }
    })
    .catch(response => {
    });
  }

}
