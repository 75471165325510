/*******************************************************************************
  설  명 : 아이디 찾기 - 성공
  생성일 : 2020-09-10
  생성자 : 송영석
*******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { SMemberService} from '@app/service/member.service';

@Component({
  selector: 'app-member-find-id-success',
  templateUrl: './member-find-id-success.component.html',
  styleUrls: ['./member-find-id-success.component.scss']
})
export class MemberFindIdSuccessComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  public result: any = '';

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public memberService: SMemberService
  ) {
  }

  /*******************************************************************************
    설  명 : 아이디를 찾고 findResult한 데이터 로딩 2/2 수정자 김종현
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit(): void {
    this.result = this.memberService.findIdResult;

    if( !this.result || this.result.length < 1 ) {
      this.router.navigate(
        ['/main'],
        {
          relativeTo: this.activatedRoute,
          queryParams: {},
          queryParamsHandling: '', // remove to replace all query params by provided
        }
      );
    }

    this.memberService.findIdResult = [];
  }

}
