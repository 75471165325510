import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { EventPrizeComponent } from '@page/event/event2/event-prize/event-prize.component';
import { UtilService } from '@app/service/util.service';
import { SEventService } from '@service/event.service';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';
import 'moment/locale/ko';

const options: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  size: '500',
  centered: true,
  windowClass:'modal-fadeInDown'
};

@Component({
  selector: 'app-event2',
  templateUrl: './event2.component.html',
  styleUrls: ['./event2.component.scss']
})
export class Event2Component implements OnInit {

  public form: FormGroup;
  public formErrors = {};  
  public quiz_seq: string[];
  public questions: string[];
  public hint: string[];
  public start_date: string;
  public end_date: string;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private utilService: UtilService,
    private eventService: SEventService,
    private toastrService: ToastrService,
  ) { 
    this.form = this.formBuilder.group({
      answer: ['',[Validators.required]]
    });
  }

  ngOnInit(): void {
    this.form.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.form, this.formErrors );
    });

    this.eventService.getEventQuiz().then(response => {
      if (response.ResultCode) {
        this.quiz_seq = response.data[0].seq;
        this.questions = response.data[0].question ? response.data[0].question.split('') : '';
        this.hint = response.data[0].hint;
        this.start_date = moment(response.event[0].start_date).format('M월 D일(ddd)');
        this.end_date = moment(response.event[0].end_date).format('M월 D일(ddd)');
      }
    });
  }

 submit() {
  this.utilService.makeFormDirtyAndUpdateErrors(this.form, this.formErrors);

  if (this.form.valid) {
    this.eventService.setEventQuiz(this.quiz_seq, this.form).then( response => {
      if (response.ResultCode) {
        this.toastrService.success(response.ResultMessage);
        this.form.reset();
        this.eventPrize(response.data.name, response.data.mileage);
      } else {
        if (response.errorCode === 403) {
          return this.router.navigate(['/member/login']);
        }
        this.toastrService.error(response.ResultMessage);
      };
    });
  } else {
    this.utilService.showToast(this.form, this.formErrors);
  }
 }

  /*******************************************************************************
    설  명 : 이벤트당첨
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/  
  eventPrize(name, mileage) {
    const modalRef = this.modalService.open(EventPrizeComponent, options);

    modalRef.componentInstance.name = name;
    modalRef.componentInstance.mileage = mileage;

    modalRef.result.then((result) => {
    }, (reason) => {
    });  
  }

}
