import { Component, Input, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { AuthService } from '@app/service/auth.service';
import { ApiResponse } from '@app/service/api-response';
import { UtilService } from '@app/service/util.service';

import { SBoardService } from '@app/service/board.service';

import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-mypage-myqna-write',
  templateUrl: './mypage-myqna-write.component.html',
  styleUrls: ['./mypage-myqna-write.component.scss']
})
export class MypageMyqnaWriteComponent implements OnInit {

    /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  errorResponse: ApiResponse;
  public boardInfo: any = {};
  public memberInfo: any = {};
  public boardId: any = {};

  public params: any = {
    type: 'list',
    board_id: 'question',
    group_id: '전체',
    row: 10,
    no: 1,
    group: 'subject',
    text: '',
    subject: '',
    contents: '',
    reply: false
  };

  public boardWriteForm: FormGroup;
  public formErrors = {
    'board_seq': '',
    'board_id': '',
    'subject': '',
    'group_id': '',
    'contents': '',
  };

  public groupTab: any = [];

    /*******************************************************************************
    설  명 : 생성자
  *******************************************************************************/
  constructor(
    private authService: AuthService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private sBoardService: SBoardService,
    private formBuilder: FormBuilder,
    private utilService: UtilService,
    private toastrService: ToastrService,
  ) { 
    this.buildForm();
  }

  /*******************************************************************************
    설  명 : 폼 생성
  *******************************************************************************/
  buildForm(): void {
    this.boardWriteForm = this.formBuilder.group({
      board_seq: [""],
      board_id: ["", [Validators.required]],
      subject: ["", [Validators.required]],
      group_id: ["", [Validators.required]],
      contents: ["", [Validators.required]],
    });

    this.boardWriteForm.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors(this.boardWriteForm, this.formErrors);
    });
  };

  /*******************************************************************************
    설  명 : 데이터 초기화
  *******************************************************************************/
  ngOnInit() {
    this.authService.getLoginInfo.subscribe(data => {
      this.memberInfo = data;
    });

    this.activatedRoute.queryParams.subscribe(params => {
      this.params.board_seq = ( typeof params.board_seq == 'undefined' || params.board_seq == '' ) ? '' : params.board_seq;
      this.params.board_id = ( typeof params.board_id == 'undefined' || params.board_id == '' ) ? 'question' : params.board_id;
      this.params.group_id = ( typeof params.group_id == 'undefined' || params.group_id == '' ) ? '전체' : params.group_id;
      this.params.type = ( typeof params.type == 'undefined' || params.type == '' ) ? '' : params.type;
      this.params.row = ( typeof params.row == 'undefined' || params.row == '' ) ? 10 : parseInt(params.row);
      this.params.no = ( typeof params.no == 'undefined' || params.no == '' ) ? 1 : parseInt(params.no);
      this.params.group = ( typeof params.group == 'undefined' || params.group == '') ? 'subject' : params.group;
      this.params.text = ( typeof params.text == 'undefined' ) ? '' : params.text;
      this.params.subject = ( typeof params.subject == 'undefined' ) ? '' : params.subject;
      this.params.contents = ( typeof params.contents == 'undefined' ) ? '' : params.contents;

      this.getBoardInfo( this.params.board_id );

    });

    // 1:1 문의 답글일 경우
    if ( this.params.contents != '' ) {
      this.boardWriteForm.patchValue({
        board_seq: this.params.board_seq,
        subject: this.params.subject,
        contents: this.params.contents,
        group_id: this.params.group_id
      });
      this.params.reply = true;
    } else {
      this.params.reply = false;
    }
  }


  /*******************************************************************************
    설  명 : 게시판 정보 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  async getBoardInfo( board_id ) {
    await this.sBoardService.getBoardInfoId( board_id ).then( response => {

      this.boardInfo = response.data;

      this.boardId = this.boardInfo.board_id;

      this.boardWriteForm.get('board_id').setValue( this.boardId );

      if( this.boardInfo.lvt_group == '1' ) {
        this.boardInfo.group = '전체|' + this.boardInfo.group;
        this.groupTab = this.boardInfo.group.split('|');
      }

    });
  }

    /*******************************************************************************
    설  명 : 문의하기 처리
  *******************************************************************************/
  submit() {
    this.utilService.makeFormDirtyAndUpdateErrors(this.boardWriteForm, this.formErrors);

    if( this.boardWriteForm.valid ) {
      // 답글일 경우
      if ( this.params.reply == true ) {
        this.sBoardService.setReply( this.boardWriteForm ).then( response => {
          if( response.ResultCode ) {
            this.toastrService.success( response.ResultMessage, '');
            this.params.reply == false;
          } else {
            this.toastrService.error( response.ResultMessage, '');
          }
        }).catch(response => {
          this.errorResponse = response;
          this.utilService.handleFormSubmitError(this.errorResponse, this.boardWriteForm, this.formErrors);
        });

        this.router.navigate(['/mypage/myqna']);
      } else {
        this.sBoardService.setWrite( this.boardWriteForm ).then( response => {
          if( response.ResultCode ) {
            this.toastrService.success( response.ResultMessage, '');
          } else {
            this.toastrService.error( response.ResultMessage, '');
          }
        }).catch(response => {
          this.errorResponse = response;
          this.utilService.handleFormSubmitError(this.errorResponse, this.boardWriteForm, this.formErrors);
        });

        this.router.navigate(['/mypage/myqna']);
      }
    }
  }
}
