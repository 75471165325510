/*******************************************************************************
  설  명 : 네이버페이 모듈 컴포넌트
  생성일 : 2021.07.27
  생성자 : 서기정
*******************************************************************************/

import { Component, Input, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { NaverPayService } from '@service/naverpay.service'
import { AuthService } from '@app/service/auth.service';

declare let nhn : any;

@Component({
  selector: 'app-naverpay',
  templateUrl: './naverpay.component.html',
  styleUrls: ['./naverpay.component.scss']
})
export class NaverpayComponent implements OnInit {

  public nPayNotBuyFunc: any;
  public nPayBuyFunc: any;

  @Input() nPay_btnCount = 1
//  @Input() nPay_btnCount = 2  찜버튼이 나올 경우
  @Input() nPay_btnEnable = "Y"
  @Input() nPay_btnID = "npayId"

  public memberInfo: any = {};
  public nhnData: any = {};
  product_data = []; 
  pay_load = false;

  constructor(
    private toastrService: ToastrService,
    private naverPayService: NaverPayService,
    private authService: AuthService,
  ) {
    this.nPayNotBuyFunc = this.nPayNotBuy.bind(this);
    this.nPayBuyFunc = this.nPayBuy.bind(this);
  }

  ngOnInit(): void {
    this.authService.getLoginInfo.subscribe(data => {
      this.memberInfo = data;
    });
  }
  
  ngAfterViewInit() {
    this.nhnData = {
      BUTTON_KEY: "4E1A4A48-899C-4D86-9F9C-7AC1C6094810", // 체크아웃에서 제공받은 버튼 인증 키 입력
      TYPE: "A", // 버튼 모음 종류 설정
      COLOR: 1, // 버튼 모음의 색 설정
      COUNT: this.nPay_btnCount, // 버튼 개수 설정. 구매하기 버튼만 있으면(장바구니 페이지) 1, 찜하기 버튼도 있으면(상품 상세 페이지) 2를 입력.
      ENABLE: this.nPay_btnEnable, // 품절 등의 이유로 버튼 모음을 비활성화할 때에는 "N" 입력
      BUY_BUTTON_HANDLER: this.nPayBuyFunc, // 구매하기 버튼 이벤트 Handler 함수 등록, 품절인 경우not_buy_nc 함수 사용
      EMBED_ID: this.nPay_btnID,
      /*
        BUY_BUTTON_LINK_URL:"https://www.bikemart.co.kr", // 링크 주소 (필요한 경우만 사용)
        WISHLIST_BUTTON_HANDLER:wishlist_nc, // 찜하기 버튼 이벤트 Handler 함수 등록
        WISHLIST_BUTTON_LINK_URL:"https://mydomain.com/wishlist/popup/url/", // 찜하기 팝업링크 주소
        CART_BUTTON_HANDLER:cart_nc, // 찜하기 버튼 이벤트 Handler 함수 등록, 품절인 경우 not_buy_nc 함수 사용
        CART_BUTTON_LINK_URL:"http://mydomain.com/cart/popup/url/", // 장바구니 팝업 링크 주소(필요한 경우만 사용)
      */
      "":""
    };

    // 일반회원에게만 네이버페이 보이도록 설정
    if( this.memberInfo.grade != '0002' && this.memberInfo.grade != '0004' && this.memberInfo.grade != '0007' ) {
      nhn.CheckoutButton.apply(this.nhnData);
    }
  }

  nPayModuleSet(data) {
    // const _script = document.createElement('script');
    // _script.type = 'text/javascript'
    // _script.innerHTML = `var _pInfo = ${JSON.stringify(data)};`
    // document.getElementsByTagName('head')[0].appendChild(_script)
    window['_pInfo'] = data
  }

  nPayBuy() {
    const item = window['_pInfo'];
    if(!item || !item['productList'] || !item['productList'].length) {this.toastrService.warning('상품을 선택하세요.'); return}
    else if(item['productList'].filter(e => (!e['product_seq'] || !e['product_property_seq'] || !e['qty'])).length) {this.toastrService.warning('상품정보가 누락되었습니다.'); return}

    let check: boolean = false;
    item['productList'].forEach(row => {
      if( row.isGift === false ) check = true;
    });

    if( check === false ) {
      this.toastrService.warning('기본옵션 상품을 선택해주시기 바랍니다.', '주문하기');
    } else {
      this.authService.getLoginInfo.subscribe(__e => {

        this.pay_load = true
        this.naverPayService.naverpay({
          _product : item['productList'].map(e => ({
            product_seq : e['product_seq'],
            product_property_seq : e['product_property_seq'],
            qty : parseInt(e['qty']),
            isGift: e['isGift'],
            mem_no : __e['mem_no'] || ''
          }))
        }).then(response => {
          if(response.ResultCode) {
            const _RD = response.data
            window.location.href = `${_RD['action']}?ORDER_ID=${_RD['ORDER_ID']}&SHOP_ID=${_RD['SHOP_ID']}&TOTAL_PRICE=${_RD['TOTAL_PRICE']}`
            
          } else this.toastrService.error(response.ResultMessage)
        })

      }).unsubscribe();
    }
  }

  nPayNotBuy() {
    alert("죄송합니다. 이 상품은 네이버페이로 구매할 수 없습니다.");
  }

  nPayModuleLoad() {
  }

}
