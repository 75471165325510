<app-header class="pc-view"></app-header>

<div class="inner clearfix">
  <!-- 왼쪽 마이페이지 메뉴 -->
  <app-mypage-menu></app-mypage-menu>
  
  <div class="mypage-contents">
    <div class="title page-title">업체회원전환/변경</div>
  
    <div class="section main" *ngIf="currPage=='main'">
      
      <ul class="clearfix">
        <li>
          <div class="title">상사회원</div>
          <div class="line"></div>
          <div class="txt1">가장 기본적인 업체 회원입니다.</div>
          <div class="txt2">
						<p class="dot">01.</p><p class="dot1">오토바이 센터 및 관련판매업에 종사하시는 경우 등록이 가능합니다.</p>
						<p class="dot">02.</p><p class="dot1">쇼핑몰 전 상품 센터가 공급 및 상사 전용 도매 공급해 드립니다.</p>
						<p class="dot">03.</p><p class="dot1">세금계산서는 요청 시 익월 발행되며, 카드결제는 불가능합니다.</p>
						<p class="dot">04.</p><p class="dot1">중고차 매물 등록 가능 건수는 <span class="red">100대</span>입니다.</p>          
          </div>
	        <button class="basic-btn red" (click)="setCurrPage('sub1')">신청하기</button>
        </li>
        <!-- <li>
          <div class="title">프렌차이즈</div>
          <div class="line"></div>
          <div class="txt1">신차/중고차/벼룩시장 결제도우미협력점 업체 회원입니다.</div>
          <div class="txt2">
            <p class="dot">01.</p><p class="dot1">오일협력점이란 일반고객님께서 바이크마트에서 구매한 CEPSA(셉사), PACO(파코)오일과 오일 구매 시 증정하는 오일교환공임할인쿠폰을 오일협력점에 주면 오일협력점은 교환공임비를 받고 오일을 교환해주는 서비스협력점입니다.</p>
            <p class="dot">02.</p><p class="dot1">바이크마트의 강력한 유통망과 서비스정책으로 일반고객님은 전국최저가로 오일 구매및 교환을, 오일협력점은 교환공임비 수입 및 홍보의 효과를 얻을 수 있습니다.</p>
            <p class="dot">03.</p><p class="dot1">오일 협력점이 되시면 잡지 및 홈페이지 내 홍보와 함께 내방고객이 늘어 자연스레 판매촉진 효과가 있습니다.</p>
          </div>
          <button class="basic-btn red" (click)="setCurrPage('sub2')">신청하기</button>
        </li> -->

        <!--li>
          <div class="title">BOM (바마신차오픈마켓)</div>
          <div class="line"></div>
          <div class="txt1">바마 신차 오픈 마켓(Bama Open Market) 입점 업체 회원입니다.</div>
          <div class="txt2">
            <p class="dot">01.</p><p class="dot1">쇼핑몰 전 상품 센터가 공급 및 상사 전용 도매 공급해 드립니다.</p>
            <p class="dot">02.</p><p class="dot1">카드/현금 동일가로 공급해 드리며 세금계산서 발행도 가능합니다.</p>
            <p class="dot">03.</p><p class="dot1">중고차 매물 등록 가능 건수를 50건으로 확대해 드립니다.</p>
            <p class="dot">04.</p><p class="dot1">BOM(바마 신차 오픈 마켓)에 입점하여 무제한으로 신차를 등록/판매 할 수 있습니다. BOM회원전용 미니홈피 등 입점업체만의 혜택을 받을 수 있습니다.</p>          
          </div>
          <button class="basic-btn red" (click)="currPage='sub3'">자세히보기</button>
        </li>
        <li>
          <div class="title">바마공식대리점</div>
          <div class="line"></div>
          <div class="txt1">제2의 바이크마트 업체 회원입니다.</div>
          <div class="txt2">
            <p class="dot">01.</p><p class="dot1">BERIK[베릭], ArlenNess[알렌네즈], ROUGH&ROAD[러프앤로드], AXO[악소], OXFORD[옥스포드], Black B. Jackal[블랙 비 자칼] 등 바이크마트 독점 수입 브랜드의 지역 독점권을 드립니다.</p>
            <p class="dot">02.</p><p class="dot1">보증금을 예치하시고 위탁판매로 진행이 되며, 계약이 끝날 경우 보증금은 돌려 드립니다.</p>
            <p class="dot">03.</p><p class="dot1">초도물량이나 매입자체가 없고, 추가 비용이 없으므로 재고 부담이 전혀 없습니다.</p>
            <p class="dot">04.</p><p class="dot1">대리점 개설의 지역 제한을 두어 상권을 보호해 드립니다.</p>          
            <p class="dot">05.</p><p class="dot1">바이크마트 이벤트와 연계하여 대리점 판매 촉진에 도움을 드립니다.</p>
          </div>
          <button class="basic-btn red">자세히보기</button>
        </li-->                        
      </ul>  
    </div>
    
    <!-- 상사회원 -->
    <div class="section sub1" *ngIf="currPage=='sub1'">
      <div class="sub-title clearfix">
        <div>상사회원</div>
        <div>가장 기본적인 업체 회원입니다.</div>
      </div>      
      <div class="txt1">
        <p class="dot">01.</p><p class="dot1">오토바이 센터 및 관련판매업에 종사하시는 경우 등록이 가능합니다.</p>
        <p class="dot">02.</p><p class="dot1">쇼핑몰 전 상품 센터가 공급 및 상사 전용 도매 공급해 드립니다.</p>
        <p class="dot">03.</p><p class="dot1">세금계산서는 요청 시 익월 발행되며, 카드결제는 불가능합니다.</p>
        <p class="dot">04.</p><p class="dot1">중고차 매물 등록 가능 건수는 <span class="red">100대</span>입니다.</p>          
      </div>  
 
      <table class="info-table">
      <colgroup>
        <col style="width: 160px">
        <col>
      </colgroup>  
      <tbody>
      <tr>
        <th class="vp">가입조건</th>
        <td>
        - 오토바이 사업주<br/>
        - 점포내 진열장 설치 가능<br/>
        - 사업장내에 PC보유
        </td>
      </tr> 
      <tr>
        <th class="vp">절차</th>
        <td>
          <ul class="ul1 clearfix">
            <li>회원가입</li>
            <li>로그인</li>
            <li>마이페이지</li>
            <li>개인정보</li>
            <li>업체회원전환신청</li>
            <li>사업자등록증 팩스로 발송</li>
            <li>담당자 확인 후 인증 </li>
            <li>오픈/협력점코드부여</li>
            <li>판매</li>
          </ul>        
        </td>
      </tr> 
      <tr>
        <th class="vp">혜택</th>
        <td>
          <ul class="ul2 clearfix">
            <li>높은 마진율로 수익성 보장</li>
            <li>신차중고차 쇼핑몰 무료 제공</li>
            <li>마이샵 광고 지원</li>
            <li>특가상품지원</li>
            <li>신상품PUSH서비스</li>
            <li>전국 지정 장착점 선정</li>
            <li>우수협력점 마일리지 지원</li>
            <li>해당 담당자의 전문적인 관리</li>
          </ul>
        </td>
      </tr> 
      <tr>
        <th>상담신청/접수</th>
        <td>전화 1544-5022 / 담당 하준성 팀장(bikemart@daum.net)</td>
      </tr> 
      </tbody>
      </table>   
    
      <div class="apply">
        <div class="clearfix">
		      <div class="title">상사회원 가입 여부 확인 및 신청</div>
		      <div class="txt1">신청아이디 성함과 사업자등록증 대표자 성함이 <span class="red">동일</span>하여야 상사 회원 전환 인증 가능합니다.</div>
	      </div>

        <form [formGroup]="form">

	      <table class="info-table">
	      <colgroup>
	        <col style="width: 160px">
	        <col>
	      </colgroup>  
	      <tbody>
	      <tr>
	        <th><label for="company_name"><i class="material-icons cred f11 lh20">star</i> 상호명</label></th>
	        <td>
            <input type="text" formControlName="company_name" placeholder="상호명 입력" [ngClass]="{formErrors:formErrors['company_name']}" />
            <span *ngIf="formErrors['company_name']" class="error-message-under">{{formErrors['company_name']}}</span>
          </td>
	      </tr> 
	      <tr>
	        <th><label for="biz_no"><i class="material-icons cred f11 lh20">star</i> 사업자번호</label></th>
	        <td class="overlap">
	          <input type="text" formControlName="biz_no" placeholder="사업자번호 입력" [ngClass]="{formErrors:formErrors['biz_no'] || formErrors['biz_no_check']}" (keydown)="setBizNoCheckInit()" />
	          <button class="basic-btn" (click)="checkBizNoDuplicate()">중복확인</button>
            <!-- <span *ngIf="formErrors['biz_no']" class="error-message">{{formErrors['biz_no']}}</span> -->
            <span *ngIf="formErrors['biz_no_check']" class="error-message-under">사업자번호 중복확인을 하시기 바랍니다.</span>
	        </td>
	      </tr> 
	      <tr>
	        <th><label for="ceo_name"><i class="material-icons cred f11 lh20">star</i> 대표자성함</label></th>
	        <td>
            <input type="text" formControlName="ceo_name" placeholder="대표자 성함 입력" [ngClass]="{formErrors:formErrors['ceo_name']}" />
            <span *ngIf="formErrors['ceo_name']" class="error-message-under">{{formErrors['ceo_name']}}</span>
          </td>
	      </tr>
	      <tr>
	        <th><label for="hp1"><i class="material-icons cred f11 lh20">star</i> 전화번호</label></th>
	        <td class="overlap-hp">
            <select formControlName="hp1">
              <option value="010">010</option>
              <option value="011">011</option>
              <option value="016">016</option>
              <option value="017">017</option>
              <option value="018">018</option>
              <option value="019">019</option>
            </select>
            -
            <input type="text" formControlName="hp2" maxlength="4" [ngClass]="{formErrors:formErrors['hp2']}" placeholder="" />
            -
            <input type="text" formControlName="hp3" maxlength="4" [ngClass]="{formErrors:formErrors['hp3']}" placeholder="" />
            <span *ngIf="formErrors['hp2'] || formErrors['hp3']" class="error-message-under">{{formErrors['hp2']}}</span>
          </td>
	      </tr>
	      <tr>
	        <th><label for="email1"><i class="material-icons cred f11 lh20">star</i> 이메일</label></th>
	        <td class="overlap-email">
            <input type="text" formControlName="email1" [ngClass]="{formErrors:formErrors['email1']}"  placeholder="이메일주소" />
            <span class="ml5 mr5">@</span>
            <input type="text" formControlName="email2" [ngClass]="{'read-input': this.form.value.email3 != '', formErrors:formErrors['email2']}" [readonly]="( this.form.value.email3 != '' ) ? true : false" #email2 />
            <select formControlName="email3" (change)="changeEmail($event, 'email2')">
              <option value="">직접입력</option>
              <option value="naver.com">naver.com</option>
              <option value="gmail.com">gmail.com</option>
              <option value="daum.net">daum.net</option>
              <option value="nate.com">nate.com</option>
              <option value="dreamwiz.com">dreamwiz.com</option>
              <option value="yahoo.co.kr">yahoo.co.kr</option>
              <option value="outlook.com">outlook.com</option>
              <option value="hotmail.com">hotmail.com</option>
            </select>
            <span *ngIf="formErrors['email1'] || formErrors['email2']" class="error-message-under">{{formErrors['email1']}}</span>
          </td>
	      </tr>
	      <tr>
	        <th><label for="zipcode"><i class="material-icons cred f11 lh20">star</i> 사업장 주소</label></th>
	        <td>
            <input type="text" class="zipcode readonly" formControlName="zipcode" [ngClass]="{formErrors: formErrors['zipcode']}" [readonly]="true" />
            <btn-daum-address (result)="setDaumAddressApi($event)" [options]="daumAddressOptions"></btn-daum-address>
            <input type="text" class="readonly" [ngClass]="{formErrors: formErrors['address']}" formControlName="address" [readonly]="true" />
            <input type="text" [ngClass]="{formErrors: formErrors['address_detail']}" formControlName="address_detail" #address_detail />
            <span *ngIf="formErrors['address']" class="error-message-under">{{formErrors['address']}}</span>
          </td>
	      </tr>
	      <tr>
	        <th><label><i class="material-icons cred f11 lh20">star</i> 가입동의</label></th>
	        <td>
	          <label>
              <input type="checkbox" class="change-checkbox square" [ngClass]="{formErrors:formErrors['agree']}" (click)="setAgree($event)" />
              <span class="new-checkbox"><i class="material-icons">check</i></span>상사 가입 및 정보활용에 동의 합니다.
            </label>
            <span *ngIf="formErrors['agree']" class="error-message-under">{{formErrors['agree']}}</span>
	        </td>
	      </tr> 
        <tr>
          <th><label>첨부파일<br>(사업자등록증)</label></th>
          <td class="pt10">
            <input type="file" ng2FileSelect (change)="handleUploadFileChanged($event)" />
            <div class="progress mt5 mb5">
              <div class="progress-bar" [ngClass]="{'width': uploadProgress}"></div>
            </div>
            <div class="mt5">
            ※ 파일별 50MB 까지, 최대 10개까지 첨부 가능
            </div>
            <div *ngIf="form.controls.files.value.length > 0" class="file-div">
              <ul class="file-ul">
                <li *ngFor="let file of form.controls.files.value; let i = index" class="fl">
                  <div class="upload-item" [ngClass]="{uploadImage: checkImage(getExt(file.origin))}">
                    <div class="upload-image">
                      <img [src]="file.filepath">
                    </div>
                    <div class="upload-filename"><a href="{{file.filepath}}" target="_blank" title="{{file.origin}}">{{file.origin_filename}}</a></div>
                    <div class="upload-size">
                      ({{getComma(file.size)}} KB)
                      <a class="cp ml5" (click)="deleteFile( file, i )">
                        <img src="/assets/images/del_ico.png" />
                      </a>
                    </div>
                  </div>
                </li>
                <li class="cb"></li>
              </ul>
            </div>
          </td>
        </tr>

	      </tbody>
	      </table>

	      <div class="txt2">최근 3개월이내 구매내역이 있어야 하며,<br class="mobile-view"> 신청일로부터 7일이내 승인됩니다.<br/>경우에 따라 상사회원 신청이 거절될 수 있습니다.</div>
	      <div class="btn-area">
	        <a class="basic-btn cp" (click)="cancel()">취소</a>
	        <a class="basic-btn cp red" (click)="submit()">신청하기</a>
	      </div>

        </form>

	    </div>
    </div>

    <!-- 프렌차이즈 -->
    <div class="section sub1" *ngIf="currPage=='sub2'">
      <div class="sub-title clearfix">
        <div>프렌차이즈</div>
        <div>신차/중고차/벼룩시장 결제도우미협력점 업체 회원입니다.</div>
      </div>      
      <div class="txt1">
        <p class="dot">01.</p><p class="dot1">오일협력점이란 일반고객님께서 바이크마트에서 구매한 CEPSA(셉사), PACO(파코) 오일과 오일 구매 시 증정하는 오일교환공임할인쿠폰을 오일협력점에 주면 오일협력점은 교환공임비를 받고 오일을 교환해주는 서비스협력점입니다.</p>
        <p class="dot">02.</p><p class="dot1">바이크마트의 강력한 유통망과 서비스정책으로 일반고객님은 전국최저가로 오일 구매 및 교환을, 오일협력점은 교환공임비 수입 및 홍보의 효과를 얻을 수 있습니다.</p>
        <p class="dot">03.</p><p class="dot1">오일 협력점이 되시면 잡지 및 홈페이지 내 홍보와 함께 내방고객이 늘어 자연스레 판매촉진 효과가 있습니다.</p>
      </div>  
 
      <table class="info-table">
      <colgroup>
        <col style="width: 160px">
        <col>
      </colgroup>  
      <tbody>
      <tr>
        <th>가입조건</th>
        <td>대한민국 상사라면 누구나! <span class="red">신청즉시 오일협력점 등록 완료</span></td>
      </tr> 
      <tr>
        <th>절차</th>
        <td>
          <ul class="ul1 clearfix">
            <li>회원가입</li>
            <li>로그인</li>
            <li>마이페이지</li>
            <li>개인정보</li>
            <li>업체회원전환신청</li>
            <li>사업자등록증 팩스로 발송</li>
            <li>담당자 확인 후 인증 </li>
          </ul>        
        </td>
      </tr> 
      <tr>
        <th class="vp">구매고객 혜택</th>
        <td>
          <ul class="ul3 clearfix">
            <li>바마 쇼핑몰에서<br class="pc-view" />오일 구매</li>
            <li class="between"></li>
            <li>오일+교환쿠폰 수령<br class="pc-view" />(오일1통당 1매 제공)</li>
            <li class="between"></li>
            <li>가까운 바이크샵 방문<br class="pc-view" />(전화 후 방문시 편리)</li>
            <li class="between"></li>
            <li>오일교환쿠폰 제시</li>
            <li class="between"></li>
            <li class="red">무상 오일교환!</li>
          </ul>
        </td>
      </tr> 
      <tr>
        <th class="vp">프렌차이즈 혜택</th>
        <td class="ul4">
          <div class="txt1">
						- 온/오프라인 업체 홍보효과<br/>
						- 신규고객 유치 및 매출향상<br/>
						- 오일대리점 우선권 기회
          </div>        
          <!--div class="img">
            <img src="assets/images/company_oil.png">
            <div>바이크마트에서 구매하신 오일에 한하며,<br class="mobile-view"> 무료교환쿠폰 적용 오일은 사전예고 없이 변동될 수 있습니다.</div>  
          </div-->
          <ul class="ul5">
            <li>바마 고객 방문<br class="pc-view">오일교환 요청</li>
            <li class="between"></li>
            <li>교환쿠폰 <span class="red">수거후</span><br class="pc-view"><span class="red">오일 무상교환</span></li>
            <li class="between"></li>
            <li>바이크마트 접속<br class="pc-view">쿠폰번호 등록</li>
            <li class="between"></li>
            <li class="div1">
              <div><span class="red">1통당 최대 6,000원<br class="pc-view">마일리지 즉시적립</span></div>
              <div>오일 4통 교환시<br class="pc-view">최대 24,000원 적립</div>
            </li>
            <li class="between1"><div></div><div></div></li>
            <li class="red div1">
              <div>쇼핑몰에서 즉시<br class="pc-view"><span> 현금으로 사용</span></div>
              <div>10만원이상 적립후<br class="pc-view"><span> 현금으로 환급</span></div>
            </li>
          </ul>          
          
        </td>
        </tr> 
      </tbody>
      </table>   
    
      <div class="apply">
        <div class="clearfix">
          <div class="title">프렌차이즈 신청</div>
          <div class="txt1">신청아이디 성함과 사업자등록증 대표자 성함이 <span class="red">동일</span>하여야 프렌차이즈 전환 인증 가능합니다.</div>
        </div>

        <form [formGroup]="form">

        <table class="info-table">
        <colgroup>
          <col style="width: 160px">
          <col>
        </colgroup>  
        <tbody>
        <tr>
          <th><label for="company_name"><i class="material-icons cred f11 lh20">star</i> 프렌차이즈상호</label></th>
          <td>
            <input type="text" formControlName="company_name" placeholder="프렌차이즈상호" [ngClass]="{formErrors:formErrors['company_name']}" />
            <span *ngIf="formErrors['company_name']" class="error-message">{{formErrors['company_name']}}</span>
          </td>
        </tr> 
        <tr>
          <th><label for="biz_no"><i class="material-icons cred f11 lh20">star</i> 사업자번호</label></th>
          <td class="overlap">
            <input type="text" formControlName="biz_no" placeholder="사업자번호" [ngClass]="{formErrors:formErrors['biz_no']}" (keydown)="setBizNoCheckInit()" />
            <button class="basic-btn" (click)="checkBizNoDuplicate()">중복확인</button>
            <span *ngIf="formErrors['biz_no']" class="error-message">{{formErrors['biz_no']}}</span>
            <span *ngIf="formErrors['biz_no_check']" class="error-message">사업자번호 중복확인을 하시기 바랍니다.</span>
          </td>
        </tr> 
        <tr>
          <th><label><i class="material-icons cred f11 lh20">star</i> 가입동의</label></th>
          <td>
            <label>
              <input type="checkbox" class="change-checkbox square" [ngClass]="{formErrors:formErrors['agree']}" (click)="setAgree($event)"  />
              <span class="new-checkbox"><i class="material-icons">check</i></span>프렌차이즈 및 정보활용에 동의 합니다.
            </label>
            <span *ngIf="formErrors['agree']" class="error-message">{{formErrors['agree']}}</span>
          </td>
        </tr> 
        <tr>
        <th><label>첨부파일<br>(사업자등록증)</label></th>
          <td>
            <input type="file"class="mt5 mb5" ng2FileSelect (change)="handleUploadFileChanged($event)" />
            <div class="progress mt5 mb5">
              <div class="progress-bar" [ngClass]="{'width': uploadProgress}"></div>
            </div>
            <div class="mt5 mb5">
              ※ 파일별 50MB 까지, 최대 10개까지 첨부 가능
            </div>
            <div *ngIf="form.controls.files.value.length > 0" class="file-div">
              <ul class="file-ul">
                <li *ngFor="let file of form.controls.files.value; let i = index" class="fl">
                  <div class="upload-item" [ngClass]="{uploadImage: checkImage(getExt(file.origin))}">
                    <div class="upload-image">
                      <img [src]="file.url">
                    </div>
                    <div class="upload-filename"><a href="{{file.url}}" target="_blank" title="{{file.origin}}">{{file.origin_filename}}</a></div>
                    <div class="upload-size">
                      ({{getComma(file.size)}} KB)
                      <a class="cp ml5" (click)="deleteFile( file, i )">
                        <img src="/assets/images/del_ico.png" />
                      </a>
                    </div>
                  </div>
                </li>
                <li class="cb"></li>
              </ul>
            </div>            
          </td>
        </tr>
        </tbody>
        </table>        
        <div class="btn-area">
          <a class="basic-btn cp" (click)="cancel()">취소</a>
          <a class="basic-btn cp red" (click)="submit()">신청하기</a>
        </div>

        </form>

      </div>   
      
      <!--div class="company">
        <div class="clearfix">
          <div class="title">전국 오일협력점</div>
          <select>
            <option>전지역</option>
          </select>
        </div>

        <table class="list-table pc-view">
        <colgroup>
          <col style="width: 15%;">
          <col style="width: 15%;">
          <col style="width: 55%;">
          <col style="width: 15%;">
          <col>
        </colgroup>  
        <thead>
        <tr>
          <th>지역</th>
          <th>상호명</th>
          <th>주소</th>
          <th>연락처</th>
        </tr>        
        </thead>
        <tbody>
        <tr *ngFor='let key of  [1,2,3,4]'>
          <td>서울특별시</td>
          <td class="tl">oooo오토바이</td>
          <td class="tl">주소</td>
          <td>000-000-0000</td>
        </tr> 
        </tbody>
        </table>        
 
        <table class="list-table mobile-view">
        <colgroup>
          <col style="width: 30%;">
          <col style="width: 70%;">
          <col>
        </colgroup>  
        <thead>
        <tr>
          <th>지역/상호명</th>
          <th>주소/연락처</th>
        </tr>        
        </thead>
        <tbody>
        <tr *ngFor='let key of  [1,2,3,4]'>
          <td class="td1">
            <div>서울특별시</div>
            <div>oooo오토바이</div>
          </td>
          <td>
            <div>0000시 000구 00000로 000번길 00, 00빌딩 102호</div>
            <div>연락처 : 000-000-0000</div>
          </td>
        </tr> 
        </tbody>
        </table>  

			  <div class="pagination-area">
			    <ngb-pagination
			      [(page)]="page"
			      [pageSize]="12"
			      [collectionSize]="24"
			      [boundaryLinks]="true"
			      >
			      <ng-template ngbPaginationFirst>처음</ng-template>
			      <ng-template ngbPaginationLast>마지막</ng-template>
			      <ng-template ngbPaginationPrevious>이전</ng-template>
			      <ng-template ngbPaginationNext>다음</ng-template>
			    </ngb-pagination>
			  </div>       
      </div-->         
    </div>
    
    <!-- BOM(바마신차오픈마켓) -->
    <div class="section sub1" *ngIf="currPage=='sub3'">
      <div class="sub-title clearfix">
        <div>BOM(바마신차오픈마켓)</div>
        <div>바마 신차 오픈 마켓(Bama Open Market) 입점 업체 회원입니다.</div>
      </div> 
      
      <div class="prepare">서비스 준비중입니다.</div>     
    </div>
    
  </div>    
   
</div>

<app-footer></app-footer>


