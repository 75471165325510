<form [formGroup]="boardWriteForm" (ngSubmit)="submit()">

  <input type="hidden" formControlName="board_id" />
  <div class="modal-header smodal-header">
      <h4 class="modal-title smodal-title fl">1:1문의</h4>
      <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">&times;</button>
  </div>

  <div class="modal-body smodal-body">
    <div class="pt5 pb5">
      <p class="item-title fl"><label for="subject">제목</label></p>
      <p class="fl item">
        <input type="text" id="subject" formControlName="subject" autocomplete="off" />
        <span *ngIf="formErrors.subject" class="alert alert-danger">{{formErrors.subject}}</span>
      </p>
      <div class="cb"></div>
    </div>
    <div class="pt5 pb5">
      <p class="item-title fl"><label for="exhibit-nm">문의종류</label></p>
      <p class="fl item">
        <select formControlName="group_id">
          <option value="">선택</option>
          <option *ngFor="let item of groupTab" value="{{item}}">{{item}}</option>
        </select>
        <span *ngIf="formErrors.group_id" class="alert alert-danger">{{formErrors.group_id}}</span>
      </p>
      <div class="cb"></div>
    </div>
    <div class="pt5 pb5">
      <p class="item-title fl"><label for="contents">문의내용</label></p>
      <p class="fl item">
        <textarea id="contents" formControlName="contents" class="form-control form-control-sm" placeholder="문의내용을 입력해주세요"></textarea>
        <span *ngIf="formErrors.contents" class="alert alert-danger">{{formErrors.contents}}</span>
      </p>
      <div class="cb"></div>
    </div>
  </div>
  
  <!-- Modal footer -->
  <div class="modal-footer smodal-footer">
    <button type="submit" class="btn1 btn">수 정</button>
  </div>
  <!-- Modal footer -->
</form>