/*******************************************************************************
  설  명 : 댓글 수정 모달
  작성일 : 2021-08-06
  작성자 : 김종현
*******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { ToastrService } from 'ngx-toastr';
import { UtilService } from '@app/service/util.service';
import { SBoardService } from '@app/service/board.service';


@Component({
  selector: 'app-view-update',
  templateUrl: './view-update.component.html',
  styleUrls: ['./view-update.component.scss']
})
export class ViewUpdateComponent implements OnInit {
  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  rowData: any;
  public form: FormGroup;
  formErrors = {
    contents: ''
  };
  mem_no: any;

  /*******************************************************************************
    설  명 : 폼 생성
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  buildForm(): void {
    this.form = this.formBuilder.group({
      comment_index: ["", [Validators.required]],
      contents: ["", [Validators.required]],
      writer: ["", [Validators.required]],
    });

    this.form.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors(this.form, this.formErrors);
    });
  };

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    public activeModal: NgbActiveModal,
    private utilService: UtilService,
    private formBuilder: FormBuilder,
    private toastrService: ToastrService,
    private boardService: SBoardService,

  ) {
    this.buildForm();
    
  }

  /*******************************************************************************
    설  명 : 데이터 로딩 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit(): void {
    this.form.patchValue({
      comment_index: this.rowData.comment_index,
      contents: this.rowData.contents,
      writer: this.mem_no
    });
  }

  /*******************************************************************************
    설  명 : 댓글 수정하기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  updateComment() {
    this.boardService.updateComment( this.form ).then( response => {
      if ( response.ResultCode ) {
        this.toastrService.success('댓글이 입력되었습니다.');
        this.activeModal.close();
      } else {
        this.toastrService.error('댓글수정이 실패했습니다.');
      }
    });
  }
}
