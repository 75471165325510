import { Injectable, NgModuleFactoryLoader } from '@angular/core';
import { ActivatedRouteSnapshot, ActivatedRoute, CanActivate, CanActivateChild, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

import { Globals } from '@app/service/globals.service';
import { AuthService } from '@app/service/auth.service';
import { SMemberService } from '@app/service/member.service';
import { UtilService } from '@app/service/util.service';

@Injectable()
export class AuthGuard implements CanActivate {

  public redirectURL: string = '';

  constructor(
    private router: Router,
    private globals: Globals,
    private member: SMemberService,
    private toastrService: ToastrService,
    private readonly loader: NgModuleFactoryLoader,
    private authService: AuthService,
    private activatedRoute: ActivatedRoute,
  ) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    this.redirectURL = state.url;

    return this.member.getMemberInfo( ).then( response => {
      if( response['ResultCode'] == true ) {
        this.authService.login( response );
        return true;
      } else {
        this.toastrService.error(response['ResultMessage'], '');
        this.router.navigate(['/member/login']);
        return false;
      }
    })
    .catch(response => {
      return false;
    });
  }

  canActivateChild(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    return this.canActivate(next, state);
  }
}