import { Component } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { filter, map, mergeMap } from 'rxjs/operators';

import { RestfulService } from '@app/service/restful.service';
import { UrlService } from '@app/service/url.service';
import { SEOService } from '@app/service/seo.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  public previousUrl: string = null;
  public currentUrl: string = null;
  public checkCompanyPage: boolean = true;

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    public router: Router,
    private activatedRoute: ActivatedRoute,
    private location: Location,
    public restful: RestfulService,
    public urlService: UrlService,
    public seoService: SEOService
  ) {}

  /*******************************************************************************
    설  명 : 데이터 초기화
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  public ngOnInit() {
    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      this.previousUrl = this.currentUrl;
      this.currentUrl = event.url;
      this.urlService.setPreviousUrl(this.previousUrl);
    });

    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map(() => this.activatedRoute),
      map((route) => {
        while (route.firstChild) route = route.firstChild;
        return route;
      }),
      filter((route) => route.outlet === 'primary'),
      mergeMap((route) => route.data)
    )
    .subscribe((event) => {
       this.seoService.updateTitle(event['title']);

       if( this.location.path().indexOf( '/mobile' ) > -1 ) {
        this.checkCompanyPage = false;
      }

    });

  }

}
