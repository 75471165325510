/*******************************************************************************
  설  명 : 상품 구매후기 신고
  생성일 : 2020-09-10
  생성자 : 송영석
*******************************************************************************/
import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

import { UtilService } from '@app/service/util.service';
import { CommonService } from '@app/service/common.service';
import { SProductService } from '@app/service/product.service';

@Component({
  selector: 'app-product-review',
  templateUrl: './product-review.component.html',
  styleUrls: ['./product-review.component.scss']
})
export class ProductReviewComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  @Input() data: any;

  public form: FormGroup;
  public formErrors = {};

  public reasonList: any = [];

  /*******************************************************************************
    설  명 : 빌드폼 생성
  *******************************************************************************/
  buildForm(): void {
    this.form = this.formBuilder.group({
      review_seq: ['', [Validators.required]],
      comment: ['', [Validators.required]],
      reason: ['', [Validators.required]],
    });

    this.form.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors(this.form, this.formErrors);
    });
  };

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private utilService: UtilService,
    private commonService: CommonService,
    private toastrService: ToastrService,
    private sProductService: SProductService,
  ) { 
    this.buildForm();
  }

  /*******************************************************************************
    설  명 : 데이터 로딩 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit(): void {
    this.form.patchValue({
      review_seq: this.data.seq
    });

    this.getCommonListCode();
  }

  /*******************************************************************************
    설  명 : 공통코드 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getCommonListCode() {
    // 반품 방식
    this.commonService.getCommonListCode( 'RVR' ).then( response => {
      if( response.ResultCode ) {
        this.reasonList = response.data;
      } else {
        this.toastrService.error( response.message, '');
      }
    });
  }
    
  /*******************************************************************************
    설  명 : 상품문의 저장
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  submit() {
    this.utilService.makeFormDirtyAndUpdateErrors(this.form, this.formErrors);

    if ( this.form.valid ) {
      this.sProductService.setOrderReviewReport( this.form ).then( response => {
        this.activeModal.close(response);
      })
      .catch(response => {
        this.toastrService.success(response, '구매후기 신고');
      });
    }
  }
  
}
