/*******************************************************************************
  설  명 : 레이아웃 푸터
  생성일 : 2020-09-10
  생성자 : 송영석
*******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Meta } from '@angular/platform-browser';
import { Observable, timer } from 'rxjs';

import { AuthService } from '@app/service/auth.service';
import { ToastrService } from 'ngx-toastr';
import { config } from '@app/service/config.service';
import { SMemberService } from '@app/service/member.service';
import { BasicService } from '@app/service/basic.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  public scmURL: string = config.scmUrl;
  isLogin$: Observable<boolean>;

  public isLogIn: boolean;

  public offlineStoreList: any = []

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private authService: AuthService,
    private toastrService: ToastrService,
    private memberService: SMemberService,
    private router: Router,
    private meta: Meta,
    private basicService: BasicService,
  ) { 
    this.isLogin$ = this.authService.isLoggedIn;

    this.isLogin$.subscribe( login => {
      this.isLogIn = login;
    });
  }

  /*******************************************************************************
    설  명 : 데이터 로딩 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit(): void {
    this.getOfflineStoreList();
  }

  /*******************************************************************************
    설  명 : 페이지 최하단으로 이동
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  businessPopup() {
    window.open('http://www.ftc.go.kr/bizCommPop.do?wrkr_no=5048164470', '사업자확인', 'width=700, height=700, scrollbars=1');
  }

  /*******************************************************************************
    설  명 : 페이지 최상단으로 이동
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  toTop() {
    window.scrollTo(0, 0);
  }

  /*******************************************************************************
    설  명 : 페이지 최하단으로 이동
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  toBottom() {
    window.scrollTo(0, 99999);
  }

  /*******************************************************************************
    설  명 : 로그아웃
  *******************************************************************************/
  setLogout() {
    this.memberService.setLogout().then( response => {
      if( response['ResultCode'] == true ) {
        this.toastrService.success(response.ResultMessage, '');

        this.authService.logout('shop');
      } else {
        this.toastrService.error(response.ResultMessage, '');
      }
    })
    .catch(response => {
    });
  }

  /*******************************************************************************
    설  명 : PC버전으로 보기
  *******************************************************************************/
  setChangeViewPC() {
    timer(0).subscribe(() => {
      this.meta.removeTag('name="viewport"');
      this.meta.addTag({ name: 'viewport', content: 'width=1700' })
    });
  }

  /*******************************************************************************
    설  명 : 오프라인 스토어 리스트 가져오기
  *******************************************************************************/
  getOfflineStoreList() {
    this.basicService.getStoreListOff({}).then( response => {
      if( response.ResultCode ) {
        this.offlineStoreList = response.data
      } else this.toastrService.error( response.ResultMessage, '')
    }, error => this.toastrService.error( error, ''))
  }
  
}
