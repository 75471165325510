import { Injectable } from '@angular/core';
import { RestfulService } from '@app/service/restful.service';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {

  constructor(
    private restful: RestfulService
  ) { }

  // 가맹/입점문의 리스트 가져오기
  getContact( search: any ): Promise<any> {
    return this.restful.get({
        program: 'shop',
        service: 'company',
        version: '1.0',
        action: 'getContact'
      }, search
    ).then(this.restful.extractData)
      .catch(this.restful.handleErrorPromise);
  }

  // 가맹문의
  setContact( form: any ): Promise<any> {
    return this.restful.post({
        program: 'shop',
        service: 'company',
        version: '1.0',
        action: 'setContact'
      }, form.value 
    ).then(this.restful.extractData)
      .catch(this.restful.handleErrorPromise);
  }

  // 동호회 등록 신청
  setSocietyApplication( form: any ): Promise<any> {
    return this.restful.post({
        program: 'shop',
        service: 'company',
        version: '1.0',
        action: 'setSocietyApplication'
      }, form.value 
    ).then(this.restful.extractData)
      .catch(this.restful.handleErrorPromise);
  }

  // 세나정품등록
  setRegistrationSena( form: any ): Promise<any> {
    return this.restful.post({
        program: 'shop',
        service: 'company',
        version: '1.0',
        action: 'setRegistrationSena'
      }, form.value 
    ).then(this.restful.extractData)
      .catch(this.restful.handleErrorPromise);
  }
}
