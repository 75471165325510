<div class="modal-header">
  <h4 class="modal-title fl">구매후기 {{title}}</h4>
  <button type="button" class="close" (click)="setClose()">&times;</button>
</div>

<form [formGroup]="form">
  <div class="modal-body">
    <div class="goods clearfix">
      <div class="img"><img [src]="rowDetail[0].thumbnail_url"></div>
      <div class="info">
        <p class="name">{{rowDetail[0].product_name}}</p>
        <p class="info1" *ngFor="let row of rowDetail;">색상 : {{row.display_color_name}} / 사이즈 : {{row.display_size_name ? row.display_size_name : '단품'}} / {{row.qty}}개</p>
        <p class="price">{{totalAmt | number}}원</p>    
      </div>
    </div>
    <div class="grade">
      <div class="img">
        <div class="img-grade-active cp" (click)="grade($event)" [ngClass]="{gradeWidth1:gradeNum==1, gradeWidth2:gradeNum==2, gradeWidth3:gradeNum==3, gradeWidth4:gradeNum==4, gradeWidth5:gradeNum==5}"></div>
        <div class="img-grade-back cp" (click)="grade($event)"></div>
      </div>
      <div class="txt">상품을 평가해 주세요.</div>
    </div>  
    <div>
      <textarea formControlName="comment"	[ngClass]="{formErrors:formErrors['comment']}" placeholder="상품평을 입력해 주세요. (최소 10자 이상)"></textarea>
      <span *ngIf="formErrors.comment" class="alert alert-danger">{{formErrors.comment}}</span>
    </div>  
    
    <div class="pic">
      <label for="input-file" class="pic-btn"><span>사진 첨부</span></label>
      <input type="file" id="input-file" formControlsName="file" class="pic-input" ng2FileSelect (change)="handleUploadFileChanged($event)" multiple="multiple" accept="image/*"  />
      <div class="progress mt5 mb5">
        <div class="progress-bar" [ngClass]="{'width': uploadProgress}"></div>
      </div>
      <div class="mt5 mb5">
        ※ 파일별 50MB 까지, 최대 5개까지 첨부 가능
      </div>
      <ul class="pic-list clearfix">
        <ng-container *ngIf="form.controls.files.value.length > 0 || form.controls.upload.value.length > 0">
          <li *ngFor="let file of form.controls.upload.value; let i = index">
            <img *ngIf="file.thumbnail" [src]="file.thumbnail" />
            <img *ngIf="!file.thumbnail" [src]="file.url" />
            <button type="button" class="del-btn" (click)="setDeleteUploadFile(file, i)"></button>
          </li>
          <li *ngFor="let file of form.controls.files.value; let i = index">
            <img *ngIf="file.thumbnail" [src]="file.thumbnail" />
            <img *ngIf="!file.thumbnail" [src]="file.url" />
            <button type="button" class="del-btn" (click)="deleteFile(file, i)"></button>
          </li>
        </ng-container>
      </ul>
    </div>  

    <div class="btn-area">
      <button type="button" class="btn1 mr10" (click)="setClose()">취소</button>
      <button type="button" class="btn1 red" (click)="submit()">저장</button>
    </div>
  </div>
</form>

