<header class="modal-header">
  <h4 class="modal-title fl">비회원 구매 안내</h4>
  <button class="close" (click)="activeModal.dismiss()">&times;</button>
</header>

<section class="modal-body">
  <div class="title">
    비회원으로 구매를 진행하시겠습니까?
  </div>
</section>

<section class="modal-footer">
  <button class="btn1 dark" (click)="activeModal.dismiss()">취소</button>
  <button class="btn1 red" routerLink="/order" (click)="activeModal.dismiss()">계속진행</button>
  <button class="btn1" routerLink="/member/login" (click)="activeModal.dismiss()">로그인</button>
</section>