/*******************************************************************************
  설  명 : 게시판
  작성일 : 2019-10-04
  작성자 : 송영석
*******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbDateStruct, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { FileUploader, FileItem, FileLikeObject } from 'ng2-file-upload';

import { UtilService } from '@app/service/util.service';
import { CustomValidator } from '@app/service/custom.validators';
import { config } from '@app/service/config.service';
import { Globals } from '@app/service/globals.service';
import { RestfulService } from '@app/service/restful.service';
import { AuthService } from '@app/service/auth.service';

import { SBoardService } from '@app/service/board.service';

import * as $ from 'jquery';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';

const URL = config.apiFileUploadUrl;

@Component({
  selector: 'app-board-add',
  templateUrl: './board-add.component.html',
  styleUrls: ['./board-add.component.scss']
})
export class BoardAddComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  public boardInfo: any = {};
  public boardData: any = {};
  public memberInfo: any = {};

  public params: any = {
    type: 'list',
    board_id: 'notice',
    group_id: '',
    board_seq: '',
    row: 10,
    no: 1,
    group: '',
    text: ''
  };

  public groupTab: any = [];
  public groupList: any = [];

  public form: FormGroup;
  public formErrors = {
    contents: ''
  };

  public uploader: FileUploader;
  public uploadProgress: any = 0;

  public baseURL = config.baseUrl;

  public summernoteConfig: any = {
    ...config.summernoteConfig,
    disableDragAndDrop: true,
    callbacks: {
      onPaste: (e) => {
        const clipboardData = (e.originalEvent || e).clipboardData;
        const items = clipboardData.items;
        let hasImage = false;
  
        for (let i = 0; i < items.length; i++) {
          if (items[i].kind === 'file' && items[i].type.indexOf('image/') !== -1) {
            hasImage = true;
            clipboardData.clearData();
            break;
          }
        }
  
        if (hasImage) {
          
          e.preventDefault();
          alert('이미지 붙여넣기는 허용되지 않습니다.');
        } 
       
        else {
          const textData = clipboardData.getData('Text');
          if (textData) {
            document.execCommand('insertText', false, textData);
          }
        } 
      }
    }
  };

  public title: string = '글쓰기';

  /*******************************************************************************
    설  명 : 폼생성
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  buildForm(): void {
    this.form = this.formBuilder.group({
      board_seq: ['', []],
      board_id: ['', [Validators.required, Validators.maxLength(50)] ],
      group_id: ['', [Validators.maxLength(255)] ],
      subject: ['', [Validators.required, Validators.maxLength(255)] ],
      contents: ['', [Validators.required] ],
      is_pw:[false, [Validators.maxLength(255)]],
      pw: ['', [Validators.maxLength(50)] ],
      email: ['', [Validators.email, Validators.maxLength(255)] ],
      notice_sdate: ['', [] ],
      notice_edate: ['', [] ],
      send_yn:[false, [] ],
      screen_x: ['', [Validators.maxLength(4), CustomValidator.numeric] ],
      screen_y: ['', [Validators.maxLength(4), CustomValidator.numeric] ],
      layer_popup: [false, [] ],
      popup_width: ['', [Validators.maxLength(4), CustomValidator.numeric] ],
      popup_height: ['', [Validators.maxLength(4), CustomValidator.numeric] ],
      popup_yn: [false, [] ],
      popup_link: ['', [] ],
      is_notice: [false, [] ],
      is_titlelink: [false, [] ],
      status: [false, []],
      title_link: ['', [Validators.maxLength(1000)] ],
      file: ['', [] ],
      upload: [ [], [] ],
      files: [ [], [] ],
      tag: ['', [] ]
    });

    this.form.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.form, this.formErrors );
    });
  };

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private utilService: UtilService,
    private toastrService: ToastrService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private boardService: SBoardService,
    private globals: Globals,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private ngbDateParserFormatter: NgbDateParserFormatter,
    private restful: RestfulService,

  ) {
    this.buildForm();
  }

  /*******************************************************************************
    설  명 : 데이터 초기화
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit() {
    this.authService.getLoginInfo.subscribe(data => {
      this.memberInfo = data;
    }).unsubscribe();

    this.activatedRoute.queryParams.subscribe(params => {
      this.params.board_id = ( typeof params.board_id == 'undefined' || params.board_id == '' ) ? 'notice' : params.board_id;
      this.params.group_id = ( typeof params.group_id == 'undefined' || params.group_id == '' || params.group_id == '전체' ) ? '' : params.group_id;
      this.params.board_seq = ( typeof params.board_seq == 'undefined' ) ? '' : params.board_seq;
      this.params.type = ( typeof params.type == 'undefined' || params.type == '' ) ? 'list' : params.type;
      this.params.row = ( typeof params.row == 'undefined' || params.row == '' ) ? '10' : params.row;
      this.params.no = ( typeof params.no == 'undefined' || params.no == '' ) ? '1' : params.no;
      this.params.group = ( typeof params.group == 'undefined' || params.group == '') ? 'subject' : params.group;
      this.params.text = ( typeof params.text == 'undefined' ) ? '' : params.text;

      this.getBoardInfo( this.params.board_id );

      if( this.params.board_seq !== '' )
        this.title = '글수정';
      else
        this.title = '글쓰기';

    }).unsubscribe();
  }

  /*******************************************************************************
    설  명 : 뷰 처리 완료 후
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngAfterViewInit() {
    // 수정일 경우 데이터 불러오기
    if( this.params.board_seq !== '' ) {
      this.getBoardView( this.params.board_id, this.params.board_seq );
    }
  }

  /*******************************************************************************
    설  명 : 게시글 정보 가져오기
    입력값 : 게시판 아이디, 게시판 코드
    리턴값 : 없음
  *******************************************************************************/
  getBoardView( board_id, board_seq ) {
    this.params.viewCheck = 0;

    this.boardService.getBoardView( board_id, board_seq, this.params.viewCheck ).then( response => {
      if( response.ResultCode && response.data !== null ) {
        this.boardData = response.data;
        this.boardData.upload = response.upload;

        this.boardData.is_pw = ( this.boardData.is_pw == '1' ) ? true : false;
        this.boardData.layer_popup = ( this.boardData.layer_popup == '1' ) ? true : false;
        this.boardData.popup_yn = ( this.boardData.popup_yn == '1' ) ? true : false;
        this.boardData.is_notice = ( this.boardData.is_notice == '1' ) ? true : false;
        this.boardData.is_titlelink = ( this.boardData.is_titlelink == '1' ) ? true : false;
        this.boardData.status = ( this.boardData.status == '1' ) ? true : false;
        if( this.boardData.tag != null && this.boardData.tag != '' )
          this.boardData.tag = '#' + this.boardData.tag;

        let dateModel: NgbDateStruct;
        let todayModel: NgbDateStruct;

        dateModel = {
          year: parseInt( moment(this.boardData.notice_sdate).format('YYYY') ),
          month: parseInt( moment(this.boardData.notice_sdate).format('MM') ),
          day: parseInt( moment(this.boardData.notice_sdate).format('DD') )
        };

        todayModel = {
          year: parseInt( moment().format('YYYY') ),
          month: parseInt( moment().format('MM') ),
          day: parseInt( moment().format('DD') )
        };

        if( this.boardData.notice_sdate != '' )
          this.boardData.notice_sdate = dateModel;
        else
          this.boardData.notice_sdate = todayModel;

        dateModel = {
          year: parseInt( moment(this.boardData.notice_edate).format('YYYY') ),
          month: parseInt( moment(this.boardData.notice_edate).format('MM') ),
          day: parseInt( moment(this.boardData.notice_edate).format('DD') )
        };

        if( this.boardData.notice_edate != '' )
          this.boardData.notice_edate = dateModel;
        else
          this.boardData.notice_edate = todayModel;

        this.form.patchValue( this.boardData );

      } else {
        this.toastrService.error( response.ResultMessage, '게시글 수정' );

        this.router.navigate(
        ['/board/galeery'],
        {
          relativeTo: this.activatedRoute,
          queryParams: this.params,
          queryParamsHandling: 'merge', // remove to replace all query params by provided
        });
      }
    });
  }

  /*******************************************************************************
    설  명 : 게시판 정보 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getBoardInfo( board_id ) {
    this.boardService.getBoardInfoId( board_id ).then( response => {
      this.boardInfo = response.data;

      // 권한 검증
      if( this.memberInfo.level < this.boardInfo.auth_write ) {
        this.toastrService.error( '게시글을 작성할 권한이 없습니다.', '게시판');

        switch( this.boardInfo.lvt_default ) {
          case 'A': this.params.type = 'album'; break;
          case 'G': this.params.type = 'gallery'; break;
          case 'C': this.params.type = 'calendar'; break;
          default: case 'L': this.params.type = 'list';
        }

        this.router.navigate(
        ['/board/list'],
        {
          relativeTo: this.activatedRoute,
          queryParams: this.params,
          queryParamsHandling: 'merge', // remove to replace all query params by provided
        });

      } else {
        if( this.boardInfo.lvt_group == '1' ) {
          this.groupList = this.boardInfo.group.split('|');

          if( this.groupList.length > 0 ) {
            this.form.controls.group_id.setValidators( [Validators.required] );
            this.form.controls.group_id.updateValueAndValidity();
          }

          this.boardInfo.group = '전체|' + this.boardInfo.group;
          this.groupTab = this.boardInfo.group.split('|');
        }

        let dateModel: NgbDateStruct;

        dateModel = {
          year: parseInt( moment().format('YYYY') ),
          month: parseInt( moment().format('MM') ),
          day: parseInt( moment().format('DD') )
        };

        // 팝업 게시판일 경우
        if( this.boardInfo.board_type == 'popup' ) {
          this.form.patchValue({
            board_id: this.params.board_id,
            group_id: this.params.group_id,
            popup_yn: true,
            notice_sdate: dateModel,
            notice_edate: dateModel,
            screen_x: 0,
            screen_y: 0,
            layer_popup: false,
            popup_width: 800,
            popup_height: 600
          });
        } else {
          this.form.patchValue({
            board_id: this.params.board_id,
            group_id: this.params.group_id,
            notice_sdate: dateModel,
            notice_edate: dateModel
          });
        }

        // 업로드 허용 파일 설정
        //var filetype: string[] = ['image', 'doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx', 'pdf', 'txt', 'hwp'];
        var filetype: string[] = this.boardInfo.upload_file_type.split('|');

        // 업로더 생성
        this.uploader = new FileUploader({
          url: URL,
          itemAlias: 'file',
          maxFileSize: this.boardInfo.upload_total_size * (1024 * 1024), // 최대 업로드 허용 용량
          allowedFileType: filetype // 허용 업로드 파일 타입
        });

        // 파일업로드 설정
        this.uploader.onAfterAddingFile = (file) => {
          file.withCredentials = false;
          this.uploadProgress = '0%';
        };

        // 업로드 폼생성시 처리
        this.uploader.onBuildItemForm = (fileItem: FileItem, form: any) => {
          form.append( "board_id", this.boardInfo.board_id );
          form.append( "token", this.globals.token );
        };

        // 업로드시 프로그레스바 처리
        this.uploader.onProgressItem = (fileItem: FileItem, progress: any) => {
          this.uploadProgress = progress + '%';
        };

        // 업로드 용량 초과시 처리
        this.uploader.onWhenAddingFileFailed = (item: FileLikeObject, filter: any, options: any) => {
          if( filter.name == 'fileSize' ) {
            this.toastrService.error( '파일 업로드 용량(' + this.boardInfo.upload_total_size + 'MB)을 초과하였습니다.', '파일 업로드');
          }
           else if( filter.name == 'fileType' ) {
            this.toastrService.error( '허용되는 업로드 파일 타입이 아닙니다.', '파일 업로드');
          }

          // indicator 표시 숨김
          setTimeout (() => {
            this.restful.indicator.next(false);
          });
        };

        // 파일업로드 완료시 처리
        this.uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
          response = $.parseJSON( response );

          if( response.result ) {
            this.toastrService.success( response.message, '파일 업로드');

            let files = this.form.controls.files.value;

            files.push({
              url: this.baseURL + response.url,
              origin: response.origin_filename,
              size: response.size,
              thumbnail: response.thumbnail,
              thumbnail_result: response.thumbnail_result,
            });

            this.form.patchValue( {files: files} );

          } else {
            this.toastrService.error( response.message, '파일 업로드');
          }

          // indicator 표시 숨김
          this.restful.indicator.next(false);
        };

        setTimeout (() => {
          this.formErrors.contents = '';
        },300);
      }
    });
  }

  /*******************************************************************************
    설  명 : 파일 변경시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  handleUploadFileChanged(event) {
    this.uploader.clearQueue();

    // 파일 없을 경우 return false;
    if( event.target.files.length < 1 ) return false;

    // 전체 허용 용량 검증

    let files:File[] = event.target.files;
    let filteredFiles:File[] = [];
    for(var f of files) {
      filteredFiles.push(f);
    }

    let options = null;
    let filters = null;

    this.uploader.addToQueue(filteredFiles, options, filters);

    // indicator 표시
    this.restful.indicator.next(true);

    this.uploader.uploadAll()
  }

  /*******************************************************************************
    설  명 : 게시글 저장
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  submit() {
    this.utilService.makeFormDirtyAndUpdateErrors(this.form, this.formErrors);

    if(this.form.valid) {

      this.form.patchValue({
        notice_sdate: this.ngbDateParserFormatter.format( this.form.controls.notice_sdate.value ),
        notice_edate: this.ngbDateParserFormatter.format( this.form.controls.notice_edate.value )
      });
      const contentsVal = this.form.value.contents;
      const maxImageTagLength = 2000;
      if(contentsVal.includes('<img')){
         if  (contentsVal.length > maxImageTagLength) {
          this.toastrService.error('본문에 이미지를 붙여넣을 수 없습니다. 첨부파일을 클릭해서 이미지를 포함해주세요.', '에러');
          return;
         }
      }      

      this.boardService.setWrite(this.form).then( response => {
        if( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, '');

          // 공지사항으로 인해  group_id 에 쇼핑몰이 넘어가므로 여기서 해제
          this.params.group_id = '';

          this.router.navigate(
          ['/board/gallery'],
          {
            relativeTo: this.activatedRoute,
            queryParams: this.params,
            queryParamsHandling: 'merge', // remove to replace all query params by provided
          });

        } else {
          this.toastrService.error( response.ResultMessage, '');
        }
      });
    } else {
      this.toastrService.error('필수 입력항목을 확인하시기 바랍니다.', '');
    }
  }

  /*******************************************************************************
    설  명 : 확장명 가져오기
    입력값 : filename
    리턴값 : ext
  *******************************************************************************/
  getExt( filename ) {
    if( typeof filename == 'undefined' ) return '';
    else return filename.substr(filename.lastIndexOf('.') + 1);
  }

  /*******************************************************************************
    설  명 : 콤마 표시
    입력값 : 숫자
    리턴값 : 콤마 숫자
  *******************************************************************************/
  getComma( num ) {
    var str = String(num);
    return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
  }

  /*******************************************************************************
    설  명 : 파일 삭제
    입력값 : 파일
    리턴값 : 없음
  *******************************************************************************/
  deleteFile( file: any, index ) {
    if( confirm("선택하신 파일을 삭제하시겠습니까?") ) {
      let tmp = this.form.controls.files.value;

      tmp.splice( index, 1 );

      this.form.patchValue({files: tmp});
    }
  }

  /*******************************************************************************
    설  명 : 파일 삭제
    입력값 : 업로드 파일 정보
    리턴값 : 없음
  *******************************************************************************/
  deleteUploadFile( upload: any, index: any ) {
    if( confirm("선택하신 파일을 삭제하시겠습니까?") ) {
      this.boardService.deleteUploadFile( upload.board_id, upload.board_seq, upload.seq ).then( response => {
        if( response.ResultCode ) {
          this.boardData.upload.splice( index, 1 );

          this.toastrService.success( response.ResultMessage, '게시글 첨부파일 삭제');
        } else {
          this.toastrService.error( response.ResultMessage, '게시글 첨부파일 삭제');
        }
      });
    }
  }

  /*******************************************************************************
    설  명 : 업로드 된 파일 명이 이미지 인지 체크
    입력값 : 확장명
    리턴값 : true / false
  *******************************************************************************/
  checkImage( ext: string ) {
    let extArray = ['jpg', 'jpeg', 'gif', 'bmp', 'png'];

    if( extArray.indexOf( ext.toLowerCase() ) == -1 ) return false;
    else return true;
  }
  

  /*******************************************************************************
    설  명 : 이미지 서머노트에 삽입
    입력값 : 파일
    리턴값 : 없음
  *******************************************************************************/
  insertImage( file ){
    if( this.checkImage(this.getExt(file.origin)) ) {
      let addHtml: any;
      if( this.params.board_id == 'popup' )
        addHtml = this.form.controls.contents.value + '<img src="' + file.url + '" />';
      else
        addHtml = this.form.controls.contents.value + '<br /><img src="' + file.url + '" style="max-width:95%;display:block; margin:auto;"  />';

      this.form.patchValue( {contents: addHtml} );
    }
  }

  /*******************************************************************************
    설  명 : 목록으로 되돌아가기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  goList() {
    let routerUri = '/board/gallery';

    if (this.boardInfo.lvt_default == 'G')  {
      this.params.type = 'gallery';
      this.params.group_id = '';
      routerUri = '/board/gallery' ; 
    } else {
      this.params.type = 'list';
      routerUri = '/board/list' ; 
    }
    
    this.router.navigate(
    [routerUri],
    {
      relativeTo: this.activatedRoute,
      queryParams: this.params,
      queryParamsHandling: 'merge', // remove to replace all query params by provided
    });
  }

  /*******************************************************************************
    설  명 : 첨부 파일 가능 여부 확인, 일반 사용자는 금지 - 해킹 가능
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  authCheck() {
    // 관리자 권한일 경우
    if( this.memberInfo.level >= 80 )
      return true;
    else
      return false;
  }

}
