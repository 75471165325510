/*******************************************************************************
  ��  �� : ��й�ȣ ã�� - ����
  ������ : 2020-09-10
  ������ : �ۿ���
*******************************************************************************/
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-member-find-pw-fail',
  templateUrl: './member-find-pw-fail.component.html',
  styleUrls: ['./member-find-pw-fail.component.scss']
})
export class MemberFindPwFailComponent implements OnInit {

  /*******************************************************************************
    ���� : ���� ���� �����
  *******************************************************************************/




  /*******************************************************************************
    ��  �� : ������
    �Է°� : ����
    ���ϰ� : ����
  *******************************************************************************/
  constructor() { }

  /*******************************************************************************
    ��  �� : ������ �ε� ó��
    �Է°� : ����
    ���ϰ� : ����
  *******************************************************************************/
  ngOnInit(): void {
  }

}
