<app-header></app-header>

<div class="inner">
  <div class="page-header clearfix">
    <div class="page-title">자주하는 질문</div>
  </div>
  
  <div class="search-area">
    <span class="pc-view">질문검색</span>
    <select class="pc-view">
      <option>전체</option>
    </select>  
    <input type="text" />
    <button class="find-btn">검색</button>
  </div>
  
  <ul class="qna-kind clearfix pc-view">
    <li [ngClass]="{on:qnaKind == 0}" (click)="qnaKind = 0;">전체</li>
    <li [ngClass]="{on:qnaKind == 1}" (click)="qnaKind = 1;">분류1</li>
    <li [ngClass]="{on:qnaKind == 2}" (click)="qnaKind = 2;">분류2</li>
    <li [ngClass]="{on:qnaKind == 3}" (click)="qnaKind = 3;">분류3</li>
    <li [ngClass]="{on:qnaKind == 4}" (click)="qnaKind = 4;">분류4</li>
    <li [ngClass]="{on:qnaKind == 5}" (click)="qnaKind = 5;">분류5</li>
    <li [ngClass]="{on:qnaKind == 6}" (click)="qnaKind = 6;">분류6</li>
    <li [ngClass]="{on:qnaKind == 7}" (click)="qnaKind = 7;">분류7</li>
  </ul>
  <div class="qna-kind mobile-view">
    <select>
      <option>전체</option>
      <option>분류1</option>
      <option>분류2</option>
      <option>분류3</option>
      <option>분류4</option>
      <option>분류5</option>
      <option>분류6</option>
      <option>분류7</option>      
    </select>
  </div>  
  <table class="list-table">
  <colgroup>
    <col style="width: 7%">
    <col style="width: 11%">
    <col style="width: 81%">
  </colgroup>    

  <thead>
  <tr>
    <th>번호</th>
    <th>분류</th>
    <th>제목</th>
  </tr>    
  </thead>
  
   <tbody>
   <tr (click)="qnaNo=1;">
     <td>2</td>
     <td>상품</td>
     <td class="tl title">자주하는 질문 제목이 나옵니다.</td>
   </tr>
   <tr class="board-contents" [ngClass]="{view:qnaNo==1}">
     <td colspan="3" class="tl ">
       자주하는 질문에 대한 내용이 나옵니다.<br/> 
       자주하는 질문에 대한 내용이 나옵니다. 자주하는 질문에 대한 내용이 나옵니다. 자주하는 질문에 대한 내용이 나옵니다.<br/> 
       자주하는 질문에 대한 내용이 나옵니다. 자주하는 질문에 대한 내용이 나옵니다. 자주하는 질문에 대한 내용이 나옵니다. 자주하는 질문에 대한 내용이 나옵니다.<br/> 
       자주하는 질문에 대한 내용이 나옵니다. 자주하는 질문에 대한 내용이 나옵니다. 자주하는 질문에 대한 내용이 나옵니다. <br/>
       자주하는 질문에 대한 내용이 나옵니다. 자주하는 질문에 대한 내용이 나옵니다. <br/>
       <br/>
       자주하는 질문에 대한 내용이 나옵니다. 자주하는 질문에 대한 내용이 나옵니다. 자주하는 질문에 대한 내용이 나옵니다.<br/> 
       자주하는 질문에 대한 내용이 나옵니다. 자주하는 질문에 대한 내용이 나옵니다. 자주하는 질문에 대한 내용이 나옵니다. 자주하는 질문에 대한 내용이 나옵니다.<br/>
       자주하는 질문에 대한 내용이 나옵니다. 자주하는 질문에 대한 내용이 나옵니다. <br/>
       <br/>
       감사합니다.
     </td>
   </tr>
   <tr (click)="qnaNo=2;">
     <td>1</td>
     <td>교환/반품/환불</td>
     <td class="tl title">자주하는 질문 제목이 나옵니다.</td>
   </tr>
   <tr class="board-contents" [ngClass]="{view:qnaNo==2}">
     <td colspan="3" class="tl">
           자주하는 질문에 대한 내용이 나옵니다.<br/> 
           자주하는 질문에 대한 내용이 나옵니다. 자주하는 질문에 대한 내용이 나옵니다. 자주하는 질문에 대한 내용이 나옵니다.<br/> 
           자주하는 질문에 대한 내용이 나옵니다. 자주하는 질문에 대한 내용이 나옵니다. 자주하는 질문에 대한 내용이 나옵니다. 자주하는 질문에 대한 내용이 나옵니다.<br/> 
           자주하는 질문에 대한 내용이 나옵니다. 자주하는 질문에 대한 내용이 나옵니다. 자주하는 질문에 대한 내용이 나옵니다. <br/>
           자주하는 질문에 대한 내용이 나옵니다. 자주하는 질문에 대한 내용이 나옵니다. <br/>
           <br/>
           자주하는 질문에 대한 내용이 나옵니다. 자주하는 질문에 대한 내용이 나옵니다. 자주하는 질문에 대한 내용이 나옵니다.<br/> 
           자주하는 질문에 대한 내용이 나옵니다. 자주하는 질문에 대한 내용이 나옵니다. 자주하는 질문에 대한 내용이 나옵니다. 자주하는 질문에 대한 내용이 나옵니다.<br/>
           자주하는 질문에 대한 내용이 나옵니다. 자주하는 질문에 대한 내용이 나옵니다. <br/>
       <br/>
              감사합니다.
     </td>
   </tr>   
   </tbody>
  </table>  
  
  <div class="pagination-area">
    <ngb-pagination
      [(page)]="page"
      [pageSize]="12"
      [collectionSize]="24"
      [boundaryLinks]="true"
      >
      <ng-template ngbPaginationFirst>처음</ng-template>
      <ng-template ngbPaginationLast>마지막</ng-template>
      <ng-template ngbPaginationPrevious>이전</ng-template>
      <ng-template ngbPaginationNext>다음</ng-template>
    </ngb-pagination>
  </div>   
</div>  

<app-footer></app-footer>