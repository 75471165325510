<section class="apage-title">
    <div class="title-bar"></div>
    <h3>{{boardInfo.board_name}} - {{title}}</h3>
  </section>
  
  <section class="adata-list-area">
    <form [formGroup]="form">
      <inputEx type="hidden" name="board_id" [formGroup]="form" [formErrors]="formErrors"></inputEx>
      <inputEx type="hidden" name="board_seq" [formGroup]="form" [formErrors]="formErrors"></inputEx>
  
      <!-- 기본게시판 -->
      <section *ngIf="boardInfo.board_type == 'normal'" class="data-list">
        <table class="table table-bordered table-small">
        <caption class="hidden">게시판 등록/수정</caption>
  
        <colgroup>
          <col style="width:200px" />
          <col />
        </colgroup>
  
        <tbody>
        <tr>
          <th scope="row"><i class="material-icons cred f11">star</i> <label for="subject">제목</label></th>
          <td>
            <inputEx type="text" name="subject" [formGroup]="form" [formErrors]="formErrors" autofocus="true"></inputEx>
          </td>
        </tr>
        <tr *ngIf="boardInfo.lvt_group && groupList.length > 0">
          <th scope="row"><i class="material-icons cred f11">star</i> 그룹선택</th>
          <td>
            <div class="btn-group">
              <button
                type="button"
                *ngFor="let group of groupList"
                class="btn btn-default btn-small f12"
                [ngClass]="{active:form.controls.group_id.value==group}"
                (click)="form.patchValue({group_id: group})"
              >{{group}}</button>
            </div>
            <inputEx type="hidden" name="group_id" [formGroup]="form" [formErrors]="formErrors"></inputEx>
          </td>
        </tr>
          <ng-container *ngIf="memberInfo.level >= 80">
          <tr >
          <th scope="row"><label for="is_notice">공지글 여부</label></th>
          <td>
            <div class="btn-group fl">
              <button type="button" class="btn btn-default" [ngClass]="{active:form.controls.is_notice.value}" (click)="form.patchValue({is_notice:true})">공지글</button>
              <button type="button" class="btn btn-default" [ngClass]="{active:form.controls.is_notice.value==false}" (click)="form.patchValue({is_notice:false})">일반글</button>
            </div>
            <div *ngIf="form.controls.is_notice.value" class="fl ml10 w400px form-inline">
              <inputEx type="date" name="notice_sdate" [formGroup]="form" [formErrors]="formErrors"></inputEx>
              ~
              <inputEx type="date" name="notice_edate" [formGroup]="form" [formErrors]="formErrors"></inputEx>
            </div>
            <div class="cb"></div>
            <inputEx type="hidden" name="is_notice" [formGroup]="form" [formErrors]="formErrors"></inputEx>
          </td>
        </tr>
        </ng-container>
       <tr>
          <th scope="row"><label for="is_notice">비밀글 여부</label></th>
          <td>
            <div class="btn-group fl">
              <button type="button" class="btn btn-default" [ngClass]="{active:form.controls.is_pw.value}" (click)="form.patchValue({is_pw:true})">비밀글</button>
              <button type="button" class="btn btn-default" [ngClass]="{active:form.controls.is_pw.value==false}" (click)="form.patchValue({is_pw:false})">일반글</button>
            </div>
            <div class="fl ml10 w400px">
              <inputEx *ngIf="form.controls.is_pw.value && memberInfo.level < 1" type="text" name="pw" [formGroup]="form" [formErrors]="formErrors" placeholder="비밀번호를 입력하세요."></inputEx>
            </div>
            <div class="fl">
              <div class="ml10 pt7">※ 비밀글을 체크하시면 작성자 본인과 관리자만 조회가 가능합니다.</div>
            </div>
            <div class="cb"></div>
          </td>
        </tr>
        <tr *ngIf="memberInfo.level >= 80">
          <th scope="row"><label for="is_notice">타이틀 클릭 링크 여부</label></th>
          <td>
            <div class="btn-group fl">
              <button type="button" class="btn btn-default" [ngClass]="{active:form.controls.is_titlelink.value}" (click)="form.patchValue({is_titlelink:true})">링크</button>
              <button type="button" class="btn btn-default" [ngClass]="{active:form.controls.is_titlelink.value==false}" (click)="form.patchValue({is_titlelink:false})">링크안함</button>
            </div>
            <div class="fl ml10 w400px">
              <inputEx *ngIf="form.controls.is_titlelink.value" type="text" name="title_link" [formGroup]="form" [formErrors]="formErrors" placeholder="http://링크주소"></inputEx>
            </div>
          </td>
        </tr>
        <tr>
          <th scope="row"><label for="tag">태그</label></th>
          <td>
            <inputEx type="text" name="tag" [formGroup]="form" [formErrors]="formErrors" placeholder="#tag #tag #tag"></inputEx>
          </td>
        </tr>
        <tr *ngIf="memberInfo.level >= boardInfo.auth_upload_file">
          <th scope="row"><label for="is_notice">파일 업로드</label></th>
          <td>
            <inputEx type="file" name="file" [formGroup]="form" [formErrors]="formErrors" ng2FileSelect (change)="handleUploadFileChanged($event)"></inputEx>
            <div class="progress mt5 mb5">
              <div class="progress-bar" [ngStyle]="{'width': uploadProgress}"></div>
            </div>
            <div class="mt5">
            ※ 파일별 {{boardInfo.upload_file_size}}MB 까지, 최대 10개까지 첨부 가능, 전체 첨부용량 {{boardInfo.upload_total_size}}MB 제한
            </div>
            <div *ngIf="form.controls.files.value.length > 0 || form.controls.upload.value.length > 0" class="file-div">
              <ul class="file-ul">
                <li *ngFor="let file of form.controls.upload.value; let i = index" class="fl">
                  <div class="upload-item" [ngClass]="{uploadImage: checkImage(getExt(file.origin))}">
                    <div *ngIf="checkImage(getExt(file.origin))" class="upload-image" (click)="insertImage(file)">
                      <img [src]="file.url" [alt]="file.origin" />
                    </div>
                    <div *ngIf="!checkImage(getExt(file.origin))" class="upload-image1">{{getExt(file.origin)}}</div>
                    <div class="upload-filename"><a href="{{file.url}}" target="_blank" title="{{file.origin}}">{{file.origin}}</a></div>
                    <div class="upload-size">
                      ({{getComma(file.size)}} KB)
                      <a class="cp ml5" (click)="deleteUploadFile( file, i )"><img src="/assets/images/del_ico.png" /></a>
                    </div>
                  </div>
                </li>
                <li *ngFor="let file of form.controls.files.value; let i = index" class="fl">
                  <div class="upload-item" [ngClass]="{uploadImage: checkImage(getExt(file.origin))}">
                    <div *ngIf="checkImage(getExt(file.origin))" class="upload-image" (click)="insertImage(file)">
                      <img [src]="file.url" [alt]="file.origin" />
                    </div>
                    <div *ngIf="!checkImage(getExt(file.origin))" class="upload-image1">{{getExt(file.origin)}}</div>
                    <div class="upload-filename"><a href="{{file.url}}" target="_blank" title="{{file.origin}}">{{file.origin}}</a></div>
                    <div class="upload-size">
                      ({{getComma(file.size)}} KB)
                      <a class="cp ml5" (click)="deleteFile( file, i )">
                        <img src="/assets/images/del_ico.png" />
                      </a>
                    </div>
                  </div>
                </li>
                <li class="cb"></li>
              </ul>
            </div>
          </td>
        </tr>
        </tbody>
        </table>
      </section>
      <!-- 기본게시판 -->
  
      <!-- 자주하는 질문 -->
      <section *ngIf="boardInfo.board_type == 'faq'" class="data-list">
        <table class="table table-bordered table-small">
        <caption class="hidden">게시판 등록/수정</caption>
  
        <colgroup>
          <col style="width:200px" />
          <col />
        </colgroup>
  
        <tbody>
        <tr>
          <th scope="row"><i class="material-icons cred f11">star</i> <label for="subject">제목</label></th>
          <td>
            <inputEx type="text" name="subject" [formGroup]="form" [formErrors]="formErrors" autofocus="true"></inputEx>
          </td>
        </tr>
        <tr *ngIf="boardInfo.lvt_group && groupList.length > 0">
          <th scope="row"><i class="material-icons cred f11">star</i> 그룹선택</th>
          <td>
            <div class="btn-group">
              <button
                type="button"
                *ngFor="let group of groupList"
                class="btn btn-default btn-small f12"
                [ngClass]="{active:form.controls.group_id.value==group}"
                (click)="form.patchValue({group_id: group})"
              >{{group}}</button>
            </div>
            <inputEx type="hidden" name="group_id" [formGroup]="form" [formErrors]="formErrors"></inputEx>
          </td>
        </tr>
        <tr>
          <th scope="row"><label for="tag">태그</label></th>
          <td>
            <inputEx type="text" name="tag" [formGroup]="form" [formErrors]="formErrors" placeholder="#tag #tag #tag"></inputEx>
          </td>
        </tr>
        <tr *ngIf="memberInfo.level >= boardInfo.auth_upload_file">
          <th scope="row"><label for="is_notice">파일 업로드</label></th>
          <td>
            <inputEx type="file" name="file" [formGroup]="form" [formErrors]="formErrors" ng2FileSelect (change)="handleUploadFileChanged($event)"></inputEx>
            <div class="progress mt5 mb5">
              <div class="progress-bar" [ngStyle]="{'width': uploadProgress}"></div>
            </div>
            <div class="mt5">
            ※ 파일별 {{boardInfo.upload_file_size}}MB 까지, 최대 10개까지 첨부 가능, 전체 첨부용량 {{boardInfo.upload_total_size}}MB 제한
            </div>
            <div *ngIf="form.controls.files.value.length > 0 || form.controls.upload.value.length > 0" class="file-div">
              <ul class="file-ul">
                <li *ngFor="let file of form.controls.upload.value; let i = index" class="fl">
                  <div class="upload-item" [ngClass]="{uploadImage: checkImage(getExt(file.origin))}">
                    <div *ngIf="checkImage(getExt(file.origin))" class="upload-image" (click)="insertImage(file)">
                      <img [src]="file.url" [alt]="file.origin" />
                    </div>
                    <div *ngIf="!checkImage(getExt(file.origin))" class="upload-image1">{{getExt(file.origin)}}</div>
                    <div class="upload-filename"><a href="{{file.url}}" target="_blank" title="{{file.origin}}">{{file.origin}}</a></div>
                    <div class="upload-size">
                      ({{getComma(file.size)}} KB)
                      <a class="cp ml5" (click)="deleteUploadFile( file, i )"><img src="/assets/images/del_ico.png" /></a>
                    </div>
                  </div>
                </li>
                <li *ngFor="let file of form.controls.files.value; let i = index" class="fl">
                  <div class="upload-item" [ngClass]="{uploadImage: checkImage(getExt(file.origin))}">
                    <div *ngIf="checkImage(getExt(file.origin))" class="upload-image" (click)="insertImage(file)">
                      <img [src]="file.url" [alt]="file.origin" />
                    </div>
                    <div *ngIf="!checkImage(getExt(file.origin))" class="upload-image1">{{getExt(file.origin)}}</div>
                    <div class="upload-filename"><a href="{{file.url}}" target="_blank" title="{{file.origin}}">{{file.origin}}</a></div>
                    <div class="upload-size">
                      ({{getComma(file.size)}} KB)
                      <a class="cp ml5" (click)="deleteFile( file, i )">
                        <img src="/assets/images/del_ico.png" />
                      </a>
                    </div>
                  </div>
                </li>
                <li class="cb"></li>
              </ul>
            </div>
          </td>
        </tr>
        </tbody>
        </table>
      </section>
      <!-- 자주하는 질문 -->
  
      <!-- 팝업 -->
      <section *ngIf="boardInfo.board_type == 'popup'" >
        <table class="table table-bordered table-small">
        <caption class="hidden">게시판 등록/수정</caption>
  
        <colgroup>
          <col style="width:200px" />
          <col />
        </colgroup>
  
        <tbody>
        <tr>
          <th scope="row"><i class="material-icons cred f11">star</i> <label for="subject">제목</label></th>
          <td>
            <inputEx type="text" name="subject" [formGroup]="form" [formErrors]="formErrors" autofocus="true"></inputEx>
          </td>
        </tr>
        <tr>
          <th scope="row"><i class="material-icons cred f11">star</i> <label for="subject">팝업게시기간</label></th>
          <td>
            <div class="btn-group fl mr10">
              <button type="button" class="btn btn-default" [ngClass]="{active:form.controls.layer_popup.value}" (click)="form.patchValue({layer_popup:true})">레이어팝업</button>
              <button type="button" class="btn btn-default" [ngClass]="{active:form.controls.layer_popup.value==false}" (click)="form.patchValue({layer_popup:false})">새창으로</button>
            </div>
            <div class="btn-group fl">
              <button type="button" class="btn btn-default" [ngClass]="{active:form.controls.popup_yn.value}" (click)="form.patchValue({popup_yn:true})">팝업표시</button>
              <button type="button" class="btn btn-default" [ngClass]="{active:form.controls.popup_yn.value==false}" (click)="form.patchValue({popup_yn:false})">표시안함</button>
            </div>
            <div *ngIf="form.controls.popup_yn.value" class="fl ml10 w400px form-inline">
              <inputEx type="date" name="notice_sdate" [formGroup]="form" [formErrors]="formErrors" placeholder="팝업게시시작일"></inputEx>
              ~
              <inputEx type="date" name="notice_edate" [formGroup]="form" [formErrors]="formErrors" placeholder="팝업게시종료일"></inputEx>
            </div>
            <div class="cb"></div>
            <div class="mt5">
              ※ 팝업 게시기간을 선택하지 않으면 팝업이 표시되지 않습니다.
            </div>
            <inputEx type="hidden" name="popup_yn" [formGroup]="form" [formErrors]="formErrors"></inputEx>
          </td>
        </tr>
        <tr>
          <th scope="row"><i class="material-icons cred f11">star</i> <label for="popup_link">팝업링크</label></th>
          <td>
            <inputEx type="text" name="popup_link" [formGroup]="form" [formErrors]="formErrors" placeholder="이미지 클릭 시 이동할 링크를 입력하시기 바랍니다"></inputEx>
          </td>
        </tr>
        <tr>
          <th scope="row"><i class="material-icons cred f11">star</i> <label for="popup_width">크기</label></th>
          <td>
            <div class="form-inline">
              <inputEx type="text" name="popup_width" [formGroup]="form" [formErrors]="formErrors" placeholder="가로크기"></inputEx>
              <span class="ml10 mr10">/</span>
              <inputEx type="text" name="popup_height" [formGroup]="form" [formErrors]="formErrors" placeholder="세로크기"></inputEx>
  
              <span class="ml10">※ 단위는 픽셀(Pixel) 단위로 입력하시기 바랍니다.</span>
            </div>
          </td>
        </tr>
        <tr>
          <th scope="row"><i class="material-icons cred f11">star</i> <label for="screen_x">위치</label></th>
          <td>
            <div class="form-inline">
              <inputEx type="text" name="screen_x" [formGroup]="form" [formErrors]="formErrors" placeholder="왼쪽"></inputEx>
              <span class="ml10 mr10">/</span>
              <inputEx type="text" name="screen_y" [formGroup]="form" [formErrors]="formErrors" placeholder="위"></inputEx>
  
              <span class="ml10">※ 단위는 픽셀(Pixel) 단위로 입력하시기 바랍니다.</span>
            </div>
          </td>
        </tr>
        <tr *ngIf="memberInfo.level >= boardInfo.auth_upload_file">
          <th scope="row"><label for="is_notice">파일 업로드</label></th>
          <td>
            <inputEx type="file" name="file" [formGroup]="form" [formErrors]="formErrors" ng2FileSelect (change)="handleUploadFileChanged($event)"></inputEx>
            <div class="progress mt5 mb5">
              <div class="progress-bar" [ngStyle]="{'width': uploadProgress}"></div>
            </div>
            <div class="mt5">
            ※ 파일별 {{boardInfo.upload_file_size}}MB 까지, 최대 10개까지 첨부 가능, 전체 첨부용량 {{boardInfo.upload_total_size}}MB 제한
            </div>
            <div *ngIf="form.controls.files.value.length > 0 || form.controls.upload.value.length > 0" class="file-div">
              <ul class="file-ul">
                <li *ngFor="let file of form.controls.upload.value; let i = index" class="fl">
                  <div class="upload-item" [ngClass]="{uploadImage: checkImage(getExt(file.origin))}">
                    <div *ngIf="checkImage(getExt(file.origin))" class="upload-image" (click)="insertImage(file)">
                      <img [src]="file.url" [alt]="file.origin" />
                    </div>
                    <div *ngIf="!checkImage(getExt(file.origin))" class="upload-image1">{{getExt(file.origin)}}</div>
                    <div class="upload-filename"><a href="{{file.url}}" target="_blank" title="{{file.origin}}">{{file.origin}}</a></div>
                    <div class="upload-size">
                      ({{getComma(file.size)}} KB)
                      <a class="cp ml5" (click)="deleteUploadFile( file, i )"><img src="/assets/images/del_ico.png" /></a>
                    </div>
                  </div>
                </li>
                <li *ngFor="let file of form.controls.files.value; let i = index" class="fl">
                  <div class="upload-item" [ngClass]="{uploadImage: checkImage(getExt(file.origin))}">
                    <div *ngIf="checkImage(getExt(file.origin))" class="upload-image" (click)="insertImage(file)">
                      <img [src]="file.url" [alt]="file.origin" />
                    </div>
                    <div *ngIf="!checkImage(getExt(file.origin))" class="upload-image1">{{getExt(file.origin)}}</div>
                    <div class="upload-filename"><a href="{{file.url}}" target="_blank" title="{{file.origin}}">{{file.origin}}</a></div>
                    <div class="upload-size">
                      ({{getComma(file.size)}} KB)
                      <a class="cp ml5" (click)="deleteFile( file, i )">
                        <img src="/assets/images/del_ico.png" />
                      </a>
                    </div>
                  </div>
                </li>
                <li class="cb"></li>
              </ul>
            </div>
          </td>
        </tr>
        </tbody>
        </table>
      </section>
      <!-- 팝업 -->
  
      <!-- 컨텐츠 게시판 -->
      <section *ngIf="boardInfo.board_type == 'contents'" class="data-list">
        <table class="table table-bordered table-small">
        <caption class="hidden">게시판 등록/수정</caption>
  
        <colgroup>
          <col style="width:200px" />
          <col />
        </colgroup>
  
        <tbody>
        <tr>
          <th scope="row"><i class="material-icons cred f11">star</i> <label for="subject">제목</label></th>
          <td>
            <inputEx type="text" name="subject" [formGroup]="form" [formErrors]="formErrors" autofocus="true"></inputEx>
          </td>
        </tr>
        <tr>
          <th scope="row"><label for="is_notice">파일 업로드</label></th>
          <td>
            <inputEx type="file" name="file" [formGroup]="form" [formErrors]="formErrors" ng2FileSelect (change)="handleUploadFileChanged($event)"></inputEx>
            <div class="progress mt5 mb5">
              <div class="progress-bar" [ngStyle]="{'width': uploadProgress}"></div>
            </div>
            <div class="mt5">
            ※ 파일별 {{boardInfo.upload_total_size}}MB 까지, 최대 10개까지 첨부 가능, 전체 첨부용량 {{boardInfo.upload_total_size}}MB 제한
            </div>
            <div *ngIf="form.controls.files.value.length > 0 || form.controls.upload.value.length > 0" class="file-div">
              <ul class="file-ul">
                <li *ngFor="let file of form.controls.upload.value; let i = index" class="fl">
                  <div class="upload-item" [ngClass]="{uploadImage: checkImage(getExt(file.origin))}">
                    <div *ngIf="checkImage(getExt(file.origin))" class="upload-image" (click)="insertImage(file)">
                      <img [src]="file.url" [alt]="file.origin" />
                    </div>
                    <div *ngIf="!checkImage(getExt(file.origin))" class="upload-image1">{{getExt(file.origin)}}</div>
                    <div class="upload-filename"><a href="{{file.url}}" target="_blank" title="{{file.origin}}">{{file.origin}}</a></div>
                    <div class="upload-size">
                      ({{getComma(file.size)}} KB)
                      <a class="cp ml5" (click)="deleteUploadFile( file, i )"><img src="/assets/images/del_ico.png" /></a>
                    </div>
                  </div>
                </li>
                <li *ngFor="let file of form.controls.files.value; let i = index" class="fl">
                  <div class="upload-item" [ngClass]="{uploadImage: checkImage(getExt(file.origin))}">
                    <div *ngIf="checkImage(getExt(file.origin))" class="upload-image" (click)="insertImage(file)">
                      <img [src]="file.url" [alt]="file.origin" />
                    </div>
                    <div *ngIf="!checkImage(getExt(file.origin))" class="upload-image1">{{getExt(file.origin)}}</div>
                    <div class="upload-filename"><a href="{{file.url}}" target="_blank" title="{{file.origin}}">{{file.origin}}</a></div>
                    <div class="upload-size">
                      ({{getComma(file.size)}} KB)
                      <a class="cp ml5" (click)="deleteFile( file, i )">
                        <img src="/assets/images/del_ico.png" />
                      </a>
                    </div>
                  </div>
                </li>
                <li class="cb"></li>
              </ul>
            </div>
          </td>
        </tr>
        </tbody>
        </table>
      </section>
      <!-- 컨텐츠 게시판 -->
  
      <!-- 1:1문의 게시판 -->
      <section *ngIf="boardInfo.board_type == 'qna'" class="data-list">
        <table class="table table-bordered table-small">
        <caption class="hidden">게시판 등록/수정</caption>
  
        <colgroup>
          <col style="width:200px" />
          <col />
        </colgroup>
  
        <tbody>
        <tr>
          <th scope="row"><i class="material-icons cred f11">star</i> <label for="subject">제목</label></th>
          <td>
            <inputEx type="text" name="subject" [formGroup]="form" [formErrors]="formErrors" autofocus="true"></inputEx>
          </td>
        </tr>
        <tr *ngIf="boardInfo.lvt_group && groupList.length > 0">
          <th scope="row"><i class="material-icons cred f11">star</i> 그룹선택</th>
          <td>
            <div class="btn-group">
              <button
                type="button"
                *ngFor="let group of groupList"
                class="btn btn-default btn-small f12"
                [ngClass]="{active:form.controls.group_id.value==group}"
                (click)="form.patchValue({group_id: group})"
              >{{group}}</button>
            </div>
            <inputEx type="hidden" name="group_id" [formGroup]="form" [formErrors]="formErrors"></inputEx>
          </td>
        </tr>
        <tr *ngIf="authCheck()">
          <th scope="row"><label for="is_notice">파일 업로드</label></th>
          <td>
            <inputEx type="file" name="file" [formGroup]="form" [formErrors]="formErrors" ng2FileSelect (change)="handleUploadFileChanged($event)"></inputEx>
            <div class="progress mt5 mb5">
              <div class="progress-bar" [ngStyle]="{'width': uploadProgress}"></div>
            </div>
            <div class="mt5">
            ※ 파일별 {{boardInfo.upload_total_size}}MB 까지, 최대 10개까지 첨부 가능,  전체 첨부용량 {{boardInfo.upload_total_size}}MB 제한
            </div>
            <div *ngIf="form.controls.files.value.length > 0 || form.controls.upload.value.length > 0" class="file-div">
              <ul class="file-ul">
                <li *ngFor="let file of form.controls.upload.value; let i = index" class="fl">
                  <div class="upload-item" [ngClass]="{uploadImage: checkImage(getExt(file.origin))}">
                    <div *ngIf="checkImage(getExt(file.origin))" class="upload-image" (click)="insertImage(file)">
                      <img [src]="file.url" [alt]="file.origin" />
                    </div>
                    <div *ngIf="!checkImage(getExt(file.origin))" class="upload-image1">{{getExt(file.origin)}}</div>
                    <div class="upload-filename"><a href="{{file.url}}" target="_blank" title="{{file.origin}}">{{file.origin}}</a></div>
                    <div class="upload-size">
                      ({{getComma(file.size)}} KB)
                      <a class="cp ml5" (click)="deleteUploadFile( file, i )"><img src="/assets/images/del_ico.png" /></a>
                    </div>
                  </div>
                </li>
                <li *ngFor="let file of form.controls.files.value; let i = index" class="fl">
                  <div class="upload-item" [ngClass]="{uploadImage: checkImage(getExt(file.origin))}">
                    <div *ngIf="checkImage(getExt(file.origin))" class="upload-image" (click)="insertImage(file)">
                      <img [src]="file.url" [alt]="file.origin" />
                    </div>
                    <div *ngIf="!checkImage(getExt(file.origin))" class="upload-image1">{{getExt(file.origin)}}</div>
                    <div class="upload-filename"><a href="{{file.url}}" target="_blank" title="{{file.origin}}">{{file.origin}}</a></div>
                    <div class="upload-size">
                      ({{getComma(file.size)}} KB)
                      <a class="cp ml5" (click)="deleteFile( file, i )">
                        <img src="/assets/images/del_ico.png" />
                      </a>
                    </div>
                  </div>
                </li>
                <li class="cb"></li>
              </ul>
            </div>
          </td>
        </tr>
        </tbody>
        </table>
      </section>
      <!-- 1:1문의 게시판 -->
  
      <section *ngIf="boardInfo.board_type != 'popup'">
        <div id="summernote" #summernote [ngxSummernote]="summernoteConfig" formControlName="contents"></div>
        <div *ngIf="formErrors.contents != ''" class="error-message">{{formErrors.contents}}</div>
      </section>
  
      <section>
        <div class="mt10 mb30">
          <div class="fr">
            <button class="btn btn-default mr10" (click)="goList()">취소</button>
            <button type="button" class="btn btn-primary" (click)="submit()">저장하기</button>
          </div>
  
          <div class="cb"></div>
        </div>
      </section>
    </form>
  
  </section>
  