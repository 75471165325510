/*******************************************************************************
  설  명 : 마이페이지 - 구매내역/배송조회 반품신청 모달
  생성일 : 2020-09-10
  생성자 : 송영석
*******************************************************************************/
import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { NgbActiveModal, NgbModalOptions, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { FileUploader, FileLikeObject } from 'ng2-file-upload';

import { config } from '@app/service/config.service';
import { CommonService } from '@app/service/common.service';
import { SOrderService } from '@app/service/order.service';
import { UtilService } from '@app/service/util.service';
import { RestfulService } from '@app/service/restful.service';
import { SProductService } from '@app/service/product.service';
import { SmsService } from '@app/service/sms.service';
import { AuthService } from '@app/service/auth.service';

import { returnNoticeComponent } from '@app/page/mypage/mypage-return/returnNotice/returnNotice.component';

const URL = config.apiImageUploadUrl;

const options: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  size: '480',
  centered: true,
  windowClass:'modal-fadeInDown'
};

@Component({
  selector: 'app-mypage-return',
  templateUrl: './mypage-return.component.html',
  styleUrls: ['./mypage-return.component.scss']
})
export class MypageReturnComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  @Input() rowData: any;
  @Input() memberOrder: any;
  @Input() hp: any;
    
  public isLogIn: boolean;
  isLogin$: Observable<boolean>; // 로그인여부확인

  public form: FormGroup;
  public formErrors = {};

  public rowDetail: any;
  public bankInfo: any = {};
  public memberInfo: any = {};
  public delivery: any = {};

  public detailList = [];
  public imageList: any = [];
  public retTypeList: any = [];
  public retPayTypeList: any = [];
  
  public baseURL = config.baseUrl;

  public uploader: FileUploader;
  public uploadProgress: any = 0;

  public total_qty: any = 0;
  public paid_delivery: any = 0;
  public pay_delivery: any = 0;
  public req_qty_master: any = 0;
  public req_amt_master: any = 0;
  public deduction_amt_master: any = 0;
  public real_ret_master: any = 0;

  public orgOrderAmt: any = 0;
  public orderSeq: any = '';
  public orderDate: any = '';

  public isEnclose: boolean;

  /*******************************************************************************
    설  명 : 빌드폼 생성
  *******************************************************************************/
  buildForm(): void {
    this.form = this.formBuilder.group({
      order_seq:[ '', [Validators.required] ],
      order_detail:[[], [Validators.required]],
      req_qty:[ '', [] ],
      req_reason:[ '', [Validators.required] ],
      req_type: ['', [Validators.required]],
      req_pay_type: ['', []],
      comment:['', [Validators.maxLength(100)] ],
      bank:['', [Validators.required] ],
      depositor:['', [Validators.required] ],
      account:['', [Validators.required] ],
      upload: [[], []],
      req_qty_master: ['', []],
      req_amt_master: ['', []],
      deduction_amt_master: ['', []],
      real_ret_master: ['', []],
      pay_delivery: ['', []],
    });

    this.form.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.form, this.formErrors );
    });
  };

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    public activeModal: NgbActiveModal,
    private CommonService: CommonService,
    private sOrderService: SOrderService,
    private formBuilder: FormBuilder,
    private utilService: UtilService,
    private restful: RestfulService,
    private toastrService: ToastrService,
    private modalService: NgbModal,
    private sProductService: SProductService,
    private smsService: SmsService,
    private authService: AuthService,

  ) {
    // 로그인 여부 가져오기
    this.isLogin$ = this.authService.isLoggedIn;

    this.isLogin$.subscribe( login => {
      this.isLogIn = login;
    });

    this.buildForm();
  }

  /*******************************************************************************
    설  명 : 데이터 로딩 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit(): void {
    this.authService.getLoginInfo.subscribe(data => {
      this.memberInfo = data;
    });

    this.getCommonListCode();
    this.getRefundBank();
    this.getConfigDelivery();

    // 회원 로그인 상태에서 넘어올경우 
    if ( this.memberOrder === true ) {
      this.rowDetail = this.rowData.detail;
      // 상단 주문번호, 주번날짜 변수 설정
      this.orderSeq = this.rowData.seq;
      this.orderDate = this.rowData.order_date;

      // 총주문 가격
      this.orgOrderAmt = this.rowData.order_amt;

      // 주문상세 변수들중 this.form으로 보내기 위해 필요한 변수만 선택
      this.rowDetail.map( rows => {
        rows.product.map( rows1 => {
          this.total_qty += parseInt(rows1.qty);
          this.detailList.push({
            seq: rows1.seq,
            qty: rows1.qty,
            req_qty: '',
            total_amt: rows1.total_amt,
            req_amt: 0
          });
        });
      });
      
      this.form.patchValue({
        order_seq: this.rowData.seq,
        order_detail: this.detailList
      });

      this.memberOrder === false;

    // 비회원 상태에서 넘어올경우 
    } else {
      this.rowDetail = this.rowData;
      this.orderSeq = this.rowData[0].seq;
      this.orderDate = this.rowData[0].order_date;
      this.rowDetail.map(row => {
        this.orgOrderAmt += row.order_amt
      });

      this.rowDetail.map( rows => {
        rows.product.map( rows1 => {
          this.total_qty += parseInt(rows1.qty);
          this.detailList.push({
            seq: rows1.seq,
            qty: rows1.qty,
            req_qty: '',
            total_amt: rows1.total_amt,
            req_amt: 0
          });
        });
      });

      this.form.patchValue({
        order_seq: this.rowData[0].seq,
        order_detail: this.detailList
      });
    }

    // 업로드 허용 파일 설정
    var filetype: string[] = ['image', 'jpg', 'gif', 'png', 'bmp'];

    // 업로더 생성
    this.uploader = new FileUploader({
      url: URL,
      itemAlias: 'file',
      maxFileSize: 50 * (1024 * 1024), // 최대 업로드 허용 용량
      allowedFileType: filetype // 허용 업로드 파일 타입
    });

    // 파일업로드 설정
    this.uploader.onAfterAddingFile = (file) => {
      file.withCredentials = true;
    };

    // 업로드 용량 초과시 처리
    this.uploader.onWhenAddingFileFailed = (item: FileLikeObject, filter: any, options: any) => {
      if( filter.name == 'fileSize' ) {
        this.toastrService.error( '파일 업로드 용량(50MB)을 초과하였습니다.', '파일 업로드');
      } else if( filter.name == 'fileType' ) {
        this.toastrService.error( '허용되는 업로드 파일 타입이 아닙니다.', '파일 업로드');
      }

      // indicator 표시 숨김
      setTimeout (() => {
        this.restful.indicator.next(false);
      });
    };

    // 파일업로드 완료시 처리
    this.uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      response = $.parseJSON( response );
      
      if( response.result ) {
        
        this.imageList.push(
          {
            filename: response.filename,
            origin_filename: response.origin_filename,
            size: response.size,
            filepath: response.url,
            thumbnail_org: response.thumbnail_org,
            thumbnail_path: response.thumbnail_path,
            url: this.baseURL + response.url
          }
        );
          
        this.form.patchValue({
          upload: this.imageList
        });
      
        this.toastrService.success( response.message, '');
      } else {
        this.toastrService.error( response.message, '');
      }
        
      // indicator 표시 숨김
      this.restful.indicator.next(false);
    };
  }

  /*******************************************************************************
    설  명 : 파일 변경시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  handleUploadFileChanged(event) {
    this.uploader.clearQueue();

    // 파일 없을 경우 return false;
    if( event.target.files.length < 1 ) return false;

    // 전체 허용 용량 검증
    let files:File[] = event.target.files;
    let filteredFiles:File[] = [];
    for(var f of files) {
      filteredFiles.push(f);
    }

    let options = null;
    let filters = null;

    this.uploader.addToQueue(filteredFiles, options, filters);

    // indicator 표시
    this.restful.indicator.next(true);

    this.uploader.uploadAll()
  }

  /*******************************************************************************
    설  명 : 공통코드 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getCommonListCode() {
    // 반품 방식
    this.CommonService.getCommonListCode( 'RTY' ).then( response => {
      if( response.ResultCode ) {
        this.retTypeList = response.data;
      } else {
        this.toastrService.error( response.message, '');
      }
    });

    // 반품 배송비 지급 방식
    this.CommonService.getCommonListCode( 'RPT' ).then( response => {
      if( response.ResultCode ) {
        this.retPayTypeList = response.data;
      } else {
        this.toastrService.error( response.message, '');
      }
    });
  }
  
  /*******************************************************************************
    설  명 : 반품 계좌 정보 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getRefundBank() {
    this.sOrderService.getRefundBank().then( response => {
      if ( response.data != "" ) {
        this.bankInfo = response.data;
        this.form.patchValue({
          bank: this.bankInfo.bank,
          depositor: this.bankInfo.depositor,
          account: this.bankInfo.account
        });
      } else {

      }
    });
  }

  /*******************************************************************************
    설  명 : 배송료 가져오기
  *******************************************************************************/
  async getConfigDelivery() {
    this.sProductService.getConfigDelivery().then( response => {
      if( response.ResultCode ) {
        this.delivery = response.data;
        this.delivery.delivery_amt = this.delivery.delivery_amt * 2;
      } else {
        this.toastrService.error( response.ResultMessage, '');
      }
    })
  }

  /*******************************************************************************
    설  명 : 반품신청 
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  submit() {
    this.utilService.makeFormDirtyAndUpdateErrors(this.form, this.formErrors);
    
    if( this.form.valid ) {
      if( parseInt(this.form.controls.req_qty_master.value) < 1 ) {
        this.toastrService.error('반품할 상품의 신청수량을 선택하시기 바랍니다.');
      } else {
        if( confirm('반품신청을 하시겠습니까?') ) {
          this.sOrderService.setRefundSave( this.form ).then( response => {
            if( response.ResultCode ) {
              this.toastrService.success(response.ResultMessage);
              this.activeModal.close(true);
              this.returnNotice();
            } else {
              this.toastrService.error(response.ResultMessage);
            }
          });
        }
      }
    }
  }

  /*******************************************************************************
    설  명 : 반품신청 수량 option 생성 
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  makeFakeArray( row: any ) {
    const fakeArray = [];
    for( let i = 1; i <= row.qty - row.used_req_qty; i++ ) {   
      fakeArray.push(i);
    }

    return fakeArray;
  }

  /*******************************************************************************
    설  명 : 반품신청 수량 option event 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  selectedQty(event: any, row: any) {
    this.detailList.map( rows => {
      if ( rows.seq == row.seq ) {
        rows.req_qty = event.target.value;
        rows.req_amt = (row.total_amt/row.qty) * event.target.value; 
      }
    });
    
    this.form.patchValue({
      order_detail: this.detailList
    });

    this.setCalc();
  }

  /*******************************************************************************
    설  명 : 반품사유 선택 이벤트
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  selectedOptionReason( event: any ) {
    if( event.target.value == '3' || event.target.value == '4' ) {
      this.form.get('req_pay_type').setValidators( [Validators.required] );
      this.form.get('req_pay_type').updateValueAndValidity();
    } else {
      this.form.get('req_pay_type').setValidators( [] );
      this.form.get('req_pay_type').updateValueAndValidity();
    }

    this.setCalc();
  }

  /*******************************************************************************
    설  명 : 반품 방식 선택 이벤트
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  selectedOptionType( event: any ) {
    this.setCalc();
  }

  /*******************************************************************************
    설  명 : 배송비 지급 방식 선택 이벤트
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  selectedOptionPayType( event: any ) {
    this.setCalc();
  }
  
  /*******************************************************************************
    설  명 : 총 반품 비용 계산
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setCalc() {
    let order_detail: any = this.form.controls.order_detail.value;
    let req_reason: any = this.form.controls.req_reason.value;
    let req_type: any = this.form.controls.req_type.value;
    let req_pay_type: any = this.form.controls.req_pay_type.value;

    let base_cnt: any = 2;
    let calc_cnt1: any = 0;  // 차감 계산 시
    let calc_cnt2: any = 0;  // 배송비 계산 시

    this.req_qty_master = 0;
    this.req_amt_master = 0;

    // 선택된 반품 총 수량과 총 합계 구하기
    order_detail.forEach(row => {
      this.req_qty_master += parseInt(row.req_qty || 0);
      this.req_amt_master += parseInt(row.total_amt) / parseInt(row.qty) * parseInt(row.req_qty || 0);
    });

    if( this.req_qty_master == 0 ) {
      this.toastrService.info('반품 신청 할 상품의 수량을 선택하시기 바랍니다.');
    }

    // 주문시 지불된 배달료
    if( this.isLogIn ) {
      this.paid_delivery = this.rowData.delivery_price * 1;
    } else {
      this.paid_delivery = this.rowDetail[0].delivery_price * 1;
    }

    // 전체 반품의 경우
    if( this.total_qty == this.req_qty_master ) {
      base_cnt = 2;
    }

    // 반품 상품을 제외한 상품의 합이 무료배송 조건 미달일 때
    if( (parseInt(this.orgOrderAmt) - parseInt(this.req_amt_master)) < this.delivery.delivery_free_condition ) {
      base_cnt = 2;
    } else {
      base_cnt = 1;
    }

    // 사유가 업체부담일 경우
    if( req_reason == '1' || req_reason == '2' ) {
      calc_cnt1 = calc_cnt2 = 0;

    // 사유가 고객부담일 경우
    } else if( req_reason == '3' || req_reason == '4' ) {

      // 직접발송 시
      if( req_type == '2000' ) {
        calc_cnt1 = calc_cnt2 = base_cnt - 1;
        
        // 택배에 동봉 시
        if( req_pay_type == '1000' || req_pay_type == '3000' ) {
          calc_cnt1 = 0;
        }

      // 회수신청 시
      } else {
        calc_cnt1 = calc_cnt2 = base_cnt;
        
        // 택배에 동봉 시
        if( req_pay_type == '1000' || req_pay_type == '3000' ) {
          calc_cnt1 = 0;
        }
      }
    }

    // 차감금액과 반품배송비 계산
    this.deduction_amt_master = parseInt(this.delivery.delivery_amt) * calc_cnt1;
    this.pay_delivery = parseInt(this.delivery.delivery_amt) * calc_cnt2;

    // 주문금액이 지정된 배송비를 넘지 못하면 무조건 동봉으로 처리
    if( ( req_reason == '3' || req_reason == '4' ) && parseInt(this.orgOrderAmt) < this.pay_delivery ) {
      if( req_pay_type == '2000' ) this.form.patchValue({ req_pay_type: '' });
      this.isEnclose = true;
    }
    
    // 최종 환불 금액 계산
    this.real_ret_master = this.req_amt_master + this.paid_delivery - this.deduction_amt_master;
    
    // 설정된 금액 form patchvalue
    this.form.patchValue({
      req_qty_master: this.req_qty_master,
      req_amt_master: this.req_amt_master,
      deduction_amt_master: this.deduction_amt_master,
      real_ret_master: this.real_ret_master,
      pay_delivery: this.pay_delivery
    });
  }

  /*******************************************************************************
    설  명 : 선택된 이미지 삭제
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  imageDelete( row: any ) {
    this.imageList = this.imageList.filter( rows => rows.filename != row.filename );

    this.form.patchValue({
      upload: this.imageList
    });
  }

  /*******************************************************************************
    설  명 : 반품 신청 후 반품확인공지 모달
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/  
  returnNotice() {
    const modalRef = this.modalService.open(returnNoticeComponent, options);

    modalRef.result.then((result) => {
    }, (reason) => {
    });  
  }  

}
