<app-header></app-header>

<div class="event-page inner">
  <div class="event3">
    <div class="event-logo"></div>
    <div class="event-title">
      <span class="bold">바이크마트</span> 카카오톡 플러스친구<br/>
      추가하고 <span class="bold">2,000M</span> 받자!
    </div>
    <div class="line">
      <div class="line1"></div>
    </div>
    <div class="event-txt">
      신제품과 할인정보를 카카오톡 채널에서<br/>
      가장 빨리 만나보세요!
    </div>
    <form [formGroup]="form">
      <div class="answer clearfix">
        <input type="text" placeholder="쿠폰번호를 입력해주세요" maxlength="12" formControlName="coupon_number" [ngClass]="{formErrors:formErrors['coupon_number']}">
        <!-- <input type="text" formControlName="coupon1" placeholder="XXXX" maxlength="4" [ngClass]="{formErrors:formErrors['coupon1']}">
        <span class="between">-</span>
        <input type="text" formControlName="coupon2" placeholder="XXXX" maxlength="4" [ngClass]="{formErrors:formErrors['coupon2']}">
        <span class="between">-</span>
        <input type="text" formControlName="coupon3" placeholder="XXXX" maxlength="4" [ngClass]="{formErrors:formErrors['coupon3']}"> -->
        <button (click)="submit()">쿠폰등록</button>
      </div>
    </form>
    
    <div class="info">
      <div class="info-title">
        <div class="title">카카오톡 채널 추가방법</div>
      </div>
      <ul class="clearfix">
        <li>
          <div class="img"></div>
          <div class="txt">카카오톡 실행하기</div>
        </li>  
        <li class="between"></li>
        <li>
          <div class="img"></div>
          <div class="txt">검색창에 <span class="bold">‘바이크마트’</span><br/>입력하기</div>
        </li>  
        <li class="between"></li>
        <li>
          <div class="img"></div>
          <div class="txt">채널 추가하기</div>
        </li>                  
      </ul>
    </div>

  </div>
</div>

<app-footer></app-footer>
