<ng-container *ngIf="isMobileApp === false">
  <app-header></app-header>
</ng-container>

<ul class="menu clearfix" *ngIf="isMobileApp === false">
  <li><a routerLink="/company/info">회사소개</a></li>
  <li><a routerLink="/company/history" class="on">회사연혁</a></li>
  <li><a routerLink="/company/society">동호회등록</a></li>
  <li><a routerLink="/company/supply">입점안내</a></li>
	<li><a routerLink="/company/story">창업스토리</a></li>
	<li><a routerLink="/company/contact">가맹문의</a></li>
  <li><a routerLink="/company/policy/service">이용약관</a></li>
</ul>

<ul class="menu menu-mobile clearfix" *ngIf="isMobileApp === true">
  <li><a routerLink="/mobile/company/info">회사소개</a></li>
  <li><a routerLink="/mobile/company/history" class="on">회사연혁</a></li>
  <li><a routerLink="/company/society">동호회등록</a></li>
  <li><a routerLink="/mobile/company/supply">입점안내</a></li>
	<li><a routerLink="/mobile/company/story">창업스토리</a></li>
	<li><a routerLink="/mobile/company/contact">가맹문의</a></li>
  <li><a routerLink="/mobile/company/policy/service">이용약관</a></li>
</ul>

<div style="margin-top: 37px" *ngIf="isMobileApp === true"></div>

<section class="contents">

  <div class="main-img">
    <img src="/assets/images/history.jpg">
  </div>

  <div class="section inner">
    <div>
      <p class="txt1">결국 잘하는 분야를 떠올렸고 오토바이를 판매할 수 있는 온라인 공간을 만들었다.</p>
      <p class="txt2">"최 대표는 2000년 3월 (주)마이돈닷컴을 설립했다. 증권정보 사이트를 운영하는 업체였다.</p>
      <p class="txt2">1986년부터 대구 중구 서성로 오토바이 골목에서 오토바이 판매점을 운영하던 그는 당시 전국적인 인터넷망 확산에 힘입어 인터넷 관련 사업에 뛰어들었다. ... 고민 끝에 2001년 중고 오토바이 거래 사이트를 개설하고 2005년에는 법인을 바이크마트로 변경했다."</p>
      <p class="txt2">- 2019.05.11 '영남일보'에서 발췌</p>
    </div>

    <div class="history">
      <ul class="clearfix">
        <li>
          <div>2020</div>
          <div>
            <p class="dot">07</p><p class="dot1">벤처기업 (기술평가대출기업) 인증 (벤처번호: 20200401551)</p>
            <p class="dot">01</p><p class="dot1">Maxlauf (PB브랜드, 바람막이), TUBA (PB브랜드, 블루투스) 런칭</p>
          </div>
        </li>
        <li>
          <div>2019</div>
          <div>
            <p class="dot">08</p><p class="dot1">BBJ(PB브랜드) 제다이R2 청바지 개발</p>
            <p class="dot">06</p><p class="dot1">BBJ(PB브랜드) 글래디에이터 자켓, 팬츠 개발</p>
            <p class="dot">05</p><p class="dot1">BBJ(PB브랜드) 아이오니아 자켓, 팬츠 개발</p>
            <p class="dot">04</p><p class="dot1">산업통상자원부 디자인혁신유망기업 선정</p>
            <p class="dot">04</p><p class="dot1">BBJ(PB브랜드) 제다이R 청바지 개발</p>
            <p class="dot">04</p><p class="dot1">배달조끼 (PB상품) 개발</p>
            <p class="dot">04</p><p class="dot1">기업부설연구소 설립</p>
          </div>
        </li>
        <li>
          <div>2018</div>
          <div>
            <p class="dot">12</p><p class="dot1">글로벌 마켓 진출 (베트남, 인도, 일본)</p>
            <p class="dot">12</p><p class="dot1">PB브랜드 상품 누적개발 50여종</p>
            <p class="dot">01</p><p class="dot1">ISPO 북경 공동관 박람회 참가 (대구TP)</p>
          </div>
        </li>
        <li>
          <div>2017</div>
          <div>
            <p class="dot">05</p><p class="dot1">대구광역시 스타기업 100 선정</p>
          </div>
        </li>
        <li>
          <div>2016</div>
          <div>
            <p class="dot">12</p><p class="dot1">매출 50억 달성</p>
          </div>
        </li>
        <li>
          <div>2015</div>
          <div>
            <p class="dot">05</p><p class="dot1">바이크마트 서울직영점 매장확장이전</p>
            <p class="dot">04</p><p class="dot1">전국 협력점 / 전문점 30여개 계약체결</p>
            <p class="dot">03</p><p class="dot1">제10회 슈퍼세일 개최</p>
            <p class="dot">02</p><p class="dot1">블랙비자칼 (PB브랜드, 청바지) 런칭</p>
            <p class="dot">01</p><p class="dot1">오픈마켓(옥션,지마켓,11번가) 바이크 용품 카테고리 1위 달성</p>
          </div>
        </li>
        <li>
          <div>2014</div>
          <div>
            <p class="dot">12</p><p class="dot1">회원 950,000명 돌파</p>
            <p class="dot">11</p><p class="dot1">본프리 (PB브랜드, 가죽자켓) 런칭</p>
            <p class="dot">10</p><p class="dot1">바이크마트 전국 협력점 / 전문점 개설</p>
            <p class="dot">09</p><p class="dot1">9회 슈퍼세일 개최</p>
            <p class="dot">04</p><p class="dot1">모바일 쇼핑몰 리뉴얼</p>
            <p class="dot">03</p><p class="dot1">제 8회 쇼킹할인파티 개최</p>
            <p class="dot">02</p><p class="dot1">회원 850,000명 돌파</p>
            <p class="dot">01</p><p class="dot1">오픈마켓 최우수 판매자 선정</p>
          </div>
        </li>
        <li>
          <div>2013</div>
          <div>
            <p class="dot">10</p><p class="dot1">제 7회 쇼킹할인파티 개최</p>
            <p class="dot">09</p><p class="dot1">중고 오토바이 거래 모바일 서비스 오픈</p>
            <p class="dot">08</p><p class="dot1">오픈마켓 판매 전면 확대</p>
            <p class="dot">07</p><p class="dot1">FIVE(프랑스) 글러브 런칭</p>
            <p class="dot">03</p><p class="dot1">제 6회 쇼킹할인파티 개최</p>
          </div>
        </li>
        <li>
          <div>2012</div>
          <div>
            <p class="dot">10</p><p class="dot1">MACNA 브랜드 런칭</p>
            <p class="dot">09</p><p class="dot1">제 5회 바이크마트 쇼킹할인파티 개최</p>
            <p class="dot">04</p><p class="dot1">오픈마켓 입점 및 판매</p>
            <p class="dot">03</p><p class="dot1">제 4회 바이크마트 쇼킹할인파티 개최</p>
            <p class="dot">01</p><p class="dot1">NOLAN/X-Lite 헬멧 브랜드 런칭</p>
          </div>
        </li>
        <li>
          <div>2011</div>
          <div>
            <p class="dot">09</p><p class="dot1">바이크마트 서울직영점 오픈 (서울시 성동구 용답동 233-4번지 장한평역 부근)</p>
            <p class="dot">08</p><p class="dot1">결제도우미 시스템 전면 개편 - 국내 최저 수수료 선언</p>
            <p class="dot">08</p><p class="dot1">바이크마트 메인페이지 리뉴얼</p>
            <p class="dot">04</p><p class="dot1">문태산 선수와 레이싱 스폰서 계약 체결</p>
            <p class="dot">04</p><p class="dot1">네이버 체크아웃 서비스 시행</p>
            <p class="dot">04</p><p class="dot1">바이크마트 중고차 사이트 전면 개편</p>
            <p class="dot">03</p><p class="dot1">제 2회 바이크마트 쇼킹할인파티 개최</p>
            <p class="dot">01</p><p class="dot1">바이크마트 중고차 등록 서비스 무료 전환</p>
          </div>
        </li>
        <li>
          <div>2010</div>
          <div>
            <p class="dot">09</p><p class="dot1">네이버 지식쇼핑 업무제휴 계약 체결</p>
            <p class="dot">08</p><p class="dot1">쿠리야킨 해외 구매대행 서비스 오픈</p>
            <p class="dot">05</p><p class="dot1">회원 750,000명 돌파</p>
            <p class="dot">05</p><p class="dot1">바마 독점수입 브랜드 목포점 오픈</p>
            <p class="dot">03</p><p class="dot1">제 2회 바이크마트 쇼킹할인파티 개최</p>
            <p class="dot">02</p><p class="dot1">한국모터사이클연맹(KMF) 스폰서쉽 체결</p>
            <p class="dot">02</p><p class="dot1">바마 독점수입 브랜드 서울2호점 오픈</p>
            <p class="dot">01</p><p class="dot1">바마 독점수입 브랜드 부산4호점 오픈</p>
          </div>
        </li>
        <li>
          <div>2009</div>
          <div>
            <p class="dot">09</p><p class="dot1">한국이륜차신문(KMN) 업무제휴 계약 체결</p>
            <p class="dot">09</p><p class="dot1">미니모터스 14호점 오픈 바마독점수입 브랜드대리점 계약 체결</p>
            <p class="dot">06</p><p class="dot1">BOM을 위한 바마오토바이 공식홈페이지 개설 www.bama.co.kr</p>
            <p class="dot">06</p><p class="dot1">다음 Tv팟 업무제휴 및 브랜드 Tv팟 실시</p>
            <p class="dot">05</p><p class="dot1">바마 독점수입 브랜드 창원점 및 거제점 오픈</p>
            <p class="dot">03</p><p class="dot1">회원 700,000명 돌파</p>
            <p class="dot">03</p><p class="dot1">바이크마트 신용할부 서비스 실시</p>
            <p class="dot">03</p><p class="dot1">MotoGP 출전 최동관 선수 공식 스폰서 계약 체결</p>
            <p class="dot">02</p><p class="dot1">365일 할인쇼핑몰 바마아울렛 오픈</p>
            <p class="dot">02</p><p class="dot1">바마 독점수입 브랜드 수원점 오픈</p>
            <p class="dot">02</p><p class="dot1">인터파크 샵플러스 스포츠/레져부분 공급 체결 계약</p>
            <p class="dot">01</p><p class="dot1">바마 독점수입 브랜드 부산 2호점 오픈</p>
          </div>
        </li>
        <li>
          <div>2008</div>
          <div>
            <p class="dot">12</p><p class="dot1">강원랜드 하이원 마제스티클럽 부분 업무제휴</p>
            <p class="dot">11</p><p class="dot1">YTN, 매일경제 비즈라이프 선정 추천 우수기업 선정</p>
            <p class="dot">11</p><p class="dot1">바마 독점수입 브랜드 울산점 오픈</p>
            <p class="dot">10</p><p class="dot1">2008 친환경 모터쇼 온라인 광고 협찬</p>
            <p class="dot">09</p><p class="dot1">바마 독점수입 브랜드 중국 북경점 오픈</p>
            <p class="dot">08</p><p class="dot1">바마 독점수입 브랜드 대전점 오픈</p>
            <p class="dot">06</p><p class="dot1">회원수 650,000명 돌파</p>
            <p class="dot">05</p><p class="dot1">국내 최대 바이크매장 오픈</p>
            <p class="dot">04</p><p class="dot1">바마 독점수입 브랜드 대구점 오픈</p>
            <p class="dot">03</p><p class="dot1">2008 슈퍼카 코리아투어 온라인 광고 협찬</p>
            <p class="dot">03</p><p class="dot1">3바이크마트 브랜드 전문 대리점 오픈 (광주, 부산, 서울)</p>
          </div>
        </li>
        <li>
          <div>2007</div>
          <div>
            <p class="dot">09</p><p class="dot1">회원수 600,000명 돌파</p>
            <p class="dot">08</p><p class="dot1">해외 부품 주문 시스템 도입, 일본과 업무 제휴</p>
            <p class="dot">05</p><p class="dot1">업계 최초 신차 역경매 사이트 오픈 - 입찰경매 시스템 구축</p>
            <p class="dot">05</p><p class="dot1">회원수 550,000명 돌파</p>
            <p class="dot">04</p><p class="dot1">업계 최초 BOM (Bama OpenMarket) 실시</p>
            <p class="dot">04</p><p class="dot1">BERIK, ArlenNess, Madif 독점 공급 계약 체결</p>
            <p class="dot">02</p><p class="dot1">바마 캐주얼코너 오픈</p>
            <p class="dot">01</p><p class="dot1">일본 현지와 독점 제휴 해외특송 사이트 오픈</p>
            <p class="dot">01</p><p class="dot1">AXO 공식 홈페이지 www.axokorea.co.kr 개설</p>
            <p class="dot">01</p><p class="dot1">AXO(이탈리아) 독점 공급 계약 체결</p>
          </div>
        </li>
        <li>
          <div>2006</div>
          <div>
            <p class="dot">12</p><p class="dot1">회원수 500,000명 돌파</p>
            <p class="dot">11</p><p class="dot1">(주)대림자동차공업 신차 카테고리 입점 업무제휴 계약 체결</p>
            <p class="dot">08</p><p class="dot1">옥스포드 (영국, 용품 1위 브랜드) 독점 공급 계약 체결</p>
            <p class="dot">07</p><p class="dot1">중고바이크 사이트 디자인/프로그램 개편</p>
            <p class="dot">06</p><p class="dot1">바마네트웍스 전국 협력점 구축, 타이어 전국 장착 서비스 실시</p>
            <p class="dot">05</p><p class="dot1">회원수 450,000명 돌파</p>
            <p class="dot">05</p><p class="dot1">쇼핑몰 사이트 디자인/프로그램 개편</p>
            <p class="dot">03</p><p class="dot1">러프앤로드(일본, 용품 3대 브랜드) 독점 공급 계약 체결</p>
          </div>
        </li>
        <li>
          <div>2005</div>
          <div>
            <p class="dot">12</p><p class="dot1">회원수 400,000명 돌파</p>
            <p class="dot">12</p><p class="dot1">(주)마이돈닷컴에서 (주)바이크마트로 법인변경</p>
            <p class="dot">03</p><p class="dot1">회원수 300,000명 돌파</p>
          </div>
        </li>
        <li>
          <div>2004</div>
          <div>
            <p class="dot">10</p><p class="dot1">중고컨텐츠 유료화</p>
            <p class="dot">07</p><p class="dot1">회원수 250,000명 돌파</p>
          </div>
        </li>
        <li>
          <div>2003</div>
          <div>
            <p class="dot">03</p><p class="dot1">회원수 200,000명 돌파</p>
          </div>
        </li>
        <li>
          <div>2002</div>
          <div>
            <p class="dot">12</p><p class="dot1">회원수 100,000명 돌파</p>
            <p class="dot">07</p><p class="dot1">인터파크, 한솔CSN 제품공급 계약</p>
            <p class="dot">04</p><p class="dot1">회원수 60,000명 돌파</p>
            <p class="dot">04</p><p class="dot1">월매출 2억 돌파</p>
            <p class="dot">02</p><p class="dot1">회원수 40,000명 돌파</p>
          </div>
        </li>
        <li>
          <div>2001</div>
          <div>
            <p class="dot">12</p><p class="dot1">결제도우미 시행, 회원실명제 실시, 상품 600여종 입점</p>
            <p class="dot">11</p><p class="dot1">수입차 부품판매 개시, 월간 오토바이크 정기구독권 판매계약 체결</p>
            <p class="dot">09</p><p class="dot1">회원수 30,000명 돌파</p>
            <p class="dot">09</p><p class="dot1">보험업무 제휴, 카드결제 시행</p>
            <p class="dot">07</p><p class="dot1">회원수 20,000명 돌파</p>
            <p class="dot">06</p><p class="dot1">HJC(국산, 미국 1위 헬멧 브랜드) 업무제휴</p>
            <p class="dot">05</p><p class="dot1">관련용품 아웃소싱 제작, 중고시세검색 추가</p>
            <p class="dot">03</p><p class="dot1">회원수 10,000명 돌파</p>
            <p class="dot">02</p><p class="dot1">전국중소기업학회 바이크마트 사례발표 (울산대학교)</p>
            <p class="dot">02</p><p class="dot1">산학경영기술연구원과 공동프로젝트 (바이크마트의 중장기 발전방향과 마케팅 전략에 관한 연구)</p>
            <p class="dot">01</p><p class="dot1">회원수 1,000명 돌파</p>
            <p class="dot">01</p><p class="dot1">영화 속 오토바이 세상(Movie&amp;Bike), 바이크게임, 자유게시판 신설</p>
            <p class="dot">01</p><p class="dot1">대림, 효성자동차 판매개시</p>
          </div>
        </li>
        <li>
          <div>2000</div>
          <div>
            <p class="dot">12</p><p class="dot1">오토바이 전문 사이트(bikemart.co.kr) 개설</p>
            <p class="dot">06</p><p class="dot1">증권정보 사이트(mydon.com) 개설</p>
            <p class="dot">03</p><p class="dot1">(주)마이돈닷컴 법인설립</p>
          </div>
        </li>
		  </ul>
    </div>

  </div>
</section>

<ng-container *ngIf="isMobileApp === false">
  <app-footer></app-footer>
</ng-container>
