<div class="member-page">
  <div class="member-inner">
    <div class="logo">
      <a routerLink="/"><img src="/assets/images/logo1.png?date=20210913" alt="바이크마트 로고"></a>
    </div>
    
    <form [formGroup]="findIdForm" (ngSubmit)="submitId()">
      <div class="title">아이디찾기</div>
      <div class="info-txt"><p class="dot">·</p><p class="dot1">이름, 이메일주소, 전화번호 입력 시 영문 대소문자 및 공백을 잘 구분 입력하였는지 확인 바랍니다.</p></div>
      <label>
        <span>이름</span>
        <input type="text" formControlName="name" maxLength="20" [ngClass]="{formErrors:formIdErrors.name}" placeholder="{{ formIdErrors.name ? formIdErrors.name : '실명을 입력해주세요. 예) 홍길동' }}" autofocus />
        <!-- <div *ngIf="formIdErrors.name" class="error-message">{{formIdErrors.name}}</div> -->
      </label>

      <mat-tab-group [selectedIndex]="activeTab" (selectedIndexChange)="tabSetValue($event)">
        <mat-tab style="width: 50% !important;" label="전화번호로 찾기">
          <label class="tel" for="hp1">
            <span>전화번호</span>
              <div class="select-box fl">
                <!-- <input type="hidden" formControlName="hp1" /> -->
                <select formControlName="hp1" [ngClass]="{formErrors:formIdErrors.hp1}">
                  <!-- <option value="">선택</option> -->
                  <option value="010">010</option>
                  <option value="011">011</option>
                  <option value="016">016</option>
                  <option value="017">017</option>
                  <option value="018">018</option>
                  <option value="019">019</option>
                </select>
                <div *ngIf="formIdErrors.hp1" class="error-message">{{formIdErrors.hp1}}</div>
              </div>
              -
              <input type="text" formControlName="hp2" maxLength="4" [ngClass]="{formErrors:formIdErrors.hp2}" placeholder="{{ formIdErrors.hp2 ? formIdErrors.hp2 : '' }}" />
              <!-- <div *ngIf="formIdErrors.hp2" class="error-message">{{formIdErrors.hp2}}</div> -->
              -
              <input type="text" formControlName="hp3" maxLength="4" [ngClass]="{formErrors:formIdErrors.hp3}" placeholder="{{ formIdErrors.hp3 ? formIdErrors.hp3 : '' }}" />
              <!-- <div *ngIf="formIdErrors.hp3" class="error-message">{{formIdErrors.hp3}}</div> -->
          </label>
        </mat-tab>
        <mat-tab style="width: 50% !important;" label="이메일로 찾기">
          <label class="email">
            <span>이메일주소</span>
            <input type="text" class="email1" formControlName="email1" [ngClass]="{formErrors:formIdErrors.email1}" placeholder="가입 시 입력하신 이메일 주소" />
            @
            <input type="text" class="dir_email2 pc-view" #email2 formControlName="email2" [ngClass]="{formErrors:formIdErrors.email2, readonly: emailReadonly == true}" placeholder="{{ emailReadonly == false ? '직접 입력해 주세요' : '' }}" [readonly]="emailReadonly" />
            <input type="text" class="dir_email2 mobile-view" #email2 formControlName="email2" [ngClass]="{formErrors:formIdErrors.email2, readonly: emailReadonly == true}" placeholder="{{ emailReadonly == false ? '직접 입력해 주세요' : '' }}" [readonly]="emailReadonly" style="width: calc(100%); margin-right: 0px !important;"/>
            <select formControlName="emailSelected" class="pc-view" (change)="selectEmail($event)" >
              <option value="">직접입력</option>
              <option value="naver.com" >naver.com</option>
              <option value="hanmail.net" >hanmail.net</option>
              <option value="daum.net" >daum.net</option>
              <option value="yahoo.co.kr" >yahoo.co.kr</option>
              <option value="gmail.com" >gmail.com</option>
              <option value="nate.com" >nate.com</option>
              <option value="hotmail.com" >hotmail.com</option>
              <option value="empal.com" >empal.com</option>
              <option value="freechal.com" >freechal.com</option>
              <option value="paran.com" >paran.com</option>
              <option value="koera.com" >koera.com</option>
              <option value="dreamwiz.com" >dreamwiz.com</option>
            </select>
            <select formControlName="emailSelected" class="mobile-view" style="width: calc(100%);" (change)="selectEmail($event)" >
              <option value="">직접입력</option>
              <option value="naver.com" >naver.com</option>
              <option value="hanmail.net" >hanmail.net</option>
              <option value="daum.net" >daum.net</option>
              <option value="yahoo.co.kr" >yahoo.co.kr</option>
              <option value="gmail.com" >gmail.com</option>
              <option value="nate.com" >nate.com</option>
              <option value="hotmail.com" >hotmail.com</option>
              <option value="empal.com" >empal.com</option>
              <option value="freechal.com" >freechal.com</option>
              <option value="paran.com" >paran.com</option>
              <option value="koera.com" >koera.com</option>
              <option value="dreamwiz.com" >dreamwiz.com</option>
            </select>
          </label>
        </mat-tab>
      </mat-tab-group>

      <div class="guide-txt hidden">
        <div class="title">· 메일이 전송이 안되는 현상</div>
        <div>
          <p class="dot">-</p><p class="dot1">정상적으로 메일 발송이 이루어지고 있으나, 간혹 고객님의 컴퓨터 또는 사내 보안정책에 따라서<br> 메일발송이 지연/차단 되는 경우가 있습니다.</p>
          <p class="dot">-</p><p class="dot1">이럴 경우에는 부득이 보안정책이 없는 타 컴퓨터를 통해 이용해주시길 권해드립니다.</p>
        </div>
      </div>
        
      <input type="submit" value="확인" class="basic-btn red" />
    </form>
    
    <div class="member-footer">
      <ul class="clearfix">
        <li><a routerLink="/company/policy/service">이용약관</a></li>
        <li><a routerLink="/company/policy/privacy" class="bold">개인정보처리방침</a></li>
        <li><a routerLink="/customer/center">고객센터 1544-5022</a></li>
      </ul>
      <div>Copyright © 2008 <span class="bold">bikemart.co.kr</span> ALL RIGHTS RESERVED.</div>
    </div>    
  </div>
</div>
