/*******************************************************************************
  설  명 : 비밀번호 찾기 성공
  생성일 : 2021-07-15
  생성자 : 김종현
*******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { SMemberService} from '@app/service/member.service';

@Component({
  selector: 'app-member-find-pw-success',
  templateUrl: './member-find-pw-success.component.html',
  styleUrls: ['./member-find-pw-success.component.scss']
})
export class MemberFindPwSuccessComponent implements OnInit {
  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  public password: any = '';

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute, 
    public memberService: SMemberService
  ) { }

  /*******************************************************************************
    설  명 : 데이터 로딩 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit(): void {
    this.password = this.memberService.findPwResult;

    if( !this.password ) {
      this.router.navigate(
        ['/main'],
        {
          relativeTo: this.activatedRoute,
          queryParams: {},
          queryParamsHandling: '', // remove to replace all query params by provided
        }
      );
    }

    this.memberService.findPwResult = '';
  }

}
