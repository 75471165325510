/*******************************************************************************
  설  명 : 상품 상세
  생성일 : 2020-09-10
  생성자 : 송영석
*******************************************************************************/
import { Component, OnInit, HostListener } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';

import { config } from '@app/service/config.service';
import { AuthService } from '@app/service/auth.service';
import { Globals } from '@app/service/globals.service';
import { SProductService } from '@app/service/product.service';
import { SCartService } from '@app/service/cart.service';
import { SEOService } from '@app/service/seo.service';
import { SBoardService } from '@app/service/board.service';
import { UrlService } from '@app/service/url.service';
import { SMemberService } from '@app/service/member.service';

import { ProductNonmemberComponent } from '@page/product/product-nonmember/product-nonmember.component';
import { ProductQuestionComponent } from '@page/product/product-question/product-question.component';
import { NaverpayComponent } from '@app/components/naverpay/naverpay.component';
import { ProductShareComponent } from '@page/product/product-info/product-share/product-share.component';
import { ProductReviewComponent } from '@page/product/product-review/product-review.component';

const options: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  size: '480',
  centered: true,
  windowClass:'modal-fadeInDown'
};

const optionsLG: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  size: 'lg',
  centered: true,
  windowClass:'modal-fadeInDown'
};

@Component({
  selector: 'app-product-info',
  templateUrl: './product-info.component.html',
  styleUrls: ['./product-info.component.scss']
})
export class ProductInfoComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  isLogin$: Observable<boolean>;
  isLogin: boolean;
  loggedInInfo$: any;
  loggedInInfo: any;

  public baseURL: string = config.baseUrl;

  public isChecked: boolean = false;
  public qty: any;

  public propertyList: any = [];
  public productData: any = [];
  public sizeList: any = [];
  public giftList: any = [];
  public colorList: any = [];
  public productList: any = [];
  public relationList: any = [];
  public reviewList: any = [];
  public reviewImageList: any = [];
  public reviewCount: any = 0;
  public reviewAverageGrade: any = 0;
  public brandNotice = '';
  public memberInfo: any = {};

  public questionList: any = [];
  public questionCount: any = 0;
  public isMy: any = '2';
  public state: any = '0';
  public openRow: any = [];
  public questionAddList: any = [{
    seq: "0"
  }];

  public guideView: any = '';
  public reviewImageURL: any = '';
  public topPropertyColor: any = '색상';
  public bottomPropertyColor: any = '색상';
  public topPropertySize: any = '사이즈';
  public bottomPropertySize: any = '사이즈';
  public topGift: any = '사은품';

  public product: any = {};
  public delivery: any = {};

  public totalPrice: number = 0;

  public category_name1: any;
  public category_name2: any;

  public params: any = {
    seq: '',
    type: 'list',
    group_id: '전체',
    row: 10,
    no: 1,
    group: 'tag',
    text: ''
  };

  public search: any = {
    pageNo: 1,
    pageRow: 10,
    searchdate_group: '0'
  };

  public referrer: any = {
    productSeq: '',
    categoryCode: '',
    eventSeq: ''
  };

  // 주문/결제안내, 입금확인/배송안내, 교환반품안내, A/S안내
  productGuide: any = [];

  favorite: boolean = false; // 찜하기
  review_sort: any = 4; // 구매후기 sort
  qna_range: any = 2;  // 내문의보기 / 전체문의보기
  mqnaView: any = 999999999999999; // 문의답변보기 (모바일)
  openOrderbox: boolean = false; // 주문창 열기

  fixedMenu : boolean = false; // 중간 메뉴 고정
  fixedOrder : boolean = false; // 오른쪽 메뉴 고정

  relationPageNo: any = 1;
  relationPageRow: any = 7;
  relationTotalPage: any = 0;

  reviewPageNo: any = 1;
  reviewPageRow: any = 4;
  reviewTotalPage: any = 0;

  questionPageNo: any = 1;
  questionPageRow: any = 10;
  questionTotalPage: any = 0;

  // 별점비율계산 변수
  public one = 0;
  public two = 0;
  public three = 0;
  public four = 0;
  public five = 0;

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private toastrService: ToastrService,
    private authService: AuthService,
    private domSanitizer: DomSanitizer,
    private modalService: NgbModal,
    private globals: Globals,
    private sProductService: SProductService,
    private sCartService: SCartService,
    private seoService: SEOService,
    private naverpayComponent: NaverpayComponent,
    private sBoardService: SBoardService,
    private urlService: UrlService,
    private memberService: SMemberService,
  ) {
    this.isLogin$ = this.authService.isLoggedIn;
    this.loggedInInfo$ =this.authService.getLoginInfo;

    this.isLogin$.subscribe( login => {
      this.isLogin = login;
    });
    this.loggedInInfo$.subscribe( loginInfo => {
      this.loggedInInfo = loginInfo;
    });


  }

  /*******************************************************************************
    설  명 : 데이터 로딩 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  async ngOnInit() {
    // 회원정보를 가져온다.
    this.getmemberInfo();
    // 주문/결제안내, 입금확인/배송안내, 교환반품안내, A/S안내 가져오기
    this.getProductGuide();

    // 배송료 가져오기
    await this.getConfigDelivery();

    await this.activatedRoute.queryParams.subscribe( async params => {
      this.referrer.productSeq = ( typeof params.productSeq == 'undefined' || params.productSeq == '' ) ? '' : params.productSeq;
      this.referrer.categoryCode = ( typeof params.categoryCode == 'undefined' || params.categoryCode == '' ) ? '' : params.categoryCode;
      this.referrer.eventSeq = ( typeof params.eventSeq == 'undefined' || params.eventSeq == '' ) ? '' : params.eventSeq;

      await this.getProductDetail();
      await this.getKeepProductInfo();
    });
  }

  /*******************************************************************************
    설 명 : 회원정보를 가져온다. 
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
    getmemberInfo() {
      this.memberService.getMemberAddressInfo().then(response => {
        if(response.ResultCode) {
          this.memberInfo = response.data;
        }  
  
      });
  
    }
  

  /*******************************************************************************
    설 명 : 주문/결제안내, 입금확인/배송안내, 교환반품안내, A/S안내 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getProductGuide() {
    this.sBoardService.getProductGuide().then( response => {
      if( response.ResultCode ) {
        this.productGuide = response.data;
      }
    });
  }

  /*******************************************************************************
    설  명 : API 파라미터 데이터 삭제
  *******************************************************************************/
  deleteParams() {
    delete this.params.program;
    delete this.params.service;
    delete this.params.version;
    delete this.params.action;
    delete this.params.token;
  }

  /*******************************************************************************
    설  명 : 상품정보 가져오기
  *******************************************************************************/
  getProductDetail() {
    this.sProductService.getProductDetail( this.referrer.productSeq ).then( response => {
      if( response.ResultCode ) {
        if( response.data && response.data.seq === undefined ) {
          this.toastrService.error( '존재하지 않는 상품입니다.', '');
          this.router.navigate(['/']);
        }

        this.productData = response.data;
        this.propertyList = response.data.property;
        this.giftList = response.data.gift;
        this.relationList = response.data.relation;

        this.relationTotalPage = Math.ceil(this.relationList.length / this.relationPageRow);

        this.qty = this.productData.min_qty || 1;

        let color: any = [];
        for( var i = 0; i < this.propertyList.length; i++ ) {
          if( this.propertyList[i].display_color_name !== '' && this.propertyList[i].display_color_name !== null ) {
            color.push(this.propertyList[i].display_color_name.toUpperCase());
          }

          this.colorList = color.filter((element, index) => {
            return color.indexOf(element) == index;
          });
        }

        // 색상이 없는 상품 일 때
        if( this.colorList.length < 1 ) {
          if( this.propertyList.length == 1 && 
              ( this.propertyList[0]['display_size_name'] === null || this.propertyList[0]['display_size_name'] == '' ) && 
              this.propertyList[0]['is_soldout'] == '0'
            ) {
            this.sizeList = [];

            this.productList.push({
              isGift: false,
              product_seq: this.productData.seq,
              product_name: this.productData.product_name,
              product_code: this.productData.product_code,
              product_property_seq: this.propertyList[0].product_property_seq,
              qty: this.qty,
              unit_price: this.propertyList[0].unit_price,
              consumer_price: this.propertyList[0].consumer_price,
              display_color_name: this.propertyList[0].display_color_name,
              display_size_name: this.propertyList[0].display_size_name,
              // discount_amt: parseInt(this.propertyList[0].unit_price) - parseInt(this.propertyList[0].consumer_price),
              discount_amt: 0,
              discount_rate: '',
              event_seq: this.referrer.eventSeq
            });

            this.setCalcAmt();
          } else {
            for( var i = 0; i < this.propertyList.length; i++ ) {
              this.sizeList.push( this.propertyList[i] );
            }
          }
        }

        // 검색엔진 SEO 타이틀 및 메타 설정
        let title = '오토바이 ' + this.productData.category_name2 + ' ' + this.productData.category_name3 + ' ' + this.productData.product_name;
        this.seoService.updateTitle( title );
        this.seoService.updateDescription( title );
        this.seoService.updateSubject( title );
        this.seoService.updateKeywords( title );
        this.seoService.updateOGDescription( title );
        this.seoService.updateOGImage( this.productData.origin_url );
        this.seoService.updateOGUrl( window.location.href );

        // 브랜드 공지 연결
        this.brandNoticeBinding();
      } else {
        this.toastrService.error( response.ResultMessage, '');
      }

      this.deleteParams();
      if( this.productData.is_review == '1' ) this.getReview();
      this.getProductQuestion();
    })
  }

  /*******************************************************************************
    설  명 : 배송료 가져오기
  *******************************************************************************/
  getConfigDelivery() {
    this.sProductService.getConfigDelivery().then( response => {
      if( response.ResultCode ) {
        this.delivery = response.data;
      } else {
        this.toastrService.error( response.ResultMessage, '');
      }
    })
  }

  /*******************************************************************************
    설  명 : selecbox 열기
  *******************************************************************************/
  selectbox_pulldown(event, type) {
    var that = $(event.target);
    var findrowA  = that.closest("a");    //  클릭한 행의 A

    if( type == 'color' ) $(".size-box").removeClass("pulldown");

    if( findrowA.parent().hasClass("pulldown") ) {
      findrowA.parent().removeClass("pulldown");
    } else {
      findrowA.parent().addClass("pulldown");
    }
  }

  /*******************************************************************************
    설  명 : 기본 옵션
  *******************************************************************************/
  selectedOption( event ) {
    const value: any = event.target.textContent;

    $(".select-box").removeClass("pulldown");

    this.topPropertyColor = value;
    this.bottomPropertyColor = value;

    let row: any = [];
    for( var i = 0; i < this.propertyList.length; i++ ) {
      if( value == "" ) {
        this.sizeList = [];
      } else if( value == this.propertyList[i].display_color_name.toUpperCase() ) {
        row.push( this.propertyList[i] );
        this.sizeList = row;
      }
    }
  }

  /*******************************************************************************
    설  명 : 상품 선택 시 상품 리스트에 추가
  *******************************************************************************/
  // 기본 옵션
  selectProduct( row: any ) {
    const value: any = row.product_property_seq;
    const isSoldout: any = row.is_soldout;

    if( parseInt(isSoldout) !== 0 ) {
      this.toastrService.warning('일시품절된 옵션입니다.', '');
      return false;
    }

    $(".select-box").removeClass("pulldown");

    let dupleCheck = this.productList.filter(item => item.product_property_seq === value);

    if( dupleCheck.length > 0 ) {
      this.toastrService.warning('이미 선택한 상품입니다.', '');
    } else {
      this.sizeList.forEach(item => {
        if( value == item.product_property_seq ) {
          this.productList.push({
            isGift: false,
            product_seq: this.productData.seq,
            product_name: this.productData.product_name,
            product_code: this.productData.product_code,
            product_property_seq: item.product_property_seq,
            qty: this.qty,
            unit_price: item.unit_price,
            consumer_price: item.consumer_price,
            display_color_name: item.display_color_name,
            display_size_name: item.display_size_name,
            // discount_amt: parseInt(item.unit_price) - parseInt(item.consumer_price),
            discount_amt: 0,
            discount_rate: '',
            event_seq: this.referrer.eventSeq
          });
        }
      });
    }

    // 색상을 고르는 상품이라면 초기화
    if( this.colorList.length > 0 ) {
      setTimeout(() => {
        this.topPropertyColor = '색상';
        this.bottomPropertyColor = '색상';
        this.topPropertySize = '사이즈';
        this.bottomPropertySize = '사이즈';
      }, 0);

      this.sizeList = [];
    } else {
      setTimeout(() => {
        this.topPropertySize = '사이즈';
        this.bottomPropertySize = '사이즈';
      }, 0);
    }

    this.setCalcAmt();
  }

  /*******************************************************************************
    설  명 : 사은품 선택 이벤트 설정
  *******************************************************************************/
  selectGift( row: any ) {
    const value: any = row.product_freegift_seq;

    $(".select-box").removeClass("pulldown");

    let dupleCheck = this.productList.filter(item => item.product_freegift_seq === value);

    if( dupleCheck.length > 0 ) {
      this.toastrService.warning('이미 선택한 상품입니다.', '');
    } else {
      this.giftList.forEach(item => {
        if( value == item.product_freegift_seq ) {
          this.productList.push({
            isGift: true,
            product_seq: item.product_seq,
            product_name: item.product_name,
            product_code: item.product_code,
            product_property_seq: item.product_property_seq,
            display_color_name: item.display_color_name,
            display_size_name: item.display_size_name,
            product_freegift_seq: item.product_freegift_seq,
            freegift_max_count: item.max_count,
            qty: this.qty,
            unit_price: 0,
            consumer_price: 0,
            discount_amt: 0,
            discount_rate: '',
            event_seq: this.referrer.eventSeq
          });
        }
      });
    }

    // 선택 초기화
    this.topGift = '사은품';

    this.setCalcAmt();
  }

  /*******************************************************************************
    설  명 : 주문정보 상품리스트 선택 삭제
  *******************************************************************************/
  deleteProduct(index) {
    const set: any = this.productList;

    set.splice(index, 1);

    this.setCalcAmt();
  }

  /*******************************************************************************
    설  명 : 전체 금액 계산
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setCalcAmt() {
    let total = 0;

    if( this.productList.length > 0 ) {
      this.productList.forEach(obj => {
        if( this.productData.brand_name == 'HJC' )
          total += parseInt(obj.unit_price) * obj.qty;
        else
          total += parseInt(obj.consumer_price) * obj.qty;
      });
    }

    this.totalPrice = total;

    this.naverpayComponent.nPayModuleSet({
      productList: this.productList,
      deliveryCharge : this.delivery,
      totalPrice: this.totalPrice
    })
  }

  /*******************************************************************************
    설  명 : 수량 변경 시
  *******************************************************************************/
  clickQty( product: any, value: any ) {
    let calcCount = parseInt(product.qty) + parseInt(value);

    if( parseInt(this.productData.min_qty) > 0 ) {
      if( calcCount < this.productData.min_qty ) {
        this.toastrService.warning('상품의 최소 수량보다 낮게 입력할 수 없습니다.', '');

        calcCount = this.productData.min_qty;
      }
    }

    if( parseInt(this.productData.max_qty) > 0 ) {
      if( calcCount > this.productData.max_qty ) {
        this.toastrService.warning('상품의 최대 수량보다 높게 입력할 수 없습니다.', '');

        calcCount = parseInt(product.qty);
      }
    }

    product.qty = calcCount;

    this.setCalcAmt();
  }

  /*******************************************************************************
    설  명 : 수량 변경 시
  *******************************************************************************/
  changeQty( product: any, event: any ) {
    if( parseInt(this.productData.min_qty) > 0 && parseInt(event.target.value) < parseInt(this.productData.min_qty) ) {
      this.toastrService.warning('상품의 최소 수량보다 낮게 입력할 수 없습니다.', '');
      event.target.value = this.productData.min_qty;
    }

    if( parseInt(this.productData.max_qty) > 0 && parseInt(event.target.value) > parseInt(this.productData.max_qty) ) {
      this.toastrService.warning('상품의 최대 수량보다 높게 입력할 수 없습니다.', '');
      event.target.value = this.productData.max_qty;
    }

    product.qty = event.target.value;

    this.setCalcAmt();
  }

  /*******************************************************************************
    설  명 : 숫자만 입력
  *******************************************************************************/
  isNumber( product: any, event: any ) : void {
    if( !isNaN(Number(event.target.value)) === false ) {
      event.target.value = this.productData.min_qty;
    }

    product.qty = event.target.value;

    this.setCalcAmt();
  }

  /*******************************************************************************
    설  명 : 탭 클릭 시 이동
  *******************************************************************************/
  scrollToElement(element: any) : void {
    element.scrollIntoView();
  }

  /*******************************************************************************
    설  명 : 회원가 할인
  *******************************************************************************/
  memberSale() {
    if ( !this.isLogin ) {
      this.router.navigate(['/member/login']);
    }
  }

  /*******************************************************************************
    설  명 : 상세 정보
  *******************************************************************************/
  getOrderGuideText(): SafeHtml {
    return this.domSanitizer.bypassSecurityTrustHtml( this.productData.contents );
  }

  /*******************************************************************************
    설  명 : 옵션 차액 표시
  *******************************************************************************/
  getDisplayDifferencePrice( row: any ) {
    if( row.difference_price != '0' ) {
      return ( row.difference_price > 0 ) ? '(+' + this.getComma(row.difference_price) + ' 원)' : '(' + this.getComma(row.difference_price) + ' 원)';
    } else {
      return '';
    }
  }

  /*******************************************************************************
    설  명 : 콤마 표시
    입력값 : 숫자
    리턴값 : 콤마 숫자
  *******************************************************************************/
  getComma( num ) {
    var str = String(num);
    return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
  }

  /*******************************************************************************
    설  명 : 함께 보면 좋은 상품 페이징 처리
  *******************************************************************************/
  setRelationPage( value: any ) {
    if( parseInt(this.relationPageNo) + value < 1 ) {
      this.relationPageNo = 1;
    } else if( parseInt(this.relationPageNo) + value > this.relationTotalPage ) {
      this.relationPageNo = this.relationTotalPage;
    } else {
      this.relationPageNo = parseInt(this.relationPageNo) + value;
    }
  }

  /*******************************************************************************
    설  명 : 상품 찜 여부 정보 가져오기
  *******************************************************************************/
  getKeepProductInfo() {
    this.sProductService.getKeepProductInfo(this.referrer.productSeq).then( response => {
      if( response.ResultCode == true && response.data != null ) {
        if( response.data.keep_yn == 1 ) {
          this.favorite = true;
        } else if ( response.data == null ) {
          this.favorite = false;
        }
      }
    })
    .catch(response => {
      this.toastrService.error(response, '상품 찜하기');
    });
  }

  /*******************************************************************************
    설  명 : 상품 찜하기 저장
  *******************************************************************************/
  setKeepProductSave() {
    // 회원일 경우만
    if( this.isLogin ) {
      if( this.favorite == false ) {
        this.favorite = true;
      } else {
        this.favorite = false;
      }

      this.sProductService.setKeepProductSave([this.referrer.productSeq], this.favorite).then( response => {
        if( response.ResultCode == true ) {
          this.toastrService.success(response.ResultMessage, '상품 찜하기');
        } else {
          this.toastrService.error(response.ResultMessage, '상품 찜하기');
        }
      })
      .catch(response => {
        this.toastrService.success(response, '상품 찜하기');
      });

    } else {
      this.toastrService.error('로그인이 필요한 서비스입니다.', '상품 찜하기');
    }
  }

  /*******************************************************************************
    설  명 : 상품 찜하기 저장
  *******************************************************************************/
  setShare() {
    const modalRef = this.modalService.open(ProductShareComponent, options);
    modalRef.componentInstance.url = 'https:' + this.baseURL + '/product/info?productSeq=' + this.productData.seq;
    modalRef.componentInstance.img = this.productData.origin_url;
    modalRef.componentInstance.title = '오토바이 ' + this.productData.category_name2 + ' ' + this.productData.category_name3 + ' ' + this.productData.product_name;
  }

  /*******************************************************************************
    설  명 : 장바구니 저장
  *******************************************************************************/
  setCartSave() {
    if( this.productList.length < 1 ) {
      this.toastrService.error('기본옵션 상품을 선택해주시기 바랍니다.', '장바구니');
    } else {

      let check: boolean = false;
      this.productList.forEach(item => {
        if( item.isGift === false ) check = true;
      });

      if( check === false ) {
        this.toastrService.error('기본옵션 상품을 선택해주시기 바랍니다.', '주문하기');
      } else {      
        let params: any = {
          product_seq: '',
          productList: []
        };

        params.product_seq = this.referrer.productSeq;
        params.productList = this.productList;

        // 품절 상품인 경우 장바구니 담기 불가
        // if( this.productData.is_soldout == false ) {
        //   this.toastrService.error('품절인 상품은 장바구니에 담을 수 없습니다.', '장바구니');
        //   return false;
        // }

        // 회원인 경우
        // if( this.isLogin ) {
        //   this.sCartService.setCartSave( params ).then(response => {
        //     if( response.ResultCode == true ) {
        //       this.toastrService.success(response.ResultMessage, '장바구니');

        //       this.sCartService.setCartBadge( response.data || 0 );
        //     } else {
        //       this.toastrService.error(response.ResultMessage, '장바구니');
        //     }
        //   })
        //   .catch(response => {
        //     this.toastrService.success(response, '장바구니');
        //   });
        // }
        // // 비회원인 경우
        // else {
          let data: any = this.globals.load( 'bikemart_cart' );
          let cart: any = ( data !== false ) ? data : [];
          let cartList: any = ( data !== false ) ? JSON.parse(cart) : [];

          let check = cartList.filter((obj: any) => obj.product_seq === this.referrer.productSeq);
          if( check.length < 1 ) {
            cartList.push(params);
          } else {
            this.productList.forEach((obj: any) => {

              cartList.forEach((row1: any, index1: number) => {
                if( obj.product_seq == row1.product_seq ) {
                  
                  let check = false;
                  row1.productList.forEach((row2: any, index2: number) => {
                    if( obj.product_property_seq == row2.product_property_seq ) {
                      row2.qty = parseInt(row2.qty) + parseInt(obj.qty);

                      check = true;
                    }
                  });

                  if( check === false ) cartList[ index1 ].productList.push(obj);
                }                
              });
            });
          }

          this.toastrService.success('장바구니에 성공적으로 담았습니다.', '장바구니');

          this.sCartService.setCartBadge( cartList.length || 0 );

          this.globals.save( 'bikemart_cart', cartList );
        // }
      }
    }
  }

  /*******************************************************************************
    설  명 : 비회원 주문 시 표시
  *******************************************************************************/
  nonMemberOrder() {
    const modalRef = this.modalService.open(ProductNonmemberComponent, options);
  }

  /*******************************************************************************
    설  명 : 주문하기
  *******************************************************************************/
  setOrder() {
 
    
    if( this.productList.length < 1 ) {
      this.toastrService.error('기본옵션 상품을 선택해주시기 바랍니다.', '주문하기');
    } else {

      let check: boolean = false;
      this.productList.forEach(item => {
        if( item.isGift === false ) check = true;
      });

   
      if( check === false ) {
        this.toastrService.error('기본옵션 상품을 선택해주시기 바랍니다.', '주문하기');
      } else {
        let cartList: any = [];
        let item: any = {
          product_seq: '',
          productList: [],
          giftList: []
        };

        item.product_seq = this.referrer.productSeq;

        for( let i in this.productList ) {
          item.productList.push({
            product_seq: this.productList[i].product_seq,
            product_property_seq: this.productList[i].product_property_seq,
            product_freegift_seq: '',
            qty: this.productList[i].qty,
            unit_price: this.productList[i].unit_price,
            consumer_price: this.productList[i].consumer_price,
            // discount_amt: this.productList[i].discount_amt,
            discount_amt: 0,
            discount_rate: '',
            event_seq: this.referrer.eventSeq,
            is_voucher:this.productList[i].is_voucher

          });
        }
      
        if (! this.isLogin && this.productData.is_voucher == '1' )  {
          this.toastrService.error('상품권은 일반회원전용 상품입니다. 회원가입 후 구매해주세요.', '주문하기');
        } else if (this.isLogin && this.memberInfo.grade != '0001' && this.productData.is_voucher == '1') {
          this.toastrService.error('상품권은 일반회원만 구매가 가능합니다.', '주문하기');
        } else {
        cartList.push(item);

        this.sCartService.getCartProductInfoList( JSON.stringify(cartList), '', '' ).then( response => {
          if( response.ResultCode ) {
            sessionStorage.setItem('bikemart_order', JSON.stringify(response.data));

            if( ! this.isLogin ) {
              // 비회원인 경우 모달 창 띄우기
              this.nonMemberOrder();

            } else {
              this.router.navigate(
                ['/order'],
                {
                  relativeTo: this.activatedRoute,
                  queryParamsHandling: '',
                }
              );
            }
          } else {
            this.toastrService.error(response.ResultMessage, '');
          }
        });
       }
      
      }
    }
  }

  /*******************************************************************************
    설  명 : 구매후기 신고
  *******************************************************************************/
  setOrderReviewReport( row: any ) {
    // 회원일 경우만
    if( this.isLogin ) {
      if( row.reportSeq !== null ) {
        this.toastrService.error('이미 신고처리되었습니다.', '구매후기 신고');
      } else {
        const modalRef = this.modalService.open(ProductReviewComponent, optionsLG);

        modalRef.componentInstance.data = row;

        modalRef.result.then((result) => {
          if( result.ResultCode == true ) {
            this.toastrService.success(result.ResultMessage, '구매후기 신고');
            this.getReview();
          } else {
            this.toastrService.error(result.ResultMessage, '구매후기 신고');
          }
        }, (reason) => {
        });
      }
    } else {
      this.toastrService.error('로그인이 필요한 서비스입니다.', '구매후기 신고');
    }
  }
  
  /*******************************************************************************
    설  명 : 상품문의 - 문의하기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  qnaWrite() {
    if( this.isLogin === false ) {
      this.router.navigate(['/member/login']);
    } else {
      const modalRef = this.modalService.open(ProductQuestionComponent, options);

      modalRef.componentInstance.product_seq = this.productData.seq;

      modalRef.result.then((result) => {
        this.getProductQuestion();
      }, (reason) => {
      });
    }
  }

  /*******************************************************************************
    설  명 : 상품문의리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getProductQuestion() {
    let product_seq = this.productData.seq;

    this.sProductService.getProductQuestion( product_seq, this.isMy, this.state ).then( response => {
      if ( response.ResultCode ) {
        this.questionList = response.data;
        this.questionCount = response.count;
        this.questionTotalPage = Math.ceil(this.questionCount / this.questionPageRow);
        this.state = '0';
        this.addFiveRow();
      }
    });
  }

  /*******************************************************************************
    설  명 : 상품문의 페이징 처리
  *******************************************************************************/
  setQuestionPage( event: any ) {
    this.questionPageNo = event;

    this.openRow.map( rows => {
      $("#child_" + rows).remove();
    });
    this.openRow = [];
  }

  /*******************************************************************************
    설  명 : 상품문의 내 문의 선택
  *******************************************************************************/
  isMyQuestion( num ) {
    this.isMy = num;

    this.getProductQuestion();
  }

  /*******************************************************************************
    설  명 : 상품문의 상태선택
  *******************************************************************************/
  setState( event ) {
    this.state = event.target.value;

    this.getProductQuestion();
  }

  /*******************************************************************************
    설  명 : 상품문의 내용보기
  *******************************************************************************/
  view(row) {
    if ( row.secret == "1" && this.loggedInInfo.id != row.id) {
      this.toastrService.error('비밀글입니다.');
    } else if ( row.secret == "1" && !this.isLogin ) {
      this.toastrService.error('비밀글입니다.');
    } else {
      let child = $("#child_"+ row.seq);

      if( child.length < 1 ) {
        let answer = '';
        if( row.answer !== null ) {
          answer = '<br><br><span class="red ml10">답변입니다.</span><br><span class="red ml10">' + row.answer +'</span></td></tr>'
        }
        let node =
          '<tr id="child_'+ row.seq +'"><td></td><td colspan="6" style="padding: 30px 0px 40px 0px;line-height: 150%;" class="tl"><span class="ml10">'
            + row.comment + '</span>' + answer;
        $("#tr_" + row.seq).after( node );

        this.openRow.push(row.seq);
      } else {
        $("#child_" + row.seq).remove();
      }
    }
  }

  /*******************************************************************************
    설  명 : 상품문의 내용보기
  *******************************************************************************/
  viewMobile(row) {
    if ( row.secret == "1" && this.loggedInInfo.id != row.id) {
      this.toastrService.error('비밀글입니다!!');
    } else if ( row.secret == "1" && !this.isLogin ) {
      this.toastrService.error('비밀글입니다!!');
    } else {
      let child = $("#child_"+ row.seq);

      if( child.length < 1 ) {
        let answer = '';
        if( row.answer !== null ) {
          answer = '<br><br><span class="red ml10">답변입니다.</span><br><span class="red ml10">' + row.answer +'</span></div></li></ul>'
        }
        // let node = '<div>test</div>'
        let node =
          '<ul id="child_'+ row.seq +'"><li><div class="txt"><span class="ml10">'
            + row.comment + '</span>' + answer;
        $("#tr_" + row.seq).after( node );

        this.openRow.push(row.seq);
      } else {
        $("#child_" + row.seq).remove();
      }
    }
  }


  @HostListener("window:scroll", [])
  onWindowScroll() {
    const offset = window.pageYOffset;
    const offset1 = document.body.scrollHeight;
    if (offset >= 1782) {
      this.fixedOrder = true;
    }
    else {
      this.fixedOrder = false;
    };
    if (offset >= 1381) {
      this.fixedMenu = true;
    }
    else {
      this.fixedMenu = false;
    };
    if (offset + 1700 >= offset1) {
      this.fixedOrder = false;
      this.fixedMenu = false;
    };
  }

  /*******************************************************************************
    설  명 : 구매후기 get
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getReview() {
    let product_seq = this.productData.seq;

    this.sProductService.getReview( product_seq, this.review_sort ).then( response => {
      if( response.ResultCode ) {
        this.reviewList = response.data;
        this.reviewCount = response.total;
        this.reviewImageList = response.image;
        this.one = response.count.one;
        this.two = response.count.two;
        this.three = response.count.three;
        this.four = response.count.four;
        this.five = response.count.five;
        this.reviewTotalPage = Math.ceil(this.reviewCount / this.reviewPageRow);
        if ( response.count.average_grade == null ) {
          this.reviewAverageGrade = 0;
        } else {
          this.reviewAverageGrade = response.count.average_grade.substr(0,3);
        }
      }
    });
  }

  /*******************************************************************************
    설  명 : 구매후기 이미지 리스트 생성
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getReviewImageList( row: any ) {
    if( this.reviewImageList === undefined ) return null;

    return this.reviewImageList.filter((obj: any) => obj.review_seq === row.seq);
  }

  /*******************************************************************************
    설  명 : 구매후기 이미지 클릭 시 원본보기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setGuideView( value: any, url: any ) {
    this.guideView = value;
    this.reviewImageURL = url;
  }

  /*******************************************************************************
    설  명 : 구매후기 별점비율 계산
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  reviewCal(key) {
    let tmp = 0;

    if ( key == 5 ) {
      tmp = this.five / this.reviewCount;
    } else if ( key == 4 ) {
      tmp = this.four / this.reviewCount;
    } else if ( key == 3 ) {
      tmp = this.three / this.reviewCount;
    } else if ( key == 2 ) {
      tmp = this.two / this.reviewCount;
    } else if ( key == 1 ) {
      tmp = this.one / this.reviewCount;
    }

    if ( isNaN(tmp) ) {
      tmp = 0;
    }

    return 'calc(60px *' + tmp + ')';
  }

  /*******************************************************************************
    설  명 : 모바일 구매후기 별점비율 계산
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  reviewMobileCal(key) {
    let tmp = 0;

    if ( key == 5 ) {
      tmp = this.five / this.reviewCount;
    } else if ( key == 4 ) {
      tmp = this.four / this.reviewCount;
    } else if ( key == 3 ) {
      tmp = this.three / this.reviewCount;
    } else if ( key == 2 ) {
      tmp = this.two / this.reviewCount;
    } else if ( key == 1 ) {
      tmp = this.one / this.reviewCount;
    }

    if ( isNaN(tmp) ) {
      tmp = 0;
    }

    return 'calc(100% *' + tmp + ')';
  }

  /*******************************************************************************
    설  명 : 구매후기 별점비율 계산
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getRatio(key) {
    let tmp = '0';

    if ( this.reviewAverageGrade == 0 ) {
      tmp = '0';
    } else {
      if ( key == 5 ) {
        tmp = this.five / this.reviewCount * 100 +"";
        tmp = tmp.substr(0,4);
      } else if ( key == 4 ) {
        tmp = this.four / this.reviewCount * 100 +"";
        tmp = tmp.substr(0,4);
      } else if ( key == 3 ) {
        tmp = this.three / this.reviewCount * 100 +"";
        tmp = tmp.substr(0,4);
      } else if ( key == 2 ) {
        tmp = this.two / this.reviewCount * 100 +"";
        tmp = tmp.substr(0,4);
      } else if ( key == 1 ) {
        tmp = this.one / this.reviewCount * 100 +"";
        tmp = tmp.substr(0,4);
      }
    }

    return tmp;
  }

  /*******************************************************************************
    설  명 : 메세지 보이기
  *******************************************************************************/
  showMessage( msg: any, type: any ) {
    switch( type ) {
      case 'success':
        this.toastrService.success( msg, '' );
        break;
      case 'error':
        this.toastrService.error( msg, '' );
        break;
      case 'info':
        this.toastrService.info( msg, '' );
        break;
      default:
    }
  }

  /*******************************************************************************
    설  명 : 구매후기 페이징 처리
  *******************************************************************************/
  setReviewPage( event: any ) {
    this.reviewPageNo = event;
  }

  /*******************************************************************************
    설  명 : 구매후기 전체 평균점수
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setAverageGarde() {
    return 'calc(170px *' + this.reviewAverageGrade + '/ 5)';
  }

  /*******************************************************************************
    설  명 : 구매후기 전체 평균점수
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setMobileAverageGarde() {
    return 'calc(110px *' + this.reviewAverageGrade + '/ 5)';
  }

  /*******************************************************************************
    설  명 : 구매후기 평균점수 설정
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setGarde(row) {
    return 'calc(90px *' + row.grade + '/ 5)';
  }

  /*******************************************************************************
    설  명 : 구매후기 평균점수 설정
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  mqnaDetail(row, i) {
    if ( row.secret == "1" && this.loggedInInfo.id != row.id) {
      this.toastrService.error('비밀글입니다!!');
    } else if ( row.secret == "1" && !this.isLogin ) {
      this.toastrService.error('비밀글입니다!!');
    } else if ( i == this.mqnaView ) {
      this.mqnaView = 999999999999999;
    } else {
      this.mqnaView = i;
    }
  }

  /*******************************************************************************
    설  명 : 구매후기 더보기 5줄 추가
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  addRow() {
    let num = this.questionAddList.length;

    if ( this.questionList.length == this.questionAddList.length ) {
      this.toastrService.error('더이상 문의가 없습니다.');
    } else {
      for ( let i = num; i < num + 5; i++ ) {
        if ( this.questionList[i] != null ) {
          this.questionAddList.push(this.questionList[i]);
        }
      }
    }
  }

  /*******************************************************************************
    설  명 : 구매후기 초기 5줄 설정
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  addFiveRow() {
    this.questionAddList = [{
      seq: "0"
    }];

    if ( this.questionList.length >= 5 ) {
      for ( let i = 0; i < 5; i++ ) {
        let result = this.questionAddList.find( item => item.seq == this.questionList[i].seq);

        if( result !== undefined ) {
          return false;
        } else {
          this.questionAddList.push(this.questionList[i]);
        }
      }

    } else {
      for ( let i = 0; i < this.questionList.length; i++ ) {
        let result = this.questionAddList.find( item => item.seq == this.questionList[i].seq);

        if( result !== undefined ) {
          return false;
        } else {
          this.questionAddList.push(this.questionList[i]);
        }
      }
    }

    if ( this.questionAddList[0].seq == "0" ) {
      this.questionAddList.shift();
    }
  }

  /*******************************************************************************
    설  명 : 브랜드 공지 연결
  *******************************************************************************/
  brandNoticeBinding() {
    // if ( this.productData.brand_name )
  }

  /*******************************************************************************
    설  명 : 무이자 할부 안내 링크
  *******************************************************************************/
  goFreeInterest() {
    var gourl = "https://www.inicis.com/blog/archives/16302#content"
    window.open(gourl, 'popup1','scrollbars=yes, width=1200, height=900');
  }

  /*******************************************************************************
    설  명 : KC인증 팝업 띄우기
    입력값 : seq
    리턴값 : 없음
  *******************************************************************************/
  openCert() {
    var gourl = "https://safetykorea.kr/search/searchPop?certNum=" + this.productData.kc_number;
    window.open(gourl, 'popup2','scrollbars=yes, width=800, height=900');
  }
  
  /*******************************************************************************
    설  명 : 가격 비교 함수
  *******************************************************************************/
  comparePrice(item: any, condition: any) {
    if( !item || item === undefined ) return;

    if( eval(condition) ) return true;
    else return false;
  }
}
