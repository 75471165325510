import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-story',
  templateUrl: './story.component.html',
  styleUrls: ['./story.component.scss']
})
export class StoryComponent implements OnInit {
  public site: 'daejeon' | 'ulsan' | 'pohang' | 'gyeonggi_seobu';

  public isMobileApp: Boolean = false;

  constructor(
    public router: Router,
    private location: Location,
    public activeModal: NgbActiveModal,
  ) { }

  ngOnInit(): void {
    if( this.location.path().indexOf( '/mobile' ) > -1 ) {
      this.isMobileApp = true;
    }
  }

  closeModal(): void {
    this.activeModal.close();
  }

}
