<app-header class="pc-view"></app-header>

<div class="inner">
  <form [formGroup]="orderForm" #kginicis method="POST">
  <div class="page-header">
    <div class="page-title">주문/결제</div>
    <ul class="step clearfix pc-view">
      <li>장바구니</li>
      <li class="active">주문/결제</li>
      <li>완료</li>
    </ul>  
    <a routerLink="" class="close-btn mobile-view"></a>
  </div>
  
  <div class="left-area">
	  <div class="guide">
			<div>
        <p class="dot">·</p><p class="dot1">배송비는 주문 시 확인 바랍니다.(구매대행 해외배송비는 추가로 청구됩니다). *단 제주도는 3,000원, 도서/산간지역은 4,000원 추가 별도 부담 됩니다.</p>
        <p class="dot">·</p><p class="dot1"><span class="cred">교환받을 상품이 품절시 택배비 고객부담입니다.</span></p>
        <p class="dot">·</p><p class="dot1">배송일은 2~5일 정도 소요되며 지역 및 재고 상황에 따라 변동될 수 있습니다. (해외주문 상품은 개별 연락)</p>
        <!-- <p class="dot">·</p><p class="dot1">주문 선착순 발송을 기본으로 하고 실시간 재고는 연동되지 않으며 품절시 문자, 전화 안내 후 결제 부분취소 될 수 있습니다.</p> -->
        <p class="dot">·</p><p class="dot1">묶음 주문 시 재고 가능 상품 먼저 우선 출고됩니다. 재고 부족 시 문자 알림 후 자동 부분 취소(환불) 됩니다.</p>
        <p class="dot">·</p><p class="dot1">실시간 재고는 연동되지 않으며 1:1 문의 상품번호로 재고를 먼저 확인하시기 바랍니다.</p>
        <p class="dot">·</p><p class="dot1">송장번호 입력 후 취소는 반품접수 후 환불받으시기 바랍니다. (왕복택배비 고객부담)</p>
        <p class="dot">·</p><p class="dot1">모든 상품은 받은 날 바로 불량 유무와 부속품 누락을 확인해 주시기 바랍니다.<span class="cred">보관만 하셨다고 보름 뒤 연락 오는 건 초기 불량과 누락으로 인정되지 않음을 알려드립니다.</span></p>
      </div>
	  </div>

    <!-- 주문자 정보 -->
    <div class="section">
      <div class="title">주문자 정보</div>   

      <table class="info-table">
      <colgroup>
        <col class="col1">
        <col>
      </colgroup>  
      <tbody>
      <tr>
        <th><label for="info_order_name"><i class="material-icons cred f11">star</i> 이름</label></th>
        <td>
          <input type="text" [ngClass]="{formErrors: formErrors['info_order_name'], readonly: memberInfo.grade != ''}" id="info_order_name" formControlName="info_order_name" [readonly]="memberInfo.grade != '' ? true : false" [autocomplete]="'off'" />
          <!-- <span><span class="red"><br>주문자성명은 입금자명과 일치해야 합니다.</span></span> -->
        </td>
      </tr>
      <tr>
        <th><label for="info_order_hp1"><i class="material-icons cred f11">star</i> 연락처</label></th>
        <td>
          <!-- <select formControlName="info_order_hp1" [ngClass]="{formErrors: formErrors['info_order_hp1'], 'phone': true}">
            <option value="010">010</option>
            <option value="011">011</option>
            <option value="016">016</option>
            <option value="017">017</option>
            <option value="018">018</option>
            <option value="019">019</option>
          </select> -->
          <input type="text" [ngClass]="{formErrors: formErrors['info_order_hp1'], 'phone': true}" maxlength="4" id="info_order_hp1" formControlName="info_order_hp1" (keyup)="hpKeyUp($event)" [autocomplete]="'off'" />
          <span class="m-2">-</span>
          <input type="text" [ngClass]="{formErrors: formErrors['info_order_hp2'], 'phone': true}" maxlength="4" id="info_order_hp2" formControlName="info_order_hp2" #info_order_hp2 (keyup)="hpKeyUp($event)" [autocomplete]="'off'" />
          <span class="m-2">-</span>
          <input type="text" [ngClass]="{formErrors: formErrors['info_order_hp3'], 'phone': true}" maxlength="4" id="info_order_hp3" formControlName="info_order_hp3" #info_order_hp3 [autocomplete]="'off'" />

          <label *ngIf="isLogIn === true" class="ml10 cblack mt10">
            <input type="checkbox" class="change-checkbox square" id="isEditMember" formControlName="isEditMember" [checked]="orderForm.controls.isEditMember.value">
            <span class="new-checkbox margin-custom"><i class="material-icons">check</i></span>회원 정보에 반영
          </label>
        </td>
      </tr>
      <tr>
        <th><label for="info_order_email1"><i class="material-icons cred f11">star</i> 이메일주소</label></th>
        <td>
          <!-- <input type="text" [ngClass]="{formErrors: formErrors['info_order_email']}" formControlName="info_order_email" /> -->
          <input type="text" class="pc-view" id="info_order_email1" formControlName="info_order_email1" [ngClass]="{formErrors: formErrors['info_order_email1']}" [autocomplete]="'off'" />
          <input type="text" class="mobile-view mb5" id="info_order_email1" formControlName="info_order_email1" [ngClass]="{formErrors: formErrors['info_order_email1']}" [autocomplete]="'off'" />
          <span>@</span>
          <input type="text" 
            class="dir_email2 pc-view ml5" 
            id="info_order_email2"
            formControlName="info_order_email2"
            [ngClass]="{formErrors:formErrors['info_order_email2']}" 
            placeholder="{{ emailReadonly == false ? '직접 입력해 주세요' : emailAddress }}" 
            [readonly]="emailReadonly"
            style="width: 200px;"
            [autocomplete]="'off'"
          />
          <input type="text" 
            class="dir_email2 mobile-view mb5" 
            id="info_order_dir_email2" 
            formControlName="info_order_email2"  
            placeholder="{{ emailReadonly == false ? '직접 입력해 주세요' : emailAddress }}"
            [ngClass]="{formErrors:formErrors['info_order_email2']}" 
            [readonly]="emailReadonly"
            [autocomplete]="'off'"
          />
          <select id="email_select" (change)="selectEmail($event)" style="width: 200px;">
            <option value="">직접입력</option>
            <option *ngFor="let email of emailAddressList" [value]="email" selected="{{orderForm.controls.info_order_email2.value == email ? 'selected' : ''}}">{{email}}</option>
          </select>
        </td>
      </tr> 
      </tbody>
      </table>        
    </div>  

    <!-- 배송지 정보 -->
    <div class="section">
      <div class="title">배송지 정보</div>
        
      <table class="info-table">
      <colgroup>
        <col class="col1">
        <col>
      </colgroup>  
      <tbody>
        <tr>
          <th><label for="">배송지선택</label></th>
          <td>
            <div *ngIf="isLogIn === true" class="radio radio-d">
              <label (click)="getInfoReceive(false)">기본배송지
                <input type="radio" name="radio-d" [checked]="isLogIn === true ? true : false" />
                <span class="checkmark"></span>
              </label>
            </div>
            <div class="radio radio-d">
              <label (click)="getInfoReceive(true)">신규배송지
                <input type="radio" name="radio-d" [checked]="isLogIn === false ? true : false" />
                <span class="checkmark"></span>
              </label>
            </div>
            <button *ngIf="isLogIn === true" type="button" class="btn1 dest-btn" (click)="openDestination()">배송지 목록</button>
          </td>
        </tr>
      <tr>
        <th><label for="info_receive_name"><i class="material-icons cred f11">star</i> 받는 사람 이름</label></th>
        <td>
          <input type="text" [ngClass]="{formErrors: formErrors['info_receive_name']}" id="info_receive_name" formControlName="info_receive_name" [autocomplete]="'off'" />
          <button type="button" class="btn2 dest-btn" (click)="setInfoReceive()">주문자 정보와 동일</button>
        </td>
      </tr>
      <tr>
        <th><label for="info_receive_phone1"><i class="material-icons cred f11">star</i> 연락처</label></th>
        <td>
          <!-- <select formControlName="info_receive_phone1" [ngClass]="{formErrors: formErrors['info_receive_phone1'], 'phone': true}">
            <option value="010">010</option>
            <option value="011">011</option>
            <option value="016">016</option>
            <option value="017">017</option>
            <option value="018">018</option>
            <option value="019">019</option>
          </select> -->
          <input type="text" [ngClass]="{formErrors: formErrors['info_receive_phone1'], 'phone': true}" id="info_receive_phone1" maxlength="4" formControlName="info_receive_phone1" (keyup)="hpKeyUp($event)" [autocomplete]="'off'" />
          <span class="m-2">-</span>
          <input type="text" [ngClass]="{formErrors: formErrors['info_receive_phone2'], 'phone': true}" id="info_receive_phone2" maxlength="4" formControlName="info_receive_phone2" #info_receive_phone2 (keyup)="hpKeyUp($event)" [autocomplete]="'off'" />
          <span class="m-2">-</span>
          <input type="text" [ngClass]="{formErrors: formErrors['info_receive_phone3'], 'phone': true}" id="info_receive_phone3" maxlength="4" formControlName="info_receive_phone3" #info_receive_phone3 [autocomplete]="'off'" />
        </td>
      </tr> 
      <tr>
        <th class="vt"><label for="info_receive_address_zipcode"><i class="material-icons cred f11">star</i> 주소</label></th>
        <td>
          <input type="text" class="zipno readonly" id="info_receive_address_zipcode" formControlName="info_receive_address_zipcode" #info_receive_address_zipcode [ngClass]="{formErrors: formErrors['info_receive_address_zipcode']}" [autocomplete]="'off'" [readonly]="true" />
          <!--<button class="btn1 zip-btn" (click)="getZipcode()">우편번호 검색</button>-->
          <btn-daum-address (result)="setDaumAddressApi($event)" [options]="daumAddressOptions"></btn-daum-address>

          <label *ngIf="isLogIn === true" class="ml10 cblack mt10">
            <input type="checkbox" class="change-checkbox square" id="isPushAddress" formControlName="isPushAddress" [checked]="orderForm.controls.isPushAddress.value">
            <span class="new-checkbox margin-custom"><i class="material-icons">check</i></span>배송지 목록에 추가
          </label>
          <label *ngIf="isLogIn === true" class="ml10 cblack mt10">
            <input type="checkbox" class="change-checkbox square" id="isDefaultAdress" formControlName="isDefaultAdress" [checked]="orderForm.controls.isDefaultAdress.value">
            <span class="new-checkbox margin-custom"><i class="material-icons">check</i></span>기본 배송지로 설정
          </label>

          <div class="addr">
            <input type="text" class="readonly" [ngClass]="{formErrors: formErrors['info_receive_address_main']}" id="info_receive_address_main" formControlName="info_receive_address_main" [autocomplete]="'off'" [readonly]="true" />
            <input type="text" [ngClass]="{formErrors: formErrors['info_receive_address_sub']}" id="info_receive_address_sub" formControlName="info_receive_address_sub" #info_receive_address_sub (focus)="setTotalDelivery()" [autocomplete]="'off'" />
          </div>
        </td>
      </tr> 
      <tr>
        <th><label for="info_receive_comment">배송시 요청사항</label></th>
        <td>
          <input type="text" id="info_receive_comment" formControlName="info_receive_comment" style="width: 100% !important;" placeholder="100자 이내" [autocomplete]="'off'" />
        </td>
      </tr> 
      </tbody>
      </table>        
    </div>  
    
    <!-- 주문상품 -->
    <div class="section">
      <div class="title">주문상품</div>   

	    <table class="list-table">
	    <colgroup class="pc-view">
	      <col style="width: 68%">
	      <!-- <col style="width: 11%"> -->
	      <col style="width: 15%">
	      <col style="width: 17%">
	    </colgroup>    

      <!-- <colgroup class="mobile-view">
        <col style="width: 50%">
        <col style="width: 50%">
      </colgroup>  -->
	
	    <thead>
	    <tr>
	      <th>상품정보/옵션</th>
	      <!-- <th>수량</th> -->
	      <th>할인</th>
	      <th>상품금액</th>
	    </tr>    
	    </thead>
	    
	    <tbody class="pc-view">
	    <tr *ngFor="let item of info_product">
	      <td class="info">
	        <div class="img"><img [src]="item.thumbnail_url" alt="{{item.product_name}}"></div>
	        <div class="info1">
	          <p>{{item.product_name}}</p>
	          <ul *ngIf="item.children.length > 0">
              <ng-container *ngFor="let option of item.children; let j = index">
                <li *ngIf="!option.product_freegift_seq">기본옵션{{j+1}} : 색상 : {{option.display_color_name}} / 사이즈 : {{option.display_size_name ? option.display_size_name : '단품'}} / {{option.qty | number}} 개</li>
                <li *ngIf="option.product_freegift_seq">추가옵션 : {{option.product_name}} / 색상 : {{option.display_color_name}} / 사이즈 : {{option.display_size_name ? option.display_size_name : '단품'}} / {{option.qty | number}} 개</li>
              </ng-container>
	          </ul>  
            <div class="add-option" *ngIf="item.product_option_seq">
              <div>추가옵션1 : </div>
            </div> 	          
	        </div>
	      </td>
	      <!-- <td>{{( item.sum_discount_amt ? item.sum_discount_amt : 0 ) | number}} 원</td> -->
        <td>0 원</td>
	      <td class="price">
          <p class="price1" [ngClass]="{'coupon':item.isCoupon == true}">{{item.sum_total_amt | number}} 원</p>
          <p *ngIf="info_delivery.delivery_use_yn == '0'" class="price2">{{item.delivery_price | number}} 원</p>
	      </td>
	    </tr>
	    </tbody>

      <tbody class="mobile-view">
        <tr *ngFor="let item of info_product">
          <td class="info">
            <div class="img"><img [src]="item.thumbnail_url" alt="{{item.product_name}}"></div>
            <div class="info1">
              <p>{{item.product_name}}</p>
              <ul *ngIf="item.children.length > 0">
                <ng-container *ngFor="let option of item.children; let j = index">
                  <li *ngIf="!option.product_freegift_seq">기본옵션{{j+1}} : 색상 : {{option.display_color_name}} / 사이즈 : {{option.display_size_name ? option.display_size_name : '단품'}} / {{option.qty | number}} 개</li>
                  <li *ngIf="option.product_freegift_seq">추가옵션 : {{option.product_name}} / 색상 : {{option.display_color_name}} / 사이즈 : {{option.display_size_name ? option.display_size_name : '단품'}} / {{option.qty | number}} 개</li>
                </ng-container>
              </ul>  
              <div class="add-option" *ngIf="item.product_option_seq">
                <div>추가옵션1 : </div>
              </div> 	          
            </div>
          </td>
          <td class="price">
            <!-- <p *ngIf="item.sum_discount_amt">할인 : {{item.sum_discount_amt | number}} 원</p> -->
            <p>할인 : 0 원</p>
            <p class="price1" [ngClass]="{'coupon':item.isCoupon == true}">{{item.sum_total_amt | number}} 원</p>
            <p *ngIf="info_delivery.delivery_use_yn == '0'" class="price2">{{item.delivery_price | number}} 원</p>
          </td>
        </tr>
        </tbody>
	    </table>
    </div>

    <!-- 업체별 배송 목록 -->
    <div *ngIf="memberInfo.grade == '0004' || memberInfo.grade == '0007'" class="section">
      <div class="title">업체별 배송 목록</div>   

	    <table class="list-table">
	    <colgroup class="pc-view">
	      <col style="width: 83%">
	      <col style="width: 17%">
	    </colgroup>    

	    <thead>
	    <tr>
	      <th>업체명</th>
	      <th>배송비</th>
	    </tr>    
	    </thead>
	    
	    <tbody class="pc-view">
	    <tr *ngFor="let item of orderForm.controls.delivery_charge.value">
	      <td class="info">
	        <div class="info1 pl0">
	          <p>{{item.customer_name}}</p>
	        </div>
	      </td>
	      <td class="price">
          <p class="price1">{{item.delivery_price | number}} 원</p>
	      </td>
	    </tr>
      <tr *ngIf="orderForm.controls.delivery_charge.value.length < 1">
        <td colspan="2">배송비 내역이 없습니다.</td>
      </tr>
	    </tbody>

      <tbody class="mobile-view">
        <tr *ngFor="let item of orderForm.controls.delivery_charge.value">
          <td class="info pr0">
            <div class="info1 w100p pl0">
              <p>{{item.customer_name}}</p>
            </div>
          </td>
          <td class="price dtc">
            <p class="price1">{{item.delivery_price | number}} 원</p>
          </td>
        </tr>
        </tbody>
	    </table>
    </div>
      <ng-container *ngIf="info_product && info_product.length > 0 && info_product[0].is_voucher !== undefined && info_product[0].is_voucher !== '1'">
        <!-- 할인/마일리지 -->
        <div class="sale section">
          <div class="title">할인/마일리지</div>   

          <table class="info-table">
          <colgroup>
            <col  class="col1">
            <col>
          </colgroup>  
          <tbody>
          <!-- <tr>
            <th><label for="">할인쿠폰</label></th>
            <td>
              <input type="text" class="readonly" [value]="(total_sale | number)" readonly /><span>원</span>
              <button type="button" class="btn1" (click)="couponApply()">쿠폰 적용</button>
            </td>        
          </tr>     -->
          <tr>
            <th><label for="use_mileage">마일리지 사용</label></th>
            <td>
              <input type="text" id="use_mileage" formControlName="use_mileage" (keyup)="useMileage($event.target.value)" [autocomplete]="'off'" /><span>M</span>
              <!--<button class="btn1 use-btn">사용하기</button>-->
              <button type="button" class="btn1 use-btn dark" (click)="useMileage('all')">모두 사용</button>
              <span class="info">(보유 마일리지 : <span class="bold">{{info_mileage | number}}M</span>)</span>
            </td>
          </tr> 
          </tbody>
          </table>        
        </div>     
        <!-- 바마 쿠폰  -->
        <ng-container *ngIf="couponList.length> 0">
          <div class="sale section">
            <div class="title">바마쿠폰</div>   

            <table class="info-table">
            <colgroup>
              <col  class="col1">
              <col>
            </colgroup>  
            <tbody>
            <tr>
              <th><label for="use_coupon">사용가능쿠폰</label></th>
              <td>
                <select id="bamacoupon_seq" formControlName="bamacoupon_seq" (change)="selectedCoupon($event)" class="mt5">
                  <option value="0">사용할 쿠폰을 선택하세요.</option>
                  <option *ngFor="let row of couponList" [value]="row.seq">{{row.coupon_name}}</option>
                </select>
              <!--   <button type="button" class="btn1 use-btn dark" (click)="useMileage('all')">사용하기</button> -->
              </td>
            </tr> 
            </tbody>
            </table>        
          </div>          
       </ng-container>
      <!-- 공동구매 쿠폰 -->
      <div *ngIf="isCoupon" class="sale section">
        <div class="title">공동구매 쿠폰사용</div>   

        <table class="info-table">
        <colgroup>
          <col  class="col1">
          <col>
        </colgroup>  
        <tbody>
        <tr>
          <th><label for="coupon_code">쿠폰코드</label></th>
          <td>
            <input type="text" class="" id="coupon_code" formControlName="coupon_code"  [maxlength]="20" [autocomplete]="'off'" />
            <input type="text" class="" id="coupon_type" formControlName="coupon_type"  hidden />
            <input type="text" class="" id="coupon_seq" formControlName="coupon_seq"  hidden />
            <button type="button" class="btn1 dark" (click)="setCouponCode()">쿠폰 적용</button>
          </td>
        </tr>
        </tbody>
        </table>
      </div>
    </ng-container>
    
    <!-- 결제방법 -->
    <div class="pay-method section">
      <div class="title">결제방법</div>   

      <table class="info-table">
      <colgroup>
        <col  class="col1">
        <col>
      </colgroup>  
      <tbody>
      <ng-container *ngIf="info_product && info_product.length > 0 && info_product[0].is_voucher !== undefined && info_product[0].is_voucher !== '1'">  
        <tr *ngIf="memberInfo.grade != '0004' && memberInfo.grade != '0007'">
          <th class="" colspan="2" [ngClass]="{'white':gopaymethod != 'Card'}" (click)="setGopaymethod('Card')">
            <div class="radio">
              <label>신용/체크카드
                <input type="radio" name="gopaymethod" [(ngModel)]="gopaymethod" value="Card" [ngModelOptions]="{standalone: true}" />
                <span class="checkmark"></span>
              </label>
            </div>
          </th>

        </tr>
      </ng-container>  
      <tr>
        <th class="" colspan="2" [ngClass]="{'white':gopaymethod != 'NBank'}" (click)="setGopaymethod('NBank')">
          <div class="radio">
            <label class="mr65">무통장입금
              <input type="radio" name="gopaymethod" [(ngModel)]="gopaymethod" value="NBank" [ngModelOptions]="{standalone: true}" />
              <span class="checkmark"></span>
            </label>
            <select id="account_seq" formControlName="account_seq" (change)="selectedAccount($event)" class="mt5">
              <option value="">입금하실 은행을 선택하세요</option>
              <option *ngFor="let row of accountList" [value]="row.seq">{{row.bank_name}} : {{row.bank_account}}</option>
            </select>
            <input type="text" id="receipt_name" formControlName="receipt_name" class="receipt-name mt5 mr0" [ngClass]="{formErrors: formErrors['receipt_name']}" placeholder="실입금자명을 입력하세요" [autocomplete]="'off'" />
            <button type="button" class="btn2 dest-btn" style="height:33px;" (click)="setInfoReceipt()">주문자명과 동일</button>
          </div>
        </th>
      </tr>
      </tbody>
      </table>
    </div>
  </div>

  <div class="right-area">
    <div class="right-div">
	    <div class="div1">
	      <div class="title">적립 혜택</div>
	      <ul class="clearfix">
	        <li>
	          <p>마일리지</p>
	          <p>{{total_mileage | number}}<span> M</span></p>          
	        </li>
	      </ul>
	    </div>   
	    <div class="div1">
	      <div class="title">결제 예정금액</div>
	      <ul class="clearfix">
	        <li>
	          <p>상품금액</p>
	          <p>{{total_productprice | number}}<span> 원</span></p>          
	        </li>
	        <li>
	          <p>배송비</p>
	          <p><span>+ </span>{{total_delivery | number}}<span> 원</span></p>          
	        </li>
	        <li>
	          <p>할인금액</p>
	          <p><span>- </span>{{total_sale | number}}<span> 원</span></p>          
	        </li>
	        <li>
	          <p>마일리지 사용</p>
	          <p><span>- </span>{{this.orderForm.get('use_mileage').value | number}}<span> 원</span></p>          
	        </li>
	      </ul>
	    </div>  
	    <div class="tot clearfix">
		    <p>총 결제금액</p>
		    <p>{{(total_estimate + total_delivery) | number}}<span> 원</span></p>         
	    </div>
      <button type="button" class="btn1" (click)="submit()" [disabled]="isSubmit">결제하기</button>
	  </div>
	  <div class="right-txt">
			· 안전거래를 위해 현금 등으로 5만원 이상 결제시 저희 쇼핑몰에서 가입한<br/> 
			<span class="red space">구매안전서비스 "소비자피해보상보험서비스"를 이용</span>하실 수 있습니다.<br/>
			· 보상대상 : 미배송, 반품/환불거부, 쇼핑몰부도<br/>
			· 구매안전서비스를 통하여 주문하시고 <span class="red">서울보증보험에서 발행하는</span><br/> 
			<span class="red space">보험계약 체결내역서를 반드시 확인</span>하시기 바랍니다.
	  </div>
  </div>
  <div class="cb"></div>

  <input type="hidden" name="server_name" formControlName="server_name" />
  <input type="hidden" name="mid" formControlName="mid" />
  <input type="hidden" name="oid" formControlName="oid" />
  <input type="hidden" name="price" formControlName="price" />
  <input type="hidden" name="memNo" formControlName="memNo" />
  <input type="hidden" name="isLogIn" formControlName="isLogIn" />
  <input type="hidden" name="tmpSeq" formControlName="tmpSeq" />

  </form>

  <form id="INIPayForm" name="INIPayForm">
    <input type="hidden" name="version" value="1.0" />
    <input type="hidden" name="currency" value="WON" />
    <input type="hidden" name="languageView" value="ko" />
    <input type="hidden" name="charset" value="UTF-8" />
    <input type="hidden" name="payViewType" value="overlay" />

    <input type="hidden" name="goodname" [value]="orderForm.controls.goodname.value" />
    <input type="hidden" name="buyername" [value]="orderForm.controls.info_order_name.value" />
    <input type="hidden" name="buyertel" [value]=" orderForm.controls.info_order_hp1.value + '-' + orderForm.controls.info_order_hp2.value + '-' + orderForm.controls.info_order_hp3.value" />
    <input type="hidden" name="buyeremail" [value]="orderForm.controls.info_order_email.value" />
    <input type="hidden" name="gopaymethod" [value]="gopaymethod" />
    <input type="hidden" name="nointerest" [value]="nointerest" />
    <input type="hidden" name="quotabase" [value]="quotabase" />
    <input type="hidden" name="acceptmethod" value="SKIN(#fe515d):below1000" />
    <input type="hidden" name="returnUrl" value="{{this.serverName}}/api/shop/kginicis/INIStdPay/INIStdPayReturn.php" />
  </form>

</div>

<app-footer></app-footer>