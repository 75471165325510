<div class="member-page">
  <div class="member-inner">
    <div class="logo">
      <a class="cp" (click)="goMain()"><img src="/assets/images/logo1.png?date=20210913" alt="바이크마트 로고"></a>
    </div>

    <form [formGroup]="memberForm" (ngSubmit)="memberSubmit()">

      <div class="title">로그인<a routerLink="/guide/membership/grade" class="grade-guide">회원등급 혜택안내<span>></span></a></div>
        <input type="text" formControlName="id" class="login-input" size="20" maxlength="25" [ngClass]="{formErrors:memberFormErrors['id']}" placeholder="{{ memberFormErrors.id ? memberFormErrors.id : '아이디를 입력하세요' }}" autocomplete="off" [autofocus]="true" />
        <input type="password" formControlName="pw" class="login-input" size="20" maxLength="64" [ngClass]="{formErrors:memberFormErrors['pw']}" placeholder="{{ memberFormErrors.pw ? memberFormErrors.pw : '비밀번호를 입력하세요' }}" autocomplete="off" />
        <label><input type="checkbox" formControlName="save_id">아이디 저장</label>
      <ul class="clearfix find">
        <li><a routerLink="/member/find/id">아이디 찾기</a></li>
        <li><a routerLink="/member/find/pw">비밀번호 찾기</a></li>
      </ul>
      <input type="submit" value="로그인" class="basic-btn red" />
      <a routerLink="/member/join" class="basic-btn join-btn">회원가입</a>
      <a class="basic-btn naver-btn cp" (click)="login_naver()"><span>네이버 아이디로 로그인</span></a>
      <a class="basic-btn kakao-btn cp" (click)="login_kakao()"><span>카카오 아이디로 로그인</span></a>
      <a routerLink="/mypage/nonmember/form" class="basic-btn">비회원 주문조회</a>
    </form>

    <div class="member-footer">
      <ul class="clearfix">
        <li><a routerLink="/company/policy/service">이용약관</a></li>
        <li><a routerLink="/company/policy/privacy" class="bold">개인정보처리방침</a></li>
        <li><a routerLink="/customer/center">고객센터 1544-5022</a></li>
      </ul>
      <div>Copyright © 2008 <span class="bold">bikemart.co.kr</span> ALL RIGHTS RESERVED.</div>
    </div>
  </div>
</div>
