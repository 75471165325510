/*******************************************************************************
  설  명 : 장바구니 - 상품 옵션 변경
  생성일 : 2021-06-09
  생성자 : 김종현
*******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';

import { Globals } from '@app/service/globals.service';
import { AuthService } from '@app/service/auth.service';
import { SProductService } from '@app/service/product.service';
import { SCartService } from '@app/service/cart.service';

@Component({
  selector: 'app-product-option-change',
  templateUrl: './product-option-change.component.html',
  styleUrls: ['./product-option-change.component.scss']
})
export class ProductOptionChangeComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  isLogIn$: Observable<boolean>;
  isLogIn: boolean;

  public cartInfo: any = {};
  //public rowInfo: any = {};
  public rowIndex: number = 0;
  public totalPrice: number = 0;

  public propertyList: any = [];
  public colorList: any = [];
  public sizeList: any = [];
  public productList: any = [];
  public productData: any = [];
  public giftList: any = [];

  public propertyColor: any = '색상';
  public propertySize: any = '사이즈';
  public topGift: any = '사은품';
  public qty: any = '';

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    public activeModal: NgbActiveModal,
    private globals: Globals,
    private authService: AuthService,
    private sProductService: SProductService,
    private toastrService: ToastrService,
    private sCartService: SCartService,
  ) {
    this.isLogIn$ = this.authService.isLoggedIn;

    this.isLogIn$.subscribe( login => {
      this.isLogIn = login;
    });
  }

  /*******************************************************************************
    설  명 : 데이터 로딩 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  async ngOnInit() {
    //this.cartInfo = this.rowInfo;
    await this.getCartList();

    await this.setCalcAmt();
  }

  /*******************************************************************************
    설  명 : 장바구니 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  async getCartList() {
    // 회원인 경우
    // if( this.isLogIn ) {
    //   await this.sCartService.getCartList( {} ).then( response => {
    //     if( response.ResultCode ) {

    //       response.data.forEach((obj, key) => {
    //         if( key === this.rowIndex ) {
    //           this.cartInfo = obj;
    //         }
    //       });

    //     } else {
    //       this.toastrService.error(response.ResultMessage, '장바구니' );
    //     }
    //   });

    // // 비회원인 경우 쿠키에서 가져온다.
    // } else {
      let data: any = this.globals.load('bikemart_cart');
      let cart: any = ( data !== false ) ? data : [];
      let cartList: any = ( data !== false ) ? JSON.parse(cart) : [];

      if( cartList.length > 0 ) {
        await this.sCartService.getCartProductInfoList( JSON.stringify(cartList), '', '' ).then( response => {
          if( response.ResultCode ) {

            response.data.forEach((obj, key) => {
              if( key === this.rowIndex ) {
                this.cartInfo = obj;
              }
            });
  
          } else {
            this.toastrService.error(response.ResultMessage, '장바구니' );
          }
        });
      // }
    }

    await this.getProductDetail();
  }
  
  /*******************************************************************************
    설  명 : 상품정보 가져오기
  *******************************************************************************/
  getProductDetail() {
    this.sProductService.getProductDetail( this.cartInfo.product_seq ).then( response => {
      if( response.ResultCode ) {
        this.productData = response.data;
        this.propertyList = response.data.property;
        this.giftList = response.data.gift;

        this.qty = this.productData.min_qty || 1;

        let color: any = [];
        for( var i = 0; i < this.propertyList.length; i++ ) {
          if( this.propertyList[i].display_color_name !== '' && this.propertyList[i].display_color_name !== null ) {
            color.push(this.propertyList[i].display_color_name);
          }

          this.colorList = color.filter((element, index) => {
            return color.indexOf(element) == index;
          });
        }

        // 색상이 없는 상품 일 때
        if( this.colorList.length < 1 ) {
          for( var i = 0; i < this.propertyList.length; i++ ) {
            this.sizeList.push( this.propertyList[i] );
          }
        }

      } else {
        this.toastrService.error( response.ResultMessage, '');
      }
    })
  }
  
  /*******************************************************************************
    설  명 : selecbox 열기
  *******************************************************************************/
  selectbox_pulldown(event) {
    var that = $(event.target);
    var findrowA  = that.closest("a");    //  클릭한 행의 A

    if (findrowA.parent().hasClass("pulldown")) {
      findrowA.parent().removeClass("pulldown")
    } else {
      findrowA.parent().addClass("pulldown")
    }
  }
  
  /*******************************************************************************
    설  명 : 기본 옵션
  *******************************************************************************/
  selectedOption( event: any ) {
    const value: any = event.target.textContent;

    $(".select-box").removeClass("pulldown");

    this.propertyColor = value;

    let row: any = [];
    for( var i = 0; i < this.propertyList.length; i++ ) {
      if( value == "" ) {
        this.sizeList = [];
      } else if( value == this.propertyList[i].display_color_name ) {
        row.push( this.propertyList[i] );
        this.sizeList = row;
      }
    }
  }
  
  /*******************************************************************************
    설  명 : 상품 선택 시 상품 리스트에 추가
  *******************************************************************************/
  // 기본 옵션
  selectProduct( row: any ) {
    const value: any = row.product_property_seq;
    const isSoldout: any = row.is_soldout;

    if( parseInt(isSoldout) !== 0 ) {
      this.toastrService.warning('이미 품절된 옵션입니다.', '');
      return false;
    }

    $(".select-box").removeClass("pulldown");

    let dupleCheck = this.cartInfo.children.filter(item => item.product_property_seq === value);

    if( dupleCheck.length > 0 ) {
      this.toastrService.warning('이미 선택한 상품입니다.', '');
    } else {
      this.sizeList.forEach(item => {
        if( value == item.product_property_seq ) {
          this.cartInfo.children.push({
            isGift: false,
            product_seq: this.productData.seq,
            product_name: this.productData.product_name,
            product_code: item.product_code,
            product_property_seq: item.product_property_seq,
            qty: this.qty,
            consumer_price: item.consumer_price,
            display_color_name: item.display_color_name,
            display_size_name: item.display_size_name,
            discount_amt: 0,
            discount_rate: '',
            event_seq: this.cartInfo.event_seq,
            thumbnail_url: this.cartInfo.thumbnail_url
          }); 
        }
      });
    }
    // 색상을 고르는 상품이라면 초기화
    if( this.colorList.length > 0 ) {
      setTimeout(() => {
        this.propertyColor = '색상';
      }, 0);

      this.sizeList = [];
    } else {
      setTimeout(() => {
        this.propertySize = '사이즈';
      }, 0);
    }

    this.setCalcAmt();
  }

  /*******************************************************************************
    설  명 : 사은품 선택 이벤트 설정
  *******************************************************************************/
  selectGift( row: any ) {
    const value: any = row.product_freegift_seq;

    $(".select-box").removeClass("pulldown");

    let dupleCheck = this.cartInfo.children.filter(item => item.product_freegift_seq === value);

    if( dupleCheck.length > 0 ) {
      this.toastrService.warning('이미 선택한 상품입니다.', '');
    } else {
      this.giftList.forEach(item => {
        if( value == item.product_freegift_seq ) {
          this.cartInfo.children.push({
            isGift: true,
            product_seq: item.product_seq,
            product_name: item.product_name,
            product_code: item.product_code,
            product_property_seq: item.product_property_seq,
            display_color_name: item.display_color_name,
            display_size_name: item.display_size_name,
            product_freegift_seq: item.product_freegift_seq,
            freegift_max_count: item.max_count,
            qty: this.qty,
            consumer_price: 0,
            discount_amt: 0,
            discount_rate: '',
            event_seq: this.cartInfo.event_seq
          });
        }
      });
    }

    // 선택  초기화
    this.topGift = '사은품';

    this.setCalcAmt();
  }
  
  /*******************************************************************************
    설  명 : 전체 금액 계산
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setCalcAmt() {
    let total = 0;

    if( this.cartInfo.children.length > 0 ) {
      this.cartInfo.children.forEach(obj => {
        total += parseInt(obj.consumer_price) * obj.qty;
      });
    }

    this.totalPrice = total;
  }

  /*******************************************************************************
    설  명 : 수량 변경 시
  *******************************************************************************/
  clickQty( product: any, value: any ) {
    let calcCount = parseInt(product.qty) + parseInt(value);

    if( parseInt(this.productData.min_qty) > 0 ) {
      if( calcCount < this.productData.min_qty ) {
        this.toastrService.warning('상품의 최소 수량보다 낮게 입력할 수 없습니다.', '');

        calcCount = this.productData.min_qty;
      }
    }

    if( parseInt(this.productData.max_qty) > 0 ) {
      if( calcCount > this.productData.max_qty ) {
        this.toastrService.warning('상품의 최대 수량보다 높게 입력할 수 없습니다.', '');

        calcCount = parseInt(product.qty);
      }
    }

    product.qty = calcCount;

    this.setCalcAmt();
  }

  /*******************************************************************************
    설  명 : 수량 변경 시
  *******************************************************************************/
  changeQty( product: any, event: any ) {
    if( parseInt(this.productData.min_qty) > 0 && parseInt(event.target.value) < parseInt(this.productData.min_qty) ) {
      this.toastrService.warning('상품의 최소 수량보다 낮게 입력할 수 없습니다.', '');
      event.target.value = this.productData.min_qty;
    }
    
    if( parseInt(this.productData.max_qty) > 0 && parseInt(event.target.value) > parseInt(this.productData.max_qty) ) {
      this.toastrService.warning('상품의 최대 수량보다 높게 입력할 수 없습니다.', '');
      event.target.value = this.productData.max_qty;
    }
    
    product.qty = event.target.value;

    this.setCalcAmt();
  }
  
  /*******************************************************************************
    설  명 : 주문정보 상품리스트 선택 삭제
  *******************************************************************************/
  // 기본상품 선택 삭제
  deleteProduct(index) {
    const set: any = this.cartInfo.children;

    set.splice(index, 1);

    this.setCalcAmt();
  }

  /*******************************************************************************
    설  명 : 옵션 추가/변경 저장
  *******************************************************************************/
  setOptionChange() {
    if( this.cartInfo.children.length < 1 ) {
      this.toastrService.error('기본옵션 상품을 선택해주시기 바랍니다.', '장바구니');
    } else {
      let params: any = {
        cart_seq: '',
        product_seq: '',
        productList: []
      };

      params.cart_seq = this.cartInfo.cart_seq;
      params.product_seq = this.cartInfo.product_seq;
      
      this.cartInfo.children.forEach(item => {
        params.productList.push({
          isGift: item.isGift,
          cart_seq: this.cartInfo.cart_seq,
          product_seq: item.product_seq,
          product_name: item.product_name,
          product_code: item.product_code,
          product_property_seq: item.product_property_seq,
          display_color_name: item.display_color_name,
          display_size_name: item.display_size_name,
          product_freegift_seq: item.product_freegift_seq,
          freegift_max_count: item.max_count,
          qty: item.qty,
          consumer_price: item.consumer_price,
          discount_amt: 0,
          discount_rate: '',
          event_seq: this.cartInfo.event_seq,
          thumbnail_url: this.cartInfo.thumbnail_url
        });
      });

      // 품절 상품인 경우 장바구니 담기 불가
      // if( this.productData.is_soldout == false ) {
      //   this.toastrService.error('품절인 상품은 장바구니에 담을 수 없습니다.', '장바구니');
      //   return false;
      // }

      // 회원인 경우
      // if( this.isLogIn ) {
      //   this.sCartService.setOptionChange( params ).then(response => {
      //     if( response.ResultCode == true ) {
      //       this.toastrService.success(response.ResultMessage, '장바구니');
          
      //       this.activeModal.close(true);
      //     } else {
      //       this.toastrService.error(response.ResultMessage, '장바구니');
      //     }
      //   })
      //   .catch(response => {
      //     this.toastrService.success(response, '장바구니');
      //   });
      // }

      // 비회원인 경우
      // else {
        let data: any = this.globals.load('bikemart_cart');
        let cart: any = ( data !== false ) ? data : [];
        let cartList: any = ( data !== false ) ? JSON.parse(cart) : [];

        cartList[ this.rowIndex ] = params;

        this.globals.save( 'bikemart_cart', cartList );

        this.activeModal.close(true);
      // }
    }
  }
  
  /*******************************************************************************
    설  명 : 옵션 차액 표시
  *******************************************************************************/
  getDisplayDifferencePrice( row: any ) {
    if( row.difference_price !== '0' ) {
      return ( row.difference_price > 0 ) ? '(+' + this.getComma(row.difference_price) + ' 원)' : '(' + this.getComma(row.difference_price) + ' 원)';
    } else {
      return '';
    }
  }
  
  /*******************************************************************************
    설  명 : 콤마 표시
    입력값 : 숫자
    리턴값 : 콤마 숫자
  *******************************************************************************/
  getComma( num ) {
    var str = String(num);
    return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
  }
  
}
