<app-header class="pc-view"></app-header>

<div class="inner clearfix">
  <!-- 왼쪽 마이페이지 메뉴 -->
  <app-mypage-menu></app-mypage-menu>

  <div class="mypage-contents pw-confirm" [ngClass]="{confirm:pwConfirm}">
    <div class="guide">
      <div class="title">안내사항</div>
      <p class="dot">·</p><p class="dot1">개인정보 보호를 위해 비밀번호를 다시 한번 확인합니다.</p>
    </div>

    <div class="section">
      <form [formGroup]="confirmForm">
      <div class="section-header">
        <div class="title page-title">비밀번호 확인</div>
      </div>

      <table class="info-table">
      <colgroup>
        <col style="width: 160px">
        <col>
      </colgroup>
      <tbody>
      <tr>
        <th><label>아이디</label></th>
        <td>
          <input type="text" formControlName="id" [readonly]="true" autocomplete="off" />
        </td>
      </tr>

      <tr>
        <th><label for="pw">비밀번호</label></th>
        <td>
          <input type="password" formControlName="pw" class="pw-input" placeholder="비밀번호 확인" autocomplete="off" autofocus />
          <span *ngIf="formErrors['pw']" class="error-message-under">{{formErrors['pw']}}</span>
        </td>
      </tr>

      </tbody>
      </table>

      <div class="btn-area">
       <button class="basic-btn red" (click)="confirm()">확인</button>
      </div>
      </form>
    </div>
  </div>

  <form [formGroup]="memberEditForm">
  <div class="mypage-contents modify" [ngClass]="{confirm:pwConfirm}">
    <div class="guide">
      <div class="title">안내사항</div>
				<p class="dot">·</p><p class="dot1">초기 가입시 작성한 아이디/이름/필명은 수정이 불가능합니다.</p>
				<p class="dot">·</p><p class="dot1">고의적으로 정보 누락/변경 입력에 따라 향후 적발시에는 별도의 동의없이 서비스 제한됩니다.</p>
				<p class="dot">·</p><p class="dot1">회원정보가 정확하지 않을 경우 쇼핑몰 및 중고/신차/셀프장터 서비스가 이용 불가능할 수 있으니 신중히 작성 부탁드립니다.</p>
				<p class="dot">·</p><p class="dot1">이메일주소 변경후에는 반드시 재로그인해주세요. 다시 로그인하여야 변경된 메일주소로 전송가능합니다.</p>
    </div>

    <div class="section" [formGroup]="memberEditForm">
      <div class="section-header">
        <div class="title page-title fl">회원정보수정</div>
        <div class="right-btn fr">
          <button class="small-btn silver" (click)="destination();">배송지 관리</button>
        </div>        
      </div>


      <table class="info-table">
      <colgroup>
        <col style="width: 160px">
        <col>
      </colgroup>
      <tbody>
      <tr>
        <th><label>이름</label></th>
        <td>
          <input type="text" formControlName="name" [readonly]="true" autocomplete="off" />
        </td>
      </tr>
      <tr>
        <th><label>아이디</label></th>
        <td>
          <input type="text" formControlName="id" [readonly]="true" autocomplete="off" />
        </td>
      </tr>
      <tr>
        <th><label for="pw">새 비밀번호</label></th>
        <td>
          <input type="password" formControlName="pw" [ngClass]="{formErrors:formErrors['pw']}" placeholder="{{ formErrors['pw'] ? formErrors['pw'] : '새 비밀번호 (영문/숫자 조합 최대 20자 이하)' }}" autocomplete="off" />
          <span class="red ml5">새 비밀번호는 영문/숫자 조합 최대 20자 이하로 적어주시기 바랍니다.</span>
        </td>
      </tr>
      <tr>
        <th><label for="pw_check">새 비밀번호 확인</label></th>
        <td>
          <input type="password" formControlName="pw_check" [ngClass]="{formErrors:formErrors['pw_check']}" pattern="{{ this.memberEditForm.get('pw').value }}" placeholder="새 비밀번호 확인" autocomplete="off" />
          <span class="red ml5">새롭게 사용할 비밀번호를 한번 더 입력해 주십시오.</span>
        </td>
      </tr>
      <tr>
        <th><label for="hp2">전화번호</label></th>
        <td class="tel">
          <select class="hp-no input3" formControlName="hp1" [ngClass]="{formErrors:formErrors['hp1']}">
            <option value="010">010</option>
            <option value="011">011</option>
            <option value="016">016</option>
            <option value="017">017</option>
            <option value="018">018</option>
            <option value="019">019</option>
          </select>
          -
          <input type="text" formControlName="hp2" maxlength="4" [ngClass]="{formErrors:formErrors['hp2']}" placeholder="" />
          -
          <input type="text" formControlName="hp3" maxlength="4" [ngClass]="{formErrors:formErrors['hp3']}" placeholder="" />
          <span class="ml5">SMS서비스를 받으시려면 휴대폰 연락처를 적어주십시오.</span>
          <div *ngIf="formErrors['hp2'] || formErrors['hp3']" class="error-message-under">{{formErrors['hp2']}}</div>
        </td>
      </tr>
      <tr>
        <th><label for="email1">이메일주소</label></th>
        <td class="email">
          <input type="text" formControlName="email1" placeholder="이메일 아이디" [ngClass]="{formErrors:formErrors['email1']}" />
          @
          <input type="text" formControlName="email2" [ngClass]="{formErrors:formErrors['email2'], 'read-input': this.memberEditForm.value.email3 != ''}" [readonly]="( this.memberEditForm.value.email3 != '' ) ? true : false" #email2 />
          <select formControlName="email3" (change)="setEmailChange($event, 'email2')">
            <option value="">직접입력</option>
            <option value="naver.com">naver.com</option>
            <option value="gmail.com">gmail.com</option>
            <option value="daum.net">daum.net</option>
            <option value="nate.com">nate.com</option>
            <option value="dreamwiz.com">dreamwiz.com</option>
            <option value="yahoo.co.kr">yahoo.co.kr</option>
            <option value="outlook.com">outlook.com</option>
            <option value="hotmail.com">hotmail.com</option>
          </select>
          <div *ngIf="formErrors['email1'] || formErrors['email2']" class="error-message-under">{{formErrors['email1']}}</div>
        </td>
      </tr>
      <tr>
        <th><label for="">성별</label></th>
        <td>
          <div class="radio pc-view">
            <label>남성
              <input type="radio" formControlName="gender" value="M"/>
              <span class="checkmark"></span>
            </label>
            <label>여성
              <input type="radio" formControlName="gender" value="F"/>
              <span class="checkmark"></span>
            </label>
          </div>
	        <ul class="radio clearfix mobile-view">
	          <li [ngClass]="{active:memberEditForm.controls.gender.value=='M'}" (click)="memberEditForm.get('gender').setValue( 'M' )">남성</li>
	          <li [ngClass]="{active:memberEditForm.controls.gender.value=='F'}" (click)="memberEditForm.get('gender').setValue( 'F' )">여성</li>
	        </ul>
        </td>
      </tr>
      <tr>
        <th><label for="birthdate">생년월일</label></th>
        <td class="date">
          <select formControlName="birthdate1" class="" [ngClass]="{formErrors:formErrors['birthdate1']}">
            <option value="">년도 선택</option>
            <option *ngFor="let row of yearList" value="{{row}}">{{row}}</option>
          </select>
          -
          <select formControlName="birthdate2" class="" [ngClass]="{formErrors:formErrors['birthdate2']}">
            <option value="">월 선택</option>
            <option *ngFor="let row of monthList" value="{{row}}">{{row}}</option>
          </select>
          -
          <select formControlName="birthdate3" [ngClass]="{formErrors:formErrors['birthdate3']}">
            <option value="">일 선택</option>
            <option *ngFor="let row of dayList" value="{{row}}">{{row}}</option>
          </select>
        </td>
      </tr>
      <tr>
        <th><label for="region">회원지역</label></th>
        <td class="region">
          <select formControlName="region1" class="mr5 mb5" [ngClass]="{formErrors:formErrors['region1']}" (change)="changeSido($event)">
            <option value="">시도 선택</option>
            <option *ngFor="let row of region_A_list" value="{{row.seq}}">{{row.sido_name}}</option>
          </select>
          <select formControlName="region2" [ngClass]="{formErrors:formErrors['region2']}">
            <option value="">구군 선택</option>
            <option *ngFor="let row of region_B_list" value="{{row.seq}}">{{row.sigungu_name}}</option>
          </select>
          <div *ngIf="formErrors['region1']" class="error-message-under">시도: {{formErrors['region1']}}</div>
          <div *ngIf="formErrors['region2']" class="error-message-under">구군: {{formErrors['region2']}}</div>
        </td>
      </tr>
      <tr>
        <th><label for="">SMS 수신여부</label></th>
        <td>
          <div class="radio pc-view">
            <label>수신동의
              <input type="radio" formControlName="sms_yn" value="1" />
              <span class="checkmark"></span>
            </label>
            <label>수신하지 않음
              <input type="radio" formControlName="sms_yn" value="0" />
              <span class="checkmark"></span>
            </label>
          </div>
	        <ul class="radio clearfix mobile-view">
	          <li [ngClass]="{active:memberEditForm.controls.sms_yn.value=='1'}" (click)="memberEditForm.get('sms_yn').setValue( '1' )">SMS 수신동의</li>
	          <li [ngClass]="{active:memberEditForm.controls.sms_yn.value=='0'}" (click)="memberEditForm.get('sms_yn').setValue( '0' )">SMS 수신하지 않음</li>
	        </ul>
        </td>
      </tr>
      <tr>
        <th><label for="">kakao 수신여부</label></th>
        <td>
          <div class="radio pc-view">
            <label>수신동의
              <input type="radio"  formControlName="kakao_yn" value="1"/>
              <span class="checkmark"></span>
            </label>
            <label>수신하지 않음
              <input type="radio" formControlName="kakao_yn" value="0" />
              <span class="checkmark"></span>
            </label>
          </div>
	        <ul class="radio clearfix mobile-view">
	          <li [ngClass]="{active:memberEditForm.controls.kakao_yn.value=='1'}" (click)="memberEditForm.get('kakao_yn').setValue( '1' )">Kakao 수신동의</li>
	          <li [ngClass]="{active:memberEditForm.controls.kakao_yn.value=='0'}" (click)="memberEditForm.get('kakao_yn').setValue( '0' )">Kakao 수신하지 않음</li>
	        </ul>
        </td>
      </tr>
      <tr>
        <th><label for="">바이크유무</label></th>
        <td>
          <div class="radio pc-view">
            <label>갖고 있다
              <input type="radio" formControlName="bike_yn" value="1" />
              <span class="checkmark"></span>
            </label>
            <label>구입할 계획이다
              <input type="radio" formControlName="bike_yn" value="0" />
              <span class="checkmark"></span>
            </label>
          </div>
	        <ul class="radio clearfix mobile-view">
	          <li [ngClass]="{active:memberEditForm.controls.bike_yn.value=='1'}" (click)="memberEditForm.get('bike_yn').setValue( '1' )">바이크 있음</li>
	          <li [ngClass]="{active:memberEditForm.controls.bike_yn.value=='0'}" (click)="memberEditForm.get('bike_yn').setValue( '0' )">바이크 구입 예정</li>
	        </ul>
        </td>
      </tr>
      <!-- <tr>
        <th><label for="">상사상호</label></th>
        <td>
          <input type="text" placeholder="상사상호" />
        </td>
      </tr>
      <tr>
        <th><label for="">사업자번호</label></th>
        <td class="overlap">
          <input type="text" placeholder="사업자번호" />
          <button class="basic-btn" >중복확인</button>
        </td>
      </tr> -->
      </tbody>
      </table>
      <div class="btn-area">
       <button class="basic-btn red" (click)="submit()">수정하기</button>
      </div>
    </div>

  </div>
  </form>
</div>

<app-footer></app-footer>


