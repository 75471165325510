<div class="modal-header">
  <h4 class="modal-title fl">현금영수증 신청</h4>
  <button class="close" (click)="activeModal.dismiss()">&times;</button>
</div>

<div class="modal-body">
  <div class="section notice">
    <div class="title">현금영수증 신청 관련공지</div>
    <div class="div1 info">
      1) 신분확인번호는 구분기호없이 숫자만 입력하여 주십시오.<br><br>
      2) 소득공제용신청시 신분확인번호에 주민(핸드폰)번호를<br> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;소득지출증빙용선택시 사업자번호를 입력하여 주십시오.<br><br>
      3) 거래자구분에 따라서 신분확인번호를 입력하여 주십시오.<br><br>
      4) 결제금액이 5,000원 이상이어야 합니다.<br> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;공급가액/부가세/봉사료의 합이 결제금액과 맞아야 합니다.<br><br>
    </div>
  </div>  
  
  <form [formGroup]="form">
  <div class="section">
    <div class="title">현금영수증 신청 정보</div>
    <table class="info-table">
    <colgroup>
      <col style="width: calc(30%);">
      <col>
    </colgroup>    
    <tbody>
    <tr>
      <th>주문번호</th>
      <td>
        <input formControlName="LGD_OID" type="text" id="col1" value="{{this.form.controls.LGD_OID.value}}" [ngClass]="{formErrors:formErrors['LGD_OID']}" [readonly]="true" />
        <span *ngIf="formErrors['LGD_OID']" class="error-message">{{formErrors['LGD_OID']}}</span>
      </td>
    </tr>
    <tr>
      <th>결제금액</th>
      <td>
        <input formControlName="LGD_AMOUNT" value="{{this.form.controls.LGD_AMOUNT.value | number}}" type="text" id="col2" [ngClass]="{formErrors:formErrors['LGD_AMOUNT']}" [readonly]="true"/>
        <span *ngIf="formErrors['LGD_AMOUNT']" class="error-message">{{formErrors['LGD_AMOUNT']}}</span><br>
        <span> [현금으로 결제한 총 금액] </span>
      </td>
    </tr>
    <tr>
      <th>공급가액</th>
      <td>
        <input formControlName="vos" value="{{this.form.controls.vos.value | number}}" type="text" id="col3" [ngClass]="{formErrors:formErrors['vos']}" [readonly]="true"/>
        <span *ngIf="formErrors['vos']" class="error-message">{{formErrors['vos']}}</span>
      </td>
    </tr>    
    <tr>
      <th>부가가치세</th>
      <td>
        <input formControlName="vat" value="{{this.form.controls.vat.value*1 | number}}" type="text" id="col4" [ngClass]="{formErrors:formErrors['vat']}" [readonly]="true"/>
        <span *ngIf="formErrors['vat']" class="error-message">{{formErrors['vat']}}</span>
      </td>
    </tr>   
    <tr>
      <th>봉사료</th>
      <td>
        <input formControlName="tip" placeholder="0" type="text" id="col5" [ngClass]="{formErrors:formErrors['tip']}" [readonly]="true"/>
        <span *ngIf="formErrors['tip']" class="error-message">{{formErrors['tip']}}</span>
      </td>
    </tr>   
    <tr>
      <th>상품명</th>
      <td>
        <input formControlName="LGD_PRODUCTINFO" type="text" id="col6" value="{{this.form.controls.LGD_PRODUCTINFO.value}}" [ngClass]="{formErrors:formErrors['LGD_PRODUCTINFO']}" [readonly]="true"/>
      </td>
    </tr>   
    <tr>
      <th>상품수량</th>
      <td>
        <input formControlName="orderqty" type="text" id="col7" value="{{this.form.controls.orderqty.value}}" [ngClass]="{formErrors:formErrors['orderqty']}" [readonly]="true"/>
      </td>
    </tr>   
    <tr>
      <th>이메일주소</th>
      <td>
        <input formControlName="email" type="text" id="col8" value="{{this.form.controls.email.value}}" [ngClass]="{formErrors:formErrors['email']}"/>
      </td>
    </tr>   
    <tr>
      <th>신분확인번호</th>
      <td>
        <input formControlName="LGD_CASHCARDNUM" placeholder="번호를 입력해 주세요." type="text" id="col9" [ngClass]="{formErrors:formErrors['LGD_CASHCARDNUM']}"/>
        <span *ngIf="formErrors['LGD_CASHCARDNUM']" class="error-message">{{formErrors['LGD_CASHCARDNUM']}}</span><br>
        <span> "-"제외 [주민(핸드폰)번호 & 사업자번호] </span>
      </td>
    </tr>   
    <tr>
      <th>사용용도</th>
      <td>
        <select class="reason" [ngClass]="{formErrors:formErrors['LGD_CASHRECEIPTUSE']}" id="color" (change)="selectedUse($event)">
          <option [value]="">-- 용도를 선택해 주세요 --</option>
          <option [value]="1">소득공제용</option>
          <option [value]="2">지출증빙용</option>
        </select>
        <span *ngIf="formErrors['LGD_CASHRECEIPTUSE']" class="error-message">{{formErrors['LGD_CASHRECEIPTUSE']}}</span>
      </td>
    </tr>   
    </tbody>
    </table> 
  </div>
  </form>

  <div class="btn-area">
   <button class="btn1" (click)="activeModal.dismiss()">취소</button>
   <button class="btn1 red" (click)="submit()">현금영수증 신청</button>
  </div>

</div>



