<ng-container *ngIf="isMobileApp === false">
    <app-header></app-header>
  </ng-container>
  
  <ul class="menu clearfix" *ngIf="isMobileApp === false">
    <li><a routerLink="/company/info">회사소개</a></li>
    <li><a routerLink="/company/history">회사연혁</a></li>
    <li><a routerLink="/company/society" class="on">동호회등록</a></li>
    <li><a routerLink="/company/supply">입점안내</a></li>
    <li><a routerLink="/company/story">창업스토리</a></li>
    <li><a routerLink="/company/contact">가맹문의</a></li>
    <li><a routerLink="/company/policy/service">이용약관</a></li>
  </ul>
  
  <ul class="menu menu-mobile clearfix" *ngIf="isMobileApp === true">
    <li><a routerLink="/mobile/company/info">회사소개</a></li>
    <li><a routerLink="/mobile/company/history">회사연혁</a></li>
    <li><a routerLink="/company/society" class="on">동호회등록</a></li>
    <li><a routerLink="/mobile/company/supply">입점안내</a></li>
    <li><a routerLink="/mobile/company/story">창업스토리</a></li>
    <li><a routerLink="/mobile/company/contact">가맹문의</a></li>
    <li><a routerLink="/mobile/company/policy/service">이용약관</a></li>
  </ul>
  
  <div style="margin-top: 37px" *ngIf="isMobileApp === true"></div>
  
  <section class="contents">
  
    <div class="main-img">
      <img src="/assets/images/company_society.jpg">
    </div>
  
    <div class="section inner">
      <div>
        <p class="txt1">바이크마트가 바이크동호회의 활동을 지원합니다.</p>
        <p class="txt2">바이크마트 등록 동호회 신청을 해주세요(대표자 회원 가입 후 신청)</p>
        <p class="txt2">신청 문의 사항에 연락처 남겨주시면 담당자가 신속히 연락드려 안내 및 승인절차를 도와드리도록 하겠습니다.</p>
        <p class="txt2">바이크마트는 언제나 동호회 여러분과 함께 나아가겠습니다.</p>
      </div>
  
      <!-- <div class="div1">
        <div><span class="dept">전략소싱팀</span>하준성 팀장</div>
          <p>bikemart@daum.net</p>
          <a href="/assets/download/2020_bikemart_joinshop_form.docx" download="">신청양식 다운받기</a>
        </div> -->
  
      <article class="article">
        <div class="title">동호회 등록신청</div>
        <form [formGroup]="form">
          <div>
            <p><span>*</span>동호회명</p>
            <input type="text" formControlName="society_name" placeholder="동호회 이름을 입력해 주세요." [ngClass]="{formErrors:formErrors['society_name']}" />
            <div *ngIf="formErrors['society_name']" class="error-message-contact">{{formErrors['society_name']}}</div>
          </div>
          <div>
            <p><span>*</span>활동지역</p>
            <select formControlName="region_cd">
                <option *ngFor="let row of regionList" value="{{row.common_code}}">{{row.common_name}}</option>
            </select>
            <div *ngIf="formErrors['region_cd']" class="error-message-contact">{{formErrors['region_cd']}}</div>
          </div>
          <div>
            <p>동호회커뮤니티 링크</p>
            <input type="text" formControlName="society_link" placeholder="동호회 활동 커뮤니티의 URL을 입력해주세요." [ngClass]="{formErrors:formErrors['society_link']}" />
            <div *ngIf="formErrors['society_link']" class="error-message-contact">{{formErrors['email']}}</div>
          </div>
          <div>
            <p style="vertical-align: top;"><span>*</span>동호회소개</p>
            <textarea formControlName="society_description" placeholder="간단한 동호회 소개를 부탁드립니다." style="height: 90px;" [ngClass]="{formErrors:formErrors['society_description']}"></textarea>
            <div *ngIf="formErrors['society_description']" class="error-message-contact">{{formErrors['society_description']}}</div>
          </div>          
          <div>
            <p style="vertical-align: top;"><span>*</span>신청문의</p>
            <textarea formControlName="application_memo" placeholder="신청시 문의 사항 및 연락드릴 연락처를 입력해 주세요." style="height: 90px;" [ngClass]="{formErrors:formErrors['application_memo']}"></textarea>
            <div *ngIf="formErrors['application_memo']" class="error-message-contact">{{formErrors['application_memo']}}</div>
          </div>
          <div>
            <input type="checkbox" id="개인정보동의" formControlName="agreement">
            <label for="개인정보동의">
              <a [routerLink]="isMobileApp === false ? '/company/policy/privacy' : '/mobile/company/policy/privacy'">개인정보취급방침</a> 에 동의합니다.
            </label>
          </div>
          <button type="submit" (click)="submit()">동호회 등록신청</button>
        </form>
      </article>
  
      <section class="table-div clearfix">
        <div class="fl">
          <div class="table-header">
            <h4 style="width:100%;text-align:center;">동호회 등록현황</h4>
            <!-- <a routerLink="/company/supply">입점문의하기</a> -->
          </div>
          <div class="table-wrap">
            <table class="list-table">
              <colgroup class="pc-view">
                <col class="col1">
                <col class="col2">
                <col class="col3">
              </colgroup>
    
              <tbody>
                <ng-container *ngFor="let row of societyList">
                <tr>
                  <td>{{row.society_name}}</td>
                  <td>{{row.region_name}}</td>
                  <td [ngClass]="(row.society_seq == society_id)?  '': 'desc'" (click)="ellipsisId(row.society_seq)">{{row.society_description}}</td>
                </tr>
                </ng-container>
              </tbody>
            </table>
          </div>  
        </div>
  
      </section>
  
    </div>
  </section>
  
  <ng-container *ngIf="isMobileApp === false">
    <app-footer></app-footer>
  </ng-container>
  