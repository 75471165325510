/*******************************************************************************
  설  명 : 회원가입 - 약관 동의
  생성일 : 2020-09-10
  생성자 : 송영석
*******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as $ from 'jquery';
import { CookieService } from 'ngx-cookie-service';
import { ToastrService } from 'ngx-toastr';

import { ApiResponse } from '@app/service/api-response';
import { SBoardService } from '@app/service/board.service';
import { UrlService } from '@app/service/url.service';
import { UtilService } from '@app/service/util.service';

@Component({
  selector: 'app-member-join',
  templateUrl: './member-join.component.html',
  styleUrls: ['./member-join.component.scss']
})
export class MemberJoinComponent implements OnInit {

/*******************************************************************************
 * 설명 : 전역 변수 선언부
*******************************************************************************/
  daumAddressOptions = {
    class: ['btn', 'overlap-btn', 'zip-btn', 'h42']
  };

  public checkIdDup: boolean = true;
  public inputCustomerReadonly: boolean = false;

  public previousOrgUrl: any = '';
  public previousUrl: any = '';
  public isInAppBrowser: any;
  public retUrl: any = '';
  public previousParams: any = {};

  public tabIndex: any = 1;

  private subscription: any;

  errorResponse: ApiResponse;
  public memberAddForm: FormGroup;
  public formErrors = {};

  public joinGuide: any = [];

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private activatedRoute: ActivatedRoute,
    private utilService: UtilService,
    private toastrService: ToastrService,
    private router: Router,
    private formBuilder: FormBuilder,
    private cookieService: CookieService,
    private urlService: UrlService,
    private sBoardService: SBoardService
  ) {
    // http로 접속 시 https로 리다이렉트
    if( location.hostname.indexOf('localhost') < 0 && location.protocol == 'http:' ) {
      window.location.href = location.href.replace('http:', 'https:');
    }

    this.buildForm();

    this.activatedRoute.queryParams.subscribe(params => {
      if( params.retUrl !== undefined ) this.retUrl = params.retUrl;
      if( params.isApp !== undefined ) this.isInAppBrowser = params.isApp;
    });
  }

  /*******************************************************************************
    설  명 : 빌드폼 생성
  *******************************************************************************/
  buildForm(): void {
    this.memberAddForm = this.formBuilder.group({
      agree1:[ false, [Validators.required] ],
      agree2:[ false, [Validators.required] ],
      agree3:[ false, [Validators.required] ],
      all_check:[ false, [] ],
    });

    this.memberAddForm.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.memberAddForm, this.formErrors );
    });
  }

  login_naver(): void {
    let params = {
      client_id: "dsCQ97zq5MpVQO_WBVmJ",
      redirect_uri: location.origin + "/api/auth/callback-login-naver.php",
      response_type: "code",
    };

    let redirect_uri = ( this.previousUrl && this.previousUrl.indexOf('/login') == -1 ) ? this.previousUrl : "/main";
    this.cookieService.set( 'redirect_uri', redirect_uri, 1, '/', location.hostname, true, 'None' );

    this.cookieService.set( 'isInAppBrowser', this.isInAppBrowser, 1, '/', location.hostname, true, 'None' );

    let query = $.param( params );

    window.open(
      "https://nid.naver.com/oauth2.0/authorize?" + query.toString(),
      "_blank",
      "resizable,scrollbars,status"
    );
  }

  login_kakao(): void {
    let params = {
      client_id: "da7b490b744585c60ebebe292b7ac476",
      redirect_uri: location.origin + "/api/auth/callback-login-kakao.php",
      response_type: "code",
    };

    let redirect_uri = ( this.previousUrl && this.previousUrl.indexOf('/login') == -1 ) ? this.previousUrl : "/main";
    this.cookieService.set( 'redirect_uri', redirect_uri, 1, '/', location.hostname, true, 'None' );

    this.cookieService.set( 'isInAppBrowser', this.isInAppBrowser, 1, '/', location.hostname, true, 'None' );

    let query = $.param( params );

    window.open(
      "https://kauth.kakao.com/oauth/authorize?" + query.toString(),
      "_blank",
      "resizable,scrollbars,status"
    );
  }

  oauthLoggedIn(result: any) {
    if (result["ResultCode"] ) {
      this.toastrService.success(result["ResultMessage"], '회원가입 성공');

      if( this.retUrl !== '' ) {
        window.location.href = this.retUrl + '?token=' + result.token;
        console.log( window.location.href);
      } else {
        let redirect_uri = this.previousUrl ? this.previousUrl : "/main";

        this.router.navigate(
          [
            redirect_uri,
          ],
          {
            relativeTo: this.activatedRoute,
            queryParams: this.previousParams,
            queryParamsHandling: "",
          }
        );
      }
    } else {
      this.toastrService.error(result["ResultMessage"], '회원가입 실패');
    }
  }

  /*******************************************************************************
    설  명 : 회원가입 약관동의 이용약관, 개인정보처리방침, 구매대행서비스 이용약관
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getMemberJoinGuide() {
    this.sBoardService.getMemberJoinGuide().then( response => {
      if( response.ResultCode ) {
        this.joinGuide = response.data;
      }
    })
  }

  /*******************************************************************************
    설  명 : 데이터 로딩 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit(): void {
    this.getMemberJoinGuide();
    window['memberLogin'] = {
      oauthLoggedIn: this.oauthLoggedIn.bind(this)
    };

    this.subscription = this.urlService.previousUrl$.subscribe(url => {
      if( url !== null ) {
        this.previousOrgUrl = url;

        let urlArray: any = url.split('?');

        this.previousUrl = urlArray[0];

        if( this.previousUrl.indexOf('login') > -1 || this.previousUrl.indexOf('find') > -1 || this.previousUrl.indexOf('join') > -1 ) {
          this.previousUrl = '/main';
        }

        if( urlArray[1] ) {
          let params = urlArray[1].split('&');
          params.forEach(item => {
            let data = item.split('=');
            this.previousParams[ data[0] ] = data[1];
          });
        }
      }
    });
  }

  /*******************************************************************************
    설  명 : 약관동의 체크
  *******************************************************************************/
  memberinfo() {
    if (this.memberAddForm.get('agree1').value == false) {
      this.toastrService.warning( '이용약관 동의는 필수 입력(선택) 항목입니다.', '');
      return false;
    }

    if( this.memberAddForm.get('agree2').value == false) {
      this.toastrService.warning( '개인정보처리방침 동의는 필수 입력(선택) 항목입니다.', '');
      return false;
    }

    if( this.memberAddForm.get('agree3').value == false ) {
      this.toastrService.warning( '구매대행 서비스 이용약관 동의는 필수 입력(선택) 항목입니다.', '');
      return false;

    } else {
      this.router.navigate(['/member/join/form']);
    }

    $('html, body').animate({
      scrollTop : 0
    }, 0);
  }

  /*******************************************************************************
    설  명 : 모두 동의 시 처리
  *******************************************************************************/
  all_check() {
    if( ! this.memberAddForm.controls.all_check.value ) {
      this.memberAddForm.patchValue({
        agree1: true,
        agree2: true,
        agree3: true,
        all_check: true
      });
    } else {
      this.memberAddForm.patchValue({
        agree1: false,
        agree2: false,
        agree3: false,
        all_check: false
      });
    }
  }
}
