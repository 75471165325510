/*******************************************************************************
  설  명 : 마이페이지 - 구매내역/배송조회
  생성일 : 2020-09-10
  생성자 : 송영석
*******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { NgbInputDatepicker, NgbCalendar, NgbDateStruct, NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { Router, ActivatedRoute } from '@angular/router';

import { SOrderService } from '@app/service/order.service';
import { CommonService } from '@app/service/common.service';

import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '@app/service/auth.service';
import { SmsService } from '@app/service/sms.service';

import { MypageReviewAddComponent } from '@page/mypage/mypage-review-add/mypage-review-add.component';
import { MypageNotReceivedComponent } from '@page/mypage/mypage-not-received/mypage-not-received.component';
import { MypageReceivedComponent } from '@page/mypage/mypage-received/mypage-received.component';
import { MypageExchangeComponent } from '@page/mypage/mypage-exchange/mypage-exchange.component';
import { MypageReturnComponent } from '@page/mypage/mypage-return/mypage-return.component';

const options: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  size: '480',
  centered: true,
  windowClass:'modal-fadeInDown'
};

const options1: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  size: 'xl',
  centered: true,
  windowClass:'modal-fadeInDown'
};

@Component({
  selector: 'app-mypage-order',
  templateUrl: './mypage-order.component.html',
  styleUrls: ['./mypage-order.component.scss']
})
export class MypageOrderComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  public isLogIn: boolean;
  isLogin$: Observable<boolean>; // 로그인여부확인

  public orderList: any = [];
  public totalCount: number = 0;
  public orderStatusList: any = [];

  public memberOrder: any = true;
  public orderInfo: any = {};
  public memberInfo: any = {};

  public search: any = {
    pageNo: 1,
    pageRow: 10,
    sdate: '',
    edate: '',
    searchdate_group: '',
    order_status: '',
    product_seq: ''
  };
  
  public searchdate_group: any = [
    {title: '오늘', value: 0},
    {title: '1주일', value: -7},
    {title: '1개월', value: -30},
    {title: '3개월', value: -90},
    {title: '6개월', value: -180}
  ];

  today: string;
  page = 1;

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private authService: AuthService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private calendar: NgbCalendar,
    private modalService: NgbModal,
    private toastrService: ToastrService,
    private sOrderService: SOrderService,
    private CommonService: CommonService,
    private smsService: SmsService,
  ) { 
    // 로그인 여부 가져오기
    this.isLogin$ = this.authService.isLoggedIn;

    this.isLogin$.subscribe( login => {
      this.isLogIn = login;
    });

    this.today = moment().format('YYYY-MM-DD');
  }

  /*******************************************************************************
    설  명 : 데이터 로딩 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit(): void {
    this.authService.getLoginInfo.subscribe(data => {
      this.memberInfo = data;
    });

    this.activatedRoute.queryParams.subscribe(params => {
      this.search.pageNo = ( typeof params.pageNo == 'undefined' || params.pageNo == '' ) ? '1' : params.pageNo;
      this.search.pageRow = ( typeof params.pageRow == 'undefined' || params.pageRow == '' ) ? '10' : params.pageRow;
      this.search.sdate = ( typeof params.sdate == 'undefined' || params.sdate == '' ) ? '' : params.sdate;
      this.search.edate = ( typeof params.edate == 'undefined' || params.edate == '' ) ? '' : params.edate;
      this.search.searchdate_group = ( typeof params.searchdate_group == 'undefined' || params.searchdate_group == '' ) ? 1 : params.searchdate_group;
      this.search.order_status = ( typeof params.order_status == 'undefined' || params.order_status == '' ) ? '' : params.order_status;

      this.getOrderList();
      this.getCommonListCode();
    });    
  }

  /*******************************************************************************
    설  명 : restful get, post params 삭제
  *******************************************************************************/
  deleteParams() {
    delete this.search.program;
    delete this.search.service;
    delete this.search.version;
    delete this.search.action;
    delete this.search.token;
  }
  
  /*******************************************************************************
    설  명 : 주문 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getOrderList() {
    this.sOrderService.getOrderList( this.search ).then( response => {
      if( response.ResultCode ) {
        this.orderList = response.data;
        this.totalCount = parseInt(response.total);
      } else {
        this.toastrService.error(response.ResultMessage);
      }

      this.deleteParams();
      $(window).scrollTop(0);
    });
  }

  /*******************************************************************************
    설  명 : 주문상태 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getCommonListCode() {
    this.CommonService.getCommonListCode( 'ORS' ).then( response => {
      if( response.ResultCode ) {
        this.orderStatusList = response.data;
      } else {
        this.toastrService.error(response.ResultMessage);
      }
    });
  }

  /*******************************************************************************
    설  명 : 오늘 선택 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getToday( obj: NgbInputDatepicker, check: any ) {
    if( check == 1 ) {
      this.search.sdate = this.calendar.getToday();
      obj.close();
    } else if( check == 2 ) {
      this.search.edate = this.calendar.getToday();
      obj.close();
    }
  }
  
  /*******************************************************************************
    설  명 : 검색 기간 버튼 클릭
    입력값 : 값
    리턴값 : 없음
  *******************************************************************************/
  onSearchDateClick( val ) {
    this.search.searchdate_group = val;

    let dateModel: NgbDateStruct;

    dateModel = {
      year: parseInt( moment().format('YYYY') ),
      month: parseInt( moment().format('MM') ),
      day: parseInt( moment().format('DD') )
    };

    this.search.edate = dateModel;

    dateModel = {
      year: parseInt( moment().add(val, 'day').format('YYYY') ),
      month: parseInt( moment().add(val, 'day').format('MM') ),
      day: parseInt( moment().add(val, 'day').format('DD') )
    };

    this.search.sdate = dateModel;
  }  

  /*******************************************************************************
  설  명 : 페이지 선택 처리
  입력값 : 없음
  리턴값 : 없음
  *******************************************************************************/
  loadPage( page ) {
    this.search.pageNo = page;

    this.getOrderList();
  }

  /*******************************************************************************
    설  명 : 주문상태 선택 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  selectedStatus(event) {
    this.search.order_status = event.target.value;

    this.getOrderList();
  }

  /*******************************************************************************
    설  명 : 페이지로 이동 수정자: 수정자: 2/23 김종현
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  goDetail( orderSeq ) {
    this.search.orderSeq = orderSeq;

    this.router.navigate(
    ['/mypage/order/detail'],
    {
      relativeTo: this.activatedRoute,
      queryParams: this.search,
      queryParamsHandling: 'merge', // remove to replace all query params by provided
    });
  }

  /*******************************************************************************
    설  명 : 리스트 상품 상세보기로 가기 수정자:3/4 김종현
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  goProduct(details) {
    let seq = details.product[0].product_seq;
    this.router.navigate(
      ['/product/info'],
      {
        relativeTo: this.activatedRoute,
        queryParams: {productSeq: seq},
        queryParamsHandling: 'merge', // remove to replace all query params by provided
      }
    );
  }
  
  /*******************************************************************************
    설  명 : 구매후기작성
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/  
  review( row: any ) {
    const modalRef = this.modalService.open(MypageReviewAddComponent, options);

    modalRef.componentInstance.rowData = row;
    modalRef.componentInstance.memberOrder = this.memberOrder;

    modalRef.result.then((result) => {
      if( result ) {
        this.getOrderList();
      }
    }, (reason) => {
    });
  }

  /*******************************************************************************
    설  명 : 미수취신고
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/  
  notReceived( row: any ) {
    const modalRef = this.modalService.open(MypageNotReceivedComponent, options);

    modalRef.componentInstance.rowData = row;
    modalRef.componentInstance.memberOrder = this.memberOrder;

    modalRef.result.then((result) => {
      this.getOrderList();
    }, (reason) => {
    });  
  }   
  
  /*******************************************************************************
    설  명 : 수취확인
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/  
  received( row: any ) {
    if( confirm('수취확인 하시겠습니까?\n\n반드시 실수령 후에 수취확인을 눌러주세요.') ) {
      this.sOrderService.setOrderReceived( row.seq ).then( response => {
        if ( response.ResultCode ) {
          this.toastrService.success(response.ResultMessage);

          this.getOrderList();
        } else {
          this.toastrService.error(response.ResultMessage);
        }
      });
    }
  }   

  /*******************************************************************************
    설  명 : 구매확정
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/  
  purchaseConfirm( row: any ) {
    const modalRef = this.modalService.open(MypageReceivedComponent, options);

    modalRef.componentInstance.rowData = row;
    modalRef.componentInstance.memberOrder = this.memberOrder;
  
    modalRef.result.then((result) => {
      if( result ) {
        this.getOrderList();
      }
    }, (reason) => {
    });
  } 
  
  /*******************************************************************************
    설  명 : 교환신청
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/  
  exchange( row: any, i ) {
    let qtyNum = 0;
    row.detail.map( rows => {
      rows.product.map ( rows1 => {
        qtyNum += rows1.qty - rows1.used_req_qty;
      });
    });

    if ( qtyNum < 1 ) {
      this.toastrService.error('교환 가능한 수량이 없습니다.');
    } else {
      const modalRef = this.modalService.open(MypageExchangeComponent, options1);

      this.orderInfo.o_name = this.orderList[i].o_name;
      this.orderInfo.o_hp = this.orderList[i].o_hp;
      this.orderInfo.r_zipcode = this.orderList[i].r_zipcode;
      this.orderInfo.r_address = this.orderList[i].r_address;
      this.orderInfo.r_address_detail = this.orderList[i].r_address_detail;

      modalRef.componentInstance.rowData = row;
      modalRef.componentInstance.orderData = this.orderInfo;
      modalRef.componentInstance.memberOrder = this.memberOrder;

      modalRef.result.then((result) => {
        if( result ) {
          this.getOrderList();
        }
      }, (reason) => {
      });
    }
  }
  
  
  /*******************************************************************************
    설  명 : 반품신청
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/  
  return( row: any ) {
    let qtyNum = 0;
    row.detail.map( rows => {
      rows.product.map ( rows1 => {
        qtyNum += rows1.qty - rows1.used_req_qty;
      });
    });

    if ( qtyNum < 1 ) {
      this.toastrService.error('반품 가능한 수량이 없습니다.');
    } else {
      const modalRef = this.modalService.open(MypageReturnComponent, options1);

      modalRef.componentInstance.rowData = row;
      modalRef.componentInstance.memberOrder = this.memberOrder;

      modalRef.result.then((result) => {
        if( result ) {
          this.getOrderList();
        }
      }, (reason) => {
      });  
    }
  }
  
  /*******************************************************************************
    설  명 : 주문취소
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/  
  cancel(row) {
    if ( confirm('선택하신 주문을 취소하시겠습니까?') ) {
      this.sOrderService.setOrderCancel( row.seq ).then( response => {
        if ( response.ResultCode ) {
          this.toastrService.success(response.ResultMessage);
          this.getOrderList();
        } else {
          this.toastrService.error(response.ResultMessage);
        }
      });
    }
  }

  /*******************************************************************************
    설  명 : 배송조회
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/  
  delivery( row: any ) {
    if ( row.delivery_company == "1004" ){
      var gourl = "http://service.epost.go.kr/trace.RetrieveRegiPrclDeliv.postal?sid1=" + row.delivery_no;
      window.open(gourl, 'popup','scrollbars=yes, width=1200, height=900');
    } else if ( row.delivery_company == "1001" ){
      var gourl = "http://d2d.ilogen.com/d2d/delivery/invoice_tracesearch_quick.jsp?slipno=" + row.delivery_no;
      window.open(gourl, 'popup','scrollbars=yes, width=1200, height=900');
    } else if ( row.delivery_company == "1002" ){
      var gourl = "https://www.hanjin.co.kr/kor/CMS/DeliveryMgr/WaybillResult.do?mCode=MN038&schLang=KR&wblnumText2=" + row.delivery_no;
      window.open(gourl, 'popup','scrollbars=yes, width=1200, height=900');
    } else if ( row.delivery_company == "0010" ){
      var gourl = "https://www.doortodoor.co.kr/parcel/doortodoor.do?fsp_action=PARC_ACT_002&fsp_cmd=retrieveInvNoACT&invc_no=" + row.delivery_no;
      window.open(gourl, 'popup','scrollbars=yes, width=1200, height=900');
    } else if ( row.delivery_company == "1003" ){
      var gourl = " https://www.lotteglogis.com/home/reservation/tracking/linkView?InvNo=" + row.delivery_no;
      window.open(gourl, 'popup','scrollbars=yes, width=1200, height=900');
    } else {
      this.toastrService.error('택배사 코드를 다시 확인하시기 바랍니다.');
    }
  }

  /*******************************************************************************
    설  명 : 총 결제금액 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getPayAmt( row: any ) {
    let sum_total_amt: any = row['detail']?.reduce((a,c) => a + parseInt(c.sum_total_amt || 0), 0);
    sum_total_amt += parseInt(row.delivery_price || 0);
    return ( sum_total_amt > 0 ) ? sum_total_amt : 0;
  }
  
}