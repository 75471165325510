/*******************************************************************************
  설  명 : 마이페이지 - 비회원 구매내역/배송 조회
  생성일 : 2021-06-11
  생성자 : 김종현
*******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';

import { SOrderService } from '@app/service/order.service';
import { SmsService } from '@app/service/sms.service';

import { MypageReviewAddComponent } from '@page/mypage/mypage-review-add/mypage-review-add.component';
import { MypageNotReceivedComponent } from '@page/mypage/mypage-not-received/mypage-not-received.component';
import { MypageReceivedComponent } from '@page/mypage/mypage-received/mypage-received.component';
import { MypageExchangeComponent } from '@page/mypage/mypage-exchange/mypage-exchange.component';
import { MypageReturnComponent } from '@page/mypage/mypage-return/mypage-return.component';
import { MypageOrderDetailCashreceiptComponent } from '@page/mypage/mypage-order/mypage-order-detail/mypage-order-detail-cashreceipt/mypage-order-detail-cashreceipt.component';
import { MypageOrderStatementComponent } from '@page/mypage/mypage-order/mypage-order-statement/mypage-order-statement.component';

const options: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  size: '480',
  centered: true,
  windowClass:'modal-fadeInDown'
};

const optionLG: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  size: 'lg',
  centered: true,
  windowClass:'modal-fadeInDown'
};

const options1: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  size: 'xl',
  centered: true,
  windowClass:'modal-fadeInDown'
};

const options2: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  size: '480',
  centered: true,
  windowClass:'modal-fadeInDown'
};

@Component({
  selector: 'app-mypage-nonmember-order',
  templateUrl: './mypage-nonmember-order.component.html',
  styleUrls: ['./mypage-nonmember-order.component.scss']
})
export class MypageNonmemberOrderComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  public seq: any;
  public mem_no: any;
  public hp: any;
  
  public detailList: any = [];
  public payList: any = [];
  public deliveryList: any = [];

  public orderInfo: any = {};
  public payCashInfo: any = {};

  public total_delivery: any = 0;
  public total_order: any = 0;
  public discount_amt: any = 0;
  public tid: any = null;
  public inicisSeq: any = null;  

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private sOrderService: SOrderService,
    private toastrService: ToastrService,
    private modalService: NgbModal,
    private smsService: SmsService,
  ) { }

  /*******************************************************************************
    설  명 : 데이터 로딩 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit(): void {
    // 입력 파라미터 처리
    this.activatedRoute.queryParams.subscribe( async params => {
      this.seq = ( typeof params.seq == 'undefined' || params.seq == '' ) ? '' : params.seq;
      this.mem_no = ( typeof params.mem_no == 'undefined' || params.mem_no == '' ) ? '' : params.mem_no;
      this.hp = ( typeof params.hp == 'undefined' || params.hp == '' ) ? '' : params.hp;

      if( this.seq == '' || this.mem_no == '' ) {
        this.toastrService.error('필수값이 없습니다. 정상적인 경로로 접근하시기 바랍니다.', '');

        this.router.navigate(
          [ '/mypage/nonmember/form' ],
          { 
            relativeTo: this.activatedRoute, 
            queryParams: {},
            queryParamsHandling: ''
          }
        );
      }

      this.getNotLoginOrderDetail();
      this.isInicis();
      this.checkOrderPayCash();

    });
  }

  /*******************************************************************************
    설  명 : 주문 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getNotLoginOrderDetail() {
    this.sOrderService.getNotLoginOrderDetail( this.seq, this.mem_no ).then( response => {
      if( response.ResultCode ) {
        this.orderInfo = response.data;
        this.detailList = response.detail;
        this.payList = response.pay;
        this.deliveryList = response.delivery;

        this.setTotalDelivery();
      } else {
        this.toastrService.error(response.ResultMessage);
      }
    });
  }

  /*******************************************************************************
    설  명 : 금액 세팅
  *******************************************************************************/  
  setTotalDelivery() {
    this.total_order = this.orderInfo.order_amt;
    this.total_delivery = ( this.orderInfo.order_amt > 0 ) ? this.orderInfo.delivery_price : 0;
  }

  /*******************************************************************************
  설  명 : 찜 리스트 상품 상세보기로 가기 수정자:3/4 김종현
  입력값 : 없음
  리턴값 : 없음
  ******************************************************************************/
  goProduct(productSeq) {
    this.router.navigate(
      ['/product/info'],
      {
        relativeTo: this.activatedRoute,
        queryParams: {productSeq: productSeq},
        queryParamsHandling: 'merge', // remove to replace all query params by provided
      });
  }

  /*******************************************************************************
    설  명 : 미수취신고
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/  
  notReceived(row: any) {
    const modalRef = this.modalService.open(MypageNotReceivedComponent, options);

    modalRef.componentInstance.rowData = row;

    modalRef.result.then((result) => {
      this.getNotLoginOrderDetail();
    }, (reason) => {
    });  
  }
  
  /*******************************************************************************
    설  명 : 수취확인
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/  
  received( row: any ) {
    const modalRef = this.modalService.open(MypageReceivedComponent, options);

    modalRef.componentInstance.rowData = row;
    modalRef.componentInstance.hp = this.hp;

    modalRef.result.then((result) => {
      this.getNotLoginOrderDetail();
    }, (reason) => {
    });  
  }


  /*******************************************************************************
    설  명 : 구매후기작성
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/  
  review( row: any ) {
    const modalRef = this.modalService.open(MypageReviewAddComponent, options);

    modalRef.componentInstance.rowData = row;

    modalRef.result.then((result) => {
      if( result ) {
        this.getNotLoginOrderDetail();
      }
    }, (reason) => {
    });
  }
  
  /*******************************************************************************
    설  명 : 주문취소
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/  
  cancel(row) {
    if ( confirm('선택하신 주문을 취소하시겠습니까?') ) {
      this.sOrderService.setOrderCancel( row.seq ).then( response => {
        if ( response.ResultCode ) {
          this.toastrService.success(response.ResultMessage);
          this.getNotLoginOrderDetail();
        } else {
          this.toastrService.error(response.ResultMessage);
        }
      });

    }
  }

  /*******************************************************************************
    설  명 : 반품신청
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/  
  return() {
    let qtyNum = 0;
    this.detailList.map( rows => {
      rows.product.map( rows1 => {
        qtyNum += rows1.qty - rows1.used_req_qty;
      });
    });

    if ( qtyNum == 0 ) {
      this.toastrService.error('반품 가능한 수량이 없습니다.');
    } else {
      const modalRef = this.modalService.open(MypageReturnComponent, options1);

      modalRef.componentInstance.rowData = this.detailList;
      modalRef.componentInstance.hp = this.hp;

      modalRef.result.then((result) => {
        this.getNotLoginOrderDetail();
      }, (reason) => {
      });  
    }
  }

  /*******************************************************************************
    설  명 : 교환신청
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/  
  exchange() {
    let qtyNum = 0;
    this.detailList.map( rows => {
      rows.product.map( rows1 => {
        qtyNum += rows1.qty - rows1.used_req_qty;
      });
    });
    
    if ( qtyNum == 0 ) {
      this.toastrService.error('교환 가능한 수량이 없습니다.');
    } else {
      const modalRef = this.modalService.open(MypageExchangeComponent, options1);

      modalRef.componentInstance.rowData = this.detailList;
      modalRef.componentInstance.orderData = this.orderInfo;
      modalRef.componentInstance.hp = this.hp;

      modalRef.result.then((result) => {
        this.getNotLoginOrderDetail();
      }, (reason) => {
      });
    }
  }
 
  /*******************************************************************************
    설  명 : 배송조회
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/  
  delivery( row: any ) {
    const deliveryCompany = row.delivery_company;
    const deliveryNo = row.delivery_no.replace(/-/g,'');

    if ( deliveryCompany == "1004" ){
      var gourl = "http://service.epost.go.kr/trace.RetrieveRegiPrclDeliv.postal?sid1=" + deliveryNo;
      window.open(gourl, 'popup','scrollbars=yes, width=1200, height=900');
    } else if ( deliveryCompany == "1001" ){
      var gourl = "http://d2d.ilogen.com/d2d/delivery/invoice_tracesearch_quick.jsp?slipno=" + deliveryNo;
      window.open(gourl, 'popup','scrollbars=yes, width=1200, height=900');
    } else if ( deliveryCompany == "1002" ){
      var gourl = "https://www.hanjin.co.kr/kor/CMS/DeliveryMgr/WaybillResult.do?mCode=MN038&schLang=KR&wblnumText2=" + deliveryNo;
      window.open(gourl, 'popup','scrollbars=yes, width=1200, height=900');
    } else if ( deliveryCompany == "0010" ){
      var gourl = "https://www.doortodoor.co.kr/parcel/doortodoor.do?fsp_action=PARC_ACT_002&fsp_cmd=retrieveInvNoACT&invc_no=" + deliveryNo;
      window.open(gourl, 'popup','scrollbars=yes, width=1200, height=900');
    } else if ( deliveryCompany == "1003" ){
      var gourl = " https://www.lotteglogis.com/home/reservation/tracking/linkView?InvNo=" + deliveryNo;
      window.open(gourl, 'popup','scrollbars=yes, width=1200, height=900');
    } else {
      this.toastrService.error('택배사 코드를 다시 확인하시기 바랍니다.');
    }
  }

  /*******************************************************************************
    설  명 : 현금 영수증 신청페이지
    입력값 : 없음
    리턴값 : 없음
  ******************************************************************************/
  goCashReceipt() {
    const modalRef = this.modalService.open(MypageOrderDetailCashreceiptComponent, options2);

    modalRef.componentInstance.orderInfo = this.orderInfo;
    modalRef.componentInstance.detail = this.detailList;
    modalRef.componentInstance.pay = this.payList;

    modalRef.result.then((result) => {
    }, (reason) => {
    });  
  }

  /*******************************************************************************
    설  명 : 현금 영수증 신청 가능한지 체크
    입력값 : 없음
    리턴값 : 없음
  ******************************************************************************/
  checkCashReceipt(data: any) {

    if( data === null || !data.pay_date || data.pay_date == null ) return false;

    return moment().diff(moment(data.pay_date), 'days') <= 15 ? true : false;
  }
  
  /*******************************************************************************
    설  명 : 영수증조회 연결
    입력값 : 없음
    리턴값 : 없음
  ******************************************************************************/
  getReceipt() {
    let gourl = 'https://iniweb.inicis.com/DefaultWebApp/mall/cr/cm/mCmReceipt_head.jsp?noTid=' + this.tid + '&noMethod=1';
    window.open(gourl, 'popup','scrollbars=yes, width=1200, height=900');
  }

  /*******************************************************************************
    설  명 : 주문내역서 연결
    입력값 : 없음
    리턴값 : 없음
  ******************************************************************************/
  getOrderList() {
    const modalRef = this.modalService.open(MypageOrderStatementComponent, optionLG);

    modalRef.componentInstance.orderInfo = this.orderInfo;
    modalRef.componentInstance.detail = this.detailList;

    modalRef.result.then((result) => {
    }, (reason) => {
    });  
  }

  /*******************************************************************************
    설  명 : 신용거래 조회
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/  
  isInicis() {
    this.sOrderService.isInicis( this.seq ).then( response => {
      if ( response.ResultCode ){
        this.inicisSeq = response.seq;
        this.tid = response.tid;
      }
    });
  }

  /*******************************************************************************
    설  명 : 신용거래 조회
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/  
  checkOrderPayCash() {
    this.sOrderService.checkOrderPayCash( this.seq ).then( response => {
      if ( response.ResultCode ){
        this.payCashInfo = response.data;
      }
    });
  }
  
  /*******************************************************************************
    설  명 : 총 결제금액 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getPayAmt( info: any ) {
    let total_amt = parseInt(info.order_amt) + parseInt(info.delivery_price);
    return ( total_amt > 0 ) ? total_amt : 0;
  }
      
}
