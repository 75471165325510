<app-header></app-header>

<form [formGroup]="form">
<div class="inner">
  <div class="page-header">
    <div class="page-title">{{boardInfo.board_name}}</div>
      <div class="fr pc-only">
        <button *ngIf="authCheck()" type="button" class="btn-ud btn-danger m10" (click)="delete()">삭제</button>
        <button *ngIf="authCheck()" class="btn-ud btn-default m10"  (click)="write()">수정</button>
      </div>
  </div>
  <div class="board-view">
    <div class="title">{{boardData.subject}}</div>
    <div class="date">{{boardData.write_date}}</div>
    <div class="board-contents">
      <div class="" [innerHTML]="boardData.contents_nl2br | safe: 'html'"></div>
    </div>
  </div>
  <ng-container *ngIf="boardInfo.board_id == 'notice'">
    <div class="btn-area">
      <table class="list-table">
        <colgroup class="pc-view">
          <col style="width: 15%" />
          <col style="width: 70%" />
          <col style="width: 15%" />
        </colgroup>

        <tbody>
          <tr *ngIf="isLogIn">
            <td>댓글입력</td>
            <td>
              <textarea id="contents" formControlName="contents" [ngClass]="{formErrors:formErrors['contents']}" rows="5" class="form-control form-control-sm" placeholder="댓글을 입력해주세요"></textarea>
              <div *ngIf="formErrors['contents']" class="error-message">{{formErrors['contents']}}</div>
            </td>
            <td>
              <button type="button" class="btn btn-primary btn-small" (click)="setComment()">입력</button>
            </td>
          </tr>
          <tr *ngFor="let row of replyList;">
            <td>{{row.user_name}}<br>{{row.write_date}}</td>
            <td style="text-align: left">{{row.contents}}</td>
            <td>
              <ng-container *ngIf="row.writer == memberInfo.mem_no">
                <button type="button" class="btn btn-warning btn-small mr10" (click)="updateComment(row)">수정</button>
                <button type="button" class="btn btn-danger btn-small" (click)="deleteComment(row)">삭제</button>
              </ng-container>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </ng-container>
  <div class="btn-area">
    <!-- <button *ngIf="boardId !== 'notice'" class="basic-btn red mr10 mb5" (click)="reply()">답글</button> -->
    <button class="basic-btn" (click)="toBoardList()">목록</button>
  </div>

  <table class="list-table">
  <colgroup class="pc-view">
    <col style="width: 7%" />
    <col style="width: 60%" />
    <col *ngIf="boardId !== 'notice'" style="width: 11%"/>
    <col style="width: 11%" />
    <col style="width: 11%" />
  </colgroup>

  <thead>
  <tr>
    <th>번호</th>
    <th>제목</th>
    <th *ngIf="boardId !== 'notice'">작성자</th>
    <th>등록일</th>
    <th>조회수</th>
  </tr>
  </thead>

  <tbody>
    <tr *ngFor='let row of boardList' [ngClass]="{important:row.is_notice==1}" (click)="view(row)">
      <td *ngIf="row.is_notice==1" class="red" >중요</td>
      <td *ngIf="row.is_notice==0" >{{row.board_seq}}</td>
      <td *ngIf="row.is_notice==1" class="tl title important">
        {{row.subject}}
        <div class="mobile-view">{{row.write_date.substr(0,10)}}</div>
      </td>
      <td *ngIf="row.is_notice==0" class="tl title">
        <span *ngIf="row.depth=='1' && row.board_id=='question'">└&nbsp;</span>
        <span *ngIf="row.depth=='2' && row.board_id=='question'">　└&nbsp;</span>
        <span *ngIf="row.depth=='3' && row.board_id=='question'">　　└&nbsp;</span>
        <span *ngIf="row.depth=='4' && row.board_id=='question'">　　　└&nbsp;</span>
        <span *ngIf="row.depth=='5' && row.board_id=='question'">　　　　└&nbsp;</span>
        {{row.subject}}
        <div class="mobile-view">{{row.write_date.substr(0,10)}}</div>
      </td>
      <td *ngIf="boardList[0].board_id!=='notice'">{{row.writer_name}}</td>
      <td>{{row.write_date.substr(0,10)}}</td>
      <td>{{row.hit}}</td>
    </tr>
    <tr *ngIf="boardList.length < 1">
      <td colspan="6" class="center">내용이 없습니다</td>
    </tr>
  </tbody>
  </table>
</div>
</form>

<app-footer></app-footer>