<app-header class="pc-view"></app-header>

<div class="inner clearfix">
  <!-- 왼쪽 마이페이지 메뉴 -->
  <app-mypage-menu></app-mypage-menu>
  <app-mypage-header class="pc-view"></app-mypage-header>
  
  <div class="mypage-contents">
    <div class="section order-list">
      <div class="title page-title">구매후기</div>

      <div class="mypage-search-area form-inline">
        <span class="bold pc-view">기간</span>

        <div class="btn-group ml10 mr10">
          <button *ngFor="let item of dateGroupList" type="button" class="btn btn-default btn-small" [ngClass]="{active:search.dateGroup==item.value}" (click)="onSearchDateClick(item.value)">{{item.title}}</button>
        </div>

        <input type="text" id="sdate" [(ngModel)]="search.sdate" class="form-control form-control-small pc-view" ngbDatepicker [footerTemplate]="footerTemplate" #d1="ngbDatepicker" [readonly]="true" (click)="d1.toggle()" />
        <ng-template #footerTemplate>
          <hr class="my-0">
          <button type="button" class="btn btn-primary btn-small m-2 float-left" (click)="getToday(d1, 1)">오늘</button>
          <button type="button" class="btn btn-secondary btn-small m-2 float-right" (click)="d1.close()">닫기</button>
        </ng-template>
        <span class="pl5 pr5 pc-view">~</span>
        <input type="text" id="edate" [(ngModel)]="search.edate" class="form-control form-control-small ml5  pc-view" ngbDatepicker [footerTemplate]="footerTemplate2" #d2="ngbDatepicker" [readonly]="true" (click)="d2.toggle()" />
        <ng-template #footerTemplate2>
          <hr class="my-0">
          <button type="button" class="btn btn-primary btn-small m-2 float-left" (click)="getToday(d2, 2)">오늘</button>
          <button type="button" class="btn btn-secondary btn-small m-2 float-right" (click)="d2.close()">닫기</button>
        </ng-template>
        
	      <button type="button" class="basic-btn sm-btn pc-view" (click)="getOrderReviewList()">조회</button>
      </div>

      <table class="list-table">
      <colgroup>
        <col style="width: 86%">
        <col style="width: 14%">
      </colgroup>    
  
      <thead>
      <tr>
        <th>내용</th>
        <th>관리</th>
      </tr>    
      </thead>
      
      <tbody>
        <tr *ngFor="let row of reviewList; let i = index;">
          <td class="info">
            <div class="fl mb10">
              <div class="img cp" (click)="goProduct(row)"><img [src]="row.thumbnail_url"></div>
              <div class="info1 cp" (click)="goProduct(row)">
                <p class="name">{{row.product_name}}</p>
                <p class="size">색상 : {{row.display_color_name}} / 사이즈 : {{row.display_size_name ? row.display_size_name : '단품'}}</p>
                <p class="price">{{row.total_amt | number}} 원</p>
              </div>
            </div>
            <hr class="cb" />
            <div class="left-review mt10">
              <div class="img form-inline">
                <div class="img-grade-active" [ngStyle]="{'width': setGarde(row)}"></div>
                <div class="img-grade-back"></div>
                <div class="grade-cnt">{{row.grade}}</div>
                <div class="write-date"><span>{{row.review_date}}</span></div>
              </div>
              <div class="cb"></div>
              <div class="review-txt" [innerHTML]="row.comment_nl2br | safe: 'html'"></div>
            </div>
            <div *ngIf="row.imageCount > 0" class="right-review">
              <ul class="pic-list clearfix">
                <li *ngFor="let file of getReviewImageList(row)">
                  <img *ngIf="file.thumbnail_url" [src]="file.thumbnail_url" />
                  <img *ngIf="!file.thumbnail_url" [src]="file.url" />
                  <!-- <button class="del-btn"></button> -->
                </li>
              </ul>
            </div>
          </td>
          <td>
            <button type="button" class="basic-btn sm-btn round cp pc-view" (click)="openReviewAdd(row)">수정하기</button>
            <button type="button" class="basic-btn sm-btn round cp red cwhite pc-view" (click)="setReviewDelete(row)">삭제하기</button>
            <span class="mobile-view" (click)="openReviewAdd(row)"><i class="material-icons">edit</i></span>
            <span class="mobile-view" (click)="setReviewDelete(row)"><i class="material-icons">delete</i></span>
          </td>
        </tr>
        <tr *ngIf="reviewList.length < 1">
          <td colspan="4">작성한 구매후기가 없습니다</td>
        </tr>
      </tbody>
      </table>
      
      <!-- 모바일 view --> 
      <!-- <ng-container *ngFor="let row of reviewList; let i = index">
        <div class="list-group mobile-view">
          <div class="date clearfix">
            <div>{{row.req_date}}</div>
          </div>
          <ul>
            <li>
              <div class="clearfix">
                <div *ngIf="row.reg_gbn == 2000 && row.approval_yn == null">반품대기중</div>      
                <div *ngIf="row.reg_gbn == 3000 && row.approval_yn == null">교환대기중</div>
                <div *ngIf="row.reg_gbn == 2000 && row.approval_yn == '1'">반품승인</div>      
                <div *ngIf="row.reg_gbn == 3000 && row.approval_yn == '1'">교환승인</div> 
                <div class="red1" *ngIf="row.reg_gbn == 2000 && row.approval_yn == '2'">반품거절</div>      
                <div class="red1" *ngIf="row.reg_gbn == 3000 && row.approval_yn == '2'">교환거절</div>   
              </div>
              <ng-container *ngFor="let detail of reviewList[i].detail;let j = index">
                <div class="info clearfix">
                  <div class="img" (click)="goProduct(row)"><img src="{{detail.thumbnail_url}}"></div>
                  <div class="info1" (click)="goProduct(row)">
                    <p class="name">{{detail.product_name}}</p>
                    <p class="size">색상 : {{detail.display_color_name}} / 사이즈 : {{detail.display_size_name ? detail.display_size_name : '단품'}}</p>
                    <p class="red" *ngIf="row.reg_gbn == 2000">반품요청 수량 : {{detail.req_qty}}개</p>
                    <p class="red" *ngIf="row.reg_gbn == 3000">교환요청 수량 : {{detail.req_qty}}개</p>
                    <div class="add-option" *ngIf="row.event_seq != '0'"></div>
                    <p class="price" *ngIf="row.reg_gbn == 2000">반품요청 금액 : {{detail.req_amt | number}} 원</p> 
                  </div>         
                </div>
              </ng-container>
            </li>
          </ul>  
        </div>
      </ng-container> -->
      <!-- 모바일 view -->      
      
      <div class="pagination-area">
        <ngb-pagination
          [(page)]="search.pageNo"
          [pageSize]="search.pageRow"
          [collectionSize]="totalCount"
          [boundaryLinks]="true"
          (pageChange)="loadPage($event)"
          >
          <ng-template ngbPaginationFirst>처음</ng-template>
          <ng-template ngbPaginationLast>마지막</ng-template>
          <ng-template ngbPaginationPrevious>이전</ng-template>
          <ng-template ngbPaginationNext>다음</ng-template>
        </ngb-pagination>
      </div>             
    </div>
         
  </div>   
</div>

<app-footer></app-footer>