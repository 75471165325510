import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

import { Globals } from '@app/service/globals.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private loggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private loggedInInfo: BehaviorSubject<Array<any>> = new BehaviorSubject([]);

  get isLoggedIn() {
    return this.loggedIn.asObservable();
  }

  get getLoginInfo() {
    return this.loggedInInfo.asObservable();
  }

  constructor(
    private router: Router,
    private globals: Globals,
  ) {}

  /*******************************************************************************
    설  명 : 로그인
  *******************************************************************************/
  login(response: any) {
    this.loggedIn.next(true);
    this.loggedInInfo.next(response.data);

    this.globals.setToken(response.token);
  }

  /*******************************************************************************
    설  명 : 로그아웃
  *******************************************************************************/
  logout(mode: string) {
    this.loggedIn.next(false);
    this.loggedInInfo.next([]);

    this.globals.clearToken();

    if( mode == 'A' ) this.router.navigate(['/admin/login']);
    else window.location.href = `${location.origin}`;
  }
}
