import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

import { UtilService } from '@app/service/util.service';
import { SEventService } from '@service/event.service';

import { Event3PrizeComponent } from '@page/event/event3/event3-prize/event3-prize.component';

const options: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  size: '500',
  centered: true,
  windowClass:'modal-fadeInDown'
};

@Component({
  selector: 'app-event3',
  templateUrl: './event3.component.html',
  styleUrls: ['./event3.component.scss']
})
export class Event3Component implements OnInit {

  public form: FormGroup;
  public formErrors = {};  

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private utilService: UtilService,
    private eventService: SEventService,
    private toastrService: ToastrService,
  ) { 
    this.form = this.formBuilder.group({
      coupon_number: ['',[Validators.required, Validators.minLength(12)]],
    });
  }

  ngOnInit(): void {
    this.form.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.form, this.formErrors );
    });
  }

  submit() {
    this.utilService.makeFormDirtyAndUpdateErrors(this.form, this.formErrors);

    if (this.form.valid) {
      this.eventService.setEventCoupon(this.form).then( response => {
        if (response.ResultCode) {
          this.toastrService.success(response.ResultMessage);
          this.form.reset();
          this.eventPrize(response.data.name, response.data.mileage);
        } else {
          if (response.errorCode === 403) {
            return this.router.navigate(['/member/login']);
          }
          this.toastrService.error(response.ResultMessage);
        };
      });
    } else {
      this.utilService.showToast(this.form, this.formErrors);
    }
  }

  /*******************************************************************************
    설  명 : 이벤트당첨
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/  
  eventPrize(name, mileage) {
    const modalRef = this.modalService.open(Event3PrizeComponent, options);

    modalRef.componentInstance.name = name;
    modalRef.componentInstance.mileage = mileage;

    modalRef.result.then((result) => {
    }, (reason) => {
    });  
  }

}
