<ng-container *ngIf="isMobileApp === false">
  <app-header></app-header>
</ng-container>

<ul class="menu clearfix" *ngIf="isMobileApp === false">
  <li><a routerLink="/company/info">회사소개</a></li>
  <li><a routerLink="/company/history">회사연혁</a></li>
  <li><a routerLink="/company/supply">입점안내</a></li>
  <li><a routerLink="/company/society">동호회등록</a></li>
  <li><a routerLink="/company/story" class="on">창업스토리</a></li>
  <li><a routerLink="/company/contact">가맹문의</a></li>
  <li><a routerLink="/company/policy/service">이용약관</a></li>
</ul>

<ul class="menu menu-mobile clearfix" *ngIf="isMobileApp === true">
  <li><a routerLink="/mobile/company/info">회사소개</a></li>
  <li><a routerLink="/mobile/company/history">회사연혁</a></li>
  <li><a routerLink="/mobile/company/supply">입점안내</a></li>
  <li><a routerLink="/company/society">동호회등록</a></li>
	<li><a routerLink="/mobile/company/story" class="on">창업스토리</a></li>
	<li><a routerLink="/mobile/company/contact">가맹문의</a></li>
  <li><a routerLink="/mobile/company/policy/service">이용약관</a></li>
</ul>

<div style="margin-top: 37px" *ngIf="isMobileApp === true"></div>

<section class="contents">

  <div class="section inner">
    <article class="article">
      <ul>
        <li class="storyList" id="daejeon" (click)="showStory('daejeon')">
          <img src="/assets/images/daejeon_banner.jpg">
          <p>[성공스토리 - 대전점] 바이크마트 4년차 베테랑, 성공한 사업가!</p>
        </li>
        <!-- li class="storyList" id="ulsan" (click)="showStory('ulsan')">
          <img src="/assets/images/ulsan_banner.jpg">
          <p>[창업스토리 - 울산점] 20대 여성 창업 성공 스토리</p>
        </li-->
        <li class="storyList" id="pohang" (click)="showStory('pohang')">
          <img src="/assets/images/pohang_banner.jpg">
          <p>[창업스토리 - 포항점] 평범한 직장인 월5000만원 매출?</p>
        </li>
        <li class="storyList" id="gyeonggi_seobu" (click)="showStory('gyeonggi_seobu')">
          <img src="/assets/images/gyeonggi_seobu_banner.jpg">
          <p>[창업스토리 - 경기서부점] 첫 달 매출 5000만원의 비결은?</p>
        </li>
        <!-- <li class="storyList">
          <a routerLink="/company/supply">
            <img src="/assets/images/empty.jpg">
            <p></p>
          </a>
        </li> -->
        <li class="step">
          <div class="li-title"><span>창업절차</span></div>
          <img src="/assets/images/shop/open_store_step.png" class="pc-img" alt="창업절차">
          <img src="/assets/images/shop/open_store_stepm.png" class="mobile-img"alt="창업절차">
        </li>
        <li class="step">
          <div class="li-title"><span>자주 묻는 질문</span></div>

          <div *ngIf="isMobileApp === false">
            <iframe width="100%" height="675" src="https://www.youtube.com/embed/MFLF7nXLdQ8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
          </div>

          <div *ngIf="isMobileApp === true">
            <a href="https://www.youtube.com/watch?v=ulcYMG-4O-k" target="_blank">
              <img src="/assets/images/company_youtube10.png" width="100%" alt="오토바이를 너무 사랑한 나머지 창업까지!! ｜바이크마트｜SBS생생경제 정보톡톡｜오토바이의 시작과 끝 (오토바이 창업 이야기)" />
            </a>
          </div>

          <ul class="qna">
            <li>
              <div class="question">오토바이 용품 공급은 어떻게 이루어지나요?</div>
              <div class="answer">바이크마트에서는 점주님들의 비지니스 요구사항을 충족하기 위해 상품, 서비스를 확보하고 해당
                상품의 품질과 가치를 보장하는 SCM 시스템을 활용하고 있습니다.</div>
            </li>
            <li>
              <div class="question">매장 직원 구인은 어떻게 하셨으며, 점포 직원수는 얼마나 필요하신가요?</div>
              <div class="answer">점주가 직접 구직 사이트등을 통해 구인하셔야하며, 직원수는 점포의 구조/매출액/영업시간에 따라 달라집니다. </div>
            </li>
            <li>
              <div class="question">본사에서 점포 입지 선정을 도와주시나요?</div>
              <div class="answer">특정 지역 및 상권에 대한 적극적인 추천은 하지 않는 것을 원칙으로 하고 있으며, 희망점포를 예비점주가 직접 확인/조사 후 슈퍼바이저에게 통보하셔야 합니다. <br/>
                해당 상권이 바이크마트와 맞는지 판단하여, 예비 점주님과 점포선정을 함께 진행합니다.
                또한 예비점주님께 브랜드별 상권 및 점포 선정에 관한 가이드를 전달 드리고 있습니다.
              </div>
            </li>
            <li>
              <div class="question">점포선정부터 오픈까지 기간은 얼마나 걸리나요?</div>
              <div class="answer">
                점포 규모, 점포 인테리어에 따라 다소 차이가 발생할 수 있지만 점포 선정 후 평균적으로 6-8주의 기간이 소요됩니다.<br/>
                이 기간동안 직원 구인, 본사 교육, 인테리어 공사, 인허가 및 관련 제반 법규 사항 처리등이 이루어집니다.
              </div>
            </li>
            <li>
              <div class="question">매장 오픈후 사후관리는 어떻게 이루어지나요?</div>
              <div class="answer">
                담당 슈퍼바이저의 방문과 경영지도를 통해 가맹점 관리와 매출 증가 위한 사후관리가 이루어지고 있습니다.
          또한 본사는 시즌별 신제품 개발, 마케팅, 이벤트등을 진행하고 있으며, 정기적인 모니터링을 통해 매출 및 수익 증대 방안을 제시하고 있습니다. <br/>

          점주님들의 홍보를 돕기 위한 정기 방문도 진행 중입니다.
              </div>
            </li>
            <li>
              <div class="question">매장의 매출과 수익률은 어느 정도인가요?</div>
              <div class="answer">매출과 수익률은 점포의 입지 조건, 운영 형태에 따라 차이가 있습니다.</div>
            </li>
            <li>
              <div class="question">오픈 가능한 평수 또는 층수에 제한이 있나요?</div>
              <div class="answer">지역별로 차이가 있습니다.<br/>
                층수와 인접 도로 주차장에 따라 최소 평수와 층수가 달라집니다.
                예를들어 바이크마트 포항점의 경우에 평수가 50평 이상입니다.<br/>

                전국에 위치한 바이크마크 프랜차이즈 점은 총 6개이며,
                평균 평수는 40평입니다.
                <br/>
                자세한 내용은 창업문의를 남겨주시면 담당자를 통해 상담받으실 수 있습니다.

              </div>
            </li>
            <li>
              <div class="question">인테리어 비용은 어떻게 되나요?</div>
              <div class="answer">점주님들의 인테리어 컨셉과 바닥과 천정 공사 여부와 에어컨, TV, 커피머신등의 기기에 따라 차이가 납니다.<br/>

                바이크마트 지역점을 방문하신 후 인테리어 비용에 대하여 문의 주시면 슈퍼바이저를 통해 상담받으실 수 있습니다.
              </div>
            </li>
            <li>
              <div class="question">1인 창업 아이템으로 적합한가요?</div>
              <div class="answer">바이크마트 경기서부점, 포항점, 창원점등 현재 1인 운영중에 있습니다.</div>
            </li>
            <li>
              <div class="question">오토바이 용품에 대한 지식이 없습니다.</div>
              <div class="answer">
                바이크마트에는 다양한 브랜드와 다양한 제품들이 존재합니다.<br/>

                새롭게 입문하고자 하는 고객들을 대응하기 위해 기초적인 제품에 대한 교육과 안내는 본사에서 주기적으로 연락드리며 진행하고 있습니다. <br/>

                매니아층과 고급유저층의 고객들의 경우엔 전문적인 지식을 보유한 상태에서 매장을 방문하시기 때문에 제품에 대한 접근 난이도는 매우 낮습니다.
              </div>
            </li>
          </ul>
        </li>
      </ul>
    </article>
  </div>

</section>

<ng-container *ngIf="isMobileApp === false">
  <app-footer></app-footer>
</ng-container>
