/*******************************************************************************
  설  명 : 마이페이지-동호회 헤더
  생성일 : 2024-03-29
  생성자 : 권세창
*******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { AuthService } from '@app/service/auth.service';

import { SMemberService } from '@app/service/member.service';

@Component({
  selector: 'app-mypage-society-header',
  templateUrl: './mypage-society-header.component.html',
  styleUrls: ['./mypage-society-header.component.scss']
})
export class MypageSocietyHeaderComponent implements OnInit {
  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  public memberInfo: any = {};
  public headerInfo: any = {};
  public repId = '';
  public repName = '';
  public repEmail = '';
  public memberCount = 0;
  public societyScore = 0;


  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private authService: AuthService,
    private memberService: SMemberService,
  ) { 

  }

  /*******************************************************************************
    설  명 : 데이터 로딩 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit(): void {
    this.authService.getLoginInfo.subscribe(data => {
      this.memberInfo = data;
    });

    this.getSocietyHeaderInfo();

  }

  /*******************************************************************************
  설  명 : 동호회 마이페이지 헤더 값 가져오기
  입력값 : 없음
  리턴값 : 없음
  *******************************************************************************/
  getSocietyHeaderInfo() {
    this.memberService.getSocietyHeaderInfo(this.memberInfo.society_seq).then( response => {
      if ( response.ResultCode ) {
        this.headerInfo = response.data;    
        this.repId = this.headerInfo[0].id;
        this.repName = this.headerInfo[0].name;
        this.memberCount = this.headerInfo[0].memberCount;
        this.societyScore = this.headerInfo[0].societyScore;
      }
    });
  }


}
