import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

import { SMemberService } from '@app/service/member.service';

@Component({
  selector: 'app-member-dormant-transform',
  templateUrl: './member-dormant-transform.component.html',
  styleUrls: ['./member-dormant-transform.component.scss']
})
export class MemberDormantTransformComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  public dormant_data: any;

  /*******************************************************************************
    설  명 : 생성자
  *******************************************************************************/
  constructor(
    public activeModal: NgbActiveModal,
    private toastrService: ToastrService,
    private memberService: SMemberService,
  ) { }

  /*******************************************************************************
    설  명 : 데이터 로딩 처리
  *******************************************************************************/
  ngOnInit(): void {
  }

  /*******************************************************************************
    설  명 : 휴면회원 해제 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  submit() {
    this.memberService.setMemberDormantTransform( this.dormant_data.mem_no ).then( response => {
      if (response.ResultCode) {
        this.toastrService.success(response.ResultMessage, '');

        this.activeModal.close();
      } else {
        this.toastrService.error(response.ResultMessage, '');
      }
    });    
  }
}
