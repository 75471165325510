/*******************************************************************************
  설  명 : 마이페이지 - 구매내역/배송 조회 미수취신고 모달
  생성일 : 2020-09-10
  생성자 : 송영석
*******************************************************************************/
import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UtilService } from '@app/service/util.service';


@Component({
  selector: 'app-mypage-not-received',
  templateUrl: './mypage-not-received.component.html',
  styleUrls: ['./mypage-not-received.component.scss']
})
export class MypageNotReceivedComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  @Input() rowData: any;
  @Input() memberOrder: any;

  public rowDetail: any;

  public form: FormGroup;
  public formErrors: any = {};

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private utilService: UtilService,
  ) {
    this.buildForm();
  }

  /*******************************************************************************
    설  명 : 폼 생성
  *******************************************************************************/
  buildForm(): void {
    this.form = this.formBuilder.group({
      order_seq: [""],
      detail: [[]],
      reason: ["", [Validators.required]],
      comment: ["", [Validators.required]],
    });

    this.form.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors(this.form, this.formErrors);
    });
  };

  /*******************************************************************************
    설  명 : 데이터 로딩 처리 수정자: 3/3 김종현
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit(): void {
    if ( this.memberOrder === true ) {
      this.rowDetail = this.rowData.product;
      this.form.patchValue({
        order_seq: this.rowData.product[0].order_seq,
        detail: this.rowDetail
      });
      
      this.memberOrder === false;

    } else {
      this.rowDetail = this.rowData.children;
      this.form.patchValue({
        order_seq: this.rowData.seq,
        detail: this.rowDetail
      });
    }
  }

  /*******************************************************************************
    설  명 : 구매후기등록
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  submit() {
    this.utilService.makeFormDirtyAndUpdateErrors(this.form, this.formErrors);

    // this.sOrderService.setOrderReview( this.form ).then( response => {
    //   if ( response.ResultCode ) {
    //     this.activeModal.close();  
    //   }
    // })
    
  }

}
