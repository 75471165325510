/*******************************************************************************
  설  명 : 반품신청 완료 후 공지
  생성일 : 2021-07-12
  생성자 : 김종현
*******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-notice',
  templateUrl: './returnNotice.component.html',
  styleUrls: ['./returnNotice.component.scss']
})
export class returnNoticeComponent implements OnInit {
  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    public activeModal: NgbActiveModal,
    
  ) { }


  /*******************************************************************************
    설  명 : 데이터 로딩 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit(): void {
  }

}
