import { Component, OnInit, Input } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector'
import { Observable } from 'rxjs';

import { AuthService } from '@app/service/auth.service';

@Component({
  selector: 'app-goods-card',
  templateUrl: './goods-card.component.html',
  styleUrls: ['./goods-card.component.scss']
})
export class GoodsCardComponent implements OnInit {

  @Input() productInfo: any;

  public isLogin$: Observable<boolean>;

  public colorList: any = [];
  public sizeList: any = [];

  constructor(
    private deviceDetectorService: DeviceDetectorService,
    private authService: AuthService,
  ) {
    this.isLogin$ = this.authService.isLoggedIn;
  }

  ngOnInit(): void {
    if( typeof this.productInfo.color !== 'undefined' && this.productInfo.color !== null )
      this.colorList = this.productInfo.color.split(',');

    if( typeof this.productInfo.size !== 'undefined' &&this.productInfo.size !== null )
      this.sizeList = this.productInfo.size.split(',');
  }

  linkTarget = this.deviceDetectorService.isMobile() ? "_self" : "_blank"

  /*******************************************************************************
    설  명 : ngif 크기비교 int로 변환
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  comparePrice(a, b) {
    let c = parseInt(a);
    let d = parseInt(b);
    if ( c > d) {
      return true;
    } else {
      return false;
    }
  }

}
