<div class="modal-header">
  <h4 class="modal-title fl">옵션 추가/변경</h4>
  <button type="button" class="close" (click)="activeModal.dismiss()">&times;</button>
</div>

<div class="modal-body">
  <div class="goods-list">
    <div class="goods clearfix">
		  <div class="img"><img [src]="cartInfo.thumbnail_url" alt="{{cartInfo.product_name}}" /></div>
		  <div class="info">
		    <p>{{cartInfo.product_name}}</p>
		    <p>{{cartInfo.sum_total_amt | number}} 원</p>  
		  </div>
	  </div>
    <div class="option">
      <p class="title">옵션 추가</p>
      <ng-container *ngIf="colorList.length > 0">
        <!-- <select [(ngModel)]="propertyColor" (change)="selectedOption($event)">
          <option value="" disabled>색상 선택</option>
          <option *ngFor="let row of colorList" [value]="row">{{row}}</option>
        </select> -->
        <div class="select-box">
          <a class="select-title cp" (click)="selectbox_pulldown($event)">
            <span class="select-span">{{propertyColor}}</span>
            <i class="material-icons selectbox-arrow">&#xe5cf;</i>						
          </a>
          <ul class="selectbox-ul">
            <li class="cp" *ngFor="let row of colorList" (click)="selectedOption($event)">{{row}}</li>
          </ul>
        </div>
      </ng-container>
      <div class="select-box" *ngIf="colorList.length > 0 && sizeList.length < 1">
        <a class="select-title cp" (click)="selectbox_pulldown($event)">
          <span class="select-span">사이즈</span>
          <i class="material-icons selectbox-arrow">&#xe5cf;</i>						
        </a>
      </div>
      <div class="select-box" *ngIf="sizeList.length > 0">
        <a class="select-title cp" (click)="selectbox_pulldown($event)">
          <span class="select-span">{{propertySize}}</span>
          <i class="material-icons selectbox-arrow">&#xe5cf;</i>						
        </a>
        <ul class="selectbox-ul">
          <li class="cp" *ngFor="let row of sizeList" [ngClass]="{clight: row.is_soldout != '0'}" (click)="selectProduct(row)">{{row.display_size_name ? row.display_size_name : '단품'}} {{getDisplayDifferencePrice(row)}} {{row.is_soldout == '0' ? '' : '(품절)'}}</li>
        </ul>
      </div>
      <div class="select-box" *ngIf="giftList.length > 0">
        <a class="select-title cp" (click)="selectbox_pulldown($event)">
          <span class="select-span">{{topGift}}</span>
          <i class="material-icons selectbox-arrow">&#xe5cf;</i>						
        </a>
        <ul class="selectbox-ul">
          <li class="cp" *ngFor="let row of giftList" (click)="selectGift(row)">{{row.product_name | nohtml}}</li>
        </ul>
      </div>
    </div>
    <ul class="list" *ngFor="let product of cartInfo.children; let i = index">
      <li>
        <div class="clearfix">
          <div class="option1" *ngIf="product.product_freegift_seq">{{product.product_name}}<br/><span *ngIf="product.display_color_name">색상 : {{product.display_color_name}} / </span>사이즈 : {{product.display_size_name ? product.display_size_name : '단품'}}</div>
          <div class="option1" *ngIf="!product.product_freegift_seq"><span *ngIf="product.display_color_name">색상 : {{product.display_color_name}} / </span>사이즈 : {{product.display_size_name ? product.display_size_name : '단품'}}</div>
          <button class="btn-del" (click)="deleteProduct(i)">삭제</button>
        </div>
        <div class="clearfix">
          <div *ngIf="product.isGift" class="qty"></div>
	        <div *ngIf="!product.isGift" class="qty">
            <button class="btn-minus" (click)="clickQty(product, -1)">&#xe15b;</button>
              <input type="text" [value]="product.qty" [(ngModel)]="product.qty" (keyup)="changeQty(product, $event)" (change)="changeQty(product, $event)" />
            <button class="btn-plus" (click)="clickQty(product, +1)">&#xe145;</button>
	        </div>
	        <div class="price">{{product.consumer_price * product.qty | number}}<span>원</span></div>
        </div>         
      </li>
    </ul> 	  
  </div>
  
  <div class="total clearfix">
    <p>총 상품금액</p>
    <p>{{totalPrice | number}}<span>원</span></p>
  </div>

  <div class="btn-area">
   <button type="button" class="btn1" (click)="activeModal.dismiss()">취소</button>
   <button type="button" class="btn1 red" (click)="setOptionChange()">확인</button>
  </div>
</div>

