<app-header class="pc-view"></app-header>

<div class="inner">
	<div class="page-header">
	  <div class="page-title">장바구니</div>
		<ul class="step clearfix pc-view">
		  <li class="active">장바구니</li>
		  <li>주문/결제</li>
		  <li>완료</li>
		</ul>  
		<a routerLink="" class="close-btn mobile-view"></a>
	</div>
	
	<div class="guide">
		<p class="dot">·</p><p class="dot1">쇼핑몰, 아울렛, 캐주얼 통합 장바구니 입니다. 구매대행은 수입 세관 등의 여건 상 장바구니가 분리되어 있습니다.</p>
		<p class="dot">·</p><p class="dot1">장바구니에 담긴 상품은 로그아웃시 삭제됩니다. 장바구니에 담긴 상품을 더 오래 보관하려면 "찜하기"기능을 이용하여 찜리스트에 등록하십시오.</p>
		<p class="dot">·</p><p class="dot1">미성년자가 상품을 주문/결제할 경우, 법정대리인이 동의하지 않으면 미성년자 본인 또는 법정대리인이 주문을 취소할 수 있습니다.</p>
		<p class="dot">·</p><p class="dot1">무이자 할부 개월수가 다른 상품을 한 번에 결제하시면 할부 개월수가 낮은 상품을 기준으로 할부가 적용됩니다.</p>
		<p class="dot">·</p><p class="dot1">해당 상품에 적용된 무이자 할부 혜택을 받기 위해선 가급적 상품을 따로 장바구니에 담아 결제해 주시기 바랍니다.</p>	
	</div>
	
	<div class="order-list">
	  <div class="header clearfix">
	     <label><input type="checkbox" class="change-checkbox square" [checked]="isAllChecked" (click)="setAllCheck($event)">
        <span class="new-checkbox"><i class="material-icons">check</i></span>
      </label>
	     <ul class="clearfix">
	       <li (click)="setDeleteSelectCart()">선택 삭제</li>
	       <li (click)="setKeepProductSave()">선택 찜하기</li>
	     </ul>
	  </div>
	  
    <table class="list-table">
    <colgroup>
      <col style="width: 47%">
      <col style="width: 38%">
      <col style="width: 16%">
    </colgroup>    

    <thead>
    <tr>
      <th>상품정보 / 상품금액</th>
      <th>옵션</th>
      <th>주문금액</th>
    </tr>    
    </thead>
    
    <tbody>
    <tr *ngFor="let row of info_product; let i = index" [ngClass]="{active: row.isChecked == 0 }">
      <td class="info" (click)="setCheck(i)">
        <label>
          <input type="checkbox" class="change-checkbox square" [checked]="row.isChecked" (click)="setCheck(i)">
          <span class="new-checkbox"><i class="material-icons">check</i></span>
        </label>
        <div class="img cp" (click)="goDetail(row.product_seq)">
          <img [src]="row.thumbnail_url" alt="{{row.product_name | nohtml}}" />
        </div>
        <div class="info1">
          <p class="pc-view">마일리지 : {{row.sum_mileage | number}} 원</p>
          <p class="cp" (click)="goDetail(row.product_seq)">{{row.product_name | nohtml}}</p>
          <p>{{row.sum_total_amt | number}} 원<span class="mobile-view">(마일리지 : {{row.mileage | number}})</span></p>            
        </div>
        <button type="button" class="btn-del" (click)="setDeleteCart(row, i)">삭제</button>
      </td>

      <td class="option">
        <ul>
          <li *ngFor="let option of row.children; let j = index">
            <p>
              <ng-container *ngIf="option.product_freegift_seq">{{option.product_name | nohtml}}<br/></ng-container>
              <span class="f13" [ngClass]="{cred: option.use_yn == '0' || option.is_soldout == '1' || option.is_soldout == '2'}">
                <ng-container *ngIf="option.display_color_name">색상 : {{option.display_color_name}} / </ng-container>
                사이즈 : {{option.display_size_name ? option.display_size_name : '단품'}} / {{option.qty}} 개 / {{option.consumer_price | number}} 원
                <ng-container *ngIf="option.use_yn == '0' || option.is_soldout == '1' || option.is_soldout == '2'"><span class="cred fb f13"> (품절)</span></ng-container>
              </span>
            </p>
            <button type="button" class="btn-del" (click)="setDeleteOption(row, i, option, j)">삭제</button>
          </li>
          <!-- <li>
            <p>추가옵션1 : </p>
            <button type="button" class="btn-del">삭제</button>
          </li> -->
        </ul>
        <div class="btn-area">
          <button type="button" class="mobile-view order-btn" (click)="setOrder()">주문하기</button>
          <button type="button" (click)="setOptionChange(row, i)">옵션변경/추가</button>
          <!-- <button type="button" (click)="couponApply()">쿠폰적용</button> -->
        </div>
      </td>

      <td class="price" (click)="setCheck(i)">
        <p class="mobile-view title">주문금액</p>
        <p class="price1">{{row.sum_total_amt | number}} 원</p>
        <!-- <p class="price2">{{row.delivery_price | number}} 원</p> -->
      </td>
    </tr>
    </tbody>
    </table>
    
    <div class="header clearfix mobile-view">
       <label><input type="checkbox" class="change-checkbox square"><span class="new-checkbox"><i class="material-icons">check</i></span></label>
       <ul class="clearfix mt10">
         <li (click)="setDeleteSelectCart()">선택삭제</li>
         <li (click)="setKeepProductSave()">선택찜하기</li>
       </ul>
    </div>
        
    <div class="total-info">
      <ul>
        <li>
          <div class="title">총 상품금액</div>
          <div class="amt">{{totalPrice | number}}<span>원</span></div>
        </li>
        <li class="sign-minus">-</li>
        <li>
          <div class="title">할인금액</div>
          <div class="amt red">{{totalDiscount | number}}<span>원</span></div>
        </li>
        <li class="sign-plus">+</li>
        <li>
          <div class="title">배송비</div>
          <div class="amt"><span class="mobile-view plus">+</span>{{totalDelivery | number}}<span>원</span></div>
        </li>
        <li class="sign-equal">=</li>
        <li class="tot-amt">
          <div class="title">전체 주문금액</div>
          <div class="amt">{{totalOrderPrice | number }}<span>원</span></div>
        </li>
      </ul>
    </div>
    
    <div class="footer-btn" style="display: inline-flex; width: 100%; justify-content: center;">
      <!-- <div class="tot-amt mobile-view"> -->
      <div class="tot-amt" style="display: none;">
        <div class="title">전체 주문금액</div>
        <div class="amt">{{totalOrderPrice | number}}<span>원</span></div>      
      </div>
      <div class="mr5" style="min-width: 170px;">
        <!-- <a routerLink="/" class="btn1 pc-view">쇼핑 계속하기</a> -->
        <a routerLink="/" class="btn1 mb5">쇼핑 계속하기</a>
        <a class="btn1 red cp" (click)="setOrder()">주문하기</a>
      </div>
      <app-naverpay nPay_btnCount=1></app-naverpay>
    </div>
	</div>
</div>

<app-footer></app-footer>