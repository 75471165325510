import { Component, OnInit, Input, Injectable } from '@angular/core';
import { NgbDateStruct, NgbCalendar, NgbDatepickerI18n, NgbDatepickerConfig } from '@ng-bootstrap/ng-bootstrap';

import * as moment from 'moment';

const I18N_VALUES = {
  ko: {
    weekdays: ['월', '화', '수', '목', '금', '토', '일'],
    months: ['1월', '2월', '3월', '4월', '5월', '6월', '7월', '8월', '9월', '10월', '11월', '12월'],
  }
};

@Injectable()
export class I18n {
  language = 'ko';
}

// Define custom service providing the months and weekdays translations
@Injectable()
export class CustomDatepickerI18n extends NgbDatepickerI18n {

  constructor(private _i18n: I18n) {
    super();
  }

  getWeekdayShortName(weekday: number): string {
    return I18N_VALUES[this._i18n.language].weekdays[weekday - 1];
  }
  getMonthShortName(month: number): string {
    return I18N_VALUES[this._i18n.language].months[month - 1];
  }
  getMonthFullName(month: number): string {
    return this.getMonthShortName(month);
  }
	getDayAriaLabel(date: NgbDateStruct): string {
		return `${date.day}-${date.month}-${date.year}`;
	}
}

@Component({
  selector: 'inputEx',
  templateUrl: './input-ex.component.html',
  styleUrls: ['./input-ex.component.scss'],
  providers: [
    I18n,
    {provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n},
    NgbDatepickerConfig
  ]
})
export class InputExComponent implements OnInit {

  @Input() type: string;
  @Input() name: string;
  @Input() placeholder: string;
  @Input() autofocus: boolean;
  @Input() formGroup: any;
  @Input() formErrors: any;
  @Input() readonly: boolean;
  @Input() size: string;
  @Input() data: any;
  @Input() addOn;
  @Input() addOnText: string;
  @Input() change;
  @Input() rows: string;
  @Input() cols: string;
  @Input() options: any;
  @Input() onChanged;
  @Input() title: string;
  @Input() dateCustomChange: any;
  @Input() pattern: any;
  @Input() keyup: any = null;
  @Input() focus: any = null;
  @Input() blur: any = null;
  @Input() summernoteConfig: any = null;
  @Input() small: boolean = true;
  @Input() disabled: any = null;

  public today: string;

  public date: any = {
    year: parseInt( moment().format('YYYY'), 10 ),
    month: parseInt( moment().format('MM'), 10 ),
    day: parseInt( moment().format('DD'), 10 )
  };
  public time: any = {
    hour: 0,
    minute: 0,
    second: 0
  };

  public datetime = '';

  public summernoteConfigDefault = {
    placeholder: '',
    tabsize: 2,
    height: 350,
    uploadImagePath: '',
    toolbar: [
        // [groupName, [list of button]]
        ['misc', ['fullscreen', 'codeview', 'undo', 'redo']],
        ['style', ['bold', 'italic', 'underline', 'clear']],
        ['font', ['bold', 'italic', 'underline', 'strikethrough', 'superscript', 'subscript', 'clear']],
        ['fontsize', ['fontname', 'fontsize', 'color']],
        ['para', ['style', 'ul', 'ol', 'paragraph', 'height']],
        ['insert', ['table', 'link', 'video', 'hr']]
    ],
    fontNames: ['Helvetica', 'Arial', 'Arial Black', 'Comic Sans MS', 'Courier New', 'Roboto', 'Times']
  }

  select2Option: any = {
    multiple: true,
    // maximumSelectionLength: 1,
    width: '100%',
    placeholder: '',
    language: 'ko',
    closeOnSelect: false,
    scrollAfterSelect: true,
    allowClear: true
  };

  constructor(
    public calendar: NgbCalendar,
    private ngbDatepickerConfig: NgbDatepickerConfig,
  ) {
    ngbDatepickerConfig.minDate = {year: 1900, month: 1, day: 1};
    ngbDatepickerConfig.maxDate = {year: 2099, month: 12, day: 31};
    ngbDatepickerConfig.displayMonths = 1;
    ngbDatepickerConfig.firstDayOfWeek = 7; // 일요일 먼저

    this.today = moment().format('YYYY-MM-DD');
  }

  ngOnInit() {
    if( typeof this.type == 'undefined' ) this.type = 'text';

    if( this.options !== undefined ) {
      this.select2Option = {
        ...this.select2Option,
        ...this.options
      };
    }

    if( this.type == 'summernote' ) {
      if( this.summernoteConfig == null ) {
        this.summernoteConfig = this.summernoteConfigDefault;
      }
    }

    if( this.type == 'datetime' ) {
      this.formGroup.controls[this.name].valueChanges.subscribe( () => {
        const datetime = this.formGroup.controls[this.name].value;

        this.date = {
          year: parseInt( moment(datetime).format('YYYY'), 10 ),
          month: parseInt( moment(datetime).format('MM'), 10 ),
          day: parseInt( moment(datetime).format('DD'), 10 )
        };
        this.time = {
          hour: parseInt( moment(datetime).format('HH'), 10 ),
          minute: parseInt( moment(datetime).format('mm'), 10 ),
          second: parseInt( moment(datetime).format('ss'), 10 )
        };
      });
      this.getDatetime();
    }
  }

  getDatetime() {
    const tmpDate = {
      year: this.date.year,
      month: this.date.month - 1,
      day: this.date.day
    };
    const datetime = Object.assign(tmpDate, this.time);
    this.datetime = moment(datetime).format('YYYY-MM-DD HH:mm:ss');

    const data: any = {};
    data[this.name] = this.datetime;
    this.formGroup.patchValue(data);
  }

  getTime() {
    const tmpDate = {
      year: this.date.year,
      month: this.date.month - 1,
      day: this.date.day
    };
    const datetime = Object.assign(tmpDate, this.time);
    this.datetime = moment(datetime).format('HH:mm');

    const data: any = {};
    data[this.name] = this.datetime;
    this.formGroup.patchValue(data);
  }

  ngAfterContentInit() {
  }

  getToday( obj ) {
    let data: any = {};

    //data[this.name] = moment( this.calendar.getToday() ).format('YYYY-MM-DD');
    data[this.name] = this.calendar.getToday();

    this.formGroup.patchValue(data);

    obj.close();
  }

  onDateSelect( obj ) {
    let data: any = {};

    //data[this.name] = moment( obj ).format('YYYY-MM-DD');
    data[this.name] = obj;

    this.formGroup.patchValue(data);

    if( typeof this.dateCustomChange !== 'undefined' ) this.dateCustomChange();
  }

  checkAddOn() {
    return ( typeof this.addOn == 'undefined' ) ? false : true;
  }

  changed( e: any): void {
    if( typeof this.onChanged !== 'undefined' ) {
      this.onChanged( e );

    } else {
      let data: any = {};

      data[this.name] = e.value;

      this.formGroup.patchValue(data);
    }
  }

  numberCheck( event: any ) {
    // let data: any = {};
    // let tmp:string = String( this.formGroup.controls[this.name].value );
    // tmp = tmp.replace(/,/gi, '');

    // data[this.name] = this.getComma( tmp );

    // this.formGroup.patchValue( data );
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;    
  }

  selectChange() {
    if( typeof this.change !== 'undefined' ) this.change();
  }

  /*******************************************************************************
    설  명 : 콤마 제거
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  removeCommas( event: any ) {
    let data: any = {};
    data[this.name] = this.deleteComma( event.target.value );
    this.formGroup.patchValue( data );    
  }

  /*******************************************************************************
    설  명 : 콤마 추가
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  addCommas( event: any ) {
    let data: any = {};
    data[this.name] = this.setComma( event.target.value );
    this.formGroup.patchValue( data );    
  }

  /*******************************************************************************
    설  명 : 콤마 표시
    입력값 : 숫자
    리턴값 : 콤마 숫자
  *******************************************************************************/
  setComma( value: any ) {
    let str = String(value);
    return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
  }

  /*******************************************************************************
    설  명 : 콤마 제거
    입력값 : 숫자
    리턴값 : 콤마 숫자
  *******************************************************************************/
  deleteComma( value: any ) {
    let str = String(value);
    return str.replace(/,/g, '');
  }
  
}
