<div class="modal-body">
  <div class="prize-area" (click)="activeModal.dismiss()">
    <div class="member">{{name}}님</div>
    <div class="prize">
      <div>{{mileage}}<span>p</span></div>
    </div>
    <div class="prize-txt"><span>{{mileage}}P</span> 당첨 축하드립니다!</div>
  </div>


</div>


