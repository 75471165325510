/*******************************************************************************
  설  명 : 회원가입 - 회원정보 입력
  생성일 : 2020-09-10
  생성자 : 송영석
*******************************************************************************/
import { Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { NgbInputDatepicker, NgbCalendar, NgbDateStruct, NgbDatepickerConfig } from '@ng-bootstrap/ng-bootstrap';
import { KakaoMapsProvider, Geocoder } from 'kakao-maps-sdk';

import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SafeHtml } from '@angular/platform-browser';

import { ApiResponse } from '@app/service/api-response';
import { UtilService } from '@app/service/util.service';
import { AuthService } from '@app/service/auth.service';
import { SMemberService } from '@app/service/member.service';
import { ToastrService } from 'ngx-toastr';
import { SmsService } from '@app/service/sms.service';
import * as moment from 'moment';


@Component({
  selector: 'app-member-join-form',
  templateUrl: './member-join-form.component.html',
  styleUrls: ['./member-join-form.component.scss']
})
export class MemberJoinFormComponent implements OnInit {
  @ViewChild('email2', {static: false}) inputEmail2: ElementRef;
  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  daumAddressOptions = {
    class: ['btn', 'overlap-btn', 'zip-btn', 'h42']
  };
 
  public checkIdDup: boolean = true;
  public inputCustomerReadonly: boolean = false;
 
  public tabIndex: any = 1;
 
  errorResponse: ApiResponse;
  public memberAddForm: FormGroup;
  public formErrors = {};
 
  public privacyData: SafeHtml;
  public termsData: SafeHtml;
  public smsData: SafeHtml;

  public region_A_list = [];
  public region_B_list = [];
  public region_B_originalList = [];
  public KaKaoJavascriptAPIKey = 'ebc6bcdc012066b1a964417ca1cc368e';

  public yearList = [];
  public monthList = [];
  public dayList = [];

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private calendar: NgbCalendar,
    private utilService: UtilService,
    private toastrService: ToastrService,
    private router: Router,
    private formBuilder: FormBuilder,
    private sMemberService: SMemberService,
    private authService: AuthService,
    config: NgbDatepickerConfig,
    private smsService: SmsService,
    public KakaoMapsProvider: KakaoMapsProvider,
    private ref: ChangeDetectorRef,
  ) { 
    this.buildForm();
    config.minDate = { "year" : 1900, "month": 1, "day": 1};
    config.maxDate = { "year" : 2030, "month": 12, "day" : 31};
  }

  /*******************************************************************************
    설  명 : 빌드폼 생성
  *******************************************************************************/
  buildForm(): void {

    const todayModel: NgbDateStruct = this.utilService.getDate('');

    this.memberAddForm = this.formBuilder.group({
      id: [ '', [Validators.required, Validators.maxLength(25), Validators.pattern('^[a-z0-9]{6,20}$')] ],
      id_check: [ false, [Validators.required] ],
      name: [ '', [Validators.required, Validators.maxLength(20), Validators.pattern('^[가-힣a-zA-Z]*$')] ],
      pw: [ '', [Validators.required, Validators.maxLength(64), Validators.pattern('^(?=.*[a-zA-Z])(?=.*[0-9])[A-Za-z0-9\d].{7,}$')] ],
      pw_check: [ '', [Validators.required ] ],
      gender: ['M', [Validators.required ] ],
      birthdate1: [ '', [Validators.required] ],
      birthdate2: [ '', [Validators.required] ],
      birthdate3: [ '', [Validators.required] ],
      hp1: [ '010', [Validators.required] ],
      hp2: [ '', [Validators.required, Validators.maxLength(4), Validators.pattern('^[0-9]{3,4}$')] ],
      hp3: [ '', [Validators.required, Validators.maxLength(4), Validators.pattern('^[0-9]{4}$')] ],
      email1:[ '', [Validators.required, Validators.pattern('^[a-zA-Z0-9._-]*$')] ],
      email2:[ '', [Validators.required, Validators.pattern('^[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,6}$')] ],
      email3: [ '' ],
      gps_lon: [ '' ],
      gps_lat: [ '' ],
      region1: [ '', [Validators.required] ],
      region2: [ '', [Validators.required] ],
      grade: [ '', [Validators.maxLength(4)] ],
      level: [ '' ],
      email_yn: [ '1', [Validators.required] ],
      sms_yn: [ '1', [Validators.required] ],
      kakao_yn: [ '0', [Validators.required] ],
      bike_yn: [ '0' ]
    });

    this.memberAddForm.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.memberAddForm, this.formErrors );
    });
  }


  /*******************************************************************************
    설  명 : 데이터 로딩 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit(): void {
    this.setBirthdayList();

    this.getRegion();
  }

  /*******************************************************************************
    설  명 : 생년월일 리스트 입력
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setBirthdayList() {
    for (let i = 1940; i <= parseInt(moment().format('YYYY')); i++) {
      this.yearList.push(i.toString());
    }

    this.monthList = [
      '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'
    ];

    this.dayList = [
      '01', '02', '03', '04', '05', '06', '07', '08', '09', '10',
      '11', '12', '13', '14', '15', '16', '17', '18', '19', '20',
      '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31'
    ];
  }

  /*******************************************************************************
    설  명 : 아이디 중복 검사
  *******************************************************************************/
  checkIdDuplicate() {
    this.utilService.makeFieldDirtyAndUpdateErrors(this.memberAddForm, this.formErrors, 'id');

    if( this.memberAddForm.get('id').valid ) {
      this.sMemberService.checkIdDuplicate( this.memberAddForm.get('id').value ).then( response => {
        if ( response.ResultCode ) {
          this.memberAddForm.get('id_check').setValue( true );
          this.toastrService.success( response.ResultMessage, '아이디 중복검사');
        } else {
          this.memberAddForm.get('id_check').setValue( false );
          this.toastrService.error( response.ResultMessage, '아이디 중복검사');
        }
      });
    }
  }

  // 아이디 변경시 중복 검사 초기화
  setIdCheckInit() {
    this.memberAddForm.get('id_check').setValue( false );
  }

  changeEmail(event, element) {
    this.memberAddForm.get(element).setValue(event.target.value);
    if( event.target.value == "" ) {
      if( element == "email2" ) this.inputEmail2.nativeElement.focus();
    }
  }

  /*******************************************************************************
    설  명 : 지역리스트 가져오기
  *******************************************************************************/
  getRegion() {
    this.sMemberService.getRegion().then( response => {
      if( response.ResultCode ) {
        this.region_A_list = response.region_A;
        this.region_B_originalList = response.region_B;
      } else {
        this.region_A_list = [];
        this.region_B_originalList = [];
      }
    });
  }

  /*******************************************************************************
    설  명 : 오늘 선택 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getToday(obj: NgbInputDatepicker, check: boolean) {
    if( check ) {
      this.memberAddForm.patchValue({
        birthdate: this.calendar.getToday()
      });
      obj.close();
    }
  }

  /*******************************************************************************
    설  명 : 사업자번호 중복 검사
  *******************************************************************************/
  checkBizNoDuplicate() {
    this.utilService.makeFieldDirtyAndUpdateErrors(this.memberAddForm, this.formErrors, 'biz_no');

    if( this.memberAddForm.get('biz_no').valid && this.memberAddForm.controls.biz_no.value != '' ) {
      this.sMemberService.checkBizNoDuplicate( this.memberAddForm.get( 'biz_no' ).value ).then( response => {
        if( response.ResultCode ) {
          this.memberAddForm.get( 'biz_no_check' ).setValue( true );
          this.toastrService.success( response.ResultMessage, '사업자번호 중복확인' );
        } else {
          this.memberAddForm.get( 'biz_no_check' ).setValue( null );
          this.toastrService.error( response.ResultMessage, '사업자번호 중복확인' );
        }
      });
    } else {
      this.toastrService.error('사업자번호 형식이 올바르지 않습니다.')
    }
  }

  /*******************************************************************************
    설  명 : 아이디 변경시 중복 검사 초기화
  *******************************************************************************/
  setBizNoCheckInit() {
    this.memberAddForm.get('biz_no_check').setValue( null );
  }

  /*******************************************************************************
		설  명 : 회원가입 처리
	*******************************************************************************/
  submit() {
    this.utilService.makeFormDirtyAndUpdateErrors(this.memberAddForm, this.formErrors);

    if( this.memberAddForm.valid ) {
      this.sMemberService.setMemberSave(this.memberAddForm).then(response => {
        if( response['ResultCode'] == true ) {

          this.toastrService.success(response['ResultMessage'], '회원가입 성공');
          this.sMemberService.setLogin(this.memberAddForm).then(response => {
            if( response['ResultCode'] == true ) {
              this.authService.login(response);
              this.router.navigate(['/member/join/complete']);
            } else {
              this.toastrService.error(response['ResultMessage'], '회원가입');
              this.router.navigate(['/']);
            }
          })
          .catch(response => {
            this.errorResponse = response;
            this.utilService.handleFormSubmitError(this.errorResponse, this.memberAddForm, this.formErrors);
          });
        } else {
          this.toastrService.error(response['ResultMessage'], '회원가입 실패');
        }
      })
      .catch(response => {
        this.errorResponse = response;
        this.utilService.handleFormSubmitError(this.errorResponse, this.memberAddForm, this.formErrors);
      })
    } else {
      this.utilService.showToast(this.memberAddForm, this.formErrors);
    }

  }

  /*******************************************************************************
		설  명 : 시도 선택이벤트
	*******************************************************************************/
  changeSido(event) {
    this.memberAddForm.patchValue({region2: ''});

    this.region_B_list = this.region_B_originalList.filter(row => {
      return row.master_seq == event.target.value
    });
  }

  /****************************************************************************
    설명 : 지역 가져오기 - 카카오
  ****************************************************************************/
  getLocationUseKakao() {
    this.KakaoMapsProvider
      .loadKakaoMapSDK(this.KaKaoJavascriptAPIKey)
      .then(() => {
        if( navigator.geolocation ) {
          navigator.geolocation.getCurrentPosition(position => {
            const geocoder = new Geocoder()

            geocoder.coord2RegionCode(position.coords.longitude, position.coords.latitude, res => {
              const myLocation = res.find(item => item.region_type === 'B');
              
              const _masterSeq = this.region_A_list.find(item => item.sido_name == myLocation.region_1depth_name).seq;
              if(!_masterSeq) {
                this.toastrService.warning('현재 위치 (시도) 가 리스트에 없습니다.');
                return;
              } else {
                this.memberAddForm.patchValue({
                  region1: _masterSeq
                });
              }

              this.region_B_list =
                this.region_B_originalList
                  .filter(item => item.master_seq == _masterSeq)
                  .map(item => ({
                    ...item,
                    value: item.seq,
                    label: item.sigungu_name})
                  );

              const _subSeq = this.region_B_list.find(item => item.label == myLocation.region_2depth_name).seq;
              if(!_subSeq) {
                this.toastrService.warning('현재 위치 (구군) 가 리스트에 없습니다.');
                return;

              } else {
                this.memberAddForm.patchValue({
                  region2: _subSeq
                });
              }

              this.memberAddForm.patchValue({
                gps_lon: myLocation.x,
                gps_lat: myLocation.y
              });

              this.ref.detectChanges();

          }, {})
          }, e => {
            this.toastrService.error('실패하였습니다. 잠시후 다시 시도해주세요.');
            return {
              enableHighAccuracy: false, maximumAge: 0, timeout: Infinity
            }
          })
        } else {
          this.toastrService.warning('GPS 권한이 필요합니다.');
        }
      }, err => {
        this.toastrService.error('실패하였습니다. 잠시후 다시 시도해주세요.');
      }
    ).catch(e => {
      this.toastrService.error('실패하였습니다. 잠시후 다시 시도해주세요.');
    })
  }

}
