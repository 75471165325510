/*******************************************************************************
  설  명 : 배송지 관리 추가/수정 모달
  생성일 : 
  생성자 : 
*******************************************************************************/

import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

import { UtilService } from '@app/service/util.service';
import { SMemberService } from '@app/service/member.service';

@Component({
  selector: 'app-mypage-destination-add',
  templateUrl: './mypage-destination-add.component.html',
  styleUrls: ['./mypage-destination-add.component.scss']
})
export class MypageDestinationAddComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  @ViewChild('recipient_hp2', {static: false}) recipientHp2: ElementRef;
  @ViewChild('recipient_hp3', {static: false}) recipientHp3: ElementRef;

  public item: any;

  public daumAddressOptions = {
    class: ['btn', 'overlap-btn', 'h33', 'f12']
  };

  public form: FormGroup;
  public formErrors = {};

  /*******************************************************************************
    설  명 : 빌드폼 생성
  *******************************************************************************/
  buildForm(): void {
    this.form = this.formBuilder.group({
      seq: ['', []],
      recipient_name: ['', [Validators.required]],
      recipient_hp1: [ '', [Validators.required, Validators.pattern('^\d{0,1}[0-9]{2,4}$')] ],
      recipient_hp2: [ '', [Validators.required, Validators.pattern('^\d{0,1}[0-9]{3,4}$')] ],
      recipient_hp3: [ '', [Validators.required, Validators.pattern('^\d{0,1}[0-9]{4}$')] ],
      zipcode: ['', [Validators.required]],
      address: ['', [Validators.required]],
      address_detail: ['', [Validators.required]],
      isDefault: ['', []],
      sido: ['', []],
      sigungu: ['', []],
    });

    this.form.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors(this.form, this.formErrors);
    });
  };
  
  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private utilService: UtilService,
    private toastrService: ToastrService,
    private sMemberService: SMemberService,
  ) {
    this.buildForm();
  }

  /*******************************************************************************
    설  명 : 데이터 로딩 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit(): void {
    if( this.item !== '' ) {
      this.item.isDefault = parseInt(this.item.isDefault);

      // hp 분리
      let hp = this.item.recipient_hp.split('-');
      this.item.recipient_hp1 = hp[0];
      this.item.recipient_hp2 = hp[1];
      this.item.recipient_hp3 = hp[2];

      this.form.patchValue( this.item );
    }
  }

  /*******************************************************************************
    설  명 : 다음 우편번호찾기 API
  *******************************************************************************/ 
  setDaumAddressApi(data: any) {
    this.form.patchValue({
      zipcode: data.zip,
      address: data.addr,
      sido: data.sido,
      sigungu: data.sigungu
    });
  }

  /*******************************************************************************
    설  명 : 배송지 저장
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setMemberAddressSave() {
    this.utilService.makeFormDirtyAndUpdateErrors(this.form, this.formErrors);

    if (this.form.valid) {
      this.sMemberService.setMemberAddressSave(this.form).then( response => {
        if ( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, '');
          
          this.activeModal.close(true);
        } else {
          this.toastrService.error( response.ResultMessage, '');
        }
      });
    } else {
      this.toastrService.error('필수 입력항목을 확인하시기 바랍니다.', '');
    }
  }

  /*******************************************************************************
    설  명 : 창닫기
  *******************************************************************************/ 
  setDismiss() {
    this.activeModal.dismiss();
  }
  
  /*******************************************************************************
    설  명 : 연락처 입력 시 자동 이동
  *******************************************************************************/
  hpKeyUp( event: any ) {
    let id: any = event.target.id;
    let value: any = event.target.value;

    if( id == 'recipient_hp1' && value == '010' || value.length == '4' ) {
      this.recipientHp2.nativeElement.focus();
    }

    if( id == 'recipient_hp2' && value.length == '4' ) {
      this.recipientHp3.nativeElement.focus();
    }
  }
  
}
