import { AbstractControl } from '@angular/forms';

export class CustomValidator {
  // Number only validation
  static numeric(control: AbstractControl) {
    let val = control.value;

    if (val === null || val === '') return null;

    if (!val.toString().match(/^[0-9]+(.?[0-9]+)?$/)) return { 'numeric': true };

    return null;
  }

  // Number only validation
  static amt(control: AbstractControl) {
    let val = control.value;

    if (val === null || val === '') return null;

    if (!val.toString().match(/^[0-9]+(.,?[0-9]+)?$/)) return { 'amt': true };

    return null;
  }

  // Alpha only validation
  static alpha(control: AbstractControl) {
    let val = control.value;

    if (val === null || val === '') return null;

    if (!val.toString().match(/^[a-zA-Z]+(.?[a-zA-Z]+)?$/)) return { 'alpha': true };

    return null;
  }

  // Alpha 대문자 only validation
  static alphaUpper(control: AbstractControl) {
    let val = control.value;

    if (val === null || val === '') return null;

    if (!val.toString().match(/^[A-Z]+(.?[A-Z]+)?$/)) return { 'alphaUpper': true };

    return null;
  }

  // Alpha 소문자 only validation
  static alphaLower(control: AbstractControl) {
    let val = control.value;

    if (val === null || val === '') return null;

    if (!val.toString().match(/^[a-z]+(.?[a-z]+)?$/)) return { 'alphaLower': true };

    return null;
  }

  // phone only validation
  static phone(control: AbstractControl) {
    let val = control.value;

    if (val === null || val === '') return null;

    if (!val.toString().match(/^[0-9\-]+(.?[0-9\-]+)?$/)) return { 'phone': true };

    return null;
  }

}