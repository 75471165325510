/*******************************************************************************
  설  명 : 마이페이지 - 관리자 답변 모달
  생성일 : 2021-07-22
  생성자 : 김종현
*******************************************************************************/
import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-mypage-cancel-reason',
  templateUrl: './mypage-cancel-reason.component.html',
  styleUrls: ['./mypage-cancel-reason.component.scss']
})
export class MypageCancelReasonComponent implements OnInit {
  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  @Input() cancelData:any;

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    public activeModal: NgbActiveModal,

  ) { }

  /*******************************************************************************
    설  명 : 데이터 로딩 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit(): void {
  }

}
