<div class="modal-header">
  <h4 class="modal-title fl">배송지관리</h4>
  <button type="button" class="close" (click)="setDismiss()">&times;</button>
</div>

<div class="modal-body">
  <form [formGroup]="form">
    <div class="title">배송지 추가/수정</div>
    <table class="info-table">
    <colgroup>
      <col class="col1">
      <col>
    </colgroup>  
    <tbody>
    <tr>
      <th><label for="recipient_name">받는 사람 이름 <i class="material-icons cred f11">star</i></label></th>
      <td><input type="text" formControlName="recipient_name" [ngClass]="{formErrors: formErrors['recipient_name']}" /></td>        
    </tr>    
    <tr>
      <th><label for="recipient_hp1">연락처 <i class="material-icons cred f11">star</i></label></th>
      <td>
        <input type="text" [ngClass]="{formErrors: formErrors['recipient_hp1'], 'phone': true}" maxlength="4" id="recipient_hp1" formControlName="recipient_hp1" (keyup)="hpKeyUp($event)" />
        <span class="m-2">-</span>
        <input type="text" [ngClass]="{formErrors: formErrors['recipient_hp2'], 'phone': true}" maxlength="4" id="recipient_hp2" formControlName="recipient_hp2" #recipient_hp2 (keyup)="hpKeyUp($event)" />
        <span class="m-2">-</span>
        <input type="text" [ngClass]="{formErrors: formErrors['recipient_hp3'], 'phone': true}" maxlength="4" id="recipient_hp3" formControlName="recipient_hp3" #recipient_hp3 />
      </td>
    </tr> 
    <tr>
      <th class="vt"><label for="address_detail">주소 <i class="material-icons cred f11">star</i></label></th>
      <td>
        <input type="text" class="zipno readonly" readonly formControlName="zipcode" [ngClass]="{formErrors: formErrors['zipcode']}" />
        <btn-daum-address (result)="setDaumAddressApi($event)" [options]="daumAddressOptions"></btn-daum-address>
        <div class="addr">
          <input type="text" class="readonly" readonly formControlName="address" [ngClass]="{formErrors: formErrors['address']}" />
          <input type="text" formControlName="address_detail" />
        </div>
      </td>
    </tr> 
    <tr>
      <th><label>기본배송지</label></th>
      <td><label class="check"><input type="checkbox" formControlName="isDefault" [checked]="form.controls.isDefault.value">기본 배송지로 설정</label></td>
    </tr>
    </tbody>
    </table>  
   
    <div class="btn-area">
      <button type="button" class="btn1" (click)="setDismiss()">취소</button>
      <button type="button" class="btn1 red" (click)="setMemberAddressSave()">확인</button>
    </div>
  </form>
</div>



