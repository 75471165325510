/*******************************************************************************
  설  명 : 마이페이지 - 구매내역/배송조회 구매확정 모달
  생성일 : 2020-09-10
  생성자 : 송영석
*******************************************************************************/
import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

import { SOrderService } from '@app/service/order.service';
import { AuthService } from '@app/service/auth.service';
import { SmsService } from '@app/service/sms.service';
@Component({
  selector: 'app-mypage-received',
  templateUrl: './mypage-received.component.html',
  styleUrls: ['./mypage-received.component.scss']
})
export class MypageReceivedComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  @Input() rowData: any;
  @Input() memberOrder: any;
  @Input() hp: any;

  public isLogIn: boolean;
  isLogin$: Observable<boolean>; // 로그인여부확인

  public memberInfo: any = {};
  public seq:any = [];
  public order_seq = 0;

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private authService: AuthService,
    public activeModal: NgbActiveModal,
    private sOrderService: SOrderService,
    private toastrService: ToastrService,
    private smsService: SmsService,
  ) { 
    // 로그인 여부 가져오기
    this.isLogin$ = this.authService.isLoggedIn;

    this.isLogin$.subscribe( login => {
      this.isLogIn = login;
    });
  }

  /*******************************************************************************
    설  명 : 데이터 로딩 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit(): void {
    this.authService.getLoginInfo.subscribe(data => {
      this.memberInfo = data;
    });

    this.order_seq = this.rowData.seq;
  }

  /*******************************************************************************
    설  명 : 구매확정
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  submit() {
    this.sOrderService.setOrderPurchaseConfirm( this.order_seq ).then( response => {
      if ( response.ResultCode ) {
        this.toastrService.success(response.ResultMessage);
        this.activeModal.close(true);
      } else {
        this.toastrService.error(response.ResultMessage);
      }
    });
  }

}
