<ng-template #notLoggedInInfo></ng-template>

<div class="header pc-view">
  <div class="header1 clearfix">
    <div class="nav-category" [ngClass]="{open:categoryView}" (click)="categoryView = !categoryView;">
      <div class="bar">
        <div></div>
        <div></div>
        <div></div>
      </div>
      <div class="txt">전체</div>
    </div>
    <div class="logo">
      <a routerLink="/"><img src="/assets/images/logo.png" alt="바이크마트 로고"></a>
    </div>
    <div class="search-wrap">
      <div class="search-box">
        <input type="text" class="input-box" placeholder="" name="searchText" [(ngModel)]="searchText" (keypress)="searchList($event)" autocomplete="off" />
        <button type="button" class="search-btn" (click)="goProductSearch()"><img src="/assets/images/icon_search.png" /></button>
	      <div class="search-list">
          <div class="lately">
  	        <ul>최근 검색어
              <li *ngIf="searchTextList.length < 1">최근 검색어 내역이 없습니다.</li>
  	          <li *ngFor="let item of searchTextList" (click)="searchText=item;goProductSearch();">{{item}}</li>
            </ul>
	        </div>
	        <div class="popular">
		        <ul>주간 인기검색어
              <li *ngIf="popularSearchText.length < 1">인기 검색 내역이 없습니다.</li>
              <li *ngFor="let item of popularSearchText; let i = index" (click)="searchText=item.text; goProductSearch();"><span>{{i + 1}}</span>{{item.text}}</li>
		        </ul>
		        <div class="update-date">{{popularUpdateDate}} 업데이트</div>
	        </div>
	      </div>
      </div>
    </div>
    <div class="top-menu top-menu1">
      <a routerLink="/cart"><p class="img"></p><p>장바구니</p><span>{{cartBadgeCnt | number}}</span></a>
    </div>
    <div class="top-menu top-menu2">
      <a routerLink="/customer/center"><p class="img"></p><p>고객센터</p></a>
    </div>
    <!-- <div class="top-menu top-menu3">
      <a routerLink="/product/list"><p class="img"></p><p>전기바이크</p></a>
    </div> -->
    <div class="top-menu top-menu4">
      <!-- <a href="https://bike.bikemart.co.kr/trade/free_sellbuy/sell_main.php" target="_blank"><p class="img"></p><p>바이크</p></a> -->
      <a href="https://bikeweb.bikemart.co.kr/" target="_blank"><p class="img"></p><p>바이크</p></a>
    </div>
    <a routerLink="/guide/membership/grade" class="grade-guide">회원등급 혜택안내<span>></span></a>
    <a routerLink="/company/contact" class="grade-guide">프랜차이즈 가맹문의<span>></span></a>
  </div>
  <div id="gnb" [ngClass]="{'gnb-project': isProject}">
    <div class="header2" *ngIf="isLogin$ | async as isLoggedIn; else notLoggedIn">
      <ul class="clearfix">
        <li><a class="cp" (click)="goBoard('notice')">공지사항</a></li>
        <li><a class="cp" (click)="setLogout()">로그아웃</a></li>
        <li><a routerLink="/mypage/dashboard">마이페이지</a></li>
        <li><a class="cp" (click)="goHoney365()">꿀득템존</a></li>
        <li><a routerLink="/event">이벤트</a></li>
      </ul>
    </div>
    <ng-template #notLoggedIn>
      <div class="header2">
        <ul class="clearfix">
          <li><a class="cp" (click)="goBoard('notice')">공지사항</a></li>
          <li><a routerLink="/member/login">로그인 / <br class="mb-view" />회원가입</a></li>
          <li><a routerLink="/mypage/dashboard">마이페이지</a></li>
          <li><a routerLink="/mypage/nonmember/form">비회원 <br class="mb-view" />주문조회</a></li>
          <li><a class="cp" (click)="goHoney365()">꿀득템존</a></li>
          <li><a routerLink="/event">이벤트</a></li>
        </ul>
      </div>
    </ng-template>
  </div>

  <div class="header3">
    <ul class="clearfix inner">
      <li><a routerLink="/product/list" [queryParams]="{categoryCode: '001001'}"><span></span>헬멧</a></li>
      <li><a routerLink="/product/list" [queryParams]="{categoryCode: '001003'}"><span></span>자켓</a></li>
      <li><a routerLink="/product/list" [queryParams]="{categoryCode: '001005'}"><span></span>팬츠</a></li>
      <li><a routerLink="/product/list" [queryParams]="{categoryCode: '001004'}"><span></span>부츠</a></li>
      <li><a routerLink="/product/list" [queryParams]="{categoryCode: '001006'}"><span></span>장갑</a></li>
      <li><a routerLink="/product/list" [queryParams]="{categoryCode: '001014'}"><span></span>블루투스</a></li>
      <li class="total-view" (click)="categoryView = !categoryView;"><span>전체보기 ></span></li>
    </ul>
  </div>

  <div class="category-menu" [ngClass]="{open:categoryView}">
    <div class="list brand">
      <div class="title">브랜드<p></p></div>
      <ul>
        <li *ngFor="let row of brandList">
          <a class="cp" (click)="goLink('/product/list', 'brand', row.seq );categoryView = !categoryView;">{{row.brand_name}}</a>
        </li>
      </ul>
    </div>
    <div class="list" *ngFor="let row of categoryList">
      <div class="title cp" (click)="goLink('/product/list', 'category', row.category_code );categoryView = !categoryView;">{{row.category_name}}<p></p></div>
      <ul>
        <li *ngFor="let row2 of row.children">
          <a class="cp" (click)="goLink('/product/list', 'category', row2.category_code );categoryView = !categoryView;">{{row2.category_name}}</a>
        </li>
      </ul>
    </div>
    <div class="name-tag"><span>오토바이 부품</span></div>
    <div class="list brand-parts">
      <div class="title">구매대행<p></p></div>
      <ul>
        <li *ngFor="let row of purchasingAgentList">
          <a class="cp" (click)="goLink('/product/list', 'brand', row.seq );categoryView = !categoryView;">{{row.brand_name}}</a>
        </li>
      </ul>
    </div>
    <div class="list" *ngFor="let row of bikePartsList">
      <div class="title cp" (click)="goLink('/product/list', 'category', row.category_code );categoryView = !categoryView;">{{row.category_name}}<p></p></div>
      <ul>
        <li *ngFor="let row2 of row.children">
          <a class="cp" (click)="goLink('/product/list', 'category', row2.category_code );categoryView = !categoryView;">{{row2.category_name}}</a>
        </li>
      </ul>
    </div>
  </div>
</div>

<!-- <div class="kakao">
  <a></a>
</div>  -->

<!-- 모바일 header -->
<div class="mheader mobile-view">
  <a routerLink="/guide/membership/grade" class="grade-guide">회원등급 혜택안내<span>></span></a>
  <div class="header1 clearfix">
    <div class="menu-open" (click)="menuOpen=true"></div>
    <div class="logo">
      <a routerLink="/"><img src="/assets/images/logo1.png?date=20210913" alt="바이크마트 로고"></a>
    </div>
    <a routerLink="/cart" class="cart"><span>{{cartBadgeCnt | number}}</span></a>
  </div>
  <div class="header2">
	  <div class="search-box">
      <input type="search" class="input-box" placeholder="바이크마트 .co.kr" name="searchText" [(ngModel)]="searchText" (keypress)="searchList($event)" />
      <button type="button" class="search-btn" (click)="goProductSearch()"></button>
	  </div>
  </div>

  <div class="menu" [ngClass]="{open:menuOpen}">
    <div class="menu-header">
      <div class="clearfix">
        <!-- <div class="user"><a routerLink="/member/login" class="login">로그인이 필요합니다.</a></div> -->
        <div class="user">
  				<a *ngIf="isLogin$ | async as isLoggedIn; else notLoggedIn1">{{memberInfo?.name}}님 환영합니다.
            &nbsp;&nbsp;<span (click)="setLogout()">로그아웃</span></a>
            <ng-template #notLoggedIn1>
              <a routerLink="/member/login" class="login">로그인/회원가입</a>
            </ng-template>
        </div>
        <button class="close-btn" (click)="menuOpen=false"></button>
      </div>
	    <div class="mileage">
	      <a routerLink="/mypage/mileage" (click)="menuOpen=false">
	        <p class="fl"><span class="red">MY</span>마일리지</p>
	        <p class="fr"><span class="red">{{remainedMileage | number}}</span>M</p>
	      </a>
	    </div>
    </div>
    <ul class="quick-menu clearfix">
      <li><a routerLink="/mypage/dashboard" (click)="menuOpen=false">나의정보</a></li>
      <li><a routerLink="/mypage/order" (click)="menuOpen=false">구매내역</a></li>
      <li><a class="cp" (click)="alertMessage('준비중입니다.', '쿠폰함')">쿠폰함</a></li>
      <li><a routerLink="/mypage/mileage" (click)="menuOpen=false">마일리지</a></li>
      <li><a routerLink="/cart" (click)="menuOpen=false">장바구니</a></li>
      <li><a routerLink="/mypage/favorite" (click)="menuOpen=false">찜리스트</a></li>
      <!-- <li><a href="https://bike.bikemart.co.kr/trade/free_sellbuy/sell_main.php" target="_blank">오토바이</a></li> -->
      <li><a href="https://bikeweb.bikemart.co.kr/" target="_blank">오토바이</a></li>
      <li><a routerLink="/customer/center" (click)="menuOpen=false">고객센터</a></li>
    </ul>
    <div class="event-menu"><a routerLink="/event" (click)="menuOpen=false">바마이벤트 보러가기</a></div>
    <ul class="category clearfix">
      <li [ngClass]="{active:mcategoryIndex==1}" (click)="mcategoryIndex=1">라이더용품</li>
      <li [ngClass]="{active:mcategoryIndex==2}" (click)="mcategoryIndex=2">오토바이 부품</li>
    </ul>
    <ul class="category-list clearfix" *ngIf="mcategoryIndex == 1">
      <ng-container *ngFor="let row of categoryList">
        <li>
          <a (click)="goLink('/product/list', 'category', row.category_code);menuOpen=false">
            <span class="img">
              <img [src]="getImageLink(row.category_name)">
            </span>{{row.category_name}}
          </a>
        </li>
      </ng-container>
    </ul>
    <ul class="category-list clearfix" *ngIf="mcategoryIndex == 2">
      <ng-container *ngFor="let row of bikePartsList">
        <li>
          <a (click)="goLink('/product/list', 'category', row.category_code);menuOpen=false">
            <span class="img">
              <img [src]="getImageLink(row.category_name)">
            </span>{{row.category_name}}
          </a>
        </li>
      </ng-container>
    </ul>
  </div>
</div>
