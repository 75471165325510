<div class="modal-header">
  <h4 class="modal-title fl">상품문의하기</h4>
  <button class="close" (click)="activeModal.dismiss()">&times;</button>
</div>


<div class="modal-body">
  <div class="guide">
	· 주민등록번호, 연락처 등의 정보는 개인정보 도용의 위험이 있으니 주의해 주시기 바랍니다.<br/>
	· 교환/환불 문의는 마이페이지 내 1:1문의를 이용해주세요.<br/>
	· 상품 문의 게시판을 통한 취소나 환불, 교환, 반품신청은 처리되지 않습니다.<br/>
	· 비방, 홍보글, 도배글, 개인정보가 포함된 글은 예고없이 삭제될 수 있습니다.    
  </div>

  <form [formGroup]="form">
    <textarea class="inputbox" placeholder="내용입력" [ngClass]="{formErrors: formErrors['comment']}" formControlName="comment"></textarea>
    <div *ngIf="formErrors['comment']" class="error-message">{{formErrors['comment']}}</div>
    <label><input type="checkbox" class="change-checkbox" formControlName="isSecret" (click)="setSecret($event)"><span class="new-checkbox"><i class="material-icons">check</i></span>비밀글로 문의하기</label>
    <div class="btn-area">
    <button class="btn1" (click)="activeModal.dismiss()">취소</button>
    <button class="btn1 red" (click)="submit()">확인</button>
    </div>
  </form>
</div>
