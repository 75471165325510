<div class="modal-header">
  <h4 class="modal-title fl">댓글 수정</h4>
  <button class="close" (click)="activeModal.dismiss()">&times;</button>
</div>

<form [formGroup]="form">
  <div class="modal-body">
    <div>
      <textarea formControlName="contents" style="width: calc(100%);" class="mb10" rows="5"	[ngClass]="{formErrors:formErrors['contents']}"></textarea>
      <span *ngIf="formErrors.contents" class="alert alert-danger">{{formErrors.contents}}</span>
    </div>   

    <div class="btn-area">
      <button class="btn1 mr10" (click)="activeModal.dismiss()">취소</button>
      <button class="btn1 red" (click)="updateComment()">수정</button>
    </div>
  </div>
</form>


