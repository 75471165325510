import { Component, OnInit, Input } from '@angular/core';
import {NgbActiveModal, NgbModal, ModalDismissReasons, NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';
import { config } from '@app/service/config.service';
import { Observable } from 'rxjs';
import { AuthService } from '@app/service/auth.service';


@Component({
  selector: 'app-mypage-order-statement',
  templateUrl: './mypage-order-statement.component.html',
  styleUrls: ['./mypage-order-statement.component.scss']
})
export class MypageOrderStatementComponent implements OnInit {

  @Input() orderInfo: any;
  @Input() detail: any;
  @Input() pay: any;

  public isLogIn: boolean;

  isLogin$: Observable<boolean>; // 로그인여부확인

  CONFIG_KEY = 'company';
  company_info = {
    biz_no : '',
    company_name : '',
    ceo_name : '',
    address : '',
    cs_tel1 : '',
    fax : '',
    biz_type : '',
    biz_category : ''
  }

  public baseURL = config.baseUrl;

  public sumList = {
    sum: 0,
    mileage: 0
  };

  public payDate: any = '';

  constructor(
    public activeModal: NgbActiveModal,
    private authService: AuthService
  ) {
    // 로그인 여부 가져오기
    this.isLogin$ = this.authService.isLoggedIn;
    
    this.isLogin$.subscribe( login => {
      this.isLogIn = login;
    });
  }

  ngOnInit(): void {
    this.orderAmt();

    // 최종입금일자 구하기
    let tmp: any = this.pay.filter((obj: any) => obj.app_gbn == 1);
    if( tmp.length > 1 ) {
      this.payDate = tmp.reduce(function(a, b) {
        return a.pay_date > b.pay_date ? a.pay_date : b.pay_date;
      });
    } else {
      this.payDate = tmp[0].pay_date;
    }
  }

  orderAmt() {
    for( let sum of this.detail ) {
      this.sumList.sum += parseInt(sum.order_amt);
    }

    for( let mileage of this.detail ) {
      this.sumList.mileage += parseInt(mileage.mileage);
    }
  }

  print() {
    let mode = 'portrait';

    const Maindiv = document.createElement('div');
    Maindiv.style.cssText = 'width: 100%; position: absolute; z-index: 9999; top: 0; left: 0; background-color: #fff; padding: 10px;';
    
    const item = document.getElementById('print_area').cloneNode(true);
    Maindiv.appendChild(item);
    const app_root = document.querySelector<HTMLElement>('app-root'); // as HTMLCollectionOf<HTMLElement>
    app_root.style.cssText = "display: none;";
    const modal_dialog = document.querySelector<HTMLElement>('.modal-dialog');
    modal_dialog.style.cssText = "display: none;";

    document.body.prepend(Maindiv);
    window.onafterprint = () => {
      document.body.removeChild(Maindiv);
      app_root.style.cssText = "display: initial;";
      modal_dialog.style.cssText = "display: flex;";
    }

    var head = document.head || document.getElementsByTagName('head')[0];
    var style = document.createElement('style');
    style.type = 'text/css';
    style.media = 'print';
    style.appendChild(document.createTextNode(`@page { size: ${mode}; }`));
    head.appendChild(style);
    window.print();
  }
}
