/*******************************************************************************
  설  명 : 마이페이지 - 대시보드
  생성일 : 2020-09-10
  생성자 : 송영석
*******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';

import { AuthService } from '@app/service/auth.service';
import { SOrderService } from '@app/service/order.service';
import { SBoardService } from '@app/service/board.service';
import { SProductService } from '@app/service/product.service';
import { SEventService } from '@app/service/event.service';
import { SmsService } from '@app/service/sms.service';

import { MypageReviewAddComponent } from '@page/mypage/mypage-review-add/mypage-review-add.component';
import { MypageNotReceivedComponent } from '@page/mypage/mypage-not-received/mypage-not-received.component';
import { MypageReceivedComponent } from '@page/mypage/mypage-received/mypage-received.component';
import { MypageExchangeComponent } from '@page/mypage/mypage-exchange/mypage-exchange.component';
import { MypageReturnComponent } from '@page/mypage/mypage-return/mypage-return.component';
import { AddComponent } from './add/add.component';

const options: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  size: '480',
  centered: true,
  windowClass:'modal-fadeInDown'
};

const options1: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  size: 'xl',
  centered: true,
  windowClass:'modal-fadeInDown'
};

@Component({
  selector: 'app-mypage',
  templateUrl: './mypage.component.html',
  styleUrls: ['./mypage.component.scss']
})
export class MypageComponent implements OnInit {
  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  public isLogIn: boolean;
  public isLogin$: Observable<boolean>; // 로그인여부확인

  public boardInfo: any = {};
  public memberInfo: any = {};
  public orderList: any = [];
  public keepList: any = [];
  public eventList: any = [];
  public memberOrder: any = true;
  public orderInfo: any = {};

  public params: any = {
    type: 'list',
    board_id: 'question',
    group_id: '전체',
    row: 10,
    no: 1,
    group: 'subject',
    text: ''
  };

  public search: any = {

  };

  public linecnt: any = [];
  public groupTab: any = [];

  public boardQuestionListLimit = [];


  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private router: Router,
    private modalService: NgbModal,
    private authService: AuthService,
    private activatedRoute: ActivatedRoute,
    private sOrderService: SOrderService,
    private sBoardService: SBoardService,
    private sProductService: SProductService,
    private sEventService: SEventService,
    private toastrService: ToastrService,
    private smsService: SmsService,
  ) {
    // 로그인 여부 가져오기
    this.isLogin$ = this.authService.isLoggedIn;

    this.isLogin$.subscribe( login => {
      this.isLogIn = login;
    }).unsubscribe();
  }

  /*******************************************************************************
    설  명 : 데이터 로딩 처리 수정자:2/19 김종현
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit(): void {
    this.authService.getLoginInfo.subscribe( data => {
      this.memberInfo = data;
    }).unsubscribe();
    
    this.getRecentOrderList();
    this.getBoardQuestionList();
    this.getBoardInfoQuestionGroupTab();
    this.getKeepList();
    this.getEvent();
  }

  /*******************************************************************************
    설  명 : 최근 주문내역 불러오기(최근 주문 3건, 1개월이내)
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getRecentOrderList() {
    this.sOrderService.getRecentOrderList().then( response => {
      if ( response.ResultCode ) {
        this.orderList = response.data;
      } else {
        this.toastrService.error(response.ResultMessage);
      }
    });
  }

  /*******************************************************************************
    설  명 : 페이지로 이동 수정자: 수정자: 2/24 김종현
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  goDetail( orderSeq ) {
    this.router.navigate(
    ['/mypage/order/detail'],
    {
      relativeTo: this.activatedRoute,
      queryParams: { orderSeq: orderSeq },
      queryParamsHandling: 'merge', // remove to replace all query params by provided
    });
  }

  /*******************************************************************************
    설  명 : 공지사항 리스트 개수 limit 불러오기 수정자:2/19 김종현
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getBoardQuestionList() {
    let params: any = {
      type: 'list',
      board_id: 'question',
      group_id: '전체',
      row: 5,
      no: 1,
      group: 'subject',
      text: '',
      board_seq: ''
    }

    this.sBoardService.getBoardList( params ).then( response => {
      if ( response.ResultCode ) {
        this.boardQuestionListLimit = response.data;
      } else {
        this.toastrService.error(response.ResultMessage);
      }
    });
  }

  /*******************************************************************************
    설  명 : 찜 리스트 불러오기 수정자:2/24 김종현
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getKeepList() {
    this.sProductService.getKeepProductList().then( response => {

      if( response.ResultCode ) {
        this.keepList = response.data;
      } else {
        this.toastrService.error(response.ResultMessage);
      }
    });
  }

  /*******************************************************************************
    설  명 : 상품 상세보기로 가기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  goProduct(details) {
    let seq = details.product[0].product_seq;
    this.router.navigate(
      ['/product/info'],
      {
        relativeTo: this.activatedRoute,
        queryParams: {productSeq: seq},
        queryParamsHandling: 'merge', // remove to replace all query params by provided
      });
  }

  /*******************************************************************************
    설  명 : 찜 상품 상세보기로 가기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  goKeepProduct(seq) {
    this.router.navigate(
      ['/product/info'],
      {
        relativeTo: this.activatedRoute,
        queryParams: {productSeq: seq},
        queryParamsHandling: 'merge', // remove to replace all query params by provided
      }
    );
  }

  /*******************************************************************************
    설  명 : 진행 중인 이벤트 리스트 불러오기 수정자:2/24 김종현
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getEvent() {
    this.sEventService.getEventBannerList().then( response => {
      if( response.ResultCode ) {
        this.eventList = response.data;
      } else {
        this.toastrService.error(response.ResultMessage);
      }
    });
  }

  /*******************************************************************************
    설  명 : 상세보기 페이지 변동없이 내려보기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  viewDown( row: any ) {
    let child = $("#child_"+ row.board_seq);

    if( child.length < 1 ) {
      let node = '<tr id="child_'+ row.board_seq +'" class="viewdown"><td colspan="6" style="padding: 30px 0px 40px 0px;line-height: 150%;" class="tl">'+ row.contents_nl2br +'</td></tr>';
      $("#tr_" + row.board_seq).after( node );
    } else {
      $("#child_" + row.board_seq).remove();
    }
  }

  /*******************************************************************************
    설  명 : 1:1 문의 게시판 그룹탭 나누기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getBoardInfoQuestionGroupTab( ) {
    this.sBoardService.getBoardInfoId( 'question' ).then( response => {
      this.boardInfo = response.data;
      this.boardInfo.group = '전체|' + this.boardInfo.group;
      this.groupTab = this.boardInfo.group.split('|');
    });
  }

  /*******************************************************************************
    설  명 : 게시글 수정
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  modify( row: any ) {
    const modalRef = this.modalService.open(AddComponent, options);

    modalRef.componentInstance.boardId = 'question';
    modalRef.componentInstance.groupTab = this.groupTab;
    modalRef.componentInstance.rowData = row;

    modalRef.result.then((result) => {
      let child = $("#child_"+ row.board_seq);
      if( child.length > 0 ) {
        $("#child_" + row.board_seq).remove();
      }

    }, (reason) => {
    });
  }

  /*******************************************************************************
    설  명 : 게시글 삭제
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  delete( row: any ) {
    if( confirm("게시글을 삭제하시겠습니까?") ) {
      this.sBoardService.deleteBoard( row.board_id, row.board_seq ).then( response => {
        if( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, '' );

          this.router.navigate(
          ['/mypage/dashboard'],
          {
            relativeTo: this.activatedRoute,
            // queryParams: this.params,
            queryParamsHandling: '', // remove to replace all query params by provided
          });

        } else {
          this.toastrService.error( response.ResultMessage, '' );
        }
      });
    }
    location.href = location.href;

    //event.stopPropagation();
  }

  /*******************************************************************************
    설  명 : 주문취소
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/  
  cancel( row:any ) {
    if ( confirm('선택하신 주문을 취소하시겠습니까?') ) {
      this.sOrderService.setOrderCancel( row.seq ).then( response => {
        if ( response.ResultCode ) {
          this.toastrService.success(response.ResultMessage);
          this.getRecentOrderList();
        } else {
          this.toastrService.error(response.ResultMessage);
        }
      });
    }
  } 

  /*******************************************************************************
    설  명 : 구매후기작성 수정자 : 3/3 김종현
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/  
  review( row:any ) {
    const modalRef = this.modalService.open(MypageReviewAddComponent, options);

    modalRef.componentInstance.rowData = row;
    modalRef.componentInstance.memberOrder = this.memberOrder;

    modalRef.result.then((result) => {
      this.getRecentOrderList();
    }, (reason) => {
    });  
  }   
  
  /*******************************************************************************
    설  명 : 미수취신고
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/  
  notReceived( row: any ) {
    const modalRef = this.modalService.open(MypageNotReceivedComponent, options);

    modalRef.componentInstance.rowData = row;
    modalRef.componentInstance.memberOrder = this.memberOrder;

    modalRef.result.then((result) => {
      this.getRecentOrderList();
    }, (reason) => {
    });  
  }

  /*******************************************************************************
    설  명 : 수취확인
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  received( row: any ) {
    if( confirm('수취확인 하시겠습니까?\n\n반드시 실수령 후에 수취확인을 눌러주세요.') ) {
      this.sOrderService.setOrderReceived( row.seq ).then( response => {
        if ( response.ResultCode ) {
          this.toastrService.success(response.ResultMessage);

          this.getRecentOrderList();
        } else {
          this.toastrService.error(response.ResultMessage);
        }
      });
    }
  }
  
  /*******************************************************************************
    설  명 : 구매확정
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/  
  purchaseConfirm( row: any ) {
    const modalRef = this.modalService.open(MypageReceivedComponent, options);

    modalRef.componentInstance.rowData = row;
    modalRef.componentInstance.memberOrder = this.memberOrder;

    modalRef.result.then((result) => {
      this.getRecentOrderList();
    }, (reason) => {
    });  
  } 
  
  /*******************************************************************************
    설  명 : 교환신청
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/  
  exchange( row: any, i ) {
    let qtyNum = 0;
    row.detail.map( rows => {
      rows.product.map ( rows1 => {
        qtyNum += rows1.qty - rows1.used_req_qty;
      });
    });

    if ( qtyNum < 1 ) {
      this.toastrService.error('교환 가능한 수량이 없습니다.');
    } else {
      const modalRef = this.modalService.open(MypageExchangeComponent, options1);

      this.orderInfo.o_name = this.orderList[i].o_name;
      this.orderInfo.o_hp = this.orderList[i].o_hp;
      this.orderInfo.r_zipcode = this.orderList[i].r_zipcode;
      this.orderInfo.r_address = this.orderList[i].r_address;
      this.orderInfo.r_address_detail = this.orderList[i].r_address_detail;

      modalRef.componentInstance.rowData = row;
      modalRef.componentInstance.orderData = this.orderInfo;
      modalRef.componentInstance.memberOrder = this.memberOrder;

      modalRef.result.then((result) => {
        this.getRecentOrderList();
      }, (reason) => {
      }); 
    } 
  }
  
  
  /*******************************************************************************
    설  명 : 반품신청
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/  
  return( row: any ) {
    let qtyNum = 0;
    row.detail.map( rows => {
      rows.product.map ( rows1 => {
        qtyNum += rows1.qty - rows1.used_req_qty;
      });
    });

    if ( qtyNum < 1 ) {
      this.toastrService.error('반품 가능한 수량이 없습니다.');
    } else {
      const modalRef = this.modalService.open(MypageReturnComponent, options1);

      modalRef.componentInstance.rowData = row;
      modalRef.componentInstance.memberOrder = this.memberOrder;

      modalRef.result.then((result) => {
        this.getRecentOrderList();
      }, (reason) => {
      });  
    }
  } 

  /*******************************************************************************
    설  명 : 상세 이벤트 페이지 링크
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/  
  goEachEvent( row: any ) {
    this.router.navigate(
      ['/event'],
      {
        relativeTo: this.activatedRoute,
        queryParams: {seq: row.seq},
        queryParamsHandling: '', // remove to replace all query params by provided
    });
  }

  /*******************************************************************************
    설  명 : 1:1 문의 리스트 링크
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/  
  goList() {
    this.router.navigate(
      ['/mypage/myqna'],
      {
        relativeTo: this.activatedRoute,
        queryParamsHandling: '', // remove to replace all query params by provided
      }
    );
  }

  /*******************************************************************************
    설  명 : 총 결제금액 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getPayAmt( row: any ) {
    let sum_total_amt: any = row['detail']?.reduce((a,c) => a + parseInt(c.sum_total_amt || 0), 0);
    sum_total_amt += parseInt(row.delivery_price || 0);
    return ( sum_total_amt > 0 ) ? sum_total_amt : 0;
  }
    
}
