/*******************************************************************************
  설  명 : 아이디 찾기 - 실패
  생성일 : 2020-09-10
  생성자 : 송영석
*******************************************************************************/
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-member-find-id-fail',
  templateUrl: './member-find-id-fail.component.html',
  styleUrls: ['./member-find-id-fail.component.scss']
})
export class MemberFindIdFailComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor() {
  }

  /*******************************************************************************
    설  명 : 데이터 로딩 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit(): void {
  }

}
