/*******************************************************************************
  설  명 : 상품 리스트
  생성일 : 2020-09-10
  생성자 : 송영석
*******************************************************************************/
import { Component, OnInit } from '@angular/core';
// import { NgbModule, NgbModal, NgbActiveModal, NgbModalOptions, NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { SProductService } from '@app/service/product.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

import { SEOService } from '@app/service/seo.service';
import { DeviceDetectorService } from 'ngx-device-detector'

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss']
})
export class ProductComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  page = 1;
  menuSelected = 0; // 카테고리(메뉴) 선택
  brandSelected = 0; // 카테고리(브랜드) 선택
  sizeSelected = 0; // 카테고리(사이즈) 선택
  colorSelected = 0; // 카테고리(색상) 선택
  moreView = 0; // 카테고리 더보기 (모바일)
  viewType = 1; // 상품조회 type

  root_category = {code: '', name: ''}; //페이지생성시 대분류 초기값
  root_brand = '';
  sub_category = ''; //사용자가 선택한 카테고리 하위분류 한글명 (html view 용도)
  product_list = []; //DB에서 받은 상품리스트
  product_total = 0; //DB에서 받은 전체상품갯수
  is_search: boolean = false;
  category_list = []; //DB에서 받은 카테고리 목록
  brand_list = []; //DB에서 받은 브랜드 목록
  brand_category_list = []; //DB에서 받은 브랜드 카테고리 목록
  size_list = []; //DB에서 받은 사이즈 목록
  color_list = []; //DB에서 받은 색상 목록

  search_mode = 0; //0: 모드 입력 없음, 1: 카테고리 탐색 모드, 2: 키워드 검색 모드, 3: 브랜드 검색 모드

  searchParams = {
    pageNo: 1,
    pageRow: 100,
    searchText: '',
    category_code: this.root_category.code,
    brand_seq: [],
    size_seq: [],
    color_seq: [],
    sort: 1
  }; //DB로 보내는 상품옵션 데이터

  choice_list = []; //선택한 상품옵션 스택

  pageMaxSize = 10;
  
  linkTarget = this.deviceDetectorService.isMobile() ? "_self" : "_blank"

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private router: Router,
    private sProductService: SProductService,
    private activatedRoute : ActivatedRoute,
    private toastrService: ToastrService,
    private seoService: SEOService,
    private deviceDetectorService: DeviceDetectorService,
  ) {
  }

  /*******************************************************************************
    설  명 : 데이터 로딩 처리
  *******************************************************************************/
  ngOnInit(): void {
    if( window.innerWidth <= 425 ) this.pageMaxSize = 5;

    this.activatedRoute.queryParams.subscribe(async query => {
      if(query.searchText) {
        this.searchParams.searchText = query.searchText;
        this.root_category.code = this.searchParams.category_code = '';
        this.category_list = [];
        this.root_category.name = '';
        this.search_mode = 2;

        // 검색엔진 SEO 타이틀 및 메타 설정
        let title = '오토바이 ' + this.searchParams.searchText + ' 검색 결과';
        this.seoService.updateTitle( title );
        this.seoService.updateDescription( title );
        this.seoService.updateSubject( title );

        this.setSearchTextSave();
      } else {
        if(query.categoryCode) {
          // this.root_category.code = this.searchParams.category_code = query.categoryCode;
          this.searchParams.searchText = '';
          this.search_mode = 1;
        } else if(query.brandSeq) {
          this.root_brand = query.brandSeq;
          this.searchParams.searchText = '';
          this.search_mode = 3;
        } else {
          this.root_category.code = this.searchParams.category_code = this.searchParams.searchText = '000';
          this.search_mode = 0;
        }

        const setQueryParams = queryParams => query[queryParams] ? query[queryParams].constructor.name == 'Array' ? query[queryParams] : [query[queryParams]] : []
        this.root_category.code = query.categoryCode ? query.categoryCode.slice(0, 6) : ''
        this.searchParams.category_code = query.categoryCode || ''
        this.searchParams.brand_seq = setQueryParams('brandSeq')
        this.searchParams.size_seq = setQueryParams('sizeSeq')
        this.searchParams.color_seq = setQueryParams('colorSeq')
      }

      this.searchParams.sort = query.sort || 1

      // this.page_reset();
      await this.getCategoryList();
      await this.getBrandList();
      await this.getSizeList();
      await this.getColorList();
      await this.getProductList();

      const category = this.category_list.find(el => el['category_code'] == this.searchParams.category_code);

      this.choice_list = [{
        key: "category", 
        value: category?.category_name
      }];

      this.searchParams.brand_seq.forEach(el => {
        const brnadName = this.brand_list.find(_el => _el['brand_seq'] == el);
        this.choice_list.push({
          key: "brand", 
          value: brnadName?.brand_name, 
          brand_name: brnadName?.brand_name, 
          brand_seq: el
        })
      });

      this.searchParams.size_seq.forEach(el => {
        const sizeName = this.size_list.find(_el => _el['seq'] == el);
        this.choice_list.push({
          key: "size", 
          value: sizeName?.size_name, 
          size_name: sizeName?.size_name, 
          seq: el
        })
      });

      this.searchParams.color_seq.forEach(el => {
        const colorPick = this.color_list.find(_el => _el['seq'] == el);
        this.choice_list.push({
          key: "color", 
          value: colorPick?.color_name, 
          color_ename: colorPick?.color_ename, 
          color_name: colorPick?.color_name, 
          color: colorPick?.color, 
          seq: el
        })
      });

    });
  }

  /*******************************************************************************
    설  명 : 전체 리셋
  *******************************************************************************/
  async page_reset() {
    await this.select_category(0, '');
    await this.select_brand(-1, '');
    await this.select_size(-1, '');
    await this.select_color(-1, '');
    
    if(this.search_mode == 1) {
      await this.getCategoryList();
      await this.getBrandList();
      await this.getSizeList();
      await this.getColorList();
    }
    if(this.search_mode == 3) {
      await this.getBrandList();
      await this.getSizeList();
      await this.getColorList();
    }
    if(this.search_mode) {
      await this.getProductList();
    }
  }

  /*******************************************************************************
    설  명 : 상품 리스트 가져오기
  *******************************************************************************/
  getProductList() {
    if(!this.searchParams.category_code) {this.searchParams.category_code = this.root_category.code;}
    this.product_list = [];
    this.product_total = 0;
    this.is_search = false;

    this.sProductService.getProductList( this.searchParams ).then( response => {
      // console.log('product', response)
      if( response.ResultCode ) {
        this.product_list = response.data;
        this.product_total = parseInt( response.total );

        if(this.searchParams.pageNo != 1) window.scrollTo(0, 0);
      } else {
        this.toastrService.error(response.ResultMessage, '상품 리스트 가져오기');
      }

      this.is_search = true;
    });
  }

  /*******************************************************************************
    설  명 : 카테고리 가져오기
  *******************************************************************************/
  getCategoryList() {
    this.category_list = [];
    this.brand_category_list = [];
    this.root_category.name = '';
    // this.root_brand = '';
    if( !this.root_category.code && this.root_brand != '' ) {
      this.sProductService.getBrandCategoryList( this.root_brand ).then( response => {
        if( response.ResultCode ) {
          // console.log('category', response)
          this.brand_category_list = response.data;

          // 검색엔진 SEO 타이틀 및 메타 설정
          let title = '오토바이 ' + response.data[0]?.brand_name;
          this.seoService.updateTitle( title );
          this.seoService.updateDescription( title );
          this.seoService.updateSubject( title );
        }
      });
    } else if( this.root_category.code ) {
      this.sProductService.getCategoryList( this.root_category.code ).then( response => {
        if( response.ResultCode ) {
          // console.log('category', response)
          this.category_list = response.data;
          this.root_category.name = response.parent[0]?.category_name;

          // 검색엔진 SEO 타이틀 및 메타 설정
          let title = '오토바이 ' + this.root_category.name;
          this.seoService.updateTitle( title );
          this.seoService.updateDescription( title );
          this.seoService.updateSubject( title );
        }
      });
    }
  }

  /*******************************************************************************
    설  명 : 브랜드 가져오기
  *******************************************************************************/
  async getBrandList() {
    this.brand_list = [];
    await this.sProductService.getBrandList( this.searchParams.category_code || this.root_category.code ).then( response => {
      if( response.ResultCode ) {
        // console.log('brand', response)
        this.brand_list = response.data;

        if( this.root_brand ) {
          const brand = this.brand_list.filter( item => item.brand_seq === this.root_brand );
          if( brand.length > 0 ) {
            this.searchParams.brand_seq.push(brand[0].brand_seq);
            this.choice_list.push({
              key: "brand", 
              value: brand[0].brand_name, 
              brand_name: brand[0].brand_name, 
              brand_seq: brand[0].brand_seq
            });
          }
        }

        // 브랜드 목록을 메타 키워드에 동적으로 반영
        const brandName = [];
        this.brand_list.forEach(item => {
          brandName.push(item.brand_name);
        });
        this.seoService.updateKeywords( brandName.join(', ') );
      }
    });
  }

  /*******************************************************************************
    설  명 : 사이즈 가져오기
  *******************************************************************************/
  async getSizeList() {
    this.size_list = [];
    await this.sProductService.getSizeList( this.searchParams.category_code || this.root_category.code ).then( response => {
      if( response.ResultCode ) {
        // console.log('size', response)
        this.size_list = response.data;
      }
    });
  }

  /*******************************************************************************
    설  명 : 색상 가져오기
  *******************************************************************************/
  async getColorList() {
    this.color_list = [];
    await this.sProductService.getColorList().then( response => {
      if( response.ResultCode ) {
        // console.log('color', response)
        this.color_list = response.data;
      }
    });
  }

  /*******************************************************************************
    설  명 : 검색어 저장하기
  *******************************************************************************/
  setSearchTextSave() {
    this.sProductService.setSearchTextSave( this.searchParams.searchText ).then( response => {
      if( response.ResultCode ) {
        //this.toastrService.success(response.ResultMessage, '');
      } else {
        this.toastrService.error(response.ResultMessage, '검색어 저장');
      }
    });
  }
  
  /*******************************************************************************
    설  명 : 카테고리 선택하였을 때
  *******************************************************************************/
  select_category(idx, item) {
    this.searchParams.category_code = item.category_code;
    this.searchParams.pageNo = 1;
    this.searchParams.searchText = '';
    
    //카테고리를 제외한 모든 선택옵션 해제
    this.searchParams.brand_seq = [];
    this.searchParams.size_seq = [];
    this.searchParams.color_seq = [];

    if(this.menuSelected == idx || !idx) {
      this.menuSelected = 0; 
      this.searchParams.category_code = this.root_category.code;
      this.sub_category = '';
      this.choice_list = [];
    } else {
      this.menuSelected = idx;
      this.sub_category = item.category_name;
      this.choice_list = [{
        key: "category", 
        value: item.category_name
      }];
    }

    // 검색엔진 SEO 타이틀 및 메타 설정
    // let title = '오토바이 ' + this.root_category.name + ' ' + this.sub_category;
    // this.seoService.updateTitle( title );
    // this.seoService.updateDescription( title );
    // this.seoService.updateSubject( title );
    
    this.setProductListPage();
  }

  /*******************************************************************************
    설  명 : 카테고리 선택하였을 때
  *******************************************************************************/
  select_brand_category(idx, item) {
    this.searchParams.category_code = item.category_code;
    this.searchParams.pageNo = 1;
    this.searchParams.searchText = '';
    
    //카테고리를 제외한 모든 선택옵션 해제
    // this.searchParams.brand_seq = [];
    this.searchParams.size_seq = [];
    this.searchParams.color_seq = [];

    this.menuSelected = idx;
    this.sub_category = item.category_name;
    this.choice_list = [{
      key: "category", 
      value: item.category_name
    }];

    // 검색엔진 SEO 타이틀 및 메타 설정
    // let title = '오토바이 ' + this.root_category.name + ' ' + this.sub_category;
    // this.seoService.updateTitle( title );
    // this.seoService.updateDescription( title );
    // this.seoService.updateSubject( title );
    
    this.setProductListPage();
  }
  
  /*******************************************************************************
    설  명 : 브랜드 선택하였을 때
  *******************************************************************************/
  select_brand(idx, item) {
    this.searchParams.pageNo = 1;

    if(this.searchParams.brand_seq.findIndex(element => element == item.brand_seq) != -1 || idx == -1) {
      this.searchParams.brand_seq.splice(this.searchParams.brand_seq.findIndex(element => element == item.brand_seq), 1);
      this.choice_list.splice(this.choice_list.findIndex(element => element.key == 'brand' && element.brand_name == item.brand_name), 1);
    } else {
      this.searchParams.brand_seq.push(item.brand_seq);
      this.choice_list.push({
        key: "brand", 
        value: item.brand_name, 
        brand_name: item.brand_name, 
        brand_seq: item.brand_seq
      });
    }

    this.setProductListPage();
  }

  /*******************************************************************************
    설  명 : 사이즈 선택하였을 때
  *******************************************************************************/
  select_size(idx, item) {
    this.searchParams.pageNo = 1;

    if(this.searchParams.size_seq.findIndex(element => element == item.seq) != -1 || idx == -1) {
      this.searchParams.size_seq.splice(this.searchParams.size_seq.findIndex(element => element == item.seq), 1); 
      this.choice_list.splice( this.choice_list.findIndex(element => element.key == 'size' && element.size_name == item.size_name), 1 );
    } else {
      this.searchParams.size_seq.push(item.seq);

      this.choice_list.push({
        key: "size", 
        value: item.size_name, 
        size_name: item.size_name, 
        seq: item.seq
      });
    }
    
    this.setProductListPage();
  }

  /*******************************************************************************
    설  명 : 색상 선택하였을 때
  *******************************************************************************/
  select_color(idx, item) {
    this.searchParams.pageNo = 1;

    if(this.searchParams.color_seq.findIndex(element => element == item.seq) != -1 || idx == -1) {
      this.searchParams.color_seq.splice(this.searchParams.color_seq.findIndex(element => element == item.seq), 1); 
      this.choice_list.splice( this.choice_list.findIndex(element => element.key == 'color' && element.color_name == item.color_name), 1 );
    } else {
      this.searchParams.color_seq.push(item.seq);
      this.choice_list.push({
        key: "color", 
        value: item.color_name, 
        color_ename: item.color_ename, 
        color_name: item.color_name, 
        color: item.color, 
        seq: item.seq
      });
    }

    this.setProductListPage();
  }

  /*******************************************************************************
    설  명 : 정렬기준 선택하였을 때
  *******************************************************************************/
  select_sort(index) {
    this.searchParams.sort = index;
    this.setProductListPage();
  }

  /*******************************************************************************
    설  명 : 사이즈, 색상 표시 여부
  *******************************************************************************/
  showColorSizeCheck() {
    if( 
      this.root_category.code.substr(0,6) == '001001' ||
      this.root_category.code.substr(0,6) == '001003' ||
      this.root_category.code.substr(0,6) == '001004' ||
      this.root_category.code.substr(0,6) == '001005' ||
      this.root_category.code.substr(0,6) == '001006' ||
      this.root_category.code.substr(0,6) == '001007'
    ) {
      return true;
    } else {
      return false;
    }
  }

  /*******************************************************************************
    설  명 : '선택한옵션' 클릭하였을 때 (삭제)
  *******************************************************************************/
  async delete_choice(item) {
    if(item == 'all') {
      await this.select_category(0, '');
      await this.select_brand(-1, '');
      await this.select_size(-1, '');
      await this.select_color(-1, '');
      await this.getProductList();
    }

    switch(item.key) {
      case 'category':
        await this.select_category(0, item);
        break;
      case 'brand':
        await this.select_brand(-1, item);
        break;
      case 'size':
        await this.select_size(-1, item);
        break;
      case 'color':
        await this.select_color(-1, item);
        break;
    }
  }

  /*******************************************************************************
    설  명 : 선택한 버튼의 active 클래스 활성화
  *******************************************************************************/
  active(type, idx, item) {
    switch(type) {
      case 'size':
        return this.searchParams.size_seq.findIndex(element => element == item.seq) != -1 ? true : false
      case 'color':
        return this.searchParams.color_seq.findIndex(element => element == item.seq) != -1 ? true : false
      case 'brand':
        return this.searchParams.brand_seq.findIndex(element => element == item.brand_seq) != -1 ? true : false
      case 'category':
        return this.searchParams.category_code == item.category_code ? true : false
    }
  }

  /*******************************************************************************
    설  명 : 대분류 카테고리별로 아이콘 변경
  *******************************************************************************/
  main_icon() {
    switch(this.root_category.code.slice(3,6)) {
      case '001':
      case '002':
        return '/assets/images/icon_helmet.png';
        break;
      case '003':
        return '/assets/images/icon_jacket.png';
        break;
      case '004':
        return '/assets/images/icon_boots.png';
        break;
      case '005':
        return '/assets/images/icon_pants.png';
        break;
      case '006':
        return '/assets/images/icon_globe.png';
        break;
      case '007':
        return '/assets/images/icon_suite.png';
        break;
      case '008':
        return '/assets/images/icon_hood.png';
        break;
      case '009':
        return '/assets/images/icon_leg_shield.png';
        break;
      default:
        return '/assets/images/icon_mcube.png';
        break;
    }
  }

  /*******************************************************************************
    설  명 : ngif 크기비교 int로 변환
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  comparePrice(a, b) {
    let c = parseInt(a);
    let d = parseInt(b);
    if ( c > d) {
      return true;
    } else {
      return false;
    }
  }
  
  /*******************************************************************************
    설  명 : 페이지 선택 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  loadPage( event: any ) {
    this.getProductList();
  }
  
  /*******************************************************************************
    설  명 : 검색조건 파라미터 담아서 페이지 호출
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setProductListPage() {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.navigate(
      ['/product/list'], 
      {
        relativeTo: this.activatedRoute,
        queryParams: {
          categoryCode : this.searchParams.category_code,
          searchText : this.searchParams.searchText,
          brandSeq : this.searchParams.brand_seq,
          sizeSeq : this.searchParams.size_seq,
          colorSeq : this.searchParams.color_seq,
          sort : this.searchParams.sort
        },
        queryParamsHandling: ''
      }
    );
  }
  
}