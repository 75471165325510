<div class="modal-header">
  <h4 class="modal-title fl">구매확정</h4>
  <button class="close" (click)="activeModal.dismiss()">&times;</button>
</div>

<div class="modal-body">
  <div class="txt1">더이상 반품/교환 할 일이 없을 때<br><span>구매확정</span>을 해주세요.</div>
  <div class="txt2">구매확정 후 마일리지가 적립됩니다.</div>

  <div class="btn-area">
   <button class="btn1" (click)="activeModal.dismiss()">취소</button>
   <button class="btn1 red" (click)="submit()">구매확정</button>
  </div>
</div>

