/*******************************************************************************
  설  명 : 마이페이지 헤더
  생성일 : 2021-06-23
  생성자 : 김종현
*******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { AuthService } from '@app/service/auth.service';

import { SOrderService } from '@app/service/order.service';
import { SMemberService } from '@app/service/member.service';

@Component({
  selector: 'app-mypage-header',
  templateUrl: './mypage-header.component.html',
  styleUrls: ['./mypage-header.component.scss']
})
export class MypageHeaderComponent implements OnInit {
  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  public memberInfo: any = {};
  public statusInfo: any = {};
  public zero = 0;
  public one = 0;
  public two = 0;
  public dep = 0;
  public eight = 0;
  public nine = 0;

  public remainedMileage = 0;
  public availableCoupon = 0;

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private authService: AuthService,
    private sOrderService: SOrderService,
    private memberService: SMemberService,
  ) { 

  }

  /*******************************************************************************
    설  명 : 데이터 로딩 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit(): void {
    this.authService.getLoginInfo.subscribe(data => {
      this.memberInfo = data;
    });

    this.getOrderStatus();
    this.getSumRemainedMileage();
    this.getSumAvailableCoupon();
  }

  /*******************************************************************************
  설  명 : 마이페이지 대시보드 현황판 수치
  입력값 : 없음
  리턴값 : 없음
  *******************************************************************************/
  getOrderStatus() {
    this.sOrderService.getOrderStatus().then( response => {
      if ( response.ResultCode ) {
        this.statusInfo = response.data;    
        this.zero = this.statusInfo[0].status_zero;
        this.one = this.statusInfo[0].status_one;
        this.two = this.statusInfo[0].status_two;
        this.eight = this.statusInfo[0].status_eight;
        this.nine = this.statusInfo[0].status_nine;
      }
    });
  }

  /*******************************************************************************
    설  명 : 마이페이지 대시보드 남은 마일리지 정보 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getSumRemainedMileage() {
    this.memberService.getSumRemainedMileage().then( response => {
      if ( response.ResultCode ) {
        this.remainedMileage = response.data;
      }
    });    

  }

  /*******************************************************************************
    설  명 : 마이페이지 대시보드 사용가능한 쿠폰 수량 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
    getSumAvailableCoupon() {
      this.memberService.getSumAvailableCoupon().then( response => {
        if ( response.ResultCode ) {
          this.availableCoupon = response.data;
        }
      });    
  
    }
  

}
