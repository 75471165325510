
<div [class]="isMobileApp === false ? 'detail' : 'detail mobile-detail'">
  <div class="block" *ngIf="site==='daejeon'">
    <h1>바이크마트 4년차 베테랑!
    <p style="text-align: right; font-size: 16px; color: #999;">바이크마트 대리점 창업 스토리 (2021.07.01)</p></h1>
    <h6>
        <img src="/assets/images/shop/daejeon/1.jpg">
        <p>(주)바이크마트 대전점 노규정 대표</p>
    </h6>
    <div class="questions">
        <h3>바이크마트 대전점 노규정 대표를 만나다.</h3>
        <article>국내 오토바이 용품 쇼핑몰인 바이크마트의 대전 대리점을 방문하였다. <br>바이크에 대한 관심과 애정이 남다른, 성공할 수 밖에 없었던 노규정 대표. </article>
    </div>
    <img src="/assets/images/shop/daejeon/2.jpg">
    <article>"취미로 바이크를 타다가 전국적으로, 특히나 지방에서 바이크용품을 살만한 곳이 많이 없다고 느끼던 참에 우연히 바이크마트를 알게되었습니다. 어떤 특정 브랜드 하나를 고집하기보다는 다양한 브랜드들을
판매하는 곳을 찾던 중 바이크마트를 알게되어 창업을 결심하게 되었습니다."</article>
    <h3>운영하신지 얼마나 되셨나요?</h3>
    <article>"횟수로 4년째에 접어듭니다. 이제는 바이크마트 대전점을 거의 다 알고계시고 전국국에서도 많은 분들이 찾아와주시고 계십니다."</article>
    <h6>
        <img src="/assets/images/shop/daejeon/3.jpg">
        <img src="/assets/images/shop/daejeon/4.jpg">
        <img src="/assets/images/shop/daejeon/5.jpg">
        <img src="/assets/images/shop/daejeon/6.jpg">
    </h6>
    <h3>온라인과 오프라인에서 매출 비중은?</h3>
    <article>"오프라인 운영에 많은 비중을 둬서 온라인 매출은 거의 미비합니다."</article>
    <h3>온라인 쇼핑시대에 오프라인이 잘되는 이유가 무엇이라 생각하시나요?</h3>
    <article>"바이크용품을 처음 접하신 입문자분들은 인터넷으로 용품을 구매하시기에 많은 어려움이 있습니다. 판매를 하다보니 같은 브랜드라도 디자인별로 사이즈가 다를 때도 많은데요, 브랜드별 사이즈 표기법도
다르고 이미 온라인에서 사이즈 실패를 많이 겪은 분들이 매장에 많이 방문해주고 계십니다. 아마 일반적인 의류를 판매했다면 벌써 문 닫았겠죠..^^"</article>
    <h3>바이크마트 대리점만의 장점은?</h3>
    <article>"일단 다양한 브랜드를 취급한다는 점, 그리고 직접 입어보고 착용해볼 수 있다는 점이 제일 큰 장점이라고 생각합니다. 입문자분들의 경우에는 특히나 온라인으로 상품을 구매하시는 것에 대해
두려움이 있기 때문에 고민을 많이 하시는데, 저희 대전점에서는 바이크 기종에 맞는 적당한 가격대의 용품들을 추천해드리고 있습니다."</article>
    <h3>대전점만의 특별한 홍보 전략?</h3>
    <article>"블로그로 열심히 홍보를 하고있습니다. 온라인 특성상 사이즈가 없는 용품들은 온라인에서도 꾸준히 판매가 되고 있으나, 사이즈가 중요한 의류/신발/헬멧의 경우는 멀리서도 직접 착용 후
구매하고 싶어하시는 분들이 많아 블로그로 다양한 제품을 소개해드리고 오프라인 매장에 오실 수 있도록 유도하고 있습니다."</article>
    <h3>바이크 동호히 활동도 하고 계시나요?</h3>
    <article>" '야타자' 라는 동호회를 운영하고 있습니다. 바이크 기종 상관없이 다양한 사람들을 만날 수 있는 친목도모의 장으로 운영중입니다."</article>
    <h6>
        <img src="/assets/images/shop/daejeon/7.jpg">
    </h6>
    <h3>바이크마트를 운영하면서 느낀 점?</h3>
    <article>"바이크마트를 운영하기 전에는 바이크 타는 분들이 이렇게 많은줄 몰랐는데요, 이제는 지나가는 바이크만 보일 정도로 푹 빠져 있답니다.
어떻게보면 가게의 '사장-손님' 이라는 단순한 관계이지만, 다른 업종과 달리 바이크를 탄다는 하나의 공통된 취미로 다양한 사람들과 관계를 맺게 되다보니 매력을 느끼며 재미있게 일하고 있습니다."</article>
    <div class="questions">
        <article>각 대리점주들은 시즌이 지난 상품의 창고 운영 또는 매장 DP 부분에 있어 어려움을 안고 있을 필요가 없다. 일정 기간을 두고 다가올 시즌에 맞추어 자유롭게 상품을 교환 할 수 있다. 수많은 양의 재고를 둘 필요도 없다. 물론 판매가 잘 이루어지는 단일 품목에 한해서는 어느 정도의 재고를 갖추고 운영하는 것을 권장하지만, 바이크마트 본사가 운영하는 대규모 창고에서 고객에게 직접 발송 및 매장으로 필요한 만큼의 수량을 주문시 발송하기 때문에, 대리점주 입장에서는 부담을 많이 더는 셈이다. 바이크마트 본사의 영업 정책의 기반은 협력하는 대리점주들이 매장을 운영하면서 실제로 어려움을 겪고 있는 부분에 대해 적절한 솔루션을 제시하며, 본사와 대리점 간의 상호 협력관계를 유지하는 것에 초점을 두고 있다.</article>
    </div>
    <h3>앞으로의 운영 계획과 예비 창업주분들께 간략한 조언을 한다면?</h3>
    <article>"앞으로는 비수기를 대비해 온라인쇼핑몰 운영에 조금 더 신경쓸 계획입니다.
어떤 사업이든 마찬가지지만 열정없이 되는 사업이 없다고 생각하는데요, 창업하시는 분께서는 바이크에 대한 관심이 없어 매출만 보고 뛰어드신다면 예상외의 어려움을 겪으실 수도 있을텐데 바이크에 대한 관심과 애정만 있다면
충분히 가능한 사업이라고 생각이 듭니다. 예비 창업주분들 화이팅!"</article>
    <img src="/assets/images/shop/daejeon/8.jpg">
  </div>

  <div class="block" *ngIf="site==='ulsan'">
      <h1>20대 여성 창업 성공 스토리
      <p style="text-align: right; font-size: 16px; color: #999;">바이크마트 대리점 창업 스토리 (2020.11.05)</p></h1>
      <iframe src="https://www.youtube.com/embed/3Alzc-9WbEE" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      <h6>
          <img src="/assets/images/shop/ulsan/4.jpg">
          <p>(주)바이크마트 울산점 이예영 대표</p>
      </h6>
      <div class="questions">
          <h3>바이크마트 울산점 이예영 대표를 만나다.</h3>
          <article>국내 오토바이 용품 쇼핑몰인 바이크마트의 울산 대리점을 방문하였다. <br>남편의 취미를 존중하고 이를 사업으로 확장하는 모습에서, 성품과 패기를 느낄 수 있었던 이예영 대표. </article>
      </div>
      <img src="/assets/images/shop/ulsan/y1.jpg">
      <article>"제가 처음에는 바이크에 대한 관심이 전혀 없었어요. 근데 이제 남편이랑 울산으로 이사를 오고 남편이 어느 순간부터 자꾸 바이크를, 바이크 매물을 찾아보다 보니까, '어.. 왜.. 바이크를 볼까?' 생각하면서도 혹시 타고 싶은 건 아닐까라는 생각도 했구요. 그러다 보니깐 나중에 몰래 타느니 제가 먼저 물어봤어요. 바이크를 타고 싶은지~ 그랬더니 남편이 자기는 처음에는 관심이 없다 하다가 한 번 더 권유를 했더니 본인이 그제서야 타고 싶다 하고 바이크를 구매를 해 오더라고요. 근데 또 바이크를 구매했는데 저랑 같이 타고 싶어가지고 텐덤용 할 수 있는 투어러용인데 또 처음 타는거다 보니깐 조금 낮은 CC 사왔거든요. 그걸 사오기는 했는데 바이크는 또 헬멧을 써야되고 또 이런 특성이 있더라고요. 그래서 둘이 같이 용품을 사러가자 하고 찾다보니깐 울산에는 바이크 용품점이 없고, 제일 가까운 곳은 부산이나 대구가 있는데, 저희가 일단 부산까지는 일단 가봤는데, 갔다오는 길에 자꾸 생각이 드는 게 울산에는 왜 용품점이 없을까 여기서는 바이크 소리도 정말 많이 나고, 바이크도 많이 보이기도 했으니깐 남편한테 우리 한번 바이크 용품점 한번 해볼까 라는 생각을 하다가, 남편이 바이크마트라는 업체를 알게되고, 이제 관계자분들도 만나게 되고 하다보니깐, 이렇게 오픈하게 되었습니다."</article>
      <h3>창업은 처음이신가요?</h3>
      <article>"네, 창업은 처음입니다. 근데 저희 집(친가)에서는 지금 사업을 하고 계시기 때문에, 옆으로 조금 많이 봤었어요. 오픈은 7월 4일날 했구요. 지금은 거의 4개월 정도 됐고, 첫달 매출은 6천 조금 덜 되요. 저희는 오프라인이 90% 정도구요, 온라인은 5~10% 정도 차지하고 있습니다. 저희 고객님들은 대부분 오시면 온라인에서 실패를 많이 해보신 분들이 많아요. 일단 사이즈적인 것도 있고, 헬멧도 그렇고, 전부 다 몸에 맞지 않으면 입을 수가 없는 제품들이기 때문에, 오프라인으로 오게 되는 것 같아요."</article>
      <h6>
          <img src="/assets/images/shop/detail_ulsan1.jpg">
          <img src="/assets/images/shop/detail_ulsan2.jpg">
          <img src="/assets/images/shop/detail_ulsan3.jpg">
          <img src="/assets/images/shop/detail_ulsan4.jpg">
      </h6>
      <h3>지금까지 운영하면서 어려운 점은?</h3>
      <article>"일단 저희 매장에 오픈한 지가 얼마 안됐기 때문에, 기본 구성은 갖추어져 있긴 하지만, 좀 더 다양한 제품들이.. 볼 수가 없어요. 어느 정도는 있겠지만, 고객님들이 원하는 게 워낙 많다보니깐 그 모든 물건을 채우기가 아직은 조금 어려움이 있거든요. 그래서 이 부분이 가장 어려운 것 같아요. 고객님 한 분 한 분 저희는 다 맞춰서 고객님들한테 잘 해드리고 싶은데, 고객님이 원하는 물건을 저희가 이제 구비가 안되어 있을 때, 그게 조금 많이 아쉬운 것 같아요."</article>
      <h3>바이크마트 울산점의 홍보 전략은?</h3>
      <article>"일단 저는 친절함과, 그리고 고객님들이 왔을 때 편안함을 느낄 수 있는, 그런 홍보 전략을 갖고 있구요, 저희 매장은 위치가 일단 시내에서 들어오다 보면 무룡산 라이딩 코스라고 있어요. 그 구간을 지나고 오면 또 바다가 앞에 보이기도 하고, 또 여기를 꼭 지나가야 하는 구간이기도 하거든요. 경주나 또 포항으로 넘어 가려면 이 구간을 지나가야 하기 때문에, 이것도 하나의 홍보라고 생각합니다."</article>
      <div class="questions">
          <article>이예영 대표는 남편의 취미에 영향을 받아 오토바이에 관심을 가지게 되었으며, 이러한 과정에서 겪고 느낀 점을 사업으로 전환하는 추진성이 돋보였다. 여기에 전략적으로 선정한 매장 위치, 친가의 사업 노하우, 고객을 위하는 마음이 어우러져, 울산점은 순조롭게 출발하고 있었다. 성공할 수 밖에 없었다고 보는 것이 맞을 것 같다.</article>
      </div>
      <h3>동호회 활동 계획은?</h3>
      <article>"지금은 바이크 동호회 활동을 하고 있지 않구요, 하고 있지는 않지만 앞으로 할 생각은 있습니다."</article>
      <h6>
          <img src="/assets/images/shop/ulsan/1.jpg">
      </h6>
      <h3>바이크마트를 운영하면서 느낀 점?</h3>
      <article>"우선 큰 비용을 들이지 않고 다양한 브랜드를 취급할 수 있는 것도 하나의 장점이구요, 또 시즌이 지난 상품에 대해서 자유롭게 재고를 처리할 수 있다는 점이 가장 큰 또 다른 장점인 것 같습니다."</article>
      <div class="questions">
          <article>각 대리점주들은 시즌이 지난 상품의 창고 운영 또는 매장 DP 부분에 있어 어려움을 안고 있을 필요가 없다. 일정 기간을 두고 다가올 시즌에 맞추어 자유롭게 상품을 교환 할 수 있다. 수많은 양의 재고를 둘 필요도 없다. 물론 판매가 잘 이루어지는 단일 품목에 한해서는 어느 정도의 재고를 갖추고 운영하는 것을 권장하지만, 바이크마트 본사가 운영하는 대규모 창고에서 고객에게 직접 발송 및 매장으로 필요한 만큼의 수량을 주문시 발송하기 때문에, 대리점주 입장에서는 부담을 많이 더는 셈이다. 바이크마트 본사의 영업 정책의 기반은 협력하는 대리점주들이 매장을 운영하면서 실제로 어려움을 겪고 있는 부분에 대해 적절한 솔루션을 제시하며, 본사와 대리점 간의 상호 협력관계를 유지하는 것에 초점을 두고 있다.</article>
      </div>
      <h3>향후 목표는?</h3>
      <article>"저희가 앞으로는 다음 시즌에는 더 많은 제품들로 보실 수 있게 준비하겠구요, 그리고 언제든지 저희 바이크마트 울산점에서 편안하게 들려서 음료도 마시고 소통도 할 수 있는 공간으로 더 자리매김을 하도록 하겠습니다. 모든 라이더 분들의 안전문화 정착을 위해 열심히 달려보겠습니다."</article>
      <img src="/assets/images/shop/ulsan/y99.jpg">
  </div>

  <div class="block" *ngIf="site==='pohang'">
    <h1>평범한 직장인 월 5,000만원 매출?
    <p style="text-align: right; font-size: 16px; color: #999;">바이크마트 대리점 창업 스토리 (2020.11.05)</p></h1>

    <div *ngIf="isMobileApp === false">
      <iframe src="https://www.youtube.com/embed/MFLF7nXLdQ8" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </div>

    <div *ngIf="isMobileApp === true">
      <a href="https://www.youtube.com/watch?v=ulcYMG-4O-k" target="_blank">
        <img src="/assets/images/company_youtube10.png" width="100%" alt="오토바이를 너무 사랑한 나머지 창업까지!! ｜바이크마트｜SBS생생경제 정보톡톡｜오토바이의 시작과 끝 (오토바이 창업 이야기)" />
      </a>
    </div>

    <div class="questions">
        <h3>바이크마트 포항점 박아영 대표를 만나다.</h3>
        <article>국내 오토바이 용품 쇼핑몰인 바이크마트의 포항 대리점을 방문하였다. <br>취미로 오토바이를 타면서 느꼈던 불편사항을 개선하기 위해 직접 창업을 결심했다는 박아영 대표.</article>
    </div>
    <article>"여성 라이더? 다소 생소하게 들릴 수 있으나, 남자들만 바이크를 타라는 법은 없잖아요! 바이크 라이딩을 너무 좋아하고 바이크 용품도 좋아해서 이와 관련된 사업을 찾아보니, '바이크마트'라는 회사가 있더라구요. 오픈 첫달 전 직장 월급의 약 20배가 넘는 매출을 기록할 수 있었던 비결은 바로 '바이크마트' 덕분입니다."</article>
    <h6>
        <img src="/assets/images/shop/pohang/1.jpg">
        <img src="/assets/images/shop/pohang/2.jpg">
        <p>(주)바이크마트 포항점 박아영 대표</p>
    </h6>
    <img src="/assets/images/shop/pohang/y1.jpg">
    <article>"바이크를 타는 취미를 가지고 있구요, 제가 사는 '포항'에 바이크를 타면서 필요한 제품(바이크용품)을 사기 위해서 매장을 알아보니까, 포항에는 용품점이 없더라구요. 그리고 라이더분들이 쉽게 쉬어갈 수 있는 휴식공간조차 없었고, 그런 공간이 하나 있었으면 좋겠다고 생각해서 그 부분을 제가 실행하게 되었습니다."</article>
    <div class="questions">
        <article>바이크마트 포항점은 북구 영일대 해수욕장을 지나 월포 해수욕장으로 가는 해안도로의 중간에 위치하고 있다. 이 부근은 동해안 투어링의 주요 코스인데, 그에 반해 마땅한 휴식공간이 없었다.
            <p>'이 분들에게 특별한 휴식 서비스를 제공할 수는 없을까?'</p>
            <p>'오토바이에 필요한 용품을 투어 도중 만나볼 수 있도록 할 수는 없을까?'</p>
            <p>'포항에는 오토바이 용품 종합브랜드 오프라인 매장이 있나?'</p>
            이 같은 고민들을 계기로 박아영 대표는 (주)바이크마트와 협약하여 오토바이 용품 오프라인 매장을 오픈하였고, '많은 오토바이 라이더 분들이 이곳에서 꼭 한 번은 쉬고싶다.'라는 생각이 들게끔 휴식 공간을 제공할 수 있어서 기쁘다며 만족하였다.</article>
    </div>
    <h3>창업은 처음이신가요?</h3>
    <article>"네, 온라인 쇼핑몰을 직장인으로 일을 병행하면서, 운영을 하고 있었는데, 오프라인으로 매장을 낸 것은 처음입니다. 오프라인 매장의 경우에는 3개월 차에 접어들었구요, 온라인 쇼핑몰까지 기간을 본다면 2년 정도 되었어요."</article>
    <h3>오픈 첫달 매출?</h3>
    <article>"오픈 첫달 매출은 제가 생각했던 것보다 엄청 잘 나왔어요. 첫달 매출은 한 5천 만원 정도 나왔습니다."</article>
    <h3>온라인과 오프라인의 매출 비중은?</h3>
    <article>"초반에는 제가 아무래도 온라인 사업을 먼저 했기 때문에, 온라인 매출이 주였다면, 오프라인 매장을 오픈하고 난 뒤부터는 실제로 상품(재고)를 보러오시는 분들이 많으셔서, 총 매출의 70~80% 정도의 비중은 오프라인이 차지하고 있어요."</article>
    <h6>
        <img src="/assets/images/shop/pohang/3.jpg">
        <img src="/assets/images/shop/pohang/4.jpg">
        <img src="/assets/images/shop/pohang/5.jpg">
        <img src="/assets/images/shop/pohang/6.jpg">
    </h6>
    <h3>요즘 본격적인 '온라인 쇼핑시대' 가운데, 오프라인이 잘 되는 이유가 뭐라고 생각하시나요?</h3>
    <article>"아무래도 젊으신 분들은 온라인으로 제품 구매를 많이 하시겠지만, 보통 고객들이 직접 사용하는 제품에 한해서는 사이즈 확인이나 다른 부분들까지 직접 눈으로 보고 구매를 하시는게 더 확실하다 생각하시는 것 같아요. 그래서 온라인도 온라인이지만 직접 매장에 오셔서 보고 구경도 하시고 그 비중이 더 크다 보니까 매장으로 더 많은 고객분들이 찾아주시는 것 같아요."</article>
    <div class="questions">
        <article>현재 코로나 바이러스로 인해 많은 오프라인 매장들이 경제적으로 큰 어려움을 겪고 있지만, 바이크 용품이라는 특성상 분명히, 직접 오셔서 상품을 눈으로 확인하고 직접 입어보기도 할 것이다. 다년간의 오프라인 매장을 운영한 바이크마트 본사의 노하우였다.</article>
    </div>
    <h3>매장을 운영하시다 보면 어려운 점들이 있을텐데..</h3>
    <article>"저는 딱히 매장을 운영하면서 힘들다고 생각한 적은 없었구요, 굳이 말씀을 드리자면 저는 바이크를 타는 취미가 있는데, 매장을 오픈하고 나서는 바이크를 탈 시간이 별로 없더라구요. 그래서 사실 조금 힘들었어요."</article>
    <h3>바이크마트를 운영하면서 느낀점?</h3>
    <article>"바이크 용품들의 다양한 브랜드를 한 곳에서 볼 수 있다는 점이 참 좋았구요, 매장을 운영하다보면 많은 재고를 안고 시작을 해야된다고 생각을 하시는데, 그런 부담감없이 운영을 할 수 있어서 좋았고, 지금처럼 홍보 부분에 관해서 본사에서 지원을 많이 해주셔서 참 감사하게 생각하고 있습니다."</article>
    <img src="/assets/images/shop/pohang/7.jpg">
    <div class="questions">
        <article>바이크마트는 대한민국 오토바이 라이더라면 적어도 한번은 들어봤을 법한 업체이다. 2001년부터 온라인 쇼핑몰을 오픈하며, 현재까지 무서운 속도로 성장 진행중이다. 대구시 '스타기업 100' 으로 지정되어 있고, 2019년에는 디자인 혁신 유망기업으로, 또한 2년 연속 대한민국 굿컴퍼니 브랜드 대상, 제품혁신 대상으로, 위상을 떨치고 있다. 다른 수식어 보다는 다양한 브랜드를 한 자리에서 만나볼 수 있는, 국내 1위의 종합 오토바이 용품 쇼핑몰임에는 틀림없다. 바이크마트 본사에서 운영하는 홈페이지(www.bikemart.co.kr)에는 약 100만명의 회원이 현재 가입되어 있으며, 메인 홈페이지 광고를 통해 특정이벤트 및 특정 상품에 대한 홍보가 효과적으로 이루어진다.</article>
    </div>
    <h3>앞으로의 전망과 향후 목표는?</h3>
    <article>"바이크를 타는 사람으로써 어드벤쳐를 타고 있거든요. 방문해주시는 고객분들 오토바이 기종을 보면 너무나 다양한 바이크들이 많기 때문에, 지금 어드벤쳐 외에 클래식 바이크, 산악바이크, 레플리카, 네이키드 등 요런 종류까지 바이크를 타고 싶은 욕심이 좀 생겼구요, 매장으로 보면은 아무래도 라이더 분들이 편히 쉴 수 있는 공간을 만드는 것이 제 주목적이기 때문에, 라이더 분들이 쉽게 찾아주시고 행복한 추억을 만들 수 있는 그런 공간으로 자리매김으로 했으면 좋겠습니다."</article>
    <h6>
        <img src="/assets/images/shop/pohang/8.jpg">
        <img src="/assets/images/shop/pohang/9.jpg">
    </h6>
    <div class="questions">
        <article>650cc 고배기량의 바이크 유저로 매장을 오프(OFF)하는 날에는 어김없이 바이크로 라이딩을 즐기시는 박 대표의 당찬 포부가 인터뷰를 통해 느껴진다. 포항지역 바이크용픔점을 대표하여 매장으로 방문해주신 고객들의 피드백을 한마디도 놓치지 않는다. 고객들이 어떠한 용품이 필요한지, 어떠한 상품의 실용성과 디자인에 대한 피드백을 구하는 갈급함으로 본사직원과의 커뮤니케이션이 하루에도 수십건에 다다른다. 또한 바이크마트가 자체적으로 생산하는 상품을 고객분들께 선보일때 어떠한 상품을 선택하고 집중적으로 판매해야 하는지 바이크마트 본사 직원들의 도움이 큰 역할을 하였다고 덧붙였다. <br>바이크마트 본사 또한 '가성비' 라는 타이틀을 앞세워 다양한 고객의 소리에 귀를 기울였다. 실제 오토바이 유저들의 NEEDS에 맞는 자체상품을 개발하며, 2020년 역대 최고의 매출을 기록중에 있으며, 국내시장 뿐만 아니라 해외 시장으로 성공적인 수출을 이뤄낼 수 있었다.</article>
    </div>
  </div>

  <div class="block" *ngIf="site==='gyeonggi_seobu'">
    <h1>첫 달 매출 5000만원의 비결은?
    <p style="text-align: right; font-size: 16px; color: #999;">바이크마트 대리점 창업 스토리 (2021.11.05)</p></h1>
    <div class="questions">
        <h3>바이크마트 경기서부점 박종우 대표를 만나다.</h3>
        <article>국내 오토바이 용품 쇼핑몰인 바이크마트의 경기서부점을 방문하였다.<br/>오토바이를 취미로 즐기는 입장에서 고객의 입장을 누구보다 잘 이해하는 박종우대표.</article>
    </div>
    <h6>
        <img src="/assets/images/shop/gyeonggi_seobu/1.jpg">
        <p>(주)바이크마트 경기서부점 박종우 대표</p>
    </h6>
    <img src="/assets/images/shop/gyeonggi_seobu/2.jpg">
    <!-- <h3>창업 동기가 궁금합니다.</h3> -->
    <article>
        "일단 저는 바이크를 조금 오래 탔고요<br/>
        취미생활로 타다 보니까 여기저기서 용품을 많이 구입을 하게 됐었는데<br/>
        일단 온라인에서 구입을 하게 되면 본인 신체 사이즈라던가 각각 브랜드마다의 차이가 있어서<br/>
        오프라인 매장에 와서 직접 구매하게 되는데  가까운 곳에 없기 때문에<br/>
        제가 집 주변 가까운 매장을 찾다가 스스로 창업을 하게 되었습니다."
    </article>
    <h3>이전에도 창업 경험이 있으신데 바이크마트는 어떤 점이 달랐나요?</h3>
    <article>
        "일단 제가 음식점 쪽에 치킨집을 운영을 했었고요<br/>
        다른 어떤 사업과의 차이점은 일단 신선 제품 위주로 하다 보니까<br/>
        재고 문제라던가 유통기한 문제 때문에<br/>
        재료가 빨리 소진되거나 아니면 재료를 지나치게 많이 받았을 경우에<br/>
        유통기한 때문에 폐기해야 하는 그런 부분들이 있는데<br/>
        바이크마트 같은 경우에는 어떤 상품에 대한 그런 기간(유통기한)이 없고<br/>
        재고에 대한 부담도 본사에서 새로 신상품이 나올 때나 아니면 재고상품, 계절상품들이<br/>
        변할 때마다 교환을 할 수 있기 때문에 그런 부분에 메리트를 느껴서 창업을 결심하게 되었습니다.<br/>
        바이크마트의 전폭 지원인 시즌 제품 교환이 된다는 큰 장점이 또 하나의 메리트라고 할 수 있죠."
    </article>
    <h3>바이크마트 오픈 첫 달 매출은?</h3>
    <article>
        "2021년 11월 5일 첫 오픈<br/>
        바이크 비수기 때 오픈을 한 것이 조금의 문제가 있긴 했지만<br/>
        첫 달 매출이 5000만 원 정도 나왔습니다."
    </article>
    <h3>비수기였지만 어느 정도 잘 나온 것 같네요?</h3>
    <article>
        "네. 어느 정도 괜찮게 나와서 다행이라고 생각합니다."
    </article>
    <h3>현재 매장의 온라인&오프라인 비중은?</h3>
    <article>
        "저희가 오프라인 매장 오픈을 하고 난 뒤에 12월 초부터 온라인 판매를 시작했는데요.<br/>
        아직 홍보가 덜 되어있고 인지도가 낮기 때문에 하루에 한두 건 정도<br/>
        택배로 발송을 하고 있는데 비중이라고 하기엔 아직 미비한 부분이 있고<br/>
        아직 좀 더 온라인에 대한 홍보라던가 좀 더 많은 상품들을 준비하고 업데이트를 할 예정이기 때문에<br/>
        온라인을 최대한 20% 정도까지 끌어올릴 생각입니다.<br/>
        현재는 5% 정도 미만일 것 같습니다."
    </article>
    <h3>바이크마트 본사에서도 오프라인 비율이 상당히 높습니다.<br/>
        오프라인 판매가 많이 이뤄지고 계시고 온라인도 앞으로 많이 노력하시겠다는 그런 말씀으로 들리네요 ^^</h3>
    <article>
        "네. 맞습니다."
    </article>
    <h6>
        <img src="/assets/images/shop/gyeonggi_seobu/3.jpg">
        <img src="/assets/images/shop/gyeonggi_seobu/4.jpg">
    </h6>
    <h3>특별히 오프라인 매장이 잘되는 이유는?</h3>
    <article>
        "온라인으로 판매되는 다양한 브랜드나 그런 사이트들이 있겠지만<br/>
        가장 중요한 것은 직접 손님들이 본인이 원하는 디자인이나 모양을 일단 직접 오셔서 다 보신 다음<br/>
        각자 맞는지 사이즈를 착용을 해보시고 입어본 다음에 본인에게 몸에 잘 맞는 핏이<br/>
        제대로 나오는지를 확인하신 뒤에 구입을 하시기 때문에 온라인보다는 오프라인 매장을 선호하시는 것 같습니다."
    </article>
    <h3>저도 하나 차리고싶습니다ㅎㅎ</h3>
    <h3>매장을 운영하면서 가장 힘들었던 부분이 있다면?</h3>
    <article>
        "오픈한지 두 달 정도 넘었는데 저희 나름대로는 다양하게 준비를 해놓는다고 했는데도 불구하고<br/>
        원체 찾으시는 물건이 다양하다 보니까 없는 물건을 찾으실 때나 구하기 힘든 어떤 사이즈라던가<br/>
        사이즈가 가끔 품절되는 경우가 있는데 그런 물건들을 다시 구매하실 수 있도록<br/>
        사이즈를 다시 공급을 해드리면 좋은데 품절된 경우에 그냥 돌아가시는 경우가 있어서<br/>
        그럴 때 조금 안타깝습니다."
    </article>
    <h3>본사에서도 더 신경쓰도록 하겠습니다.</h3>
    <h3>바이크마트 경기 서부점만의 전략은?</h3>
    <article>
        "모든 매장이 마찬가지겠지만 오신 손님한테 최대한 친절하게 안내해 드리려고 하고요<br/>
        제가 오토바이를 타면서 가입했던 동호회라던가 카페라던가 그런 곳의 아는 인맥들을 이용해서<br/>
        다른 분들을 좀 더 저희 매장으로 끌어들이려고 유도하고 있습니다. 홍보는 그렇게 하고 있고요.<br/>
        온라인 쪽에서는 페이스북이라던가 인스타그램 같은 경우를 활용을 해서 좀 더 홍보를 할 예정입니다."
    </article>
    <h3>오프라인 활동도 많이 하시는 것 같은데, 동호회 활동도 하고 계신가요?</h3>
    <article>
        "네. 전에는 오프로드 바이크도 탔었고요.<br/>
        현재는 bmw 바이크 카페에 가입이 되어있습니다.<br/>
        지역 라이더들 모임에도 가입되어 있기 때문에 이제 그런 분들의 도움을 가끔 받고 있습니다."
    </article>
    <h3>부럽습니다. 저도 동호회 활동도 많이 하고 많이 놀러 다녔으면 좋겠는데요.<br/>
        혼자 운영하시는 장점도 있지만 매장에 매여있어야 해서 예전만큼은 많이 못 타시겠어요.</h3>
    <article>
        "오히려 지금 오토바이를 좋아해서 시작했는데 지금 탈 수 있는 시간이 더 없기 때문에 좀 안타깝습니다"
    </article>
    <h3>많이 더 버셔야겠네요.</h3>
    <article>
        "ㅎㅎㅎㅎㅎㅎ"
    </article>
    <h6>
      <img src="/assets/images/shop/gyeonggi_seobu/5.jpg">
      <img src="/assets/images/shop/gyeonggi_seobu/6.jpg">
    </h6>
    <h3>바이크마트 운영 하면서 느낀점은?</h3>
    <article>
        "일단 팀장님을 비롯해서 다른 직원들이 저희 서부점을 많이 도와주고 계시고<br/>
        매출이 많이 오를 수 있도록 지원해 주시고 계신데 아직은 바이크 비수기이기 때문에 <br/>
        내년 봄에 좀 더 많은 매출을 올리기 위해서 지금 다양한 방법들을 연구하고 있거든요<br/>
        그리고 저희 서부점만의 장점은 39번 국도변에 위치하고 있고<br/>
        바이크들이 많이 다니는 길목에 위치하고 있기 때문에<br/>
        오시다 가시다 편하게 들릴 수 있는 그런 휴게시설도 많이 확충을 하려고 하고요.<br/>
        다양한 물건들을 많이 구비를 해서 한번 오신 손님이 그냥 빈손으로 돌아가지 않을 수 있도록<br/>
        좀 더 다양하게 물건을 더 늘릴 작정입니다."
    </article>
    <h3>예전에 처음 창업하러 오셨을 때도 바이크마트 지점들 중에<br/>
        최고 매출 찍어보고 싶다 말씀을 하셨는데 지금도 그러신가요?</h3>
    <article>
        "지금도 그 생각은 변함이 없고요. 다른 어떤 부족한 부분이 있겠지만<br/>
        좀 더 개선해서 노력해서 저희가 다른 지점들보다도 좀 더 우수하고<br/>
        모범적인 가맹점이 되고 싶고요.<br/>
        거기에 따라서 또 매출도 꼭 전국에서 일등 할 수 있는<br/>
        꼭! 일등할 수 있는 그런 경기 서부점이 될 수 있도록 하겠습니다."
    </article>
    <h3>향후 목표는 그렇게 하실 수 있을 것 같고 마지막으로 간단하게 포부나 파이팅으로 마무리 한번 해주세요.</h3>
    <article>
        "네. 많은 라이더들이 저희 바이크마트 경기서부점을 찾아주시고<br/>
        아직까지 물건이 많이 없는데도 불구하고 많이 응원해 주셔서 많은 힘이 되고 있고요.<br/>
        내년 봄이 되면 아마 본사에서도 깜짝 놀랄만한 매출이 나오지 않을까 기대하고 있습니다.<br/>
        그렇게 되기 위해서 좀 더 많이 친절하고 좀 더 많이 깨끗한 환경 만들려고 노력하고 있고요.<br/>
        일단 제 스스로 많이 다짐을 다져서 꼭 열심히 하고<br/>
        우수한 모범적인 경기 서부점이 될 수 있도록 최선을 다해서 노력하겠습니다.<br/>
        경기 서부점 파이팅!!!"
    </article>
    <h3>파이팅!!!</h3>
  </div>

  <div class="mb50 mt20 center">
    <a class="btn1 cp" (click)="closeModal()">창닫기</a>
  </div>
</div>
